import styled, { css } from 'styled-components';
import { FlexBox } from '../layout/FlexBox';
import { Card } from './Card';

export const StyledActionCard = styled(Card)(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    overflow: hidden;
  `
);

export const StyledCardFooter = styled(FlexBox).attrs({ pad: 'small', justify: 'space-between' })(
  ({ theme }) => css`
    background: ${theme.colors.neutral.xxxlight};

    > :not(:last-child) {
      padding-right: ${theme.spacing.small};
      border-right: 1px solid ${theme.colors.neutral.xlight};
      margin-right: ${theme.spacing.small};
    }
  `
);

export const CardActionButton = styled.button<{ disabled?: boolean }>(
  ({ theme, disabled }) => css`
    border-radius: ${theme.borderRadius.small};
    cursor: pointer;

    display: flex;
    place-content: center;
    padding: ${theme.spacing.xsmall} 0;

    width: 100%;
    background: none;
    border: none;
    outline: none;
    color: ${theme.colors.text.default};

    font-size: 1rem;
    font-family: ${theme.typography.family};

    :hover,
    :focus {
      background: ${theme.colors.neutral.xlight};
    }

    svg {
      pointer-events: none;
    }

    ${disabled &&
    css`
      cursor: not-allowed;
      color: ${theme.colors.neutral.light};
    `}
  `
);

export const StyledCardAction = styled.div(
  ({ theme }) => css`
    flex: 1;
    text-align: center;
  `
);
