import styled, { css } from 'styled-components';
import { Scrollable } from './Scrollable';

export const StyledHorizontalScrollBox = styled(Scrollable).attrs({ pad: 'small' })(
  ({ theme }) => css`
    display: grid;
    grid-gap: 0.5rem;
    grid-auto-flow: column;
    grid-auto-columns: min-content;

    overscroll-behavior: contain;
  `
);
