import styled, { css } from 'styled-components';
import { breakpoints } from '../../variables/breakpoints';

export const StyledOptionTabPanel = styled.div<{
  selected?: boolean;
  $height?: number;
}>(
  ({ selected, $height }) => css`
    display: ${!selected && $height != null && 'none'};

    ${$height == null &&
    css`
      position: absolute;
      visibility: hidden;
    `}

    ${$height != null &&
    css`
      @media screen and ${breakpoints.tablet} {
        // 75vh - header size - padding
        height: min(calc(75vh - 43px - 2rem), ${$height}px);
      }
    `}
  `
);
