import { css } from 'styled-components';

// IOS like blurry white background
export const frostedGlass = css`
  background-color: rgba(255, 255, 255, 0.9);

  @supports (backdrop-filter: blur(20px)) or (-webkit-backdrop-filter: blur(20px)) {
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: saturate(180%) blur(20px);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
  }
`;
