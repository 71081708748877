import * as React from 'react';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import urlRegex from 'url-regex';
import { Anchor } from '../Anchor';

export const HyperlinkParser = ({ children }: BaseProps & ChildrenProp) => {
  const _children: React.ReactNode[] = React.useMemo(() => {
    const result = [];
    // If the input type is only string, we will check for URLs
    if (typeof children === 'string') {
      // Match all URLs in string
      const matches = children.match(urlRegex({ strict: false }));

      // No URLs, render string directly
      if (!matches) {
        result.push(children);
        return result;
      }

      // Split said text with the same regular expression: generates an array with each space containing a URL removed
      const split = children.split(urlRegex({ strict: false }));
      // For each of said sections
      for (let i = 0; i < split.length; i++) {
        // Push the prefix
        result.push(split[i]);

        // Insert an anchor for the first match (which comes after the split string index)
        // The last split value is the end of the text, so there's no accompanying URL match.
        if (i !== matches.length) {
          const href = !/^(?:f|ht)tps?:\/\//.test(matches[i]) ? `//${matches[i]}` : matches[i];
          result.push(
            <Anchor href={href} target={'_blank'}>
              {matches[i]}
            </Anchor>
          );
        }
      }
    }
    // No string: return plain?
    else result.push(children);
    return result;
  }, [children]);

  return (
    <>
      {_children.map((node, i) => (
        <React.Fragment key={i}>{node}</React.Fragment>
      ))}
    </>
  );
};
