import * as React from 'react';
import { Severity, severityIconMap } from '@innobrix/definitions';
import { StyledIcon } from './Notification.styled';
import { Alert, AlertProps } from './Alert';

interface SeverityAlertProps extends Omit<AlertProps, 'icon'> {
  severity: Severity;
}

const SeverityAlert = React.forwardRef<HTMLDivElement, SeverityAlertProps>(({ severity, ...rest }, ref) => (
  <Alert {...rest} ref={ref} icon={<StyledIcon icon={severityIconMap[severity]} severity={severity} />} />
));

export { SeverityAlert, SeverityAlertProps };
