import * as React from 'react';
import { StyledTable } from './Table.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';

interface TableProps extends BaseProps, ChildrenProp {}

const Table = React.forwardRef<HTMLTableElement, TableProps>(({ ...rest }, ref) => {
  return <StyledTable {...rest} ref={ref} />;
});

export { Table, TableProps };
export { StyledTable };
