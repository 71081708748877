import { borderRadius, fontSize, klavika, weight } from '@innobrix/components';
import styled, { css } from 'styled-components';
import hexagons from '../../../../../../libs/shared/assets/src/lib/images/repeating-hexagons.svg';

export const StyledStatsCard = styled.div(
  ({ theme }) => css`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${klavika};
    font-size: ${fontSize.s20};
    line-height: 1;
    font-weight: ${weight.regular};

    background: url(${hexagons}) repeat white;
    border-radius: ${borderRadius.r4};
    box-shadow: ${theme.elevation.light};

    b {
      font-weight: ${weight.medium};
    }
  `
);
