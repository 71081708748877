import styled, { css } from 'styled-components';
import { StyledCloseButtonWrapper } from '../navigation/CloseButton.styled';
import { StyledHeading } from '../typo/Heading.styled';
import { StyledIcon } from '../Icon.styled';
import { useSeverityColorMap } from '../../interfaces/Severity';
import { FlexBox } from '../layout/FlexBox';
import { breakpoints } from '../../variables/breakpoints';
import { Severity } from '@innobrix/definitions';
import { spacing } from '../../variables/spacing';
import { Scrollable } from '../layout/Scrollable';

type HasFooter = { hasFooter: boolean };

export const StyledHeader = styled.header<HasFooter>(
  ({ theme, hasFooter }) => css`
    margin-bottom: ${spacing.s8};
    font-size: 1.25rem;

    ${!hasFooter
      ? css`
          padding: ${spacing.s32} ${spacing.s32} 0 ${spacing.s32};
        `
      : undefined};

    padding-right: ${spacing.s32};

    & ${StyledHeading} {
      margin-bottom: 0.25em;
      line-height: 1;
      font-size: 1em;
    }

    & ${StyledCloseButtonWrapper} {
      font-size: 1.5em;
      position: absolute;
      top: 1.75rem;
      right: 2rem;
    }
  `
);

export const StyledContent = styled.div<HasFooter>(
  ({ theme, hasFooter }) => css`
    flex: 1;
    min-width: 60ch;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    ${hasFooter
      ? css`
          margin: 0 -1.75rem;
          padding: 0 1.75rem;
        `
      : undefined}

    ${Scrollable} {
      padding-top: ${spacing.s16};
    }
  `
);

export const StyledInnerModal = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;

    outline: none;
    max-height: calc(100vh - 4rem);

    border-radius: ${theme.borderRadius.large} ${theme.borderRadius.large} 0 0;
    background-color: ${theme.colors.neutral.white};
    box-shadow: ${theme.elevation.large};
    overflow: hidden;

    position: fixed;
    z-index: 5001;
    bottom: 0;
    left: 0;
    right: 0;

    @media screen and ${breakpoints.tablet} {
      top: 50%;
      right: unset;
      bottom: unset;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: ${theme.borderRadius.medium};
    }
  `
);

export const StyledModalContainer = styled(FlexBox)<HasFooter>(
  ({ theme, hasFooter }) => css`
    overflow-y: auto;
    padding: ${hasFooter
      ? css`
          ${spacing.s32} ${spacing.s32} 0
        `
      : undefined};

    > :last-child {
      flex: 1;
    }
  `
);

export const StyledOverlay = styled.div<{ overlay?: boolean }>(
  ({ theme, overlay }) => css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5000;

    ${overlay &&
    css`
      background: rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(2px);
    `};
  `
);

export const ModalFormWrapper = styled.div(
  ({ theme }) => css`
    overflow: inherit;
    height: 100%;
    display: flex;
    flex-direction: column;
  `
);

export const StyledModalFooter = styled.footer(
  ({ theme }) => css`
    padding: ${spacing.s16} ${spacing.s32};
    background-color: ${theme.colors.neutral.xxxlight};

    display: flex;
    justify-content: flex-end;
  `
);

export const StyledIconWrapper = styled.aside<{ severity?: Severity }>(({ theme, severity = 'message' }) => {
  const severityColorMap = useSeverityColorMap();

  const bg = severityColorMap[severity].xxlight;
  const color = severity !== 'message' ? severityColorMap[severity].default : undefined;

  return css`
    display: flex;
    height: max-content;

    background: ${bg};
    color: ${color};
    padding: ${spacing.s16};
    margin-right: ${spacing.s16};
    border-radius: 100%;

    > ${StyledIcon} {
      font-size: 1.25em;
    }
  `;
});
