import * as React from 'react';
import { createContext, Ref, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ChildrenProp } from '../../interfaces/BaseProps';
import { noop } from 'lodash-es';
import { useScroll } from 'react-use';
import mergeRefs from 'react-merge-refs';

//region Context
interface CarouselContextProps {
  currentIndex: number;
  count: number;
  visibleItems: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
  setVisibleItems: React.Dispatch<React.SetStateAction<number>>;
  goToIndex: (index: number) => void;
  carouselRef?: Ref<HTMLDivElement>;
}

const CarouselContext = createContext<CarouselContextProps>({
  currentIndex: 0,
  count: 0,
  visibleItems: 1,
  setCount: noop,
  goToIndex: noop,
  setVisibleItems: noop,
  carouselRef: null,
});

const useCarousel = () => useContext(CarouselContext);
//endregion

const CarouselProvider = ({ children }: ChildrenProp) => {
  const [index, setIndex] = useState(0);
  const [count, setCount] = useState(0);
  const [visibleItems, setVisibleItems] = useState(1);

  const carouselRef = useRef<HTMLDivElement>(null);

  const scrollRef = useRef<HTMLDivElement>(null);

  const childWidth = useMemo(
    () =>
      visibleItems === 1
        ? scrollRef.current?.clientWidth ?? 0
        : (scrollRef.current?.clientWidth ?? 0) / (visibleItems + 1),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleItems, scrollRef.current?.clientWidth]
  );
  // Percentage width of the child items
  const childWidthPercent = useMemo(() => (visibleItems === 1 ? 1 : 1 / (visibleItems + 0.5)), [visibleItems]);
  // Offset based on "half-visible" child
  const childOffset = useMemo(() => childWidth * childWidthPercent, [childWidth, childWidthPercent]);

  const { x } = useScroll(scrollRef);
  // Calculate index based on scroll value
  const calculatedIndex = useMemo(() => {
    const scrollX = Math.round(x);
    const scrollAreaWidth = scrollRef.current?.clientWidth ?? 0;

    if (scrollX === 0) {
      // we're at the start
      return 0;
    } else if (scrollX >= (scrollRef.current?.scrollWidth ?? 0) - scrollAreaWidth) {
      // we're at the end
      return count - 1;
    } else {
      if (visibleItems === 1) return scrollX / childWidth;

      // Add half of an item as half of one should be visible on either side
      return Math.round((scrollX + childOffset) / childWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleItems, childWidth, count, childOffset, x, scrollRef.current?.clientWidth]);

  // Update index when scroll position or children width changes
  useEffect(() => {
    if (index !== calculatedIndex) setIndex(calculatedIndex);
  }, [calculatedIndex, index, setIndex]);

  // Tell carousel to go to a certain item.
  const goToIndex = useCallback(
    (index: number) => {
      const newIndex = Math.min(count - 1, Math.max(0, index));

      const offset =
        visibleItems === 1 ? childWidth * newIndex : childWidth * newIndex - childOffset * (newIndex - index);

      carouselRef.current?.scrollTo({
        left: offset,
      });
      setIndex(newIndex);
    },
    [count, visibleItems, childWidth, childOffset]
  );

  return (
    <CarouselContext.Provider
      value={{
        currentIndex: index,
        count,
        visibleItems,
        setCount,
        setVisibleItems,
        goToIndex,
        carouselRef: mergeRefs([carouselRef, scrollRef]),
      }}
    >
      {children}
    </CarouselContext.Provider>
  );
};

export { CarouselProvider, CarouselContext, CarouselContextProps, useCarousel };
