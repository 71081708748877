import styled, { css } from 'styled-components';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { spacing } from '../../variables/spacing';

export const StyledOptionTabListWrapper = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    margin-bottom: ${theme.spacing.small};
    padding: ${spacing.s8} ${spacing.s8} 0;
    // Don't show a scrollbar
    --scrollbar-width: 0;

    > :not(:last-child) {
      margin-right: ${theme.spacing.small};
    }
  `
);

export const LeftArrow = styled(FaChevronLeft)`
  cursor: pointer;
`;
export const RightArrow = styled(FaChevronRight)`
  cursor: pointer;
`;

export const StyledOptionTabList = styled.div(
  ({ theme }) => css`
    border: none;

    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
  `
);

export const StyledTabListItems = styled.div`
  width: fit-content;
  display: flex;
  flex-flow: row nowrap;
  white-space: nowrap;

  > * {
    scroll-snap-align: center;

    :not(:last-child) {
      margin-right: ${(p) => p.theme.spacing.medium};
    }
  }
`;
