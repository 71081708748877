import * as React from 'react';
import { StyledSliderWrapper } from './Slider.styled';
import RCSlider, { SliderProps as SProps } from 'rc-slider';
import { BaseInputProps } from '../../interfaces/InputProps';
import { ErrorMessage } from './ErrorMessage';

export interface SliderProps extends SProps, Omit<BaseInputProps, 'onChange' | 'onBlur' | 'value'> {}

export const Slider = React.forwardRef<RCSlider, SliderProps>(({ className, error, touched, marks, ...rest }, ref) => (
  <StyledSliderWrapper className={className} error={error} touched={touched} hasMarks={marks != null}>
    <RCSlider {...rest} marks={marks} ref={ref} />

    {touched && error && <ErrorMessage error={error} />}
  </StyledSliderWrapper>
));
