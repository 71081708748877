import styled from 'styled-components';
import { ListItem } from '../list/ListItem';
import { weight } from '../../variables/typography';

export const StyledUserLabel = styled(ListItem)`
  width: max-content;
  line-height: 1.3;

  span:first-of-type {
    font-weight: ${weight.semibold};
  }
`;
