import * as React from 'react';
import { NotificationDot, StyledHamburger, StyledNavbar, StyledNavbarMobile } from './NavbarMobile.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { Relative } from '../../utilities/mixins/components/Relative';

interface NavbarMobileProps extends BaseProps, ChildrenProp {
  isOpen: boolean;
  onChange?: (open: boolean) => void;
  unreadNotifications?: boolean;
}

const NavbarMobile = React.forwardRef<HTMLDivElement, NavbarMobileProps>(
  ({ unreadNotifications, isOpen, onChange, children, ...rest }, ref) => {
    return (
      <>
        <StyledNavbarMobile {...rest} ref={ref}>
          <Relative>
            <StyledHamburger onClick={() => onChange?.(!isOpen)} />
            {unreadNotifications && <NotificationDot />}
          </Relative>
        </StyledNavbarMobile>
        <StyledNavbar
          $isOpen={isOpen}
          onClick={(e) => {
            if (e.currentTarget.nodeName !== 'LI') return;
            // When clicking on an item close the menu.
            onChange?.(false);
          }}
        >
          {children}
        </StyledNavbar>
      </>
    );
  }
);

export { NavbarMobile, NavbarMobileProps };
