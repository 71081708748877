import { useAuth0 } from '@auth0/auth0-react';
import useSWR from 'swr';

/**
 * SWR Fetch function
 * @see https://swr.vercel.app/docs/getting-started
 */
export const fetcher = (...args: FetchArgs) => fetch(...args).then((res) => res.json());

type FetchArgs = Parameters<typeof fetch>;

/**
 * Use SWR with Auth0 Authentication
 * @param endpoint The endpoint to call with an accessToken
 */
export function useAuthenticatedSWR<T = unknown>(endpoint: string) {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  return useSWR<T>(isLoading || !isAuthenticated ? null : endpoint, async (url) => {
    const accessToken = await getAccessTokenSilently();
    return fetcher(url, { headers: { authorization: `Bearer ${accessToken}` } });
  });
}
