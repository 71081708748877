import styled, { css } from 'styled-components';
import * as React from 'react';
import { visuallyHidden } from '../../utilities/mixins/visuallyHidden';
import { ErrorMessage } from './ErrorMessage';
import { Label } from '../form/Label';
import { Stack } from '../layout/Stack';
import { checkStyle } from '../../utilities/mixins/checkStyle';

const Checkmark = ({ tri, ...props }: any) => (
  <svg viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1.5 5.5L5 9L11 1" />
    {tri && <path d="M0,6 L12,6" />}
  </svg>
);

export const StyledCheckboxWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const StyledCheckmark = styled(Checkmark)(
  ({ theme }) => css`
    ${checkStyle(theme)};
    border-radius: ${theme.borderRadius.small};

    transition: ${theme.transition.slow};
    transition-property: box-shadow, background, color;

    path {
      stroke: ${theme.colors.primary.default};
      stroke-width: 2px;
      stroke-dasharray: 19;

      opacity: 0.3;
      stroke-dashoffset: 19;

      transition: ${theme.transition.slow};
      transition-property: opacity, stroke-dashoffset;
    }
  `
);

export const StyledLabel = styled(Label)(
  ({ theme }) => css`
    display: inline-flex;
    align-items: center;
    flex-flow: row nowrap;

    transition: ${theme.transition.xSlow};
    transition-property: color;

    input:not(:disabled) + &:hover ${StyledCheckmark}, input:not(:disabled):not(:checked):focus + & ${StyledCheckmark} {
      box-shadow: 0 0 0 ${theme.spacing.xsmall} ${theme.colors.primary.xxlight};

      path {
        opacity: 0.3;
        stroke-dashoffset: 0;
      }
    }

    // active state
    input:checked + & {
      ${StyledCheckmark} {
        color: ${theme.colors.primary.default};
        background: currentColor;

        path {
          opacity: 1;
          stroke-dashoffset: 0;
          stroke: ${theme.colors.text.light};
        }
      }
    }

    input:disabled + &,
    input:disabled + & ${StyledCheckmark} {
      color: ${theme.colors.neutral.light};
      cursor: not-allowed;
    }

    input:disabled + & ${StyledCheckmark} {
      background: ${theme.colors.neutral.xxlight};
    }

    // focus, hover + checked
    input:not(:disabled):checked:focus + &,
    input:not(:disabled):checked:hover + & {
      ${StyledCheckmark} {
        box-shadow: 0 0 0 0.25em ${theme.colors.primary.xxlight};
      }
    }
  `
);

export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  ${visuallyHidden};
`;

export const StyledErrorMessage = styled(ErrorMessage)(
  ({ theme }) => css`
    padding: 0 0 0 2.25em;
  `
);

export const StyledBox = styled(Stack).attrs({ inline: true })`
  grid-column: 1 / span 2;
`;
