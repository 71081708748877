import * as React from 'react';
import { StyledChatsList } from './ChatsList.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';

interface ChatsListProps extends BaseProps, ChildrenProp {}

const ChatsList = React.forwardRef<HTMLUListElement, ChatsListProps>(({ ...rest }, ref) => {
  return <StyledChatsList {...rest} ref={ref} />;
});

export { ChatsList, ChatsListProps };
