import styled, { css } from 'styled-components';
import { IconButtonRadio } from '../IconButtonRadio';
import { math } from 'polished';
import * as React from 'react';
import { useMedia } from 'react-use';
import { spacing } from '../../variables/spacing';
import { IconButton } from '../IconButton';

export const StyledActionbar = styled(({ vertical, maxValue, value, ...rest }) => <div {...rest} />)<{
  value?: number | null;
  maxValue?: number;
  vertical?: boolean;
}>(({ theme, value, maxValue = 1, vertical }) => {
  const isPhone = useMedia('screen and (max-width: 375px)');

  const padding = '0.25em';
  // const padding = theme.spacing.xsmall;
  const size = `2.375em`;
  const itemSize = isPhone ? `2.5em` : `2.75em`;
  const translation = `calc(${Math.min(value ?? 0, maxValue)} * ${itemSize} + (${Math.min(value ?? 0, maxValue)} * ${
    theme.spacing.small
  }))`;

  const calculatedOffset = math(`${padding} + ((${itemSize} - ${size}) / 2)`);
  const offset = {
    x: vertical ? '50%' : calculatedOffset,
    y: vertical ? calculatedOffset : '50%',
  };

  return css`
    width: max-content;
    display: flex;
    overflow: hidden;

    ${vertical &&
    css`
      flex-direction: column;
      width: min-content;
    `};

    background: ${theme.colors.neutral.white};
    border-radius: 0.4rem;
    box-shadow: ${theme.elevation.light};

    padding: ${padding};
    position: relative;

    ::before {
      content: '';
      display: ${value == null ? 'none' : 'block'};

      width: ${size};
      height: ${size};

      position: absolute;
      top: ${offset.y};
      left: ${offset.x};
      z-index: 0;

      transition: transform ${theme.transition.slow};
      transform: ${vertical ? `translate(-50%, ${translation})` : `translate(${translation}, -50%)`};

      background: ${theme.colors.primary.default};
      border-radius: ${theme.borderRadius.medium};
    }

    > :not(:last-child) {
      ${vertical
        ? css`
            margin-bottom: ${theme.spacing.small};
          `
        : css`
            margin-right: ${theme.spacing.small};
          `}
    }
  `;
});

export const ActionbarIconButtonRadio = styled(IconButtonRadio)<{ active?: boolean }>(
  ({ theme, active }) => css`
    color: ${active && theme.colors.text.light};
    border-radius: ${theme.borderRadius.medium};
    transition: background-color ${theme.transition.default};

    :hover,
    :focus-within,
    :active {
      background: ${theme.colors.primary.xxxlight};
    }
  `
);

export const ActionbarItem = styled(IconButton)(
  ({ theme }) => css`
    && {
      position: relative;
      z-index: 1;

      color: currentColor;
      background: none !important;
      padding: ${spacing.s12};
      border-radius: inherit;

      transition: color ${theme.transition.slow};

      svg {
        transition: transform ${theme.transition.default};
      }

      @media screen and (min-width: 376px) {
        padding: 0.875em;
      }

      :disabled {
        color: ${theme.colors.neutral.light};
      }

      :hover:not(:disabled),
      :focus.focus-visible:not(:disabled) {
        color: currentColor;
      }

      :active svg {
        transform: scale(0.75);
      }
    }
  `
);
