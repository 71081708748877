import * as React from 'react';
import { forwardRef, useCallback, useMemo } from 'react';
import { StyledButton } from './Button.styled';
import { Icon } from './Icon';
import { BaseProps } from '../interfaces/BaseProps';
import { MouseEventProps } from '../interfaces/EventProps';
import { vibration } from '../variables/vibration';

type ButtonVariant = 'primary' | 'secondary' | 'error' | 'warning' | 'success';

interface ButtonProps extends BaseProps, MouseEventProps<HTMLButtonElement> {
  type?: 'button' | 'submit' | 'reset';
  form?: string;
  variant?: ButtonVariant;
  small?: boolean;
  large?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  children: string | React.ReactElement | React.ReactElement[];
}

/**
 * TypeGuard to check if the only child is an Icon
 * @param children - Children object
 */
const childIsIcon = (children: any) => {
  return children.type === Icon;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ children, onClick, ...rest }, ref) => {
  const singleChild = useMemo(() => {
    return React.Children.count(children) === 1 && childIsIcon(children);
  }, [children]);

  // Vibrate on click
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      // This can be overridden by doing this again in another onclick handler
      // Calling `navigator.vibrate` again will override a running one
      if ('vibrate' in navigator) navigator.vibrate(vibration.click);
      onClick?.(e);
    },
    [onClick]
  );

  return (
    <StyledButton {...rest} onClick={handleClick} singleChild={singleChild} ref={ref}>
      {children}
    </StyledButton>
  );
});

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
  small: false,
  large: false,
  fullWidth: false,
};

export { Button, ButtonProps, ButtonVariant };
