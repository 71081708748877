import * as React from 'react';
import {
  StyledCloseButton,
  Wrapper,
  StyledImageBox,
  StyledImage,
  StyledPlaceholder,
  Relative,
} from './ImageBox.styled';
import { BaseProps } from '../../interfaces/BaseProps';

type ImageBoxProps = BaseProps & {
  value?: string;
  id?: string;
  onClear?: React.MouseEventHandler;
  onClick?: React.MouseEventHandler;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  children: React.ReactNode;
};

const ImageBox = React.forwardRef(
  (
    { children, value, onClear, onClick, id, onMouseEnter, onMouseLeave, ...rest }: ImageBoxProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <Wrapper {...rest} ref={ref} hoverable={onClick != null}>
        <StyledImageBox id={id} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <Relative onClick={onClick}>
            {value && <StyledImage src={value} />}
            {children}
          </Relative>
        </StyledImageBox>

        {value && onClear != null && <StyledCloseButton onClick={onClear} />}
      </Wrapper>
    );
  }
);

export { ImageBox, ImageBoxProps, StyledPlaceholder as ImageBoxPlaceholder, Wrapper as ImageBoxWrapper };
