import styled, { css } from 'styled-components';
import { Slider } from './Slider';

const selector = '.rc-slider';
export const LODSlider = styled(Slider).attrs({ vertical: true, included: false })(
  ({ theme }) => css`
    height: 125px;

    ${selector}-vertical {
      ${selector}-mark {
        width: 16rem;

        &-text {
          width: 100%;
          text-align: left;
          margin-bottom: unset !important;
          transform: translateY(50%);

          font-size: 1rem;
          line-height: 2;
          padding: 0 ${theme.spacing.small};
          border-radius: ${theme.borderRadius.small};

          transition: ${theme.transition.slow};
          transition-property: color, background-color;

          &-active {
            background-color: ${theme.colors.primary.default};
            color: ${theme.colors.text.light};
          }
        }
      }
    }
  `
);
