import { Icon } from '../Icon';
import * as React from 'react';
import { animated } from '@react-spring/web';
import styled, { css } from 'styled-components';

const SvgCircle = (props: any) => (
  <animated.svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M99 50C99 77.062 77.062 99 50 99C22.938 99 1 77.062 1 50C1 22.938 22.938 1 50 1C77.062 1 99 22.938 99 50Z" />
    <path
      d="M99 50C99 77.062 77.062 99 50 99C22.938 99 1 77.062 1 50C1 22.938 22.938 1 50 1C77.062 1 99 22.938 99 50Z"
      strokeDasharray={308}
    />
  </animated.svg>
);

export const StyledProgressCircle = styled(SvgCircle)(
  ({ theme }) =>
    css`
      transform: rotate(-90deg);
      width: 100%;
      overflow: visible;

      path {
        stroke: ${theme.colors.primary.xxxlight};
        stroke-width: 3.5;

        :last-of-type {
          stroke: ${theme.colors.primary.default};
          stroke-width: 4;
        }
      }

      ~ span,
      ~ svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 3rem;
        line-height: 1;
        color: ${theme.colors.primary.default};
      }
    `
);

export const AnimatedIcon = animated(Icon);

export const StyledProgressCircleWrapper = styled.div`
  position: relative;
`;
