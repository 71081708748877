import * as React from 'react';
import { StyledListDetailsPage } from './ListDetailsPage.styled';
import { PageProps } from './Page';

type ListDetailsPageProps = PageProps;

const ListDetailsPage = React.forwardRef<HTMLDivElement, ListDetailsPageProps>(({ ...rest }, ref) => {
  return <StyledListDetailsPage {...rest} ref={ref} />;
});

export { ListDetailsPage, ListDetailsPageProps };
