import styled, { css } from 'styled-components';
import { Chat } from '../Chat';
import { spacing } from '../../../variables/spacing';
import { breakpoints } from '../../../variables/breakpoints';
import { DocumentsContainer } from '../Chat.styled';

export const StyledChatPanel = styled(Chat)(
  ({ theme }) => css`
    grid-template-columns: 1fr;
    gap: ${spacing.s12} 0;
    user-select: text;

    ${DocumentsContainer} {
      padding: ${spacing.s12};
    }

    @media screen and ${breakpoints.laptop} {
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr auto;
      grid-template-areas:
        'docs'
        'chat'
        'input';

      ${DocumentsContainer} {
        border-radius: 0;
        border-bottom: 2px solid ${theme.colors.neutral.xxlight};
        margin: -${spacing.s12} -${spacing.s12} 0;
      }
    }
  `
);
