import * as React from 'react';
import { components } from 'react-select';
import { StyledUserSelectItem } from './UserSelectItem.styled';
import { UserLabel } from '../../label/UserLabel';

type User = {
  label: string;
  email: string;
  picture: string;
};

const UserSelectOption = (props: React.ComponentProps<typeof components.Option>) => {
  const { picture, email, label } = props.data as User;

  return (
    <StyledUserSelectItem {...props}>
      <UserLabel name={label} email={email} picture={picture} />
    </StyledUserSelectItem>
  );
};

const UserSelectValue = (props: React.ComponentProps<typeof components.SingleValue>) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore this is a styled component so there's an as prop
  return <UserSelectOption {...props} as={components.SingleValue} />;
};

export { UserSelectOption, UserSelectValue };
