import styled, { css, keyframes } from 'styled-components';
import { animated } from '@react-spring/web';

const anim = keyframes`
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: var(--length);
  }
`;

export const StyledSplashScreen = styled(animated.div)(({ theme }) => {
  const length = 119;

  return css`
    background: ${theme.colors.primary.default};
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    place-content: center;
    z-index: 100000;
    opacity: 0;

    svg {
      width: 20vh;

      path {
        --length: ${length};
        fill: rgba(255, 255, 255, 0.2);
        stroke: rgba(255, 255, 255, 0.3);
        stroke-linecap: round;
        stroke-width: 0.33;

        stroke-dasharray: calc(var(--length) * 0.25);
        animation: ${anim} 3s linear infinite;

        @-moz-document url-prefix() {
          & {
            // firefox can't calc this, so doing it this way
            stroke-dasharray: ${length * 0.25};
          }
        }
      }
    }
  `;
});
