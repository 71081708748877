import { Vector2, Vector3 } from 'three';
import * as React from 'react';
import { ReactNode, useCallback } from 'react';
import { Label } from './Label';
import { StyledInputWrapper, StyledSpanX, StyledSpanY, StyledSpanZ, StyledVectorInput } from './VectorInput.styled';
import { FormattedMessage } from 'react-intl';
import { TextInputProps } from './TextInput';
import { NumberInput } from './number/NumberInput';

export interface VectorInputProps extends Omit<TextInputProps, 'value' | 'onChange'> {
  x: number;
  y: number;
  z?: number;
  onChange: (v: Vector2 | Vector3) => void;
  hideLabels?: boolean;
  hideXInput?: boolean;
  hideYInput?: boolean;
  hideZInput?: boolean;
  customXLabel?: ReactNode | string;
  customYLabel?: ReactNode | string;
  customZLabel?: ReactNode | string;
}

const ValueInput = React.memo(
  ({
    name,
    id,
    label,
    value,
    onChange,
  }: {
    name: string;
    id: string;
    label?: ReactNode;
    value: number;
    onChange: (v?: number) => void;
  }) => (
    <StyledInputWrapper>
      {label != null && <Label htmlFor={id}>{label}</Label>}
      <NumberInput id={id} name={name} value={value} scale={3} onChange={onChange} />
    </StyledInputWrapper>
  )
);

export const VectorInput = React.memo(
  ({
    id,
    x,
    y,
    z,
    onChange,
    hideLabels,
    hideXInput,
    hideYInput,
    hideZInput,
    customXLabel,
    customYLabel,
    customZLabel,
    name,
  }: VectorInputProps) => {
    const xId = `${id}x`;
    const yId = `${id}y`;
    const zId = `${id}z`;

    const onXChange = useCallback((v?: number) => onChange(new Vector3(v, y, z)), [onChange, y, z]);
    const onYChange = useCallback((v?: number) => onChange(new Vector3(x, v, z)), [onChange, x, z]);
    const onZChange = useCallback((v?: number) => onChange(new Vector3(x, y, v)), [onChange, x, y]);

    const xLabel =
      !hideLabels && customXLabel ? (
        customXLabel
      ) : (
        <StyledSpanX>
          <FormattedMessage id={'general.x'} defaultMessage={'X'} />
        </StyledSpanX>
      );
    const yLabel =
      !hideLabels && customYLabel ? (
        customYLabel
      ) : (
        <StyledSpanY>
          <FormattedMessage id={'general.y'} defaultMessage={'Y'} />
        </StyledSpanY>
      );
    const zLabel =
      !hideLabels && customZLabel ? (
        customZLabel
      ) : (
        <StyledSpanZ>
          <FormattedMessage id={'general.z'} defaultMessage={'Z'} />
        </StyledSpanZ>
      );

    return (
      <StyledVectorInput>
        {!hideXInput && <ValueInput name={`${name}-x`} label={xLabel} id={xId} value={x} onChange={onXChange} />}
        {!hideYInput && <ValueInput name={`${name}-y`} label={yLabel} id={yId} value={y} onChange={onYChange} />}
        {z != null && !hideZInput && (
          <ValueInput name={`${name}-z`} label={zLabel} id={zId} value={z} onChange={onZChange} />
        )}
      </StyledVectorInput>
    );
  }
);
