import * as React from 'react';
import { IconType } from 'react-icons';
import { BaseProps } from '../../interfaces/BaseProps';
import { Icon } from '../Icon';
import { StyledNavigationItem } from './NavigationItem.styled';

type Props = React.PropsWithChildren<BaseProps> & {
  icon: IconType;
};

const NavigationItem = React.forwardRef<HTMLDivElement, Props>(({ children, icon, ...rest }, ref) => {
  return (
    <StyledNavigationItem {...rest} ref={ref}>
      <Icon icon={icon} />
      <span>{children}</span>
    </StyledNavigationItem>
  );
});

export { NavigationItem };
