import { parseToRgb, rgbToColorString } from 'polished';
import { Color } from 'three';

/**
 * Convert a rgba string to threejs hex number.
 * @param rgbaString "rgba(r,g,b,a)"
 */
export function convertRgbaStringToHexNumber(rgbaString: string): number {
  const hexValue = rgbToColorString(parseToRgb(rgbaString));
  return new Color(hexValue).getHex();
}

/**
 * Convert threejs hex number to css color string
 * @param hexNumber
 */
export function convertHexNumberToHexString(hexNumber: number): string {
  return '#' + new Color(hexNumber).getHexString();
}
