export {
  Header as EditProjectModalHeader,
  ModalContent as EditProjectModalContent,
  ModalFooter as EditProjectModalFooter,
  ModalForm as EditProjectModalForm,
  ModalHeading as EditProjectModalHeading,
  StyledCloseButton as EditProjectModalCloseButton,
  StyledEditProjectModal,
} from './EditProjectModal.styled';
export { StyledOverlay as EditProjectModalOverlay } from './Modal.styled';
