import styled, { css } from 'styled-components';
import { borderRadius } from '../../../variables/borderRadius';

export const StyledDocumentUploading = styled.div(
  ({ theme }) => css`
    display: grid;
    gap: 0.5rem;
    grid-template-columns: auto 1fr;
    align-items: center;

    padding: 0.5rem 0.75rem;

    background-color: ${theme.colors.primary.dark};
    color: ${theme.colors.text.light};
    border-radius: ${borderRadius.r8};
  `
);
