import styled, { css } from 'styled-components';
import { StyledHeading } from '../typo/Heading.styled';
import { breakpoints } from '../../variables/breakpoints';
import { weight } from '../../variables/typography';

export const StyledBreadcrumb = styled.div(
  ({ theme }) => css`
    grid-column: 1/-1;

    display: inline-block;
    font-family: ${theme.typography.headingFamily};

    a,
    span {
      color: currentColor;
      margin: 0 0.25em;
    }

    > span:last-child {
      font-weight: ${weight.medium};
    }

    > :first-child {
      margin-left: 0;
    }

    > svg {
      font-size: 0.75em;
      vertical-align: middle;
    }

    > ${StyledHeading}, > h1 {
      font-size: 1.75rem;
      margin-bottom: 1rem;
      line-height: 1;

      ::first-letter {
        text-transform: uppercase;
      }

      @media screen and ${breakpoints.laptop} {
        font-size: 2rem;
      }
    }
  `
);
