import { SelectProps } from './Select';
import styled, { css, useTheme } from 'styled-components';
import { ThemedProps } from '../../interfaces/ThemedProps';
import Select, { components } from 'react-select';
import * as React from 'react';
import { inputBox, inputBoxFocus } from '../../utilities/mixins/inputStyle';
import { weight } from '../../variables/typography';
import { borderRadius } from '../../variables/borderRadius';
import { spacing } from '../../variables/spacing';

export const styledSelectMixin = ({
  theme,
  error,
  touched,
  isDisabled,
  classNamePrefix: prefix,
}: ThemedProps<SelectProps>) =>
  css`
    ${isDisabled &&
    css`
      & {
        pointer-events: auto !important;
      }
    `};

    .${prefix} {
      &__control {
        ${inputBox({ theme, error, touched, disabled: isDisabled })};

        border: none;
        height: 100%;
        min-height: 2.5rem;
        align-items: stretch;

        cursor: pointer;

        transition-property: box-shadow, border, border-radius;

        ::before {
          content: ${error};
        }

        :hover,
        &--is-focused,
        &--menu-is-open {
          ${inputBoxFocus({ theme, disabled: isDisabled })};
        }

        :active {
          box-shadow: ${theme.elevation.xlight}, ${theme.elevation.light};
        }

        &--menu-is-open {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        &--is-disabled {
          color: ${theme.colors.neutral.light};
          cursor: not-allowed;

          :hover {
            box-shadow: ${theme.elevation.xlight};
          }
        }
      }

      &__value-container {
        padding: ${theme.spacing.small} ${theme.spacing.medium};

        flex-wrap: nowrap;

        .${prefix}__single-value {
          color: currentColor;
        }

        .${prefix}__multi-value {
          border-radius: ${borderRadius.r2};
          color: currentColor;
          min-width: auto;

          &__label {
            color: currentColor;
            padding: 0;
            padding-left: ${spacing.s8};
          }

          &__remove {
            :hover {
              background: ${theme.colors.error.xxlight};
              color: ${theme.colors.error.default};
            }
          }
        }

        div[class$='-Input'] {
          padding: 0;
          margin-top: 0;
          margin-bottom: 0;
        }

        input[class$='-dummyInput'] {
          line-height: inherit;
        }

        input[type='text' i] {
          font: inherit;
          color: currentColor;
          line-height: 1;
        }

        > :last-child {
          line-height: 1;
          margin-top: 0;
          margin-bottom: 0;
          padding-top: 0;
          padding-bottom: 0;
        }
      }

      &__placeholder {
      }

      &__indicator {
        padding: ${theme.spacing.small};
      }

      &__dropdown-indicator {
        color: currentColor;
      }

      &__indicator-separator {
        display: none;
      }
    }
  `;

export const StyledSelect = styled(Select)<SelectProps>(
  ({ isGroupSticky, error, touched, isDisabled, isSearchable, classNamePrefix }) => {
    return css`
      height: 100%;

      ${styledSelectMixin({
        theme: useTheme(),
        isGroupSticky,
        error,
        touched,
        isDisabled,
        isSearchable,
        classNamePrefix,
      })};
    `;
  }
);

export const StyledSelectMenu = styled(components.Menu)(() => {
  const theme = useTheme();
  return css`
    &.rs {
      &__menu {
        border-top: 1px solid ${theme.colors.neutral.light};
        background: ${theme.colors.neutral.xxlight};
        box-shadow: ${theme.elevation.xlight}, ${theme.elevation.medium};
        border-radius: 0 0 ${borderRadius.r4} ${borderRadius.r4};
        margin: 0;

        &-list {
          padding: ${theme.spacing.small} 0;
        }
      }

      &__group {
        position: relative;
        padding-top: 0;
        padding-bottom: 0;

        &-heading {
          font-size: inherit;
          font-weight: ${weight.regular};
          padding: ${theme.spacing.small} ${theme.spacing.medium} 0;
          background: ${theme.colors.neutral.xxlight};
        }
      }

      &__option {
        line-height: 1;
        padding: ${theme.spacing.small} ${theme.spacing.medium};
        cursor: pointer;

        transition: ${theme.transition.default};
        transition-property: background-color, color;

        &--is-focused {
          background-color: ${theme.colors.primary.xxxlight};
          outline: none;
        }

        :active {
          background-color: ${theme.colors.primary.xlight};
        }

        &--is-selected {
          background-color: ${theme.colors.primary.default};
        }
      }
    }
  `;
});

export const StyledGroup = styled.div(
  ({ theme }) => css`
    span:first-child {
      font-family: ${theme.typography.headingFamily};
      font-weight: ${theme.typography.headings.h1.weight};
      line-height: ${theme.typography.headings.h1.height};
      text-transform: none;
      color: ${theme.colors.neutral.xxdark};
    }

    span:last-child {
      padding: 0 ${theme.spacing.small};
      border-radius: ${theme.borderRadius.large};

      background: ${theme.colors.primary.default};
      color: ${theme.colors.neutral.white};
      margin-left: ${theme.spacing.small};
      font-size: 75%;
    }
  `
);
