import * as React from 'react';
import { Content, StyledAlert, StyledCloseButton } from './Alert.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { IconProps } from '../Icon';

interface AlertProps extends BaseProps, ChildrenProp {
  onClose?: React.MouseEventHandler;
  icon?: React.ReactElement<IconProps>;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(({ children, onClose, icon, ...rest }, ref) => {
  return (
    <StyledAlert {...rest} ref={ref}>
      {icon}
      <Content>{children}</Content>
      {onClose && <StyledCloseButton onClick={onClose} />}
    </StyledAlert>
  );
});

export { Alert, AlertProps };
