// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

import { EnvironmentType } from './environment.type';

/**
 * Test environment variables
 */
export const environment: EnvironmentType = {
  production: false,

  // Urls
  apiUrl: 'https://api-test.innobrix.net/v1',
  assetsUrl: 'https://api-test.innobrix.net',
  websocketUrl: 'https://ibxsocket-test.innobrix.net',
  viewerUrl: 'https://view-test.innobrix.net',
  ibxToUrl: 'https://test.ibx.to',
  v4Origin: '*',
  chatSocketUrl: 'https://chat-test.innobrix.net',

  // Keys
  googleAnalyticsViewer: undefined,
  googleAnalyticsStudio: undefined,
  sentryIoViewer: undefined,
  sentryIoStudio: undefined,

  // Auth0
  auth0: {
    clientId: 'yhXdgyLCPrHACCX2PCIPiEp6fi2eBCs8',
    audience: 'https://api.innobrix.nl',
    domain: 'auth.innobrix.nl',
    scope: 'openid profile email',
    userCompanyIdClaim: 'companyId',
    userRoleClaim: 'roles',
  },

  // AFAS
  afas: {
    integrationClientId: 'CijqQJWWl3PzUv1e0sjx2cQamZBtDavA',
  },

  // Other variables
  defaultAssetPoolIds: [],
  allowedDocumentFileExtensions: ['.pdf', '.docx', '.xlsx', '.mp4', '.png', '.jpeg', '.jpg'],
};
