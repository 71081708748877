import styled from 'styled-components';
import { DashboardLayout } from './DashboardLayout.styled';

export const DevLayout = styled(DashboardLayout)`
  grid-template-areas:
    'token    token     card1     card2     ibx'
    'token    token     card3     card4     ibx'
    'card5    card6     card7     card8     ibx'
    'bigcard1 bigcard1  bigcard2  bigcard2  active'
    'bigcard1 bigcard1  bigcard2  bigcard2  active';
`;
