import * as React from 'react';
import { useEffect, useState } from 'react';
import { IconContainer, SpinnerContent, StyledSpinnerButton } from './SpinnerButton.styled';
import { FaCheck, FaSpinner, FaTimes } from 'react-icons/fa';
import { ButtonProps } from './Button';
import { Icon } from './Icon';
import { SpinnerIcon } from './SpinnerIcon';
import { usePrevious } from 'react-use';

type SpinnerButtonState = 'progress' | 'success' | 'error';
interface SpinnerButtonProps extends ButtonProps {
  state?: SpinnerButtonState;
}

const SpinnerButton = React.forwardRef<HTMLButtonElement, SpinnerButtonProps>(
  ({ state, disabled, children, ...rest }, ref) => {
    const [completed, setCompleted] = useState(false);
    const prevState = usePrevious(state);

    useEffect(() => {
      if (state !== 'progress' && prevState === 'progress') {
        // If the button is no longer progressing, set completed state.
        setCompleted(true);

        // Wait a second, then revert back to default state.
        setTimeout(() => {
          setCompleted(false);
        }, 1500);
      }
    }, [prevState, state]);

    const variant =
      state === 'progress'
        ? 'secondary'
        : completed && state === 'success'
        ? 'success'
        : completed && state === 'error'
        ? 'error'
        : 'primary';

    const hide = state !== 'progress' && !completed;

    return (
      <StyledSpinnerButton {...rest} ref={ref} variant={variant} disabled={state === 'progress' || disabled}>
        <SpinnerContent hide={!hide}>{children}</SpinnerContent>
        <IconContainer hide={hide}>
          {completed && state === 'success' ? (
            <Icon icon={FaCheck} />
          ) : completed && state === 'error' ? (
            <Icon icon={FaTimes} />
          ) : state === 'progress' ? (
            <SpinnerIcon icon={FaSpinner} />
          ) : null}
        </IconContainer>
      </StyledSpinnerButton>
    );
  }
);

export { SpinnerButton, SpinnerButtonProps, SpinnerButtonState };
