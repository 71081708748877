import styled, { css, keyframes } from 'styled-components';
import { Icon } from './Icon';

const spinAnim = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const StyledSpinnerIcon = styled(Icon)(
  ({ theme }) => css`
    animation: ${spinAnim} 1s linear infinite;
  `
);
