import * as React from 'react';
import { SyntheticEvent } from 'react';
import { StyledSearch } from './Search.styled';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { Icon } from '../Icon';
import { BaseProps } from '../../interfaces/BaseProps';

export interface SearchProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>, BaseProps {
  value?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onClear?: (e: SyntheticEvent) => void;
}
const Search = React.forwardRef<HTMLInputElement, SearchProps>(
  ({ name = 'search', value, placeholder, onChange, onClear, ...rest }, ref) => (
    <StyledSearch
      {...rest}
      name={name}
      value={value}
      placeholder={placeholder}
      type={'search'}
      iconBefore={<Icon icon={FaSearch} />}
      iconAfter={<Icon icon={FaTimes} />}
      iconAfterWrapperEvent={onClear}
      ref={ref}
      onChange={onChange}
      onKeyUp={(e: React.KeyboardEvent) => {
        if (onClear && e.key === 'Escape') {
          e.preventDefault();
          e.stopPropagation();
          onClear(e);
        }
      }}
    />
  )
);

Search.defaultProps = {
  value: '',
};

export { Search };
