import styled, { css } from 'styled-components';
import { IconProps } from './Icon';

export const StyledIcon = styled.svg<Omit<IconProps, 'icon'>>(
  ({ theme, subtle, onClick }) => css`
    ${subtle &&
    css`
      color: ${theme.colors.neutral.medium};
    `};

    ${onClick &&
    css`
      cursor: pointer;
    `};
  `
);
