import styled, { css } from 'styled-components';
import { textEllipsis } from '../../utilities/mixins/textOverflow';
import { weight } from '../../variables/typography';

export const StyledOptionName = styled.span(
  ({ theme }) => css`
    display: inline-flex;
    column-gap: ${theme.spacing.xsmall};
    font-size: 1rem;
    font-family: ${theme.typography.family};
    font-weight: ${weight.light};

    flex: 1;
    overflow: hidden;

    > span {
      flex: 1;
      ${textEllipsis};
    }

    > svg {
      color: ${theme.colors.primary.default};
      flex: 0 0 auto;
      font-size: 1.25em;
    }
  `
);
