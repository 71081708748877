import { css, DefaultTheme } from 'styled-components';
import { hideOutline } from './hideOutline';

export type ButtonColors = { background: string; color: string; border: string };
export const buttonColorMap = (theme: DefaultTheme) => ({
  primary: {
    background: theme.colors.primary.default,
    color: theme.colors.text.light,
    border: theme.colors.primary.xdark,
  },
  secondary: {
    background: theme.colors.neutral.xxlight,
    color: theme.colors.text.default,
    border: theme.colors.neutral.light,
  },
  error: {
    background: theme.colors.error.dark,
    color: theme.colors.error.xxlight,
    border: theme.colors.error.xxdark,
  },
  warning: {
    background: theme.colors.warning.default,
    color: theme.colors.warning.xxdark,
    border: theme.colors.warning.xdark,
  },
  success: {
    background: theme.colors.success.dark,
    color: theme.colors.success.xxlight,
    border: theme.colors.success.xxdark,
  },
});

export const resetButton = () => css`
  ${hideOutline};

  margin: 0;
  border: none;
  padding: 0;
  background: none;

  color: currentColor;
  line-height: 1;
  font-size: 1rem;
  font-family: ${(p) => p.theme.typography.family};
`;

// Default Innobrix button style (font, shadows, transitions)
export const buttonStyle = (theme: DefaultTheme, colors: ButtonColors) => css`
  ${hideOutline};

  margin: 0;
  border: none;
  border-radius: ${theme.borderRadius.small};

  font-size: 1rem;
  font-family: ${theme.typography.family};

  transition: ${theme.transition.slow};
  transition-property: box-shadow, background-color, color, border, transform;

  cursor: pointer;
  user-select: none;

  box-shadow: 0 2px 0 0 ${colors.border};

  :hover:not(:disabled),
  :focus.focus-visible:not(:disabled) {
    transform: translateY(-1px);
    box-shadow: 0 3px 0 0 ${colors.border};
  }

  :active:not(:disabled) {
    transform: translateY(2px);
    box-shadow: 0 0 0 0 ${colors.border};
  }

  :disabled {
    background: ${theme.colors.neutral.xlight};
    color: ${theme.colors.neutral.medium};
    box-shadow: 0 2px 0 0 ${theme.colors.neutral.light};
    cursor: not-allowed;
  }
`;
