import * as React from 'react';
import { CheckboxProps } from './Checkbox';
import { Stack } from '../layout/Stack';
import { StyledErrorMessage, StyledCheckGroup } from './CheckGroup.styled';
import { FlexBox } from '../layout/FlexBox';
import { BaseProps } from '../../interfaces/BaseProps';

export interface CheckGroupProps
  extends BaseProps,
    Pick<CheckboxProps, 'name' | 'error' | 'touched' | 'disabled' | 'id'> {
  children: (props: { name: string }) => React.ReactElement<CheckboxProps>[];
  inline?: boolean;
}

export const CheckGroup = React.forwardRef<HTMLFieldSetElement, CheckGroupProps>(
  ({ children, name, error, touched = false, inline, ...rest }, ref) => {
    return (
      <StyledCheckGroup {...rest} ref={ref}>
        {inline ? (
          <FlexBox gutter={'small'} wrapping>
            {children({ name })}
          </FlexBox>
        ) : (
          <Stack>{children({ name })}</Stack>
        )}

        {error && touched && <StyledErrorMessage error={error} />}
      </StyledCheckGroup>
    );
  }
);
