import styled, { css } from 'styled-components';
import { HeadingProps } from './Heading';

const StyledHeading = styled.h1<HeadingProps>(({ theme: { typography }, level }) => {
  const headingStyle = typography.headings['h' + level];
  return css`
    font-family: ${typography.headingFamily};
    font-size: ${headingStyle.size};
    font-weight: ${headingStyle.weight};
    line-height: ${headingStyle.height};
  `;
});

export { StyledHeading };
