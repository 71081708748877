import styled, { css } from 'styled-components';
import { inputBox, inputBoxFocus, inputStyle } from '../../utilities/mixins/inputStyle';
import { ThemedProps } from '../../interfaces/ThemedProps';

export const StyledTextArea = styled.textarea<ThemedProps<any>>(
  ({ theme, disabled, error, touched }) => css`
    ${inputStyle(theme)};

    ${inputBox({ theme, disabled, error, touched })};

    padding: ${theme.spacing.medium};

    resize: vertical;
    line-height: 1.5;
    // one line
    min-height: 1.5em;
    box-sizing: content-box;

    :focus-within:not(:disabled),
    :hover:not(:disabled) {
      ${inputBoxFocus({ theme, disabled: false })};
    }

    :disabled {
      color: ${theme.colors.neutral.light};
      resize: none;
    }
  `
);
