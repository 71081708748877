import * as React from 'react';
import { useCallback } from 'react';
import { ColorPickerPortal, StyledColor } from './Color.styled';
import { BaseProps } from '../../interfaces/BaseProps';
import { BaseInputProps } from '../../interfaces/InputProps';
import { ColorPicker, ColorPickerProps } from './ColorPicker';
import { ErrorMessage } from './ErrorMessage';

interface ColorProps extends BaseProps, Omit<BaseInputProps, 'onChange'>, Pick<ColorPickerProps, 'hideAlpha'> {
  value: string;
  disabled?: boolean;
  onChange?: (color: string) => void;
  onFinishChange?: (color: string) => void;
  placeholder?: string;
}

const Color = React.forwardRef<HTMLDivElement, ColorProps>(
  (
    { tabIndex = 0, placeholder, value, error, touched, hideAlpha, onChange, onFinishChange, disabled, ...rest },
    ref
  ) => {
    const onValueChange = useCallback(
      (color) => {
        if (typeof color !== 'string') return;
        onChange?.(color);
      },
      [onChange]
    );

    const onConfirm = useCallback(
      (color) => {
        onFinishChange?.(color);
      },
      [onFinishChange]
    );

    return (
      <div>
        <ColorPickerPortal
          overlay={<ColorPicker color={value} hideAlpha={hideAlpha} onChange={onValueChange} onConfirm={onConfirm} />}
          placement={'rightBottom'}
          trigger={['click']}
        >
          <StyledColor
            {...rest}
            value={value}
            placeholder={placeholder}
            ref={ref}
            tabIndex={tabIndex}
            disabled={disabled}
            error={error}
            touched={touched}
          />
        </ColorPickerPortal>

        {error && touched && <ErrorMessage error={error} />}
      </div>
    );
  }
);

export { Color, ColorProps };
