import styled from 'styled-components';
import { klavika } from '../../utilities/mixins/typography';
import { fontSize, weight } from '../../variables/typography';
import { SpinnerLabel } from './SpinnerLabel';

export const StyledStatLabel = styled(SpinnerLabel)`
  ${klavika};
  line-height: 1;
  font-size: ${fontSize.s48};
  font-weight: ${weight.bold};

  background: linear-gradient(90deg, #0a70ab, #489fd1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ::selection {
    -webkit-text-fill-color: ${(p) => p.theme.colors.text.light};
  }
`;
