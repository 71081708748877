import styled, { css } from 'styled-components';
import { Heading } from '../typo/Heading';
import { textEllipsis } from '../../utilities/mixins/textOverflow';

export const StyledIcon = styled.svg(
  ({ theme }) => css`
    flex: 0 0 auto;
    display: block;
    font-size: 1.5em;
    width: 1em;
    height: 1em;
  `
);

export const StyledLabel = styled(Heading).attrs({ level: 1, span: true })(
  ({ theme }) => css`
    flex: 0 1 auto;
    margin-left: ${theme.spacing.medium};
    font-size: 1.5em;
    line-height: 1;
    ${textEllipsis};
  `
);

export const StyledNavbarItem = styled.li(
  ({ theme }) => css`
    padding: ${theme.spacing.small};
    border-radius: ${theme.borderRadius.medium};
    background: transparent;
    color: currentColor;
    box-shadow: none;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    user-select: none;
    -webkit-user-select: none;

    transition: ${theme.transition.default};
    transition-property: box-shadow, background, color;

    .active & {
      background: ${theme.colors.primary.default};
      color: ${theme.colors.neutral.white};
      box-shadow: ${theme.elevation.medium};
    }

    :hover {
      box-shadow: ${theme.elevation.medium};
      cursor: pointer;
    }

    :active {
      box-shadow: ${theme.elevation.light};
    }
  `
);
