import styled, { css } from 'styled-components';
import { Heading } from '../typo/Heading';
import { spacing } from '../../variables/spacing';
import { animated } from '@react-spring/web';
import { Page } from '../page/Page';

export const AnimatedPage = animated(Page);

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: ${spacing.s32};
  align-items: stretch;
`;

export const SkeletonNavbar = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  width: 4rem;
`;

export const SkeletonTitle = styled(Heading)(
  ({ theme }) => css`
    width: 25vw;
  `
);

export const GridLastItem = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    // Last column
    grid-column: -2 / -1;
  `
);
