import styled, { css } from 'styled-components';
import { visuallyHidden } from '../../utilities/mixins/visuallyHidden';
import { inputBox } from '../../utilities/mixins/inputStyle';

export const StyledFileInputLabel = styled.label(
  ({ theme }) => css`
    position: relative;
    display: flex;
    align-items: flex-start;
    cursor: pointer;

    :disabled {
      cursor: not-allowed;
    }

    > :not(:last-child) {
      margin-right: 0.25rem;
    }

    > :last-child {
      flex: 0 0 auto;
    }
  `
);

export const StyledFileInput = styled.input(
  ({ theme }) => css`
    ${visuallyHidden};
  `
);

export const StyledFileName = styled.div<{ empty: boolean; error?: string; touched?: boolean }>(
  ({ theme, empty, error, touched }) => css`
    flex: 1 1 auto;

    ${inputBox({ theme, error, touched })};
    padding: 0.5rem 1rem;

    color: ${empty ? theme.colors.neutral.medium : undefined};
  `
);
