import * as React from 'react';
import { ReactNode } from 'react';
import { SkeletonUl, StyledTree } from './Tree.styled';
import { BaseProps } from '../../interfaces/BaseProps';
import Skeleton from 'react-loading-skeleton';

export interface TreeProps extends BaseProps {
  nodes?: ReactNode | ReactNode[];
}

export const Tree = React.memo(
  React.forwardRef((props: TreeProps, ref: React.Ref<HTMLUListElement>) => {
    const { nodes, ...rest } = props;

    return nodes == null ? (
      <SkeletonUl>
        <li>
          <Skeleton />
        </li>
        <li>
          <Skeleton />
          <SkeletonUl>
            <li>
              <Skeleton />
              <SkeletonUl>
                <li>
                  <Skeleton />
                </li>
              </SkeletonUl>
            </li>
          </SkeletonUl>
        </li>
        <li>
          <Skeleton />
        </li>
      </SkeletonUl>
    ) : (
      <StyledTree root={true} {...rest} ref={ref}>
        {nodes}
      </StyledTree>
    );
  })
);
