import styled, { css } from 'styled-components';
import { CloseButton } from '../navigation/CloseButton';
import { lineClamp } from '../../utilities/mixins/textOverflow';
import { triangle } from 'polished';
import { breakpoints } from '../../variables/breakpoints';
import { weight } from '../../variables/typography';
import { spacing } from '../../variables/spacing';

export const Wrapper = styled.div<{ open: boolean }>(
  ({ theme, open }) => css`
    display: flex;
    flex-flow: column;

    opacity: 0;
    visibility: hidden;
    will-change: opacity, visibility;

    transition: 0.3s ease-out;
    transition-property: opacity, visibility;

    ${open
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : undefined};
  `
);

export const StyledPopover = styled.div(
  ({ theme }) =>
    css`
      box-shadow: ${theme.elevation.medium};
      border-radius: ${theme.borderRadius.medium};
      background: ${theme.colors.neutral.white};
      width: 30ch;
      position: relative;
      overflow: hidden;

      @media screen and ${breakpoints.tablet} {
        width: 40ch;
      }
    `
);

export const PopoverArrow = styled.div(
  ({ theme }) => css`
    ${triangle({
      pointingDirection: 'bottom',
      width: '1.5rem',
      height: '.75rem',
      foregroundColor: theme.colors.secondary.light,
    })};
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.25));
  `
);

export const PopoverHeader = styled.header(
  ({ theme }) => css`
    padding: ${spacing.s8} 0 ${spacing.s8} ${spacing.s16};
    background: ${theme.colors.neutral.xxxlight};
    border-bottom: 2px solid ${theme.colors.neutral.xxlight};
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    font-weight: ${weight.bold};
  `
);

export const PopoverCloseButton = styled(CloseButton)(
  ({ theme }) => css`
    position: absolute;
    top: ${spacing.s12};
    right: ${spacing.s12};
    font-size: 1.5em;
  `
);

export const PopoverContent = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-gap: ${theme.spacing.medium};
    grid-auto-flow: row dense;
    padding: ${theme.spacing.medium};
    text-align: center;

    > :first-child {
      padding: 0 ${spacing.s48};
    }
  `
);

export const PopoverStats = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  svg {
    margin-right: ${(p) => p.theme.spacing.medium};
    margin-left: ${(p) => p.theme.spacing.medium};
  }
`;

export const PopoverDescription = styled.div`
  ${lineClamp(3)};
  font-size: 0.9em;
`;

export const PopoverPrice = styled.span`
  font-size: 1.5em;
`;

export const PopoverFooter = styled.footer(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    background: linear-gradient(91deg, ${theme.colors.secondary.default} 0%, ${theme.colors.secondary.xlight} 100%);
    padding: 1.5em ${(p) => p.theme.spacing.large} ${(p) => p.theme.spacing.medium};
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
    border-radius: inherit;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  `
);
