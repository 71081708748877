import styled from 'styled-components';
import { Container } from './TextInput.styled';
import { FlexBox } from '../layout/FlexBox';
import { Slider } from './Slider';
import { NumberInput } from './number/NumberInput';

export const StyledNumberSliderInput = styled(FlexBox)`
  column-gap: ${(p) => p.theme.spacing.small};

  > div {
    flex: 0;
  }

  > ${Container} {
    flex: 0 0 auto;
  }
`;

export const StyledNumberInput = styled(NumberInput)`
  input {
    width: 4ch;
  }
`;

export const StyledSlider = styled(Slider)`
  && {
    flex: 1 1 auto;
  }
`;
