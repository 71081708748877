import * as React from 'react';
import { StyledChatDivider, StyledChatDividerLabel } from './ChatDivider.styled';
import { BaseProps } from '../../interfaces/BaseProps';
import { FormattedDate, FormattedRelativeTime } from 'react-intl';
import { differenceInDays, isThisYear } from 'date-fns';

interface ChatDividerProps extends BaseProps {
  date?: Date;
}

const ChatDivider = React.forwardRef<HTMLDivElement, ChatDividerProps>(({ date = new Date(), ...rest }, ref) => {
  const today = new Date().setHours(0, 0, 0, 0);
  const target = new Date(date).setHours(0, 0, 0, 0);
  const relativeDays = differenceInDays(target, today);
  const dateIsThisYear = isThisYear(date);

  return (
    <StyledChatDivider {...rest} ref={ref}>
      <StyledChatDividerLabel>
        {relativeDays < -1 ? (
          <FormattedDate value={target} day={'numeric'} month={'long'} year={dateIsThisYear ? undefined : 'numeric'} />
        ) : (
          <FormattedRelativeTime value={relativeDays} unit={'day'} numeric="auto" />
        )}
      </StyledChatDividerLabel>
    </StyledChatDivider>
  );
});

export { ChatDivider, ChatDividerProps };
