import * as React from 'react';
import { Divider, StyledSubtotalDivider } from './SubtotalDivider.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { FaPlus } from 'react-icons/fa';

interface SubtotalDividerProps extends BaseProps, ChildrenProp {}

const SubtotalDivider = React.forwardRef<HTMLDivElement, SubtotalDividerProps>(({ ...rest }, ref) => {
  return (
    <StyledSubtotalDivider {...rest} ref={ref}>
      <Divider />
      <FaPlus />
    </StyledSubtotalDivider>
  );
});

export { SubtotalDivider, SubtotalDividerProps };
