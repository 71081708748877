import { StyledColumn } from './Column.styled';
import * as React from 'react';
import { BoxProps } from './Box';

export interface ColumnProps extends BoxProps {
  start: string | number;
  end?: string | number;
}

const Column = React.forwardRef<HTMLDivElement, ColumnProps>(({ children, ...rest }, ref) => (
  <StyledColumn {...rest} ref={ref}>
    {children}
  </StyledColumn>
));

export { Column };
