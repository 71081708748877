import { useEffectOnce } from 'react-use';
import { useCallback, useEffect } from 'react';

/**
 * Sets a css viewheight variable on window resize
 */
export function useViewHeight() {
  const handleResize = useCallback(() => {
    document.documentElement.style.setProperty('--viewheight', `${window.innerHeight}px`);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  // Also call it once on first render
  useEffectOnce(handleResize);
}
