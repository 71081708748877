import styled, { css } from 'styled-components';
import { ColumnProps } from './Column';
import { Box } from './Box';

export const StyledColumn = styled(Box)<ColumnProps>(
  ({ start, end }) => css`
    & {
      ${end != null
        ? css`
            grid-column: ${start} / ${end};
          `
        : css`
            grid-column-start: ${start};
          `};
    }
  `
);
