import * as React from 'react';
import { StyledPillStack } from './PillStack.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';

type PillStackProps = BaseProps & ChildrenProp;

const PillStack = React.forwardRef<HTMLDivElement, PillStackProps>(({ ...rest }, ref) => {
  return <StyledPillStack {...rest} ref={ref} />;
});

export { PillStack, PillStackProps };
