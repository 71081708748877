import { BaseProps, FlexBox, Label, Stack, Toggle, useChartColors } from '@innobrix/components';
import {
  CategoryScale,
  Chart as ChartJS,
  ChartArea,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { rgba } from 'polished';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { useUpdate } from 'react-use';
import { MilestoneGauge } from './MilestoneGauge';
import { MonthStatLabel } from './MonthStatLabel';
import { ChartWrapper, Header, Left, Right, UserGrowthCardStyled } from './UserGrowthCard.styled';

// Register chatjs components
ChartJS.register(LineElement, CategoryScale, TimeScale, LinearScale, PointElement, Tooltip, Filler);

function useChartGradients(ctx?: CanvasRenderingContext2D, size?: ChartArea) {
  const forceUpdate = useUpdate();
  const chartColors = useChartColors();

  useEffect(() => {
    // Force rerender until ctx is set (chart is mounted)
    if (ctx == null) forceUpdate();
  }, [ctx, forceUpdate]);

  if (ctx == null) return;

  return chartColors.map((color) => {
    const gradient = ctx.createLinearGradient(0, size?.top ?? 0, 0, size?.height ?? 100);
    gradient.addColorStop(0, rgba(color, 0.8));
    gradient.addColorStop(1, rgba(color, 0));

    return gradient;
  });
}

type Dataset = {
  label: string;
  data: Record<string, number>;
  milestone: number;
};

type Props = BaseProps & {
  datasets: Dataset[];
  total: number;
};

export const UserGrowthCard = ({ datasets, total, ...rest }: Props) => {
  const chartRef = useRef<ChartJSOrUndefined<'line', number[], string>>();

  const chartColors = useChartColors();
  const gradients = useChartGradients(chartRef.current?.ctx, chartRef.current?.chartArea);

  const [activeDatasetIndex, setActiveDatasetIndex] = useState(0);
  const activeDataset = datasets[activeDatasetIndex];
  const labels = Object.keys(activeDataset.data);
  const values = Object.values(activeDataset.data);

  const thisMonth = values[values.length - 1];
  const lastMonth = values[values.length - 2];

  return (
    <UserGrowthCardStyled {...rest}>
      <Left>
        <Stack gutter={'small'}>
          <Header>User Growth - {activeDataset.label}</Header>
          <FlexBox gutter={'medium'}>
            {activeDatasetIndex === 0 ? (
              <>
                <MonthStatLabel stat={thisMonth} compareTo={lastMonth}>
                  Today
                </MonthStatLabel>
                <MonthStatLabel stat={lastMonth}>Yesterday</MonthStatLabel>
              </>
            ) : (
              <>
                <MonthStatLabel stat={thisMonth} compareTo={lastMonth}>
                  This month
                </MonthStatLabel>
                <MonthStatLabel stat={lastMonth}>Last month</MonthStatLabel>
                <MonthStatLabel stat={total}>Total</MonthStatLabel>
              </>
            )}
          </FlexBox>
        </Stack>

        <ChartWrapper>
          <Line
            ref={chartRef}
            width={'100%'}
            data={{
              labels: labels,
              datasets: [
                {
                  label: activeDataset.label,
                  data: values,
                  borderColor: chartColors[0],
                  tension: 0.5,
                  backgroundColor: gradients?.[0],
                  fill: 'start',
                },
              ],
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              elements: {
                point: {
                  radius: 0,
                  hitRadius: 20,
                  hoverBackgroundColor: chartColors[0],
                },
              },
              scales: {
                x: {
                  type: 'time',
                  time: {
                    tooltipFormat: activeDatasetIndex === 0 ? 'eee d MMM yyyy' : 'MMM yyyy',
                    unit: activeDatasetIndex === 0 ? 'day' : 'month',
                    displayFormats: {
                      day: 'd',
                      month: 'MMM',
                    },
                  },
                  grid: {
                    display: false,
                    drawBorder: false,
                  },
                  ticks: {
                    source: 'labels',
                    font: {
                      family: 'Klavika',
                      weight: 'medium',
                    },
                  },
                },
                y: {
                  display: false,
                  // stacked: true,
                },
              },
              plugins: {
                filler: {
                  propagate: true,
                },
              },
            }}
          />
        </ChartWrapper>
      </Left>

      <Right>
        <FlexBox gutter={'small'}>
          <Label onClick={() => setActiveDatasetIndex(0)}>
            {activeDatasetIndex === 0 ? <b>Activity</b> : 'Activity'}
          </Label>
          <Toggle
            id={'active-dataset-toggle'}
            name={'active-dataset'}
            value={'activity'}
            checked={activeDatasetIndex === 1}
            onChange={(e) => setActiveDatasetIndex(e.target.checked ? 1 : 0)}
          />
          <Label onClick={() => setActiveDatasetIndex(1)}>
            {activeDatasetIndex === 1 ? <b>Signups</b> : 'Signups'}
          </Label>
        </FlexBox>

        <MilestoneGauge stat={thisMonth} milestone={activeDataset.milestone}>
          {activeDatasetIndex === 0 ? 'Active users today' : 'Users signed up this month'}
        </MilestoneGauge>
      </Right>
    </UserGrowthCardStyled>
  );
};
