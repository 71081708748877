import { Auth0Provider as Provider } from '@auth0/auth0-react';
import { environment } from '../../../../../environments/environment';
import { history } from '../router';
import * as React from 'react';
import { AppState } from '@auth0/auth0-react/dist/auth0-provider';
import { useCallback } from 'react';

export const Auth0Provider = ({ children }: React.PropsWithChildren<unknown>) => {
  const handleRedirectCallback = useCallback((appState?: AppState) => {
    // TODO don't let the callback end up in the browser history
    history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  }, []);

  return (
    <Provider
      domain={environment.auth0.domain}
      clientId={environment.auth0.clientId ?? ''}
      audience={environment.auth0.audience}
      scope={environment.auth0.scope}
      redirectUri={window.location.origin}
      onRedirectCallback={handleRedirectCallback}
      children={children}
    />
  );
};
