import * as React from 'react';
import { IconType } from 'react-icons';
import { Icon, IconProps } from '../Icon';
import { StyledContextMenuLabel, StyledLabelIcon } from './ContextMenuLabel.styled';
import { BaseProps } from '../../interfaces/BaseProps';

export interface ContextMenuLabelProps extends BaseProps {
  label: string;
  icon?: IconType;
  right?: React.ReactText | React.ReactElement<IconProps>;
  onClick?: React.MouseEventHandler;
}
export const ContextMenuLabel = React.forwardRef((props: ContextMenuLabelProps, ref: React.Ref<HTMLDivElement>) => {
  const { icon, label, right, ...rest } = props;
  return (
    <StyledContextMenuLabel wrapping={false} align={'center'} justify={'space-between'} {...rest} ref={ref}>
      <StyledLabelIcon>
        {icon && <Icon icon={icon} />}
        {label}
      </StyledLabelIcon>
      {right}
    </StyledContextMenuLabel>
  );
});
