import * as React from 'react';
import { useRef } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { BaseProps } from '../interfaces/BaseProps';
import useMeasureDirty from 'react-use/esm/useMeasureDirty';

export interface CollapseProps extends React.HTMLAttributes<HTMLDivElement>, BaseProps {
  isOpen?: boolean;
}

const Collapse = (props: CollapseProps) => {
  const { isOpen = true } = props;

  const ref = useRef<HTMLDivElement>(null);
  // Not sure why this is marked as Dirty, but it's way faster.
  const { height: viewHeight } = useMeasureDirty(ref);

  const { height, opacity, transform } = useSpring({
    from: { height: 0, opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: {
      height: isOpen ? viewHeight : 0,
      opacity: isOpen ? 1 : 0,
      transform: `translateX(${isOpen ? 0 : 20}px)`,
    },
    config: {
      tension: 300,
      friction: 33,
    },
  });

  return (
    <animated.div
      style={{
        overflow: 'hidden',
        opacity,
        height,
      }}
    >
      <animated.div ref={ref} style={{ transform }}>
        {props.children}
      </animated.div>
    </animated.div>
  );
};

Collapse.defaultProps = {
  isOpen: true,
};

export default React.memo(Collapse);
