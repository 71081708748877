import * as React from 'react';
import { StyledError, StyledErrorPage } from './ErrorPage.styled';
import { ErrorProps } from '../graphics/Error';

export const ErrorPage = ({ children, heading, subheading, ...rest }: ErrorProps) => (
  <StyledErrorPage {...rest}>
    <StyledError heading={heading} subheading={subheading}>
      {children}
    </StyledError>
  </StyledErrorPage>
);
