import styled, { css } from 'styled-components';
import * as React from 'react';
import { Checkdot } from '../form/Radio.styled';
import { textEllipsis } from '../../utilities/mixins/textOverflow';

export const StyledOption = styled.input(
  ({ theme }) => css`
    &,
    label {
      display: flex;
      line-height: 1.5;
      padding: 0.25em 0;
    }

    svg {
      flex: 0 0 auto;
      font-size: 1.25em;
    }

    ${Checkdot} {
      font-size: 1.25em;
    }
  `
);

export const StyledOptionPrice = styled(({ order, stack, ...props }) => <span {...props} />)<{
  order?: number;
  stack?: boolean;
}>`
  margin-left: ${(p) => !p.stack && 'auto'};
  order: ${(p) => p.order};
  text-align: right;
`;

export const StyledOptionContent = styled.div<{ column: boolean }>(
  ({ theme, column }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--option-size, 'auto');

    :not(:last-child),
    > :not(:last-child) {
      margin-right: ${!column && theme.spacing.small};
    }

    > span {
      ${column &&
      css`
        font-size: 0.9em;
        ${textEllipsis};
      `};
    }

    > svg {
      color: ${theme.colors.primary.default};
      flex: 0 0 auto;
    }
  `
);
