import styled, { css } from 'styled-components';
import { Heading } from '../typo/Heading';
import { spacing } from '../../variables/spacing';

export const StyledAccountPanel = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    background: ${theme.colors.neutral.xxxlight};
    border-bottom: 2px solid ${theme.colors.neutral.xxlight};
    padding: ${spacing.s12} ${spacing.s16};
  `
);

export const StyledFlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledName = styled(Heading).attrs({ level: 1, span: true })(
  ({ theme }) => css`
    font-size: 1em;
  `
);

export const StyledLogout = styled.span(
  ({ theme }) => css`
    font-family: ${theme.typography.headingFamily};
    font-weight: ${theme.typography.headings.h1.weight};
    color: ${theme.colors.text.default};
    cursor: pointer;

    :hover,
    :focus,
    :active {
      color: ${theme.colors.primary.default};
    }
  `
);

export const StyledSmall = styled.small(
  ({ theme }) => css`
    font-size: smaller;
    line-height: 1;
  `
);
