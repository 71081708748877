import * as React from 'react';
import { MouseEventHandler } from 'react';
import { GrowStack, Preview, StyledContentInput, StyledControls } from './ContentInput.styled';
import { BaseProps } from '../../interfaces/BaseProps';
import { ImageBoxPlaceholder } from './ImageBox';
import { Select, SelectOptionProps } from './Select';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { FaArrowLeft, FaEyeDropper, FaSearch, FaTimes } from 'react-icons/fa';
import { DragObjectWithType, useDrop } from 'react-dnd';
import mergeRefs from 'react-merge-refs';
import { StyledImage } from './ImageBox.styled';

// Value type
interface ControlOption extends SelectOptionProps {
  thumbnail: string;
}
// Type for drag and drop
interface DropType extends DragObjectWithType, ControlOption {}

interface ControlInputDnDProps {
  isDragging: boolean;
  canDrop: boolean;
}

// Props
interface ControlsProps {
  onInjectClick?: MouseEventHandler<HTMLElement>;
  onPickerClick?: MouseEventHandler<HTMLElement>;
  onLookupClick?: MouseEventHandler<HTMLElement>;
  onClearClick?: MouseEventHandler<HTMLElement>;
}
interface ContentInputProps extends BaseProps, ControlsProps {
  // Name of the input
  name?: string;
  // Placeholder and fallback
  placeholder?: string;
  // Whichever type of object can be placed in this input
  accept: string | symbol | string[];
  // The value of the chosen item
  value: ControlOption | undefined;
  // Contents of the dropdown
  options: ControlOption[];

  onChange: (newVal?: ControlOption) => void;
  onDrop: (droppedItem: DropType) => void;
}

const Controls = React.forwardRef<HTMLDivElement, ControlsProps>(
  ({ onClearClick, onInjectClick, onLookupClick, onPickerClick, ...rest }, ref) => {
    return (
      <StyledControls {...rest} ref={ref}>
        <Button variant={'secondary'} small title={'Use selected item in the content browser'} onClick={onInjectClick}>
          <Icon icon={FaArrowLeft} />
        </Button>
        <Button
          variant={'secondary'}
          small
          title={'Pick an item from the scene, or the content browser'}
          onClick={onPickerClick}
        >
          <Icon icon={FaEyeDropper} />
        </Button>
        <Button variant={'secondary'} small title={'Look up the item in the content browser'} onClick={onLookupClick}>
          <Icon icon={FaSearch} />
        </Button>
        <Button variant={'secondary'} small title={'Clear the item'} onClick={onClearClick}>
          <Icon icon={FaTimes} />
        </Button>
      </StyledControls>
    );
  }
);

const ContentInput = React.forwardRef<HTMLDivElement, ContentInputProps>((props, ref) => {
  const {
    accept,
    options,
    name = 'henk',
    placeholder = 'Select...',
    value,
    onChange,
    onDrop,
    onClearClick,
    onInjectClick,
    onLookupClick,
    onPickerClick,
    ...rest
  } = props;

  const [{ isDragging, canDrop }, dropRef] = useDrop<DropType, unknown, ControlInputDnDProps>({
    accept: accept,
    drop: (item, monitor) => {
      if (!monitor.canDrop()) return;

      onDrop?.(item);
      onChange(item);
    },
    collect: (monitor) => ({
      isDragging: !!monitor.getItem(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <StyledContentInput {...rest} ref={mergeRefs([dropRef, ref])} isDragging={isDragging} canDrop={canDrop}>
      <Preview>{value != null ? <StyledImage src={value.thumbnail} /> : <ImageBoxPlaceholder />}</Preview>

      <GrowStack>
        <Select
          options={options}
          name={name}
          placeholder={placeholder}
          value={value != null ? value : { label: placeholder, value: undefined }}
          onChange={onChange}
          isSearchable
        />

        <Controls
          onInjectClick={onInjectClick}
          onPickerClick={onPickerClick}
          onLookupClick={onLookupClick}
          onClearClick={(event) => {
            onClearClick?.(event);
            onChange(undefined);
          }}
        />
      </GrowStack>
    </StyledContentInput>
  );
});

export { ContentInput, ContentInputProps, ControlOption, ControlInputDnDProps };
