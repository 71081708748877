import { Severity } from '../types/severity';
import { IconType } from 'react-icons';
import { FaCheckCircle, FaExclamationCircle, FaExclamationTriangle, FaInfoCircle } from 'react-icons/fa';

export const severityIconMap: { [key in Severity]: IconType } = {
  error: FaExclamationCircle,
  warning: FaExclamationTriangle,
  success: FaCheckCircle,
  message: FaInfoCircle,
};
