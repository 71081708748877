import * as React from 'react';
import {
  Container,
  EditorHelpGrid,
  EditorHelpLink,
  EditorHelpLinks,
  StyledCloseButton,
  StyledEditorHelp,
  StyledTab,
  StyledTabList,
} from './EditorHelpOverlay.styled';
import { Tabs } from '../tabs/Tabs';
import { TabPanel } from '../tabs/TabPanel';
import { BaseProps } from '../../interfaces/BaseProps';
import ReactModal from 'react-modal';
import { FormattedMessage } from 'react-intl';
import { Urls } from '@innobrix/definitions';

interface EditorHelpOverlayProps
  extends BaseProps,
    Omit<ReactModal.Props, 'shouldCloseOnOverlayClick' | 'className' | 'style'> {
  panels: React.ReactElement[];
  headers: string[];
}

const EditorHelpOverlay = React.forwardRef<ReactModal, EditorHelpOverlayProps>(
  ({ className, style, panels, headers, onRequestClose, ...rest }, ref) => {
    // eslint-disable-next-line react/jsx-no-literals
    const dividerDot = <>&#183;</>;

    return (
      <ReactModal
        {...rest}
        ref={ref}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        onRequestClose={onRequestClose}
        closeTimeoutMS={200}
      >
        <StyledEditorHelp className={className} style={style}>
          <StyledCloseButton onClick={onRequestClose} />
          <Container>
            <Tabs>
              <StyledTabList>
                {headers.map((h, i) => (
                  <StyledTab key={`tab-${i}`}>{h}</StyledTab>
                ))}
              </StyledTabList>

              {panels.map((child, index) => (
                <TabPanel key={`tabpanel-${index}`}>{child}</TabPanel>
              ))}
            </Tabs>
          </Container>

          <EditorHelpLinks>
            <EditorHelpLink href={Urls.documentation} target={'_blank'} rel={'noopener'}>
              <FormattedMessage id={'editor.help-overlay.documentation'} />
            </EditorHelpLink>
            <span>{dividerDot}</span>
            <EditorHelpLink href={Urls.supportTicket} target={'_blank'} rel={'noopener'}>
              <FormattedMessage id={'editor.help-overlay.ticket'} />
            </EditorHelpLink>
          </EditorHelpLinks>
        </StyledEditorHelp>
      </ReactModal>
    );
  }
);

export {
  EditorHelpOverlay,
  EditorHelpOverlayProps,
  EditorHelpGrid,
  StyledTab as HelpTab,
  StyledTabList as HelpTabList,
};
