import * as React from 'react';
import { useCallback } from 'react';
import { StyledNumberWithIncDecInput, StyledZoomButtons } from './NumberWithIncDecInput.styled';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { NumberInput, NumberInputProps } from './NumberInput';
import { clamp, round } from 'lodash-es';
import { IconButton } from '../../IconButton';

type NumberWithIncDecInputProps = NumberInputProps;

function clampValue(value = 0, precision = 0, min?: number, max?: number) {
  value = round(value, precision);

  if (min != null && max != null) {
    return clamp(value, min, max);
  } else if (max != null) {
    return clamp(value, max);
  } else {
    return value;
  }
}

const NumberWithIncDecInput = ({
  value = 0,
  onChange,
  min,
  max,
  scale = 2,
  onKeyDown,
  disabled,
  ...rest
}: NumberWithIncDecInputProps) => {
  const handleIncrement = useCallback(() => {
    const newValue = clampValue(++value, scale, min, max);
    onChange?.(newValue);
  }, [max, min, onChange, scale, value]);

  const handleDecrement = useCallback(() => {
    const newValue = clampValue(--value, scale, min, max);
    onChange?.(newValue);
  }, [max, min, onChange, scale, value]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      onKeyDown?.(e);

      const increase = e.ctrlKey ? 100 : e.shiftKey ? 10 : e.altKey && scale > 0 ? 0.1 : 1;

      if (e.key === 'ArrowUp') {
        // Prevent default to stop selecting text while in/decrementing
        e.preventDefault();

        const newValue = clampValue(value + increase, scale, min, max);
        onChange?.(newValue);
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();

        const newValue = clampValue(value - increase, scale, min, max);
        onChange?.(newValue);
      }
    },
    [max, min, onChange, onKeyDown, scale, value]
  );

  return (
    <StyledNumberWithIncDecInput>
      <NumberInput
        {...rest}
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        onKeyDown={handleKeyDown}
        disabled={disabled}
      />

      <StyledZoomButtons>
        <IconButton onClick={handleDecrement} disabled={disabled}>
          <FaMinus />
        </IconButton>
        <IconButton onClick={handleIncrement} disabled={disabled}>
          <FaPlus />
        </IconButton>
      </StyledZoomButtons>
    </StyledNumberWithIncDecInput>
  );
};

export { NumberWithIncDecInput, NumberWithIncDecInputProps };
