import 'react-datepicker/dist/react-datepicker.css';
import styled, { css } from 'styled-components';
import { borderRadius } from '../../variables/borderRadius';
import { spacing } from '../../variables/spacing';
import { inputBox } from '../../utilities/mixins/inputStyle';
import { fontSize } from '../../variables/typography';
import { openSans } from '../../utilities/mixins/typography';

export const StyledDatePicker = styled.div(({ theme }) => {
  const cn = '.react-datepicker';
  return css`
    ${cn} {
      ${openSans};
      display: flex;
      border: none;
      background: ${theme.colors.neutral.white};
      border-radius: ${borderRadius.r4};
      box-shadow: ${theme.elevation.light}, ${theme.elevation.large};
      padding: ${spacing.s16} ${spacing.s12};
      font-size: ${fontSize.s14};
      color: currentColor;
      user-select: none;

      &-popper[data-placement^='bottom'] {
        margin-top: ${spacing.s8};
      }

      &__header {
        background: none;
        border-bottom: none;
        padding-top: 0;
        // max width of the header
        min-width: 242px;

        ${cn}__current-month {
          display: none;

          &--hasYearDropdown,
          &--hasMonthDropdown {
          }
        }

        &__dropdown {
          display: flex;
          padding: 0 1.5rem;
          justify-content: center;
        }
      }

      &__navigation {
        &--previous {
          left: 4px;
        }
        &--next {
          right: 4px;
        }
      }

      &__current-month {
      }

      &__day {
        color: currentColor;
        border-radius: ${borderRadius.r4};

        transition: ${theme.transition.default};
        transition-property: background, color, box-shadow;

        &--outside-month,
        &--disabled {
          color: ${theme.colors.neutral.light};
        }

        &--disabled {
          cursor: not-allowed;
        }

        &--in-range,
        &--in-selecting-range {
          background: ${theme.colors.primary.xlight};
          color: ${theme.colors.neutral.white};
        }

        &--today {
        }

        :hover:not(&--disabled),
        &--selected,
        &--range-start,
        &--range-end {
          background: ${theme.colors.primary.default};
          color: ${theme.colors.neutral.white};
        }

        :focus,
        &--keyboard-selected {
          background: ${theme.colors.primary.default};
          color: ${theme.colors.neutral.white};
          box-shadow: 0 0 0 4px ${theme.colors.primary.xlight};
          outline: none;
        }

        &--range-start {
        }

        &--range-end {
        }

        //region Day headers
        &-names {
        }

        &-name {
          color: ${theme.colors.neutral.light};
        }
        //endregion
      }

      &__month {
        margin-top: 0;
      }

      //region Header Dropdowns
      &__month,
      &__year {
        &-dropdown {
          top: 100%;
          left: 0;
          min-width: 100%;
          text-align: left;

          border: none;
          padding: ${spacing.s4} 0;
          background: ${theme.colors.neutral.xxlight};
          border-radius: ${borderRadius.r4};
          box-shadow: ${theme.elevation.medium};

          &-container {
            position: relative;
            margin: 0;

            :not(:last-child) {
              margin-right: ${spacing.s8};
            }
          }
        }

        &-option {
          border-radius: 0;
          padding: ${spacing.s2} ${spacing.s8};

          &--selected {
            display: none;
          }
        }

        &-dropdown + &-read-view {
        }

        &-read-view {
          display: flex;
          ${inputBox({ theme })};
          padding: 0.25rem 0.75rem;

          &--down-arrow {
            width: 0;
            order: 1;
            margin-left: ${spacing.s4};
            border-top-color: ${theme.colors.neutral.light};
            float: none;
            border-width: 0.33rem;
          }
        }
      }

      .react-datepicker__month-dropdown + .react-datepicker__month-read-view,
      .react-datepicker__year-dropdown + .react-datepicker__year-read-view {
        // Don't hide the label when opening the dropdown
        visibility: visible !important;
      }
      //endregion
    }
  `;
});

export const PopperContainer = styled.div(
  ({ theme }) => css`
    display: flex;

    > :not(:last-child) {
      margin-right: ${spacing.s8};
      padding-right: ${spacing.s8};
      border-right: 1px solid ${theme.colors.neutral.xxlight};
    }
  `
);

export const DatePickerContainer = styled.div(
  ({ theme }) => css`
    position: relative;
  `
);

export const ButtonList = styled.nav(
  ({ theme }) => css`
    list-style-type: none;
    margin-left: 0;

    > li {
      padding: ${spacing.s2} ${spacing.s8};
      border-radius: ${borderRadius.r4};
      cursor: pointer;

      transition: ${theme.transition.slow};
      transition-property: background-color, color;

      :hover,
      :focus {
        background: ${theme.colors.primary.default};
        color: ${theme.colors.text.light};
      }
    }
  `
);
