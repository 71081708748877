import styled, { css } from 'styled-components';
import hexagons from '../../../../../shared/assets/src/lib/images/repeating-hexagons.svg';
import { spacing } from '../../variables/spacing';
import { borderRadius } from '../../variables/borderRadius';
import { breakpoints } from '../../variables/breakpoints';
import { ChatInput } from './ChatInput';

export const StyledChat = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      'docs'
      'chat'
      'input';

    overflow: auto;

    background: url(${hexagons}) repeat;
    border-radius: ${borderRadius.r8};
    box-shadow: ${theme.elevation.medium};

    @media screen and ${breakpoints.laptop} {
      grid-template-columns: 1fr min(33%, 400px);
      grid-template-rows: 1fr auto;
      grid-template-areas:
        'chat docs'
        'input docs';
      gap: ${spacing.s12};

      padding: ${spacing.s12};
    }
  `
);

export const ChatContainer = styled.div`
  grid-area: chat;
  overflow-x: hidden;
  overflow-y: auto;

  // TODO fix overscrolling on iPad
  overscroll-behavior: contain;

  margin: 0 ${spacing.s4};
  padding: ${spacing.s8} ${spacing.s8} 0.125rem;

  display: flex;
  flex-direction: column;

  > :not(:last-child) {
    margin-bottom: ${spacing.s8};
  }

  > :first-child {
    // Force items to bottom
    margin-top: auto;
  }

  > * {
    // fix for item overlap issue on iOS
    flex: 0 0 auto;
  }

  @media screen and ${breakpoints.laptop} {
    margin: 0;
    padding: 0 ${spacing.s8} 0 0;
  }
`;

export const ChatInputContainer = styled(ChatInput)`
  grid-area: input;

  padding: 0 ${spacing.s12} ${spacing.s12};

  @media screen and ${breakpoints.laptop} {
    padding: 0;
  }
`;

export const DocumentsContainer = styled.div(
  ({ theme }) => css`
    grid-area: docs;
    overflow-x: hidden;
    overflow-y: auto;

    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: ${spacing.s8};

    background-color: ${theme.colors.neutral.xxxlight};
    padding: ${spacing.s8};
    border-bottom: 2px solid ${theme.colors.neutral.xxlight};

    @media screen and ${breakpoints.laptop} {
      display: block;
      padding: ${spacing.s16};
      margin: 0;
      border-bottom: none;
      border-radius: ${borderRadius.r8};
    }
  `
);

export const NoMessagesLabel = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
`;
