import { getCurrency } from 'locale-currency';
import { createContext, PropsWithChildren, useContext } from 'react';
import * as React from 'react';
import { code, CurrencyCodeRecord } from 'currency-codes';
import { currencyFallback } from '@innobrix/definitions';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useIntl } from 'react-intl';

/**
 * Recursively loop through languages until one returns a valid currency
 * @param index
 */
function tryGetCurrency(index = 0): string | undefined {
  if (index >= navigator.languages.length) return undefined;
  return getCurrency(navigator.languages[index]) ?? tryGetCurrency(index + 1);
}

/**
 * Get currency symbol based on ISO 4217 codes
 */
export function getCurrentCurrencyCode() {
  // Get the ISO currency for the locale.
  const currency = tryGetCurrency();
  // Return it or EUR as a fallback.
  return currency ?? 'EUR';
}

/**
 * Get the current currency
 */
export function getCurrentCurrency() {
  return code(getCurrentCurrencyCode()) ?? currencyFallback;
}

type DecimalRadix = '.' | ',';

// Context
type CurrencyRecord = CurrencyCodeRecord & { symbol?: string; radix?: DecimalRadix };
const CurrencyContext = createContext<CurrencyRecord>(currencyFallback);
export const useCurrency = () => useContext(CurrencyContext);

type Props = PropsWithChildren<{
  isoCode: string;
}>;

/**
 * Provider for currency information
 * @param children
 * @param isoCode - The ISO code for the currency (eg: "EUR")
 */
export const CurrencyProvider = ({ children, isoCode }: Props) => {
  const { formatNumber } = useIntl();

  const currency = code(isoCode) ?? currencyFallback;
  const symbol = getSymbolFromCurrency(currency.code);

  // Figure out if the current locale uses . or , for decimal delimiting.
  const radix: DecimalRadix = formatNumber(0.5).includes(',') ? ',' : '.';

  const value = {
    ...currency,
    symbol,
    radix,
  };

  return <CurrencyContext.Provider value={value}>{children}</CurrencyContext.Provider>;
};
