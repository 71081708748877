import * as React from 'react';
import { BaseProps } from '../../interfaces/BaseProps';
import { ArrowLeft, ArrowRight } from './CarouselArrows.styled';
import { useCarousel } from './CarouselProvider';
import { useCallback } from 'react';

type CarouselArrowsProps = BaseProps;

const CarouselArrows = React.forwardRef<HTMLDivElement, CarouselArrowsProps>(({ children, ...rest }, ref) => {
  const { goToIndex, currentIndex, visibleItems, count } = useCarousel();

  const goPrev = useCallback(() => {
    if (visibleItems !== 1 && currentIndex === count - 1) {
      // When at the end, go back a bit more
      goToIndex(currentIndex - (visibleItems + 2));
    } else {
      goToIndex(currentIndex - visibleItems);
    }
  }, [count, currentIndex, goToIndex, visibleItems]);

  const goNext = useCallback(() => {
    goToIndex(currentIndex + visibleItems);
  }, [currentIndex, goToIndex, visibleItems]);

  return (
    <>
      <ArrowLeft {...rest} onClick={goPrev} disabled={currentIndex <= 0} />
      <ArrowRight {...rest} onClick={goNext} disabled={currentIndex === count - 1} />
    </>
  );
});

export { CarouselArrows, CarouselArrowsProps };
