import styled from 'styled-components';
import { StyledStatsCard } from './StatCard.styled';

export const DonutStatCardStyled = styled(StyledStatsCard)`
  display: grid;
  grid-template-columns: 2fr 100px;
  grid-template-rows: 1fr;
  gap: 0 0.5rem;
  align-items: center;
`;

export const ChartWrapper = styled.div`
  ${DonutStatCardStyled} > & {
    //grid-row: span 2;
  }
`;
