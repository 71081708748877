import { BaseProps, StatLabel } from '@innobrix/components';
import * as React from 'react';
import { StyledStatsCard } from './StatCard.styled';

type Props = BaseProps & {
  stat: number;
};

export const StatCard = ({ stat, children }: React.PropsWithChildren<Props>) => {
  return (
    <StyledStatsCard>
      <div>
        <StatLabel stat={stat} />
      </div>
      <span>{children}</span>
    </StyledStatsCard>
  );
};
