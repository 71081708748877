import * as React from 'react';
import { ContextMenu as CMenu, ContextMenuProps as CMProps } from 'react-contextmenu';
import { ChildrenProp, BaseProps } from '../../interfaces/BaseProps';
import { ContextMenuWrapper } from './ContextMenu.styled';
import { ContextMenuTrigger, ContextMenuTriggerProps } from './ContextMenuTrigger';
import ReactDOM from 'react-dom';

export interface ContextMenuProps
  extends BaseProps,
    ChildrenProp,
    CMProps,
    Pick<ContextMenuTriggerProps, 'holdToDisplay' | 'collect' | 'disable'> {
  menuItems?: React.ReactNode;
  mouseButton?: number;
}

export const ContextMenu = React.forwardRef(
  (props: ContextMenuProps, ref: React.Ref<React.Component<ContextMenuTriggerProps>>) => {
    const { children, menuItems, className, holdToDisplay, collect, mouseButton, id, disable, ...rest } = props;

    return (
      <>
        <ContextMenuTrigger
          id={id}
          ref={ref}
          holdToDisplay={holdToDisplay}
          collect={collect}
          disable={disable}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore this is actually a prop
          mouseButton={mouseButton}
        >
          {children}
        </ContextMenuTrigger>

        {ReactDOM.createPortal(
          <ContextMenuWrapper className={className}>
            <CMenu {...rest} id={id}>
              {menuItems}
            </CMenu>
          </ContextMenuWrapper>,
          document.body
        )}
      </>
    );
  }
);

export const ContextMenuWithoutTrigger = React.forwardRef<HTMLDivElement, ContextMenuProps>((props, ref) => {
  const { children, menuItems, className, holdToDisplay, collect, mouseButton, id, disable, ...rest } = props;

  return ReactDOM.createPortal(
    <ContextMenuWrapper className={className} ref={ref}>
      <CMenu {...rest} id={id}>
        {menuItems}
      </CMenu>
    </ContextMenuWrapper>,
    document.body
  );
});
