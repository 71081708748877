import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';

export const StyledListItem = styled.div<any>(
  ({ theme, $hasAffix }) => css`
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr ${$hasAffix ? 'auto' : undefined};
    gap: ${spacing.s8};
  `
);
