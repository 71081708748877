import * as React from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Layout } from '../layout/Layout';
import { DevLayout } from '../layout/DevLayout.styled';
import { AccessTokenCard } from '../components/dashboard/AccessTokenCard';
import { useAuth0 } from '@auth0/auth0-react';
import { useAsync } from 'react-use';

export const Dev = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState('');

  useAsync(async () => {
    setAccessToken(await getAccessTokenSilently());
  }, [getAccessTokenSilently]);

  return (
    <Layout>
      <Helmet>
        <title>Dev | Innobrix</title>
      </Helmet>

      <DevLayout>
        <AccessTokenCard token={accessToken} style={{ gridArea: 'token' }} />
      </DevLayout>
    </Layout>
  );
};

export default Dev;
