import * as React from 'react';
import { FiMenu } from 'react-icons/fi';
import { StyledHamburger } from './Hamburger.styled';
import { OnClickProp } from '../../interfaces/EventProps';
import { BaseProps } from '../../interfaces/BaseProps';

export interface HamburgerProps extends BaseProps, OnClickProp {
  disable?: boolean;
}

export const Hamburger = React.forwardRef<HTMLDivElement, HamburgerProps>(({ ...rest }, ref) => {
  return (
    <StyledHamburger disable={rest.disable} {...rest} ref={ref}>
      <FiMenu />
    </StyledHamburger>
  );
});
