import * as React from 'react';
import { PriceListPrice, StyledPriceListFooter } from './PriceListFooter.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { FlexBox } from '../layout/FlexBox';
import { FormattedMessage } from 'react-intl';

interface PriceListFooterProps extends BaseProps, ChildrenProp {
  price: number;
}

const PriceListFooter = React.forwardRef<HTMLDivElement, PriceListFooterProps>(
  ({ children, price = 0, ...rest }, ref) => {
    return (
      <StyledPriceListFooter {...rest} ref={ref}>
        <FlexBox justify={'space-between'} align={'end'}>
          <span>
            <FormattedMessage id={'price-list.price-total'} defaultMessage={'Total price'} />
          </span>
          <PriceListPrice price={price} />
        </FlexBox>

        {children}
      </StyledPriceListFooter>
    );
  }
);

export { PriceListFooter, PriceListFooterProps, StyledPriceListFooter };
