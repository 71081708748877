import { Tooltip } from '@innobrix/components';
import { PropsWithChildren } from 'react';
import * as React from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import { FetchErrorBubbleStyled } from './FetchErrorBubble.styled';

export const FetchErrorBubble = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <Tooltip overlay={children} mouseEnterDelay={0.5} placement={'left'}>
      <FetchErrorBubbleStyled>
        <FaTimesCircle />
      </FetchErrorBubbleStyled>
    </Tooltip>
  );
};
