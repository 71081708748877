import * as React from 'react';
import { FinishButton, NavButton, Steps, StyledWizard } from './Wizard.styled';
import { BaseProps } from '../../interfaces/BaseProps';
import { FaCheck, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Icon } from '../Icon';
import { useCallback, useMemo, useState } from 'react';
import { Span } from '../typo/Span';
import { FormattedMessage } from 'react-intl';

interface WizardProps extends BaseProps {
  step: number;
  onChange: (step: number) => void;
  onFinished: () => void;
  children: React.ReactElement[];
}

const Wizard = React.forwardRef<HTMLDivElement, WizardProps>(
  ({ children, step = 0, onChange, onFinished, ...rest }, ref) => {
    // State
    const [maxStep, setMaxStep] = useState(step);
    const numChildren = useMemo(() => React.Children.count(children), [children]);

    //region callbacks
    const isStepDisabled = useCallback(
      (stepIndex: number) => (stepIndex >= numChildren ? false : children[stepIndex].props.disabled),
      [children, numChildren]
    );

    const changeStep = useCallback(
      (newStep: number) => {
        if (isStepDisabled(newStep)) return;
        // Go to previous step
        onChange?.(newStep);

        if (newStep > maxStep) setMaxStep(newStep);
      },
      [isStepDisabled, maxStep, onChange]
    );

    const handlePrevClick = useCallback(
      (event: React.MouseEvent) => {
        if (step <= 0) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          // Go to previous step
          changeStep(step - 1);
        }
      },
      [changeStep, step]
    );

    const handleNextClick = useCallback(() => {
      // Go to next step
      changeStep(step + 1);
    }, [changeStep, step]);

    const handleFinishClick = useCallback(() => {
      onFinished?.();
    }, [onFinished]);

    const handleStepClick = useCallback(
      (index: number, event: React.MouseEvent, cb?: React.MouseEventHandler) => {
        if (isStepDisabled(index)) return;
        // Call original step onClick event
        cb?.(event);

        if (index <= maxStep) {
          // If an already visited step is clicked, go there.
          changeStep(index);
        }
      },
      [changeStep, isStepDisabled, maxStep]
    );
    //endregion

    return (
      <StyledWizard {...rest} ref={ref}>
        <NavButton disabled={step === 0 || isStepDisabled(step - 1)} onClick={handlePrevClick}>
          <Icon icon={FaChevronLeft} />
        </NavButton>

        <Steps>
          {React.Children.map(children, (child, index) =>
            React.cloneElement(child, {
              ...child.props,
              onClick: (e: React.MouseEvent) => handleStepClick(index, e, child.props.onClick),
              $status: index === step ? 'progress' : index <= step ? 'done' : 'todo',
            })
          )}
        </Steps>

        {step < maxStep ? (
          <NavButton onClick={handleNextClick}>
            <Icon icon={FaChevronRight} />
          </NavButton>
        ) : step >= numChildren - 1 ? (
          <FinishButton onClick={handleFinishClick}>
            <Icon icon={step + 1 >= numChildren ? FaCheck : FaChevronRight} />
            <Span>
              <FormattedMessage id={'wizard.complete'} />
            </Span>
          </FinishButton>
        ) : undefined}
      </StyledWizard>
    );
  }
);

export { Wizard, WizardProps };
export { Step } from './Wizard.styled';
