import * as React from 'react';
import styled, { css, DefaultTheme, StyledComponent } from 'styled-components';
import { visuallyHidden } from '../../utilities/mixins/visuallyHidden';
import { StyledImage as Image, StyledImageBox } from '../form/ImageBox.styled';
import { Stack } from '../layout/Stack';
import { VisibilityToggle } from './VisibilityToggle';
import { absoluteCenter } from '../../utilities/mixins/layout';

export const StyledImageRadio = styled.label``;

export const StyledStack = styled(Stack)`
  position: relative;
`;

export const StyledHiddenInput = styled.input.attrs({ type: 'radio' })(
  ({ theme }) => css`
    ${visuallyHidden};
    outline: none;

    // Disabled
    &:disabled ~ * {
      cursor: not-allowed;
      color: ${theme.colors.neutral.light};
    }
  `
);

export const StyledImage = styled(Image)(
  ({ theme }) => css`
    width: var(--option-size, 5.5rem);
    height: var(--option-size, 5.5rem);
  `
);

export const StyledImageRadioBox = styled(StyledImageBox)(
  ({ theme }) => css`
    position: relative;
    transition-property: box-shadow, border, background-color;

    input:disabled ~ & {
      img {
        opacity: 0.75;
        filter: grayscale(1) brightness(1.25) contrast(0.5);
      }
    }

    // Hover & focus
    input:not(:disabled):hover ~ &,
    input:not(:disabled):focus.focus-visible ~ & {
      box-shadow: ${theme.elevation.light}, 0 0 0 ${theme.spacing.xsmall} ${theme.colors.primary.xxlight};
    }

    // Active
    input:checked ~ & {
      background: ${theme.colors.primary.default};
    }
  `
);

export const Checkmark = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 33 31" fill="none" {...props}>
    <path d="M9 15.75L14.25 21L23.25 9" stroke="white" strokeWidth="2" />
  </svg>
);

export const optionCheckmark = (
  parent: StyledComponent<'div', DefaultTheme>,
  parentParent: StyledComponent<'label', DefaultTheme>
) =>
  styled(Checkmark)(
    ({ theme }) => css`
      ${absoluteCenter};
      z-index: 1;
      width: var(--option-size, 3.5rem);
      height: var(--option-size, 3.5rem);
      filter: drop-shadow(0px 0px 6px black);

      path {
        stroke: ${theme.colors.neutral.white};
        stroke-width: 2px;
        stroke-dasharray: 23;

        opacity: 0.5;
        stroke-dashoffset: 23;

        transition: ${theme.transition.slow};
        transition-property: opacity, stroke-dashoffset;
      }

      input:not(:disabled):hover ~ ${parent} &,
      input:not(:disabled):focus.focus-visible ~ ${parent} & {
        path {
          stroke-dashoffset: 0;
        }
      }

      input:checked ~ ${parent} & {
        path {
          opacity: 1;
          stroke-dashoffset: 0;
        }
      }
    `
  );

export const StyledOptionCheckmark = optionCheckmark(StyledImageRadioBox, StyledImageRadio);

export const StyledVisibilityToggle = styled(VisibilityToggle)(
  ({ theme }) => css`
    color: white;

    transition: ${theme.transition.slow};
    transition-property: opacity, color;

    path {
      opacity: 1;
    }

    :hover,
    :focus {
      color: ${theme.colors.primary.xxlight};
    }
  `
);
