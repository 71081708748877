import * as React from 'react';
import {
  ActionsContainer,
  InnerContainer,
  StyledCloseButton,
  StyledHelpOverlay,
  StyledTabList,
  StyledTab,
} from './HelpOverlay.styled';
import { ChildrenProp } from '../../interfaces/BaseProps';
import ReactModal from 'react-modal';

interface HelpOverlayProps extends ChildrenProp, Omit<ReactModal.Props, 'shouldCloseOnOverlayClick'> {
  actions?: React.ReactNode;
}

const HelpOverlay = React.forwardRef<ReactModal, HelpOverlayProps>(
  ({ actions, children, onRequestClose, ...rest }, ref) => (
    <ReactModal
      {...rest}
      ref={ref}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      onRequestClose={onRequestClose}
      closeTimeoutMS={200}
    >
      <StyledHelpOverlay>
        <StyledCloseButton onClick={onRequestClose} />
        <InnerContainer>{children}</InnerContainer>
        <ActionsContainer>{actions}</ActionsContainer>
      </StyledHelpOverlay>
    </ReactModal>
  )
);

export { HelpOverlay, HelpOverlayProps, StyledTabList, StyledTab };
