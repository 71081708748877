import styled, { css, useTheme } from 'styled-components';
import { borderRadius } from '../../../variables/borderRadius';
import { spacing } from '../../../variables/spacing';
import { shouldNotForward } from '../../../utilities/Props';
import { components, MenuProps } from 'react-select';
import { StyledSelectMenu } from '../../form/Select.styled';

type Props = { noBackdrop?: boolean };

export const StyledUserSelect = styled.div.withConfig(
  shouldNotForward<Props>(['noBackdrop'])
)(
  ({ theme, noBackdrop }) => css`
    min-width: 8rem;

    .rs__single-value {
    }

    .rs__value-container {
      ${noBackdrop
        ? css`
            padding: 0;
          `
        : css`
            padding: ${spacing.s8};
          `}
    }

    .rs__single-value,
    .rs__placeholder {
      position: static;
      transform: none;
    }

    .rs__control {
      min-height: 3rem;
      flex-wrap: nowrap;

      ${noBackdrop &&
      css`
        background: none;
        box-shadow: none;
      `}

      :hover,
      &--is-focused,
      &--menu-is-open {
        ${noBackdrop &&
        css`
          box-shadow: none;
        `}
      }

      &--is-focused,
      &--is-focused:hover {
        //box-shadow: 0 0 0 4px ${theme.colors.primary.xxxlight};
      }
    }
  `
);

export const StyledUserSelectMenu = styled(StyledSelectMenu)<Props>(({ noBackdrop }) => {
  const theme = useTheme();
  return css`
    &.rs__menu {
      width: max-content;
      min-width: 100%;

      ${noBackdrop &&
      css`
        border-radius: ${borderRadius.r4};
        border-top: none;
      `}

      .rs__option {
        padding: ${spacing.s8};

        &--is-selected {
          color: ${theme.colors.primary.xxxlight};

          span + span {
            color: ${theme.colors.primary.xxlight} !important;
          }
        }
      }
    }
  `;
});
