import * as React from 'react';
import { Footer, Sidebar, StyledCropper, StyledImageCropper, StyledInner } from './ImageCropper.styled';
import Cropper from 'react-easy-crop';
import ReactModal from 'react-modal';
import { Button } from '../Button';
import { FlexBox } from '../layout/FlexBox';
import { Area } from 'react-easy-crop/types';
import { Slider } from './Slider';
import { FaImage } from 'react-icons/fa';
import { cropImage } from '@innobrix/utils';
import { Overlay } from '../overlay/Overlay';
import { FormattedMessage } from 'react-intl';

interface ImageCropperProps extends Pick<ReactModal.Props, 'isOpen' | 'onRequestClose'> {
  src: string;
  onChange?: (value: string) => void;
  outputWidth?: number;
  // outputHeight?: number;
}

const ImageCropper = React.forwardRef<ReactModal, ImageCropperProps>(
  ({ src, onChange, isOpen, outputWidth = 512, onRequestClose, ...rest }, ref) => {
    const [crop, setCrop] = React.useState({ x: 0, y: 0 });
    const [zoom, setZoom] = React.useState(1);
    const [croppedArea, setCroppedArea] = React.useState<Area | undefined>();

    const handleChange = React.useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedArea(croppedAreaPixels);
    }, []);

    const handleCancel = React.useCallback(
      (e) => {
        onRequestClose && onRequestClose(e);
      },
      [onRequestClose]
    );

    const handleSave = React.useCallback(
      async (e) => {
        if (src == null || croppedArea == null) return;

        // Crop the image
        const img = await cropImage(src, croppedArea, outputWidth);

        // Call onChange and then close the modal
        onChange && onChange(img);
        onRequestClose && onRequestClose(e);
      },
      [croppedArea, onChange, onRequestClose, outputWidth, src]
    );

    return (
      <ReactModal
        {...rest}
        ref={ref}
        isOpen={isOpen}
        shouldCloseOnOverlayClick={false}
        onRequestClose={onRequestClose}
        ariaHideApp={false}
      >
        <Overlay />
        <StyledImageCropper>
          <StyledInner>
            <StyledCropper>
              <Cropper
                image={src}
                onCropChange={setCrop}
                crop={crop}
                aspect={1}
                zoom={zoom}
                onZoomChange={setZoom}
                showGrid={false}
                onCropComplete={handleChange}
              />
            </StyledCropper>

            <Sidebar>
              <FlexBox gutter={'xsmall'}>
                <Button onClick={handleSave}>
                  <FormattedMessage id={'general.save'} />
                </Button>
                <Button variant={'secondary'} onClick={handleCancel}>
                  <FormattedMessage id={'general.cancel'} />
                </Button>
              </FlexBox>
            </Sidebar>

            <Footer>
              <FaImage />
              <Slider name={'zoom-level'} value={zoom} onChange={setZoom} min={1} max={3} step={0.1} />
              <FaImage />
            </Footer>
          </StyledInner>
        </StyledImageCropper>
      </ReactModal>
    );
  }
);

export { ImageCropper, ImageCropperProps };
