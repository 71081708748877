import styled, { css } from 'styled-components';
import { borderRadius } from '../../variables/borderRadius';
import { spacing } from '../../variables/spacing';
import { ControlInputDnDProps } from './ContentInput';

export const StyledContentInput = styled.div<ControlInputDnDProps>(
  ({ theme, isDragging, canDrop }) => css`
    display: flex;
    margin: -4px;
    border: 4px dashed transparent;
    transition: border-color ${theme.transition.default};

    > :not(:last-child) {
      margin-right: 0.5rem;
    }

    ${isDragging
      ? css`
          border-color: ${canDrop ? theme.colors.success.default : theme.colors.error.default};
        `
      : undefined}
  `
);

export const Preview = styled.div(
  ({ theme }) => css`
    display: flex;
    background: ${theme.colors.neutral.xxlight};
    border-radius: ${borderRadius.r4};
    padding: ${spacing.s4};
    box-shadow: ${theme.elevation.xlight};
  `
);

export const StyledControls = styled.div(
  ({ theme }) => css`
    display: flex;

    > :not(:last-child) {
      margin-right: 0.5rem;
    }
  `
);
export const GrowStack = styled.div(
  ({ theme }) => css`
    flex: 1;
    display: flex;
    flex-flow: column;

    > :not(:last-child) {
      margin-bottom: 0.5rem;
    }
  `
);
