import * as React from 'react';
import { IconBadge, StyledChatEventMessage, StyledMessageGroup } from './ChatEventMessage.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { Icon } from '../Icon';
import { FaExchangeAlt, FaFile, FaTrash } from 'react-icons/fa';
import { ChatMessageGroupProps } from './ChatMessageGroup';
import { ChatEventType } from '@innobrix/definitions';

// In the future move this, and add more events
const chatEventIconMap = {
  DocumentAdded: FaFile,
  DocumentRemoved: FaTrash,
  AgentChanged: FaExchangeAlt,
};

interface ChatEventMessageProps extends BaseProps, ChildrenProp, Pick<ChatMessageGroupProps, 'time'> {
  eventType: ChatEventType;
}

const ChatEventMessage = React.forwardRef<HTMLDivElement, ChatEventMessageProps>(
  ({ eventType, time, children, ...rest }, ref) => {
    return (
      <StyledMessageGroup time={time} isMine>
        <StyledChatEventMessage {...rest} ref={ref}>
          {children}

          <IconBadge>
            <Icon icon={chatEventIconMap[eventType]} />
          </IconBadge>
        </StyledChatEventMessage>
      </StyledMessageGroup>
    );
  }
);

export { ChatEventMessage, ChatEventMessageProps };
