import * as React from 'react';
import { StyledCard } from './Card.styled';
import { ChildrenProp, BaseProps } from '../../interfaces/BaseProps';
import { Spacing } from '../../interfaces/Spacing';

export interface CardProps extends BaseProps, ChildrenProp {
  round?: 'large' | 'medium' | 'small';
  hover?: boolean;
  pad?: Spacing;
  overflow?: boolean | 'x' | 'y';
  onClick?: React.MouseEventHandler;
}

const Card = React.forwardRef((props: CardProps, ref: React.Ref<HTMLDivElement>) => {
  const { round, pad, onClick, ...rest } = props;

  return (
    <StyledCard pad={pad} round={round} onClick={onClick} ref={ref} {...rest}>
      {props.children}
    </StyledCard>
  );
});

Card.defaultProps = {
  round: 'medium',
  hover: true,
};

export { Card };
