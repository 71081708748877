import * as React from 'react';
import { PropsWithChildren } from 'react';
import { BaseProps } from '../../interfaces/BaseProps';
import { useSpring, animated } from '@react-spring/web';

type Props = BaseProps & {
  stat: number;
  digits?: number;
};

const SpinnerLabel = ({ stat, digits = 0, ...rest }: PropsWithChildren<Props>) => {
  const { val } = useSpring({ val: stat });
  return <animated.span {...rest}>{val?.to((value) => value.toFixed(digits))}</animated.span>;
};

export { SpinnerLabel };
