import styled, { css } from 'styled-components';
import { inputBox, inputBoxFocus } from '../../utilities/mixins/inputStyle';
import { FormikInputProps } from '../../interfaces/InputProps';
import { hideOutline } from '../../utilities/mixins/hideOutline';
import { Tooltip } from '../tooltip/Tooltip';

type Props = Omit<FormikInputProps, 'name'> & { value: string; disabled?: boolean };

export const StyledColor = styled.div<Props>(
  ({ theme, disabled, value, placeholder, error, touched }) => css`
    ${inputBox({ theme, disabled, error, touched })};
    ${hideOutline};

    --cp-offset: ${theme.spacing.small};
    display: inline-block;
    min-width: 4rem;
    min-height: 2.5rem;
    position: relative;
    cursor: not-allowed;

    ${!disabled &&
    css`
      cursor: pointer;

      :hover,
      :focus {
        ${inputBoxFocus({ theme, disabled })}
      }
    `}

    ::before {
      content: '';
      background: ${value ?? placeholder};
      position: absolute;
      border-radius: inherit;
      top: ${theme.spacing.small};
      right: ${theme.spacing.small};
      bottom: ${theme.spacing.small};
      left: ${theme.spacing.small};
      opacity: ${disabled ? 0.5 : undefined};
    }
  `
);

export const ColorPickerPortal = styled(Tooltip)(
  ({ theme }) => css`
    &.tip {
      --bg: transparent;
      color: currentColor;
      z-index: 5500;

      .tip-inner {
        box-shadow: unset;
        padding: 0;
      }
    }
  `
);
