import styled, { css } from 'styled-components';
import * as React from 'react';
import { StyledImageBox } from '../form/ImageBox.styled';
import { optionCheckmark } from './ImageRadio.styled';

export const StyledColorRadio = styled.label``;

export const StyledColorBox = styled(StyledImageBox)(
  ({ theme }) => css`
    position: relative;
    transition-property: box-shadow, border, background-color;

    // Disabled
    input:disabled ~ & {
      cursor: not-allowed;
    }

    // Hover & focus
    input:not(:disabled):hover ~ &,
    input:not(:disabled):focus ~ & {
      box-shadow: ${theme.elevation.light}, 0 0 0 ${theme.spacing.xsmall} ${theme.colors.primary.xxlight};
    }

    // Active
    input:checked ~ & {
      background: ${theme.colors.primary.default};
    }
  `
);

export const StyledColorPreview = styled.div`
  width: var(--option-size, min(10vmin, 3.5rem));
  height: var(--option-size, min(10vmin, 3.5rem));
  border-radius: inherit;
  background-color: ${(p) => p.color};
`;

export const StyledOptionCheckmark = optionCheckmark(StyledColorBox, StyledColorRadio);
