import styled, { css, CSSProperties } from 'styled-components';
import { visuallyHidden } from '../../utilities/mixins/visuallyHidden';

export const StyledRadioGroup = styled.div(
  ({ theme }) => css`
    display: inline-flex;
    position: relative;

    padding: 0 ${theme.spacing.small};
    border-radius: ${theme.borderRadius.small};
    background-color: ${theme.colors.neutral.xxlight};

    ::before {
      content: '';
      position: absolute;
      left: -0.5rem;
      top: -0.25rem;
      transform: translateX(calc(var(--rg-offset, 0px) + var(--rg-hover-offset, 0px)));

      display: block;
      box-sizing: content-box;
      padding: ${theme.spacing.xsmall} ${theme.spacing.small};
      height: 100%;
      width: var(--rg-item-width, 0px);

      background-color: ${theme.colors.primary.default};
      border-radius: ${theme.borderRadius.small};
      box-shadow: ${theme.elevation.xlight}, ${theme.elevation.light};

      transition: ${theme.transition.slow};
      transition-property: width, transform, box-shadow;
    }

    :focus-within::before,
    :hover::before {
      box-shadow: 0 0 0 0.25em ${theme.colors.primary.xxlight};
    }

    > * {
      cursor: pointer;

      input:checked + * {
        position: relative;
        z-index: 1;
        color: ${theme.colors.text.light};
      }

      &:not(:last-child) {
        margin-right: ${theme.spacing.medium} !important;
      }
    }
  `
);

export const RadioGroupItemLabel = styled.label(
  ({ theme }) => css`
    display: flex;
    position: relative;
    padding: ${theme.spacing.small};
  `
);

export const HiddenRadio = styled.input.attrs({ type: 'radio' })(
  ({ theme }) => css`
    ${visuallyHidden};
  `
);
