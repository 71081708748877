import styled from 'styled-components';

export const StyledTree = styled.ul<{ root: boolean }>`
  margin: 0 0 0 ${(props) => (!props.root ? props.theme.spacing.medium : 0)};
  padding: 0 0 0 ${(props) => (!props.root ? props.theme.spacing.small : 0)};
  border-left: ${(props) => (props.root ? 'none' : `1px dashed currentColor`)};
`;

export const SkeletonUl = styled.ul`
  padding-left: ${(props) => props.theme.spacing.medium};
`;
