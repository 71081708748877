import * as React from 'react';
import { StyledHeading, StyledHelpOverlayIcon, StyledIcon, LightSubtleSpan } from './HelpOverlayIcon.styled';
import { IconType } from 'react-icons';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';

interface HelpOverlayIconProps extends BaseProps, ChildrenProp {
  icon: IconType;
  heading: string;
}

const HelpOverlayIcon = React.forwardRef<HTMLDivElement, HelpOverlayIconProps>(
  ({ icon, heading, children, ...rest }, ref) => (
    <StyledHelpOverlayIcon {...rest} ref={ref}>
      <StyledIcon icon={icon} />
      <StyledHeading>{heading}</StyledHeading>
      <LightSubtleSpan>{children}</LightSubtleSpan>
    </StyledHelpOverlayIcon>
  )
);

export { HelpOverlayIcon, HelpOverlayIconProps };
