import styled, { css } from 'styled-components';
import { inputBox, inputBoxFocus } from '../../utilities/mixins/inputStyle';
import { CloseButton } from '../navigation/CloseButton';
import img from '../../../../../shared/assets/src/lib/images/img.svg';

export const Wrapper = styled.div<{ hoverable?: boolean }>(
  ({ theme, hoverable }) => css`
    display: inline-flex;
    position: relative;
    padding-right: 1.5em;
    outline: none;
    ${hoverable &&
    css`
      cursor: pointer;
    `}
  `
);

export const StyledImageBox = styled.div(
  ({ theme }) => css`
    ${inputBox({ theme })};

    height: fit-content;
    display: inline-flex;
    z-index: 1;

    padding: ${theme.spacing.xsmall};

    ${Wrapper}:hover &,
    ${Wrapper}:focus-within & {
      ${inputBoxFocus({ theme })};
    }
  `
);

export const Relative = styled.div(
  ({ theme }) => css`
    display: flex;
    position: relative;
  `
);

const imageStyling = css`
  background: ${(p) => p.theme.colors.neutral.xlight};
  border-radius: ${(p) => p.theme.borderRadius.small};

  width: 4rem;
  height: 4rem;
  object-fit: contain;
`;

export const StyledImage = styled.img(
  ({ theme }) => css`
    ${imageStyling};
  `
);

export const StyledPlaceholder = styled.div(
  ({ theme }) => css`
    ${imageStyling};
    background: ${theme.colors.neutral.xlight} url(${img}) no-repeat center;
    position: relative;
  `
);

export const StyledCloseButton = styled(CloseButton)(
  ({ theme }) => css`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-1.5em, -50%);
    opacity: 0;

    transition: ${theme.transition.slow};
    transition-property: transform, opacity;

    ${Wrapper}:hover &,
    ${Wrapper}:focus & {
      opacity: 1;
      transform: translate(0, -50%);
    }
  `
);
