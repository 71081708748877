import styled, { css } from 'styled-components';
import { borderRadius } from '../../variables/borderRadius';
import { Hamburger } from './Hamburger';
import { spacing } from '../../variables/spacing';

export const StyledNavbarMobile = styled.header(
  ({ theme }) => css`
    position: fixed;
    inset: 0 0 auto 0;
    z-index: 5000;
    box-shadow: ${theme.elevation.light};

    background-color: ${theme.colors.neutral.white};
    color: currentColor;
  `
);

export const StyledHamburger = styled(Hamburger)`
  font-size: 1.25rem;
  padding: 0.75rem;

  :hover {
    background: transparent;
    color: ${(p) => p.theme.colors.primary.default};
  }
`;

export const NotificationDot = styled.div(
  ({ theme }) => css`
    position: absolute;
    left: ${spacing.s8};
    top: ${spacing.s8};
    background-color: ${theme.colors.warning.light};
    width: 8px;
    height: 8px;
    border-radius: 100%;
  `
);

export const StyledNavbar = styled.nav<{ $isOpen?: boolean }>(
  ({ theme, $isOpen }) => css`
    list-style: none;
    font-size: 1.25rem;
    padding: 0.5rem;
    background-color: white;

    position: fixed;
    inset: 44px 0 100% 0;
    z-index: 5000;

    transition: ${theme.transition.slow};
    transition-property: visibility, bottom;

    bottom: ${$isOpen ? 0 : '100%'};
    visibility: ${$isOpen ? 'visible' : 'hidden'};

    > * {
      transition: opacity ${theme.transition.slow};
      opacity: ${$isOpen ? 1 : 0};
    }

    li > * {
      padding: 0.25rem 0.5rem;
      text-decoration: none;
      color: currentColor;
      border-radius: ${borderRadius.r4};

      &.active,
      &:hover {
        background-color: ${theme.colors.primary.default};
        color: ${theme.colors.text.light};
      }
    }
  `
);
