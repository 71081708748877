import * as React from 'react';
import { HighStack, StyledAddProjectCard, StyledText } from './AddCard.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';

export interface AddProjectCardProps extends BaseProps, ChildrenProp {
  onClick?: React.MouseEventHandler;
}

export const AddCard = React.forwardRef((props: AddProjectCardProps, ref: React.Ref<HTMLDivElement>) => {
  const { children, ...rest } = props;
  return (
    <StyledAddProjectCard {...rest} ref={ref}>
      <HighStack justify={'center'} align={'center'}>
        <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
          <path d="M25 0V50M0 25H25H50" />
        </svg>
        <StyledText>{children}</StyledText>
      </HighStack>
    </StyledAddProjectCard>
  );
});
