import { BaseProps, IconButton, Tooltip } from '@innobrix/components';
import * as React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useEffect, useState } from 'react';
import { IoCopySharp } from 'react-icons/io5';
import { StyledAccessTokenCard, StyledOverlay, StyledSpanEllipsis } from './AccessTokenCard.styled';
import { StyledFlexBox } from '../../../../../../libs/components/src/lib/components/layout/FlexBox.styled';

type Props = BaseProps & {
  token: string;
};

export const AccessTokenCard = ({ token, ...rest }: Props) => {
  const [copied, setCopied] = useState(false);

  // Clear copied state after 1 second
  useEffect(() => {
    if (!copied) return;

    const timer = setTimeout(() => {
      setCopied(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [copied]);

  return (
    <StyledAccessTokenCard {...rest}>
      <StyledFlexBox justify={'space-between'} justify-content={'bottom'}>
        <b>Access Token:</b>
        <CopyToClipboard text={`Bearer ${token}`} onCopy={() => setCopied(true)}>
          <Tooltip mouseEnterDelay={1} placement={'bottom'} overlay={'Copy to clipboard'}>
            <IconButton>
              <IoCopySharp />
            </IconButton>
          </Tooltip>
        </CopyToClipboard>
      </StyledFlexBox>
      <StyledSpanEllipsis>Bearer {token}</StyledSpanEllipsis>
      <StyledOverlay copied={copied} {...rest} />
    </StyledAccessTokenCard>
  );
};
