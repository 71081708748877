import * as React from 'react';
import { StyledPrice, StyledPriceListItem } from './PriceListItem.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { Price } from '../Price';

interface PriceListItemProps extends BaseProps, ChildrenProp {
  price?: number;
}

const PriceListItem = React.forwardRef<HTMLDivElement, PriceListItemProps>(({ children, price = 0, ...rest }, ref) => (
  <StyledPriceListItem {...rest} ref={ref}>
    <span>{children}</span>
    <StyledPrice>
      <Price value={price} hideFractions />
    </StyledPrice>
  </StyledPriceListItem>
));

export { PriceListItem, PriceListItemProps, StyledPriceListItem };
