import * as React from 'react';
import { ReactElement, useCallback, useState } from 'react';
import {
  StyledCaret,
  StyledHeader,
  StyledHeaderText,
  StyledIcon,
  StyledTreeNode,
  StyledVisToggle,
} from './TreeNode.styled';
import Collapse from '../Collapse';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { IconType } from 'react-icons';
import { ConnectDropTarget } from 'react-dnd/lib/interfaces';
import { ContextMenu } from '../contextmenu/ContextMenu';
import { useDebounce } from 'react-use';

export interface NodeType {
  id: string;
  type: string;
}

export interface TreeNodeBaseProps<T = NodeType> extends BaseProps {
  childNodes?: TreeNodeBaseProps<T>[];
  openByDefault?: boolean;
  disabled?: boolean;
  active?: boolean;
  invisible?: boolean;
  icon?: IconType;
  id: string;
  label: string;
  data?: T;
}

export interface TreeNodeProps<T> extends TreeNodeBaseProps<T>, BaseProps, ChildrenProp {
  onClick?: (node: TreeNodeBaseProps<T>, event: React.MouseEvent<HTMLElement>) => void;
  onDoubleClick?: (node: TreeNodeBaseProps<T>, event: React.MouseEvent<HTMLElement>) => void;
  onMouseEnter?: (node: TreeNodeBaseProps<T>, event: React.MouseEvent<HTMLElement>) => void;
  onMouseLeave?: (node: TreeNodeBaseProps<T>, event: React.MouseEvent<HTMLElement>) => void;
  onCollapse?: (node: TreeNodeBaseProps<T>, event: React.MouseEvent<HTMLElement>) => void;
  onExpand?: (node: TreeNodeBaseProps<T>, event: React.MouseEvent<HTMLElement>) => void;
  onVisibilityToggleClick?: (node: TreeNodeBaseProps<T>, event: React.MouseEvent<HTMLElement>) => void;
  dropRef?: ConnectDropTarget;
  dragRef?: any;
  dropzone?: any;
  contextMenuItems?: React.ReactNode;
  onShowContextMenu?: () => void;
}

export const TreeNode: <T>(props: TreeNodeProps<T>) => ReactElement<TreeNodeProps<T>> = (props) => {
  const {
    label,
    disabled,
    children,
    id,
    icon,
    active,
    invisible,
    onClick,
    contextMenuItems,
    onShowContextMenu,
    onDoubleClick,
    onMouseEnter,
    onMouseLeave,
    onCollapse,
    onExpand,
    onVisibilityToggleClick,
    data,
    dragRef,
    dropRef,
    dropzone,
    ...rest
  } = props;

  const [isOpen, setIsOpen] = useState(props.openByDefault ?? false);
  const [count, setCount] = useState(0);
  const [clickEvt, setClickEvt] = useState<React.MouseEvent<HTMLElement> | undefined>();

  const doOnClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (children && !isOpen) setIsOpen(true);
      onClick?.(props, event);
    },
    [children, isOpen, onClick, props]
  );

  /**
   * Single click debounce function. Always reset after 175ms, unless event consumed in single click.
   */
  useDebounce(
    () => {
      if (clickEvt && count === 1) {
        doOnClick(clickEvt);
        setClickEvt(undefined);
      }
      setCount(0);
    },
    175,
    [clickEvt, setClickEvt, count]
  );

  const header = (
    <StyledHeader
      align={'center'}
      active={active}
      invisible={invisible}
      dropzone={dropzone}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        if (dropzone) return;
        // If no double click handler registered, or not active, relegate to onClick directly.'
        // First click on the item will respond directly.
        if (!active || onDoubleClick == null) {
          if (count === 0) {
            doOnClick(e);
            setCount(1);
          }
          return;
        }

        // If it was a double click, invoke it. Single click event won't fire as count resets to 0.
        if (count + 1 >= 2) {
          onDoubleClick?.(props, e);
          setCount(0);
        }
        // If not, start debounce. Calls onClick after [ms] of inactivity, as well as resetting count, unless double click
        else {
          setCount(count + 1);
          setClickEvt(e);
        }
      }}
      onMouseEnter={(e: React.MouseEvent<HTMLElement>) => !dropzone && onMouseEnter && onMouseEnter(props, e)}
      onMouseLeave={(e: React.MouseEvent<HTMLElement>) => !dropzone && onMouseLeave && onMouseLeave(props, e)}
      onContextMenu={(e: React.MouseEvent<HTMLElement>) => {
        contextMenuItems == null && e.preventDefault();
      }}
      ref={dragRef}
    >
      <StyledCaret
        hidden={!children}
        isOpen={isOpen}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          if (!dropzone) {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }
        }}
      />
      {icon && <StyledIcon hidden={children != null} icon={icon} />}
      <StyledHeaderText>{label}</StyledHeaderText>
      <StyledVisToggle
        icon={invisible ? FaEyeSlash : FaEye}
        active={active}
        invisible={invisible}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          if (!dropzone) {
            event.stopPropagation();
            // ignore the rule as this is a bug in eslint (https://github.com/facebook/create-react-app/issues/8107)
            // eslint-disable-next-line no-unused-expressions
            onVisibilityToggleClick?.(props, event);
          }
        }}
      />
    </StyledHeader>
  );

  return (
    <StyledTreeNode key={id} {...rest}>
      <div ref={dropRef}>
        <div ref={dragRef}>{header}</div>
      </div>
      {children && <Collapse isOpen={isOpen}>{children}</Collapse>}
    </StyledTreeNode>
  );
};
