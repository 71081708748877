import styled, { css } from 'styled-components';
import { Radio } from './Radio';
import { Stack } from '../layout/Stack';
import { Checkdot, StyledLabel } from './Radio.styled';
import { StyledHeading } from '../typo/Heading.styled';

export const StyledRadio = styled(Radio)(
  ({ theme }) =>
    css`
      background-color: ${theme.colors.neutral.xxlight};
      border-radius: ${theme.borderRadius.small};
      box-shadow: ${theme.elevation.xlight};
      user-select: none;
      grid-column: unset;

      transition: ${theme.transition.slow};
      transition-property: box-shadow, transform;

      :hover {
        box-shadow: ${theme.elevation.medium};
        transform: translateY(-0.125em);
      }

      :focus-within {
        box-shadow: 0 0 0 ${theme.spacing.xsmall} ${theme.colors.primary.xxlight};
        transform: none;
      }

      :active {
        box-shadow: none;
        transform: none;

        input:not(:disabled) + ${StyledLabel} ${Checkdot} {
          box-shadow: none;
        }
      }

      ${StyledLabel} {
        flex: 1;
        font-size: 0.5rem;
        padding: ${theme.spacing.medium} ${theme.spacing.large};
        cursor: pointer;

        ${Checkdot} {
          margin: 0 ${theme.spacing.medium} 0 ${theme.spacing.small};
          font-size: 1rem;
        }
      }
    `
);

export const TextContainer = styled(Stack)`
  font-size: 1rem;

  ${StyledHeading} {
    margin: 0;
  }
`;
