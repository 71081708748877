import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';
import { PriceListPrice } from './PriceListFooter.styled';

export const StyledPriceListSubtotal = styled.div(
  ({ theme }) => css`
    padding: ${spacing.s4} ${spacing.s16} ${spacing.s12};
    background: linear-gradient(90deg, #f8f9fb 70%, ${theme.colors.neutral.white} 100%);

    ${PriceListPrice} {
      font-size: 1rem;
    }
  `
);
