import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';
import { Flyout } from './Flyout';

export const StyledFlyoutHeader = styled.div(
  ({ theme }) => css`
    width: 100%;
    background-color: ${theme.colors.neutral.xxxlight};
    border-bottom: 2px solid ${theme.colors.neutral.xxlight};
    border-radius: ${theme.borderRadius.medium} ${theme.borderRadius.medium} 0 0;

    padding: ${theme.spacing.medium} ${theme.spacing.medium} ${theme.spacing.small};
  `
);

export const FlyoutHeaderContent = styled.div<{ hasLock: boolean }>(
  ({ theme, hasLock }) => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: ${hasLock && spacing.s24};

    > :not(:last-child) {
      margin-right: ${spacing.s16};
    }
  `
);

export const StyledFlyout = styled(Flyout)(
  ({ theme }) => css`
    && {
      --bg: ${theme.colors.neutral.white};

      .tip-arrow {
        --bg: ${theme.colors.neutral.xxxlight};
      }
    }

    &.tip .tip-inner {
      padding: 0;
    }
  `
);
