import * as React from 'react';
import { useState } from 'react';
import { StyledActionbar, ActionbarItem, ActionbarIconButtonRadio } from './Actionbar.styled';
import { BaseProps } from '../../interfaces/BaseProps';
import { IconButtonProps } from '../IconButton';

type ActionBarProps = BaseProps & {
  children?: React.ReactElement<IconButtonProps>[];
  vertical?: boolean;

  defaultValue?: number;
  // Value of the active item. -1 for no active item.
  value?: number;
  onChange?: (value: number) => void;
};

const Actionbar = React.forwardRef((props: ActionBarProps, ref: React.Ref<HTMLDivElement>) => {
  const { children, value, defaultValue, onChange, ...rest } = props;
  const isControlled: boolean = value != null;

  const [activeIndex, setActiveIndex] = useState<number>(
    defaultValue != null ? Math.min(React.Children.count(children) - 1, defaultValue) : -1
  );

  return (
    <StyledActionbar
      value={value == null ? activeIndex : value < 0 ? null : value}
      maxValue={React.Children.count(children) - 1}
      {...rest}
      ref={ref}
    >
      {children &&
        React.Children.map(children, (child, index) => {
          const newChild = React.cloneElement(child, {
            onClick: () => {
              onChange && onChange(index);
              setActiveIndex(index);
            },
          });
          return (
            <ActionbarIconButtonRadio
              onChange={(newIndex: number) => {
                onChange && onChange(newIndex);
                setActiveIndex(newIndex);
              }}
              index={index}
              active={isControlled ? value === index : activeIndex === index}
            >
              {newChild}
            </ActionbarIconButtonRadio>
          );
        })}
    </StyledActionbar>
  );
});

export { Actionbar, ActionbarItem, ActionBarProps };
