import * as React from 'react';
import { StyledDocumentUploading } from './DocumentUploading.styled';
import { BaseProps } from '../../../interfaces/BaseProps';
import { SpinnerIcon } from '../../SpinnerIcon';
import { FaCircleNotch } from 'react-icons/fa';
import { Span } from '../../typo/Span';
import { FormattedMessage } from 'react-intl';

interface DocumentUploadingProps extends BaseProps {
  filename: string;
}

const DocumentUploading = React.forwardRef<HTMLDivElement, DocumentUploadingProps>(({ filename, ...rest }, ref) => {
  return (
    <StyledDocumentUploading {...rest} ref={ref}>
      <SpinnerIcon icon={FaCircleNotch} />
      <Span>
        <b>{filename}</b> <FormattedMessage id={'chat.documents.uploading'} />
      </Span>
    </StyledDocumentUploading>
  );
});

export { DocumentUploading, DocumentUploadingProps };
