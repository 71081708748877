import styled, { css } from 'styled-components';
import { IconButton } from '../../IconButton';
import { spacing } from '../../../variables/spacing';

export const StyledChatHeader = styled.header`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: ${spacing.s4};
`;

export const ChatHeaderBackArrow = styled(IconButton)(
  ({ theme }) => css`
    color: ${theme.colors.neutral.medium};
  `
);
