import { ThemeContext } from 'styled-components';
import { useContext } from 'react';

export const useSeverityColorMap = () => {
  const theme = useContext(ThemeContext);
  return {
    error: theme.colors.error,
    warning: theme.colors.warning,
    success: theme.colors.success,
    message: theme.colors.neutral,
  };
};
