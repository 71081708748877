import * as React from 'react';
import type { BaseProps } from '../../interfaces/BaseProps';
import { Avatar } from '../atoms';
import type { AvatarProps } from '../atoms/Avatar';
import { Logo } from '../logo/Logo';
import { StyledNavigation } from './Navigation.styled';

type Props = React.PropsWithChildren<BaseProps> & {
  avatarSrc?: AvatarProps['src'];
};

const Navigation = React.forwardRef<HTMLDivElement, Props>(({ children, avatarSrc, ...rest }, ref) => {
  return (
    <StyledNavigation {...rest} ref={ref}>
      <Logo />

      <nav>{children}</nav>

      {avatarSrc != null && <Avatar size={'small'} src={avatarSrc} />}
    </StyledNavigation>
  );
});

export { Navigation };
