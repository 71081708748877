import * as React from 'react';
import { StyledImage } from './Image.styled';

export type Props = React.ImgHTMLAttributes<HTMLImageElement> & {
  src: string;
};

export const Image = React.forwardRef<HTMLImageElement, Props>(({ loading = 'lazy', ...rest }, ref) => (
  <StyledImage {...rest} ref={ref} loading={loading} />
));
