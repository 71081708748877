import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';
import { IconButton } from '../IconButton';
import { Button } from '../Button';
import { borderRadius } from '../../variables/borderRadius';

export type StepStatus = 'todo' | 'progress' | 'done';

export const StyledWizard = styled.div(
  ({ theme }) => css`
    display: inline-flex;
    align-items: center;

    background-color: ${theme.colors.neutral.xxlight};
    border-radius: 2rem;
    padding: ${spacing.s4};
  `
);

export const NavButton = styled(IconButton)(
  ({ theme }) => css`
    transition: ${theme.transition.slow};
    transition-property: color;

    :disabled {
      color: ${theme.colors.neutral.light};
    }
  `
);

export const FinishButton = styled(Button).attrs({ small: true })(
  () => css`
    margin-left: ${spacing.s8};
    padding-right: 1.25rem;
    border-radius: ${borderRadius.r8} 2rem 2rem ${borderRadius.r8};
  `
);

export const Steps = styled.ol(
  ({ theme }) => css`
    list-style-type: none;

    display: flex;
    align-items: center;
  `
);

export const Step = styled.li<{ $status?: StepStatus; disabled?: boolean }>(
  ({ theme, $status }) => css`
    display: flex;
    align-items: center;
    place-content: center;

    line-height: 1;
    padding: ${$status === 'progress' ? `${spacing.s8} ${spacing.s12}` : spacing.s8};
   /* cursor: ${$status !== 'todo' ? 'pointer' : 'not-allowed'};*/
   cursor: pointer;

    border-radius: 2rem;
    color: ${theme.colors.text.light};
    background-color: ${
      $status === 'done'
        ? theme.colors.success.default
        : $status === 'progress'
        ? theme.colors.primary.default
        : theme.colors.neutral.light
    };

    transition: ${theme.transition.slow};
    transition-property: color, background-color;

    &[disabled] {
      cursor: not-allowed;
    }

    :hover:not([disabled]) {
      background-color: ${
        $status === 'done'
          ? theme.colors.success.dark
          : $status === 'progress'
          ? theme.colors.primary.dark
          : theme.colors.neutral.medium
      };
    }

    // Dash
    :not(:last-child) {
      --dash-size: 0.5rem;

      position: relative;
      margin-right: var(--dash-size, 0);

      ::before {
        content: '';
        width: var(--dash-size, 0);
        height: 0.125rem;
        position: absolute;
        left: 100%;
        background-color: inherit;
      }
    }

    // Children
    ${
      $status !== 'progress'
        ? css`
            > span {
              display: none;
            }
          `
        : css`
            > :not(:last-child) {
              margin-right: ${spacing.s4};
            }
          `
    };
  `
);
