import * as React from 'react';
import { Header, HeaderButton, NotificationStack, StyledNotificationPanel } from './NotificationPanel.styled';
import { NoNotifications } from '../graphics/Svgs';
import { FormattedMessage } from 'react-intl';
import { BaseProps } from '../../interfaces/BaseProps';
import { Heading } from '../typo/Heading';

interface NotificationPanelProps extends BaseProps {
  children: React.ReactElement[] | undefined;
  onClear?: React.MouseEventHandler;
}

const NotificationPanel = React.forwardRef((props: NotificationPanelProps, ref: React.Ref<HTMLDivElement>) => {
  const { children, onClear, ...rest } = props;
  const isEmpty = !children?.length ?? true;

  return (
    <StyledNotificationPanel {...rest} ref={ref}>
      <Header>
        <span>
          <FormattedMessage id={'general.notifications'} defaultMessage={'Notifications'} />
        </span>

        <HeaderButton onClick={onClear} disabled={isEmpty}>
          <FormattedMessage id={'general.clear'} defaultMessage={'Clear'} />
        </HeaderButton>
      </Header>

      <NotificationStack isEmpty={isEmpty}>
        {isEmpty ? (
          <>
            <NoNotifications />
            <Heading level={6} span>
              <FormattedMessage id={'general.notifications.none'} defaultMessage={'You have no more notifications'} />
            </Heading>
          </>
        ) : (
          children
        )}
      </NotificationStack>
    </StyledNotificationPanel>
  );
});

export { NotificationPanel, NotificationPanelProps };
