import * as React from 'react';
import { StyledCarouselTabs } from './CarouselTabs.styled';
import { BaseProps } from '../../interfaces/BaseProps';
import { RadioGroupProps } from '../form/RadioGroup';
import { useCarousel } from './CarouselProvider';
import { FlexBox } from '../layout/FlexBox';
import { useCallback, useMemo } from 'react';

interface CarouselTabsProps extends BaseProps, Pick<RadioGroupProps, 'children'> {}

const CarouselTabs = React.forwardRef<HTMLDivElement, CarouselTabsProps>(({ children, ...rest }, ref) => {
  const { currentIndex, goToIndex, count } = useCarousel();

  const handleChange = useCallback(
    (newIndex: number) => {
      goToIndex(newIndex);
    },
    [goToIndex]
  );

  // Round the index so it doesn't overshoot.
  const value = useMemo(() => Math.round(currentIndex), [currentIndex]);

  if (React.Children.count(children) !== count)
    console.warn("The amount of children in the tabs and carousel isn't the same.");

  return (
    <FlexBox justify={'center'} {...rest} ref={ref}>
      <StyledCarouselTabs value={value} onChange={handleChange}>
        {children}
      </StyledCarouselTabs>
    </FlexBox>
  );
});

export { CarouselTabs, CarouselTabsProps };
