import styled, { css } from 'styled-components';
import hexagons from '../../../../../shared/assets/src/lib/images/repeating-hexagons.svg';
import { borderRadius } from '../../variables/borderRadius';
import { spacing } from '../../variables/spacing';

export const StyledChatNoSelection = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    padding: ${spacing.s16};
    place-content: center;
    align-items: center;

    background: url(${hexagons}) repeat;
    border-radius: ${borderRadius.r8};
    box-shadow: ${theme.elevation.medium};

    > :not(:last-child) {
      margin-bottom: ${spacing.s24};
    }
  `
);
