import * as React from 'react';
import { AnchorHTMLAttributes } from 'react';
import { BaseProps } from '../interfaces/BaseProps';
import { StyledAnchor, StyledLink } from './Anchor.styled';
import { LinkProps } from 'react-router-dom';
import { XOR } from 'ts-essentials/dist/types';

interface RouteAnchorProps extends BaseProps, Omit<LinkProps, 'to'> {
  to: string;
}
interface HtmlAnchorProps extends BaseProps, Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
  href: string;
}

type Props = XOR<RouteAnchorProps, HtmlAnchorProps>;

const Anchor = React.forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  // either to or href is defined, neither can be undefined
  const { children, to = '', href = '', target, rel = target === '_blank' ? 'noopener' : undefined, ...rest } = props;

  return 'to' in props ? (
    <StyledLink {...rest} to={to} target={target} rel={rel} ref={ref}>
      {children}
    </StyledLink>
  ) : (
    <StyledAnchor {...rest} href={href} target={target} rel={rel} ref={ref}>
      {children}
    </StyledAnchor>
  );
});

export { Anchor, Props as AnchorProps };
