import styled from 'styled-components';
import { spacing } from '../../../variables/spacing';
import { breakpoints } from '../../../variables/breakpoints';
import { Stack } from '../../layout/Stack';

export const StyledDocumentsList = styled.aside``;

export const DocumentsListHeader = styled.header`
  // don't display the header on small screens
  display: none;
  flex-direction: column;
  text-align: center;
  padding: ${spacing.s24} 0 ${spacing.s4};

  > :not(:last-child) {
    margin-bottom: ${spacing.s32};
  }

  @media screen and ${breakpoints.laptop} {
    display: flex;
  }
`;

export const DocumentsListHeaderUser = styled(Stack)`
  text-align: center;
`;
