import * as React from 'react';
import { StyledOptionList, OptionScrollBox } from './OptionList.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { useContext } from 'react';

interface OptionListValues {
  editVisibility?: boolean;
}

export const OptionListContext = React.createContext<OptionListValues>({});
export const useOptionList = () => useContext(OptionListContext);

interface OptionListProps extends BaseProps, ChildrenProp, OptionListValues {}
const OptionList = React.forwardRef<HTMLDivElement, OptionListProps>(({ children, editVisibility, ...rest }, ref) => {
  return (
    <StyledOptionList {...rest} ref={ref}>
      <OptionListContext.Provider value={{ editVisibility }}>{children}</OptionListContext.Provider>
    </StyledOptionList>
  );
});

export { OptionList, OptionListProps, OptionScrollBox };
export { OptionCarouselArrows } from './OptionList.styled';
