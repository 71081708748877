import { fontSize } from '@innobrix/components';
import styled, { css, keyframes } from 'styled-components';

const pulse = keyframes`
  from {
    color: var(--from-color);
    transform: scale(1);
  }

  to {
    color: var(--to-color);
    transform: scale(1.2);
  }
`;

export const FetchErrorBubbleStyled = styled.div(
  ({ theme }) => css`
    --from-color: ${theme.colors.error.dark};
    --to-color: ${theme.colors.error.default};

    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: ${fontSize.s24};
    color: var(--from-color);
    animation: ${pulse} 1.5s infinite alternate ease-in-out;
  `
);
