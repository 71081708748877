import { SearchProps } from './Search';
import styled, { css } from 'styled-components';
import { TextInput } from './TextInput';
import { IconWrapper, StyledTextInput } from './TextInput.styled';

export const StyledSearch = styled(TextInput)<SearchProps>(
  ({ theme }) => css`
    > ${IconWrapper} {
      flex: 0 0 auto;
    }

    > ${IconWrapper}:first-child {
      background: none;
      color: ${theme.colors.neutral.medium};
    }

    ${StyledTextInput} {
      padding-left: 0;
      flex: 1 1 auto;
      min-width: 0;

      -webkit-appearance: none;
      -webkit-box-sizing: content-box;

      ::-webkit-search-decoration,
      ::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }

      // The clear button
      :placeholder-shown + ${IconWrapper}, :disabled + ${IconWrapper} {
        opacity: 0;
        pointer-events: none;
      }

      + ${IconWrapper} {
        user-select: none;
        -webkit-user-select: none;

        transition: ${theme.transition.default};
        transition-property: opacity;
      }
    }
  `
);
