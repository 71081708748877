import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';
import { weight } from '../../variables/typography';

export const StyledPriceListItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-weight: ${weight.light};

  > :not(:last-child) {
    margin-right: ${spacing.s4};
  }
`;

export const StyledPrice = styled.span(
  ({ theme }) => css`
    font-weight: ${weight.regular};
  `
);
