import * as React from 'react';
import { ReactNode } from 'react';
import { Heading } from '../typo/Heading';
import { StyledRadio, TextContainer } from './RadioOption.styled';
import { CheckboxProps } from './Checkbox';
import { Span } from '../typo/Span';

export interface RadioOptionProps extends CheckboxProps {
  heading: ReactNode;
}

export const RadioOption = React.forwardRef((props: RadioOptionProps, ref: React.Ref<HTMLInputElement>) => {
  const { children, heading, ...rest } = props;

  return (
    <StyledRadio {...rest} ref={ref}>
      <TextContainer justify={'center'}>
        <Heading level={6}>{heading}</Heading>
        <Span size={'small'}>{children}</Span>
      </TextContainer>
    </StyledRadio>
  );
});
