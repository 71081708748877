import * as React from 'react';
import { FormattedDate, FormattedRelativeTime } from 'react-intl';
import { differenceInSeconds, isBefore, subMonths } from 'date-fns';

type RelativeTimeLabelProps = {
  value: Date;
};

const RelativeTimeLabel = ({ value }: RelativeTimeLabelProps) => {
  const difference = differenceInSeconds(value, new Date());

  const oneMonthAgo = subMonths(new Date(), 1);
  const isLongerThanOneMonthAgo = isBefore(value, oneMonthAgo);

  if (isLongerThanOneMonthAgo) return <FormattedDate value={value} day={'numeric'} month={'short'} year={'numeric'} />;
  return <FormattedRelativeTime value={difference} numeric={'auto'} updateIntervalInSeconds={60} />;
};

export { RelativeTimeLabel, RelativeTimeLabelProps };
