import * as React from 'react';
import { StyledContent, StyledHintIcon, StyledTutorialHint } from './TutorialHint.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { IconType } from 'react-icons';
import { useSpring } from '@react-spring/web';

interface TutorialHintProps extends BaseProps, ChildrenProp {
  icon: IconType;
}

const TutorialHint = React.forwardRef<HTMLDivElement, TutorialHintProps>(({ children, icon, ...rest }, ref) => {
  const anim = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  });

  return (
    <StyledTutorialHint {...rest} ref={ref} style={anim}>
      <StyledHintIcon icon={icon} />
      <StyledContent>{children}</StyledContent>
    </StyledTutorialHint>
  );
});

export { TutorialHint, TutorialHintProps };
