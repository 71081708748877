import styled, { css } from 'styled-components';

export const StyledOptionTabs = styled.div(
  ({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;
  `
);
