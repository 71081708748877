import { FlexBox, Span } from '@innobrix/components';
import * as React from 'react';
import { PropsWithChildren } from 'react';
import { CSSProperties } from 'styled-components';
import { Gauge, MilestoneGaugeStyled, StatLabel, TextWrapper } from './MilestoneGauge.styled';

type Props = {
  stat: number;
  milestone: number;
};

export const MilestoneGauge = ({ stat, milestone, children, ...rest }: PropsWithChildren<Props>) => {
  const progress = Math.min(stat / milestone, 1);

  return (
    <MilestoneGaugeStyled {...rest}>
      <Gauge style={{ '--progress': progress } as CSSProperties} />
      <TextWrapper>
        <FlexBox align={'center'} gutter={'xsmall'}>
          <StatLabel stat={stat} />
          <Span light>of</Span>
          <StatLabel stat={milestone} />
        </FlexBox>
        <div>{children}</div>
      </TextWrapper>
    </MilestoneGaugeStyled>
  );
};
