import styled, { css } from 'styled-components';

export const StyledImageCropper = styled.div(
  ({ theme }) => css`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${theme.colors.neutral.white};
    border-radius: ${theme.borderRadius.medium};
    box-shadow: ${theme.elevation.large};
    overflow: hidden;

    width: 700px;
    height: 500px;
    z-index: 210;
  `
);

export const StyledInner = styled.div(
  ({ theme }) => css`
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr auto;
    grid-template-areas:
      'image image'
      'footer sidebar';
  `
);

export const StyledCropper = styled.div`
  grid-area: image;
  position: relative;

  .reactEasyCrop_Container {
  }
`;

export const Sidebar = styled.aside(
  ({ theme }) => css`
    grid-area: sidebar;
    min-width: 100px;

    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: ${theme.spacing.medium};
  `
);

export const Footer = styled.footer(
  ({ theme }) => css`
    grid-area: footer;
    padding: ${theme.spacing.medium} 5rem;
    display: flex;
    align-items: center;
    width: 100%;

    > div {
      flex: 1;
    }

    > svg:last-of-type {
      font-size: 1.5em;
    }

    > :not(:last-child) {
      margin-right: ${theme.spacing.medium};
    }
  `
);
