import * as React from 'react';
import { AbsoluteBadge, StyledBadgeContainer } from './CountBadgeContainer.styled';
import { NotificationBadge } from './NotificationBadge';
import { spacing } from '../../variables/spacing';

type Props = React.ComponentProps<typeof NotificationBadge> & {
  padding?: 'none' | keyof typeof spacing;
};

const CountBadgeContainer = React.forwardRef<HTMLDivElement, Props>(
  ({ padding, className, children, ...rest }, ref) => {
    return (
      <StyledBadgeContainer className={className} ref={ref}>
        <AbsoluteBadge {...rest} $padding={padding} />
        {children}
      </StyledBadgeContainer>
    );
  }
);

export { CountBadgeContainer };
