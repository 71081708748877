import { IconType } from 'react-icons';
import {
  FaArchive,
  FaBed,
  FaBlender,
  FaCar,
  FaChalkboard,
  FaChild,
  FaCouch,
  FaDumpster,
  FaEllipsisH,
  FaLeaf,
  FaLightbulb,
  FaPagelines,
  FaTrafficLight,
  FaUmbrellaBeach,
  FaUtensils,
} from 'react-icons/fa';

export const decorationIconMap: { [key: string]: IconType } = {
  // Furniture
  'All furniture': FaArchive,
  Woonkamer: FaCouch,
  Eetkamer: FaUtensils,
  Slaapkamer: FaBed,
  Kids: FaChild,
  Verlichting: FaLightbulb,
  Planten: FaLeaf,
  Overig: FaEllipsisH,

  // Decorations
  'All decorations': FaArchive,
  Fences: FaChalkboard,
  Foliage: FaPagelines,
  Garden: FaUmbrellaBeach,
  Miscellaneous: FaDumpster,
  'Street lights': FaLightbulb,
  'Street signs': FaTrafficLight,
  Transportation: FaCar,

  // Custom
  'Voortman keukens': FaBlender,
};
