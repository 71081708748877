import styled, { css } from 'styled-components';
import { FlexBox } from '../layout/FlexBox';
import { StyledShortcutLabel } from './ShortcutLabel.styled';

export const StyledLabelIcon = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 0 1.5em;
  position: relative;

  > svg {
    position: absolute;
    left: 0;
  }
`;

export const StyledContextMenuLabel = styled(FlexBox)(
  ({ theme }) => css`
    padding: 0 ${theme.spacing.large} 0 var(--pad);

    ${StyledShortcutLabel} {
      margin-left: 1em;
    }
  `
);
