import * as React from 'react';
import { useCallback, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { ButtonProps } from '../Button';
import { Span } from '../typo/Span';
import { Icon } from '../Icon';
import ReactTooltip from 'rc-tooltip';
import { FormattedMessage } from 'react-intl';
import { FlyoutProps } from '../flyout/Flyout';
import { StyledActionsButton, StyledActionsDropdown, StyledFlyoutWrapper } from './ActionsDropdown.styled';
import { useMeasure } from 'react-use';

type Props = Omit<FlyoutProps, 'flyout' | 'visible' | 'onVisibleChange'> & Pick<ButtonProps, 'disabled'>;

const ActionsDropdown = React.forwardRef<typeof ReactTooltip, Props>(
  ({ disabled, placement = 'bottomRight', children, ...rest }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [sizeRef, { width }] = useMeasure<HTMLButtonElement>();

    const handleClick = useCallback((e: React.MouseEvent) => {
      setIsOpen(false);
    }, []);

    return (
      <StyledActionsDropdown
        visible={isOpen}
        onVisibleChange={setIsOpen}
        flyout={
          <StyledFlyoutWrapper onClick={handleClick} style={{ minWidth: `${width + 32}px` }}>
            {children}
          </StyledFlyoutWrapper>
        }
        placement={placement}
        {...rest}
        ref={ref}
      >
        <StyledActionsButton variant={'secondary'} ref={sizeRef} disabled={disabled}>
          <Span>
            <FormattedMessage id={'general.actions'} />
          </Span>
          <Icon icon={FaChevronDown} />
        </StyledActionsButton>
      </StyledActionsDropdown>
    );
  }
);

export { ActionsDropdown };
