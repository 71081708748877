import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';
import { Flyout } from '../flyout/Flyout';
import { borderRadius } from '../../variables/borderRadius';

export const StyledDropdownFlyout = styled(Flyout)(
  ({ theme }) => css`
    && {
      --bg: ${theme.colors.neutral.xxxlight};
    }

    &.tip {
      --arrowOffset: -${spacing.s4};

      .tip-inner {
        border-radius: ${borderRadius.r4};

        max-height: 66vh;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }

    .tip-arrow {
      display: none;
    }
  `
);
