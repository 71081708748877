import { Area } from 'react-easy-crop/types';

function createImage(url: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });
}

async function getBlobFromCanvas(canvas: HTMLCanvasElement): Promise<Blob | null> {
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(file);
    }, 'image/png');
  });
}

export async function cropImage(
  imageSrc: string,
  pixelCrop: Area,
  outputWidth?: number,
  outputHeight?: number
): Promise<string> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (ctx == null) return '';

  canvas.width = image.naturalWidth;
  canvas.height = image.naturalHeight;

  // draw image and store data.
  ctx.drawImage(image, 0, 0);
  const data = ctx.getImageData(0, 0, image.width, image.height);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(data, 0 - pixelCrop.x, 0 - pixelCrop.y);

  if (outputWidth != null) {
    // If not set calculate ratio corrected height
    outputHeight = outputHeight ?? outputWidth * (pixelCrop.height / pixelCrop.width);

    // Resize to output size
    const resizeCtx = document.createElement('canvas').getContext('2d');
    if (!resizeCtx) return '';
    resizeCtx.canvas.width = pixelCrop.width;
    resizeCtx.canvas.height = pixelCrop.height;

    canvas.width = outputWidth;
    canvas.height = outputHeight;

    resizeCtx.putImageData(data, 0 - pixelCrop.x, 0 - pixelCrop.y);

    ctx.scale(outputWidth / pixelCrop.width, outputHeight / pixelCrop.height);
    ctx.drawImage(resizeCtx.canvas, 0, 0);
  }

  // Return a blob
  const blob = await getBlobFromCanvas(canvas);
  return blob == null ? '' : URL.createObjectURL(blob);
}
