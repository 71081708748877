import * as React from 'react';
import { ButtonList, DatePickerContainer, PopperContainer, StyledDatePicker } from './DatePicker.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import ReactDatePicker from 'react-datepicker';
import { Span } from '../typo/Span';
import addDays from 'date-fns/addDays';
import { endOfMonth, endOfYear, startOfMonth, startOfYear } from 'date-fns';
import { SyntheticEvent, useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

//region Types
type ReactDatePickerProps = React.ComponentProps<typeof ReactDatePicker>;
type DateRangeValue = [Date, Date];

interface DatePickerProps
  extends BaseProps,
    ChildrenProp,
    Omit<ReactDatePickerProps, 'startDate' | 'endDate' | 'onChange' | 'children' | 'customInput' | 'customInputRef'> {
  startDate?: Date;
  endDate?: Date;

  onChange: (values: DateRangeValue, event?: SyntheticEvent) => void;

  children: (props: Omit<DatePickerProps, 'children'>) => React.ReactNode;
}

type ContainerProps = {
  // inherited
  className?: string;
  children: React.ReactNode[];
  showPopperArrow?: boolean;
  arrowProps?: {
    //
  };
  // extra
  onChange: (values: DateRangeValue, event?: SyntheticEvent, close?: boolean) => void;
};
//endregion

const MyCalendarContainer = ({ children, className, onChange }: ContainerProps) => {
  // This date will be calculated at render, this means if the DatePicker is open for a while (> 1 day)
  //  it will return the wrong date, but when would this really happen?
  const [now] = useState(() => new Date());

  return (
    <PopperContainer className={className}>
      <ButtonList>
        <li>
          <Span
            onClick={() => {
              onChange([now, now], undefined, true);
            }}
          >
            <FormattedMessage id={'general.date.today'} />
          </Span>
        </li>
        <li>
          <Span
            onClick={() => {
              const yesterday = addDays(now, -1);
              onChange([yesterday, yesterday], undefined, true);
            }}
          >
            <FormattedMessage id={'general.date.yesterday'} />
          </Span>
        </li>
        <li>
          <Span
            onClick={() => {
              const past7days = addDays(now, -6);
              onChange([past7days, now], undefined, true);
            }}
          >
            <FormattedMessage id={'general.date.past-7-days'} />
          </Span>
        </li>
        <li>
          <Span
            onClick={() => {
              const past30days = addDays(now, -29);
              onChange([past30days, now], undefined, true);
            }}
          >
            <FormattedMessage id={'general.date.past-30-days'} />
          </Span>
        </li>
        <li>
          <Span
            onClick={() => {
              onChange([startOfMonth(now), endOfMonth(now)], undefined, true);
            }}
          >
            <FormattedMessage id={'general.date.this-month'} />
          </Span>
        </li>
        <li>
          <Span
            onClick={() => {
              onChange([startOfYear(now), endOfYear(now)], undefined, true);
            }}
          >
            <FormattedMessage id={'general.date.this-year'} />
          </Span>
        </li>
      </ButtonList>

      <DatePickerContainer>{children}</DatePickerContainer>
    </PopperContainer>
  );
};

const DatePicker = React.forwardRef<HTMLDivElement, DatePickerProps>(
  (
    {
      className,
      style,
      startDate,
      endDate,
      onChange,
      onCalendarClose,
      children,
      popperPlacement = 'auto-start',
      popperModifiers = {
        preventOverflow: {
          enabled: true,
        },
      },
      closeOnScroll = true,
      ...rest
    },
    ref
  ) => {
    const pickerRef = useRef<ReactDatePicker | null>(null);

    const handleChange = useCallback<ContainerProps['onChange']>(
      (values, event, close) => {
        onChange?.(values, event);

        if (close) pickerRef.current?.setOpen(false);
      },
      [onChange]
    );

    const handleClose = useCallback(() => {
      // If the picker was closed without choosing an endDate, set endDate to now
      if (startDate != null && endDate == null) onChange([startDate, new Date()]);

      onCalendarClose?.();
    }, [endDate, onCalendarClose, onChange, startDate]);

    return (
      <StyledDatePicker className={className} style={style} ref={ref}>
        <ReactDatePicker
          ref={pickerRef}
          // Popper
          popperPlacement={popperPlacement}
          popperModifiers={popperModifiers}
          {...rest}
          startDate={startDate}
          endDate={endDate}
          openToDate={startDate ?? new Date()}
          onChange={onChange}
          onCalendarClose={handleClose}
          selectsRange
          // Close logic
          closeOnScroll={closeOnScroll}
          shouldCloseOnSelect={startDate != null && endDate == null}
          // Header
          showMonthDropdown
          showYearDropdown
          // Style
          showPopperArrow={false}
          customInput={children({ startDate, endDate, onChange, ...rest })}
          calendarContainer={(props) => <MyCalendarContainer onChange={handleChange} {...props} />}
        />
      </StyledDatePicker>
    );
  }
);

export { DatePicker, DatePickerProps, DateRangeValue };
