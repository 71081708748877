import { BaseProps, ChildrenProp } from '../interfaces/BaseProps';
import { MouseEventProps } from '../interfaces/EventProps';

interface IconButtonProps<T = HTMLButtonElement> extends BaseProps, ChildrenProp, MouseEventProps<T> {
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large' | 'xlarge';
}

export { StyledIconButton as IconButton } from './IconButton.styled';
export { IconButtonProps };
