import * as React from 'react';
import { useCallback, useMemo } from 'react';
import {
  StyledAssetInput,
  StyledContainer,
  StyledContentPlaceholder,
  StyledMaterialPlaceholder,
} from './AssetInput.styled';
import { ImageBoxPlaceholder } from './ImageBox';
import { BaseProps } from '../../interfaces/BaseProps';
import { BaseInputProps } from '../../interfaces/InputProps';
import { DropOverlay } from './ImageInput.styled';
import { FaCompressArrowsAlt } from 'react-icons/fa';
import { useDrop } from 'react-dnd';
import { DragObjectWithType } from 'react-dnd/lib/interfaces';
import { DragType } from '@innobrix/definitions';
import { NativeTypes } from 'react-dnd-html5-backend';

// Content type
export enum ContentType {
  Thumbnail,
  Texture,
  Material,
  Decoration,
  Furniture,
  MassVolume,
  TextureVolume,
  ParcelMapModel,
  MassPreset,
  EstateType,
  Configurable,
  OptionList,
  MaterialSwitch,
}

interface AssetData extends DragObjectWithType {
  guid: string;
}

interface AssetInputProps extends BaseProps, Omit<BaseInputProps, 'onChange'> {
  contentType: ContentType;
  onChange: (value: string | File) => void;
  onClick?: React.MouseEventHandler;
}

interface IFileData {
  files: File[];
  type: string;
}

const AssetInput = React.forwardRef<HTMLDivElement, AssetInputProps>(
  ({ name, value, onChange, onBlur, onClick, contentType, ...rest }, ref) => {
    // Get accepted drop types
    const accept = [];
    switch (contentType) {
      case ContentType.Thumbnail:
        accept.push(NativeTypes.FILE);
        break;
      case ContentType.Texture:
        //accept.push(DragType.texture, NativeTypes.FILE);
        accept.push(DragType.texture);
        break;
      case ContentType.Material:
        accept.push(DragType.material);
        break;
      case ContentType.MassPreset:
        accept.push(DragType.mass);
        break;
      case ContentType.MassVolume:
        accept.push(DragType.massVolume);
        break;
      case ContentType.Configurable:
        accept.push(DragType.configurable);
        break;
      case ContentType.OptionList:
        accept.push(DragType.optionList);
        break;
      case ContentType.MaterialSwitch:
        accept.push(DragType.materialSwitch);
        break;
    }

    // Drop callback
    const drop = useCallback(
      (item) => {
        if (onChange) {
          if ((item as IFileData).files) {
            const file = (item as IFileData).files[0];
            const extension = file.name?.split('.')?.pop()?.toLowerCase();
            if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') onChange(file);
          } else onChange((item as AssetData).guid);
        }
      },
      [onChange]
    );

    // Collect callback
    const collect = useCallback(
      (monitor) => ({
        isDragActive: monitor.canDrop(),
      }),
      []
    );

    // Use drop
    const [{ isDragActive }, dropRef] = useDrop<AssetData | IFileData, any, any>({ accept, drop, collect });

    // Input
    const input = (
      <>
        <StyledAssetInput value={value} name={name} onBlur={onBlur} readOnly />
        <DropOverlay isDragActive={isDragActive} onClick={onClick}>
          <FaCompressArrowsAlt />
        </DropOverlay>
      </>
    );

    // Placeholder
    const placeholder = useMemo(() => {
      let placeholder;
      if (!value) {
        switch (contentType) {
          case ContentType.Thumbnail:
          case ContentType.Texture:
            placeholder = <ImageBoxPlaceholder ref={dropRef}>{input}</ImageBoxPlaceholder>;
            break;
          case ContentType.Material:
            placeholder = <StyledMaterialPlaceholder ref={dropRef}>{input}</StyledMaterialPlaceholder>;
            break;
          case ContentType.MassPreset:
          case ContentType.MassVolume:
          case ContentType.Configurable:
          case ContentType.OptionList:
          case ContentType.MaterialSwitch:
            placeholder = <StyledContentPlaceholder ref={dropRef}>{input}</StyledContentPlaceholder>;
            break;
        }
      }
      return placeholder;
    }, [dropRef, input, contentType, value]);

    // Render
    return (
      <div ref={value ? dropRef : undefined}>
        <StyledContainer {...rest} ref={ref}>
          {value ? input : placeholder}
        </StyledContainer>
      </div>
    );
  }
);

export { AssetInput, AssetInputProps };
