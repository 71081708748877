import * as React from 'react';
import { PageMain, StyledPageContainer, StyledPageContent } from './Page.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { Navbar } from '../navigation/Navbar';
import { HeaderGraphic } from '../graphics/HeaderGraphic';

interface PageProps extends BaseProps, ChildrenProp {
  disableNav?: boolean;
  navbar?: React.ReactNode;
}

const Page = React.forwardRef<HTMLDivElement, PageProps>(
  ({ children, navbar = <Navbar />, disableNav, ...rest }, ref) => (
    <StyledPageContainer {...rest} ref={ref}>
      <HeaderGraphic as={'header'} />
      <PageMain>
        {!disableNav ? navbar : null}
        <StyledPageContent disableNav={disableNav}>{children}</StyledPageContent>
      </PageMain>
    </StyledPageContainer>
  )
);

export { Page, PageProps };
