import * as React from 'react';
import { FormattedNumber } from 'react-intl';
import { Unit } from '@formatjs/intl-unified-numberformat';

interface BytesLabelProps {
  // value in bytes
  value: number;
}

/**
 * Hook to format value based on bytes
 * @param value
 */
function useBytesLabel(value: number): { unit: Unit; value: number } {
  if (value >= 1000000000) {
    return {
      unit: 'gigabyte',
      value: value / 1000000000,
    };
  } else if (value >= 1000000) {
    return {
      unit: 'megabyte',
      value: value / 1000000,
    };
  } else if (value >= 1000) {
    return {
      unit: 'kilobyte',
      value: value / 1000,
    };
  } else {
    return {
      unit: 'byte',
      value,
    };
  }
}

const BytesLabel = ({ value }: BytesLabelProps) => {
  const { unit, value: val } = useBytesLabel(value);

  return (
    <FormattedNumber
      value={val}
      // eslint-disable-next-line react/style-prop-object
      style={'unit'}
      unit={unit}
      unitDisplay={'narrow'}
      maximumFractionDigits={2}
    />
  );
};

export { BytesLabel, BytesLabelProps };
