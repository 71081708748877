import { css, DefaultTheme } from 'styled-components';

// Default Innobrix checkbox/radio style
export const checkStyle = (theme: DefaultTheme) => css`
  flex: 0 0 auto;
  border: 0.125em solid currentColor;

  box-sizing: content-box;
  width: 0.75em;
  height: 0.75em;
  padding: ${theme.spacing.xxsmall};
  margin-right: ${theme.spacing.small};

  cursor: pointer;
`;
