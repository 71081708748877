import { ButtonProps } from './Button';
import styled, { css } from 'styled-components';
import { buttonColorMap, ButtonColors, buttonStyle } from '../utilities/mixins/buttonStyle';
import { spacing } from '../variables/spacing';

const StyledButton = styled.button<ButtonProps & { singleChild?: boolean }>(
  ({ theme, type = 'button', variant = 'primary', small, large, fullWidth, singleChild }) => {
    const colors: ButtonColors = buttonColorMap(theme)[variant];

    return css`
      ${buttonStyle(theme, colors)};

      display: flex;
      flex-flow: row;
      align-items: center;
      place-content: ${fullWidth ? 'center' : undefined};
      width: ${fullWidth ? '100%' : undefined};

      padding: ${small ? spacing.s4 : large ? spacing.s12 : spacing.s8} ${!singleChild ? spacing.s16 : undefined};
      background: ${colors.background};
      color: ${colors.color};
      line-height: 1.4;
      text-decoration: none;

      > :only-child {
        ${!singleChild &&
        css`
          margin: 0 -${small ? spacing.s12 : large ? spacing.s4 : spacing.s8};
        `};
      }

      svg {
        :last-child:not(:only-child) {
          margin-left: ${theme.spacing.small};
        }

        :first-child:not(:only-child) {
          margin-right: ${theme.spacing.small};
        }
      }
    `;
  }
);

export { StyledButton };
