import * as React from 'react';
import { SkeletonUl, StyledTree } from './Tree.styled';
import { TreeNodeBaseProps, TreeNode } from './TreeNode';
import { BaseProps } from '../../interfaces/BaseProps';
import Skeleton from 'react-loading-skeleton';

export type TreeEventHandler = (node: TreeNodeBaseProps, event: React.MouseEvent<HTMLElement>) => void;

export interface AutoTreeProps extends BaseProps {
  contents?: Array<TreeNodeBaseProps>;
  // Events
  onNodeCollapse?: TreeEventHandler;
  onNodeClick?: TreeEventHandler;
  onNodeDoubleClick?: TreeEventHandler;
  onNodeExpand?: TreeEventHandler;
  onNodeMouseEnter?: TreeEventHandler;
  onNodeMouseLeave?: TreeEventHandler;
  onVisibilityToggleClick?: TreeEventHandler;
}

export const AutoTree = React.memo(
  React.forwardRef((props: AutoTreeProps, ref: React.Ref<HTMLUListElement>) => {
    const {
      contents,
      onNodeClick,
      onNodeDoubleClick,
      onNodeExpand,
      onNodeCollapse,
      onNodeMouseEnter,
      onNodeMouseLeave,
      onVisibilityToggleClick,
      ...rest
    } = props;

    const renderNodes = (treeItems?: Array<TreeNodeBaseProps>, isRoot = false) => {
      if (treeItems == null) {
        return <></>;
      }

      const items = treeItems.map((item) => {
        return (
          <TreeNode<any>
            {...item}
            key={item.id}
            id={item.id}
            label={item.label}
            active={item.active}
            onClick={onNodeClick}
            onDoubleClick={onNodeDoubleClick}
            onMouseEnter={onNodeMouseEnter}
            onMouseLeave={onNodeMouseLeave}
            onVisibilityToggleClick={onVisibilityToggleClick}
          >
            {item.childNodes && renderNodes(item.childNodes)}
          </TreeNode>
        );
      });

      return (
        <StyledTree root={isRoot} {...rest} ref={ref}>
          {items}
        </StyledTree>
      );
    };

    return contents == null ? (
      <SkeletonUl>
        <li>
          <Skeleton />
        </li>
        <li>
          <Skeleton />
          <SkeletonUl>
            <li>
              <Skeleton />
              <SkeletonUl>
                <li>
                  <Skeleton />
                </li>
              </SkeletonUl>
            </li>
          </SkeletonUl>
        </li>
        <li>
          <Skeleton />
        </li>
      </SkeletonUl>
    ) : (
      renderNodes(contents, true)
    );
  })
);
