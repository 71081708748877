import * as React from 'react';
import { StyledFileInput, StyledFileInputLabel, StyledFileName } from './FileInput.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { BaseInputProps, InputValueProps } from '../../interfaces/InputProps';
import { Button } from '../Button';
import { FaFolder } from 'react-icons/fa';
import { Icon } from '../Icon';
import { XOR } from 'ts-essentials/dist/types';
import { ChangeEvent, useCallback, useRef } from 'react';
import { useDrop } from 'react-dnd';
import mergeRefs from 'react-merge-refs';
import { NativeTypes } from 'react-dnd-html5-backend';
import { validateMimeType } from '@innobrix/utils';
import { Stack } from '../layout/Stack';
import { ErrorMessage } from './ErrorMessage';
import { getType } from 'mime';

interface BaseFileInputProps extends BaseProps, ChildrenProp, Omit<BaseInputProps<File>, 'onChange' | 'value'> {
  placeholder?: string;
  // TODO add pdf support in the future.
  accept?: 'image/png,image/jpeg,image/webp' | 'model/gltf-binary' | 'application/pdf';
  // validate?: (value?: File) => boolean;
  onValidated?: (validated: boolean) => void;
}

interface UncontrolledInputProps extends BaseFileInputProps, Omit<Partial<InputValueProps<File>>, 'onChange'> {
  onChange?: (file?: File) => void;
}
interface ControlledInputProps extends BaseFileInputProps, Omit<InputValueProps<File>, 'onChange'> {
  onChange: (file?: File) => void;
}

type Props = XOR<ControlledInputProps, UncontrolledInputProps>;

const FileInput = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      onChange,
      onValidated,
      accept = 'image/png,image/jpeg,image/webp',
      value,
      placeholder = 'Select a file',
      name,
      id,
      error,
      touched,
      ...rest
    },
    ref
  ) => {
    const validateFile = useCallback(
      (file?: File): boolean => {
        // Get filetype by extension (sortof dangerous).
        const fileType = getType(file?.name ?? '');

        return validateMimeType(fileType || '', accept);
      },
      [accept]
    );

    // Handle change
    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files?.[0];
        // Clear the value if there is none.
        if (file == null) {
          onChange?.(undefined);
          return;
        }

        const validated = validateFile(file);
        onValidated?.(validated);
        // if (!validated) return;

        onChange?.(file);
      },
      [onChange, onValidated, validateFile]
    );

    const [, dropRef] = useDrop<(DataTransfer | undefined) & { type: string }, unknown, unknown>({
      accept: [NativeTypes.FILE],
      drop: (item, monitor) => {
        const file = item.files[0];

        const validated = validateFile(file);
        onValidated?.(validated);

        onChange?.(file);
      },
    });

    const labelRef = useRef<HTMLLabelElement>(null);
    const handleClick = useCallback(() => {
      labelRef.current?.click();
    }, []);

    return (
      <Stack className={className} ref={ref}>
        <StyledFileInputLabel ref={mergeRefs([dropRef, labelRef])} htmlFor={id}>
          <StyledFileName empty={value?.name == null} error={error} touched={touched}>
            {value?.name ?? placeholder}
          </StyledFileName>

          <StyledFileInput
            type={'file'}
            name={name}
            id={id}
            multiple={false}
            onChange={handleChange}
            accept={accept === 'model/gltf-binary' ? '.glb' : accept}
            {...rest}
          />

          <Button variant={'secondary'} large onClick={handleClick}>
            <Icon icon={FaFolder} />
          </Button>
        </StyledFileInputLabel>

        {error && touched && <ErrorMessage error={error} />}
      </Stack>
    );
  }
);

export { FileInput, Props as FileInputProps };
