import * as React from 'react';
import { Select } from '../../form/Select';
import ReactSelect, { MenuProps } from 'react-select';
import { UserSelectOption, UserSelectValue } from './UserSelectItem';
import { StyledUserSelect, StyledUserSelectMenu } from './UserSelect.styled';

type Props = React.ComponentProps<typeof Select> & {
  noBackdrop?: boolean;
};

const UserSelect = React.forwardRef<ReactSelect, Props>(({ noBackdrop, className, style, hidden, ...rest }, ref) => (
  <StyledUserSelect className={className} style={style} hidden={hidden} noBackdrop={noBackdrop}>
    <Select
      {...rest}
      ref={ref}
      minMenuHeight={320}
      maxMenuHeight={320}
      components={{
        Option: UserSelectOption,
        SingleValue: UserSelectValue,
        Menu: (props: MenuProps<any>) => <StyledUserSelectMenu {...props} noBackdrop={noBackdrop} />,
      }}
    />
  </StyledUserSelect>
));

export { UserSelect };
