import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';
import { hideOutline } from '../../utilities/mixins/hideOutline';

export const StyledLogoUpload = styled.div(
  ({ theme }) => css`
    position: absolute;
    bottom: 0;
    right: ${spacing.s24};
    transform: translateY(50%);
    z-index: 220;

    border-radius: 100%;
    border: 2px solid ${theme.colors.neutral.white};
    ${hideOutline};

    padding: ${spacing.s12};
    margin-left: ${spacing.s16};
    background: ${theme.colors.neutral.xxlight};

    width: var(--modal-logo-size, 5rem);
    height: var(--modal-logo-size, 5rem);

    display: flex;
    place-content: center;
    align-items: center;

    transition: box-shadow ${theme.transition.slow};

    :focus {
      box-shadow: 0 0 0 0.25rem ${theme.colors.primary.xxlight};
    }

    > svg {
      height: 100%;

      path {
        fill: ${theme.colors.neutral.light};
      }
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  `
);

export const LogoOverlay = styled.div(
  ({ theme }) => css`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 220;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    color: ${theme.colors.neutral.white};
    font-size: 1.5rem;

    background: rgba(0, 0, 0, 0.25);
    border-radius: 100%;

    opacity: 0;
    transition: opacity ${theme.transition.default};

    :hover {
      opacity: 1;
    }

    svg {
      filter: drop-shadow(0px 0px 12px black);
    }
  `
);
