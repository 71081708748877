import styled, { css } from 'styled-components';
import { Icon } from '../Icon';
import { FaCheckCircle } from 'react-icons/fa';
import { fadeIn } from '../../utilities/mixins/animations';

export const DoneIcon = styled(Icon).attrs({ icon: FaCheckCircle })(
  ({ theme }) => css`
    color: ${theme.colors.success.default};
    animation: ${fadeIn} ${theme.transition.slow} forwards;
  `
);
