import styled, { css } from 'styled-components';
import { Box } from './Box';
import { math } from 'polished';
import { FlexBoxProps } from './FlexBox';

export const StyledFlexBox = styled(Box)<FlexBoxProps>(
  ({ theme, pad, inline, reverse, wrapping, justify, align, gutter }) => css`
    display: ${inline ? `inline-flex` : `flex`};
    flex-direction: ${`row${reverse ? '-reverse' : ''}`};
    flex-wrap: ${wrapping ? 'wrap' : 'nowrap'};

    justify-content: ${justify == null
      ? 'normal'
      : justify === 'start'
      ? 'flex-start'
      : justify === 'end'
      ? 'flex-end'
      : justify};
    align-items: ${align == null ? 'normal' : align === 'start' ? 'flex-start' : align === 'end' ? 'flex-end' : align};

    ${pad && `padding: ${theme.spacing[pad]}`};

    ${gutter &&
    css`
      && {
        margin: ${math(`-1 * ${theme.spacing[gutter]}`)};

        > * {
          margin: ${theme.spacing[gutter]};
        }
      }
    `}
  `
);
