import { IJsonFile } from './IJsonFile';
import { Vector2, Vector3 } from 'three';

/**
 * Data coming from the original house, as a means of validating the current configuration to the Revit file it's
 * used with.
 */
interface IHouseTypeData {
  /**
   * Configurable entry used to generate this configuration.
   */
  configurableEntryName: string;

  /**
   * Original Revit file name upon export.
   */
  exportProjectFileName?: string;

  /**
   * Original Revit file export date.
   */
  exportDate?: Date;

  /**
   * Used measurements to export said file.
   */
  gridCellSize?: Vector3 | Vector2;

  /**
   * Used measurements to export said file.
   */
  gridOffset?: Vector3 | Vector2;

  /**
   * If the house was mirrored
   */
  mirrored?: boolean;
}

/**
 * A single configuration in {@link RevitJsonFile.Configurations}, containing all data to assemble a house.
 */
export interface IRevitConfiguration {
  /**
   * Name of the saved configuration, or the name of the estate if not saved prior.
   */
  name: string;

  /**
   * The user that created the configuration. Optional.
   */
  user: { name: string; email: string };

  /**
   * The construction number, used to inform the Revit user.
   */
  constructionNumber: string;

  /**
   * The positioning of the house inside its block (or standalone), used to inform the Revit user.
   */
  positioning: Positioning;

  /**
   * The original source that the {@link IHouseTypeData.configurableEntryName} was exported with.
   */
  houseTypeData: IHouseTypeData;

  /**
   * The cells to configure.
   */
  cells: Vector3[];

  /**
   * The material switches to apply upon importing in Revit. Every sub-array is a fixed length of 2, where source is
   * [0], target [1]. The target material name must be exported from Revit itself, and used everywhere in the process.
   */
  switches: string[][];
}

/**
 * Determining the position of an estate inside of a group (or {@link Positioning.Standalone} if not part of one.
 */
export enum Positioning {
  Standalone,
  Intermediate,
  End,
}

export class RevitJsonFile extends IJsonFile {
  readonly _kind = 'revit-json';
  readonly _version = '2.0';
  private configurations: IRevitConfiguration[] = [];

  constructor(...configurations: IRevitConfiguration[]) {
    super();
    this.configurations = configurations;
  }

  /**
   * Pushes a configuration into {@link configurations}.
   * @param configuration
   * @constructor
   */
  public Push(...configuration: IRevitConfiguration[]) {
    this.configurations.push(...configuration);
  }

  public get Configurations(): IRevitConfiguration[] {
    return this.configurations;
  }

  /**
   * Returns validity of the generated file by checking for configuration count.
   */
  public get IsValid(): boolean {
    return this._version && this._kind && this.configurations.length > 0;
  }

  /**
   * Suggested filename regarding configurations count, including suffix '.ibx.json' as per rule.
   */
  public get SuggestedFilename(): string {
    const first = this.configurations[0];
    if (this.configurations.length === 1) {
      const { name, constructionNumber } = first;
      return `${constructionNumber ? `[${constructionNumber}] ` : ''}${name}.ibx.json`;
    }
    const last = this.configurations[this.configurations.length - 1];

    if (!first.constructionNumber || !last.constructionNumber)
      return `${this.configurations.length} configurations.ibx.json`;
    else
      return `[${first.constructionNumber} - ${last.constructionNumber}] ${this.configurations.length} configurations.ibx.json`;
  }
}
