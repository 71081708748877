import styled, { css } from 'styled-components';
import { CloseButton } from '../navigation/CloseButton';
import { breakpoints } from '../../variables/breakpoints';
import { klavika } from '../../utilities/mixins/typography';
import { content } from '../../utilities/mixins/content';
import { spacing } from '../../variables/spacing';

export const StyledOptionDescriptionModal = styled.div(
  ({ theme }) => css`
    position: fixed;
    z-index: 5001;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    bottom: 8rem;
    overflow: hidden;

    display: flex;
    flex-flow: column;

    background: ${theme.colors.neutral.white};
    border-radius: ${theme.borderRadius.medium};
    box-shadow: ${theme.elevation.large};

    @media screen and ${breakpoints.tablet} {
      right: unset;
      left: 50%;
      transform: translateX(-50%);
      min-width: 34vw;
      max-width: min(75ch, calc(100vw - 2rem));
    }
  `
);

export const ModalHeader = styled.header(
  ({ theme }) => css`
    display: flex;
    position: relative;

    ${klavika};
    font-size: 1.25rem;
  `
);

export const ModalHeaderContent = styled.div(
  ({ theme }) => css`
    bottom: 0;
    left: 0;

    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: ${spacing.s16} ${spacing.s16} 0;
  `
);

export const StyledThumbnail = styled.img(
  ({ theme }) => css`
    width: 100%;

    & + ${ModalHeaderContent} {
      position: absolute;
      color: ${theme.colors.neutral.white};
      text-shadow: 0 0 1em black;
    }
  `
);

export const ModalContent = styled.div(
  ({ theme }) => css`
    position: relative;
    padding: ${spacing.s8};
    margin: ${spacing.s8};
    overflow: auto;

    // Content styling
    ${content};
  `
);

export const StyledCloseButton = styled(CloseButton)(
  ({ theme }) => css`
    position: fixed;
    font-size: 4rem;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5001;
    background: ${theme.colors.text.light};

    svg {
      width: 0.25em;
      height: 0.25em;
    }
  `
);
