import * as React from 'react';
import { StyledSplashScreen } from './SplashScreen.styled';
import { useSpring } from '@react-spring/web';
import { BaseProps } from '../../interfaces/BaseProps';

type SplashScreenProps = BaseProps;
const SplashScreen = React.forwardRef<HTMLDivElement, SplashScreenProps>(({ ...rest }, ref) => {
  const props = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <StyledSplashScreen {...rest} style={props} ref={ref}>
      <svg viewBox="0 0 32 38">
        <path d="M4.5 18.89L0 21.53l4.5 2.63zm4.73 7.46l4.54-2.56v-5l-4.54-2.66zM9.23.2v5.89L4.33 9l4.9 2.62 4.54-2.92V2.83zm13.61 13.86V2.61L18.3 0v27.25L4.51 35.38 9.12 38l9.22-5.46 9.14 5.36L32 35.35v-16zm4.63 13.35L23 24.77l4.51-2.64z" />
      </svg>
    </StyledSplashScreen>
  );
});

export { SplashScreen, SplashScreenProps };
