import * as React from 'react';
import { StyledTooltip, TooltipDisabledWrapper } from './Tooltip.styled';
import { TooltipProps as TProps } from 'rc-tooltip/lib/Tooltip';
import { BaseProps } from '../../interfaces/BaseProps';
import RCTooltip from 'rc-tooltip';

interface TooltipProps extends BaseProps, TProps {}
const Tooltip = React.forwardRef<typeof RCTooltip, TooltipProps>(({ overlay, ...rest }, ref) => (
  <StyledTooltip {...rest} ref={ref} overlay={overlay} prefixCls={'tip'} animation={'fade'} />
));

/*
 * The following makes this more easy to use but gives issues with the Flyout component that reuses this.
  {children?.props?.disabled ? <TooltipDisabledWrapper>{children}</TooltipDisabledWrapper> : children}
</StyledTooltip>
*/

export { Tooltip, TooltipProps };
export { TooltipDisabledWrapper } from './Tooltip.styled';
