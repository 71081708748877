import * as React from 'react';
import { StyledTextArea } from './TextArea.styled';
import { Stack } from '../layout/Stack';
import { FormikInputProps } from '../../interfaces/InputProps';
import { BaseProps } from '../../interfaces/BaseProps';
import { ErrorMessage } from './ErrorMessage';

export interface TextAreaProps
  extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'name'>,
    BaseProps,
    FormikInputProps {}

export const TextArea = React.forwardRef((props: TextAreaProps, ref: React.Ref<HTMLTextAreaElement>) => {
  const { error, touched, rows = 3, ...rest } = props;

  return (
    <Stack inline={true}>
      <StyledTextArea error={error} touched={touched} rows={rows} {...rest} ref={ref} />
      {/* TODO animate error in */}
      {error && touched && <ErrorMessage error={error} />}
    </Stack>
  );
});
