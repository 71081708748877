import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';
import { visuallyHidden } from '../../utilities/mixins/visuallyHidden';
import { hideOutline } from '../../utilities/mixins/hideOutline';

export const StyledImageUpload = styled.div(
  ({ theme }) => css`
    cursor: pointer;
  `
);

export const ImageUploadOverlay = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 200;
    background-color: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(0.2rem);
    -webkit-backdrop-filter: blur(0.2rem);

    transition: opacity ${theme.transition.slow};

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 2rem;
    color: white;

    border-radius: ${theme.borderRadius.medium} ${theme.borderRadius.medium} 0 0;

    > :not(:last-child) {
      margin-right: 2rem;
    }

    svg {
      color: ${theme.colors.neutral.white};
      text-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.5);

      :hover {
        color: ${theme.colors.neutral.xlight};
      }
    }

    ${Image} + & {
      opacity: 0;
    }

    ${Image}:hover + &,
    :hover {
      opacity: 1;
    }
  `
);

export const HiddenInput = styled.input(
  ({ theme }) => css`
    ${visuallyHidden};
  `
);

export const ImagePlaceholder = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    border-radius: ${theme.borderRadius.medium} ${theme.borderRadius.medium} 0 0;
    border: 0 solid transparent;

    transition: border ${theme.transition.slow};

    ${StyledImageUpload}:focus & {
      ${hideOutline};
      border: 0.25rem solid ${theme.colors.primary.xlight};
    }

    > svg:not(:last-child) {
      margin-bottom: ${spacing.s8};
    }

    img {
      width: 100%;
      height: 100%;
    }
  `
);

export const Image = styled.img(
  ({ theme, src }) => css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    position: ${!src ? 'absolute' : undefined};
    opacity: ${!src ? 0 : 1};
    transition: opacity ${theme.transition.slow} 0.2s;

    border-radius: ${theme.borderRadius.medium} ${theme.borderRadius.medium} 0 0;
  `
);
