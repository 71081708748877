import styled from 'styled-components';
import { fontSize, weight } from '../../variables/typography';
import { Span } from '../typo/Span';
import { spacing } from '../../variables/spacing';
import { NavLink } from 'react-router-dom';
import { borderRadius } from '../../variables/borderRadius';
import { A } from '../atoms/A';

const BaseItem = A.withComponent(NavLink);
export const StyledChatsListLink = styled(BaseItem).withConfig({
  // Don't pass down exact prop to html
  shouldForwardProp: (prop, valFn) => !['exact'].includes(prop) && valFn(prop),
})`
  display: grid;
  gap: 0 ${spacing.s8};
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    'avatar name time'
    'avatar sub sub';

  border-radius: ${borderRadius.r4};

  transition: background-color ${(p) => p.theme.transition.default};

  &[href] {
    padding: ${spacing.s8};

    &.active,
    &:hover,
    &:focus.focus-visible {
      background-color: ${(p) => p.theme.colors.neutral.xxlight};
    }
  }
`;

export const StyledChatsListItem = styled.li``;

export const AvatarWrapper = styled.div`
  grid-area: avatar;
  display: flex;
`;

export const NameLabel = styled(Span)`
  grid-area: name;
  font-size: ${fontSize.s18};
  font-weight: ${weight.semibold};
  line-height: 1.3;
`;

export const SubtitleLabel = styled(Span)`
  grid-area: sub;
  line-height: 1.2;
`;

export const TimeLabel = styled(Span)`
  grid-area: time;
`;
