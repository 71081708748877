import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';
import { breakpoints } from '../../variables/breakpoints';

export const StyledPageContainer = styled.div`
  isolation: isolate;
`;

export const StyledPageContent = styled.div<{ disableNav?: boolean }>(
  ({ theme, disableNav }) => css`
    grid-column: 2;
    display: flex;
    flex-flow: column;

    @media screen and ${breakpoints.tablet} {
      margin-left: ${!disableNav && spacing.s32};

      // This will cause intentional horizontal overflow
      max-width: ${!disableNav && `calc(100vw - 10rem)`};
    }
  `
);

export const PageMain = styled.main`
  min-height: var(--viewheight, 100vh);
  overflow: auto;
  padding: 3.75rem ${spacing.s16} ${spacing.s16};

  display: grid;
  grid-template-columns: auto 1fr;

  @media screen and ${breakpoints.tablet} {
    padding: ${spacing.s32};
    overflow: unset;
  }
`;
