import * as React from 'react';
import { AnimatedPage, Grid, GridLastItem, SkeletonNavbar, SkeletonTitle } from './StudioPageSkeleton.styled';
import Skeleton from 'react-loading-skeleton';
import { Stack } from '../layout/Stack';
import { HeaderGraphic } from '../graphics/HeaderGraphic';
import { useSpring } from '@react-spring/web';

export const StudioPageSkeleton = () => {
  const props = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <AnimatedPage
      style={props}
      navbar={
        <SkeletonNavbar>
          <Skeleton height={'100%'} />
        </SkeletonNavbar>
      }
    >
      <HeaderGraphic gray />

      <Stack gutter={'small'}>
        <Grid>
          <Skeleton height={40} />

          <GridLastItem>
            <Skeleton width={40} height={40} circle />
          </GridLastItem>
        </Grid>

        <Grid>
          <SkeletonTitle level={1} />
        </Grid>
      </Stack>
    </AnimatedPage>
  );
};
