import * as React from 'react';
import { ReactElement } from 'react';
import { SliderProps } from '../form/Slider';
import { StyledLayerSwitcherSingle } from './LayerSwitcher.styled';
import Slider, { Handle, Marks } from 'rc-slider';
import { Icon } from '../Icon';
import { FaGripLines } from 'react-icons/fa';

export interface LayerSwitcherProps extends Omit<SliderProps, 'name'> {
  name?: string;
  children: ReactElement | ReactElement[];
}
export const LayerSwitcher = React.forwardRef((props: LayerSwitcherProps, ref: React.Ref<Slider>) => {
  const { name = 'layerSwitcher', children, ...rest } = props;

  const marks: Marks = {};
  children &&
    React.Children.forEach(children, (child, index: number) => {
      marks[index + 1] = child;
    });

  return (
    <StyledLayerSwitcherSingle
      {...rest}
      ref={ref}
      step={null}
      name={name}
      vertical
      min={0}
      max={Object.keys(marks).length}
      marks={marks}
      handle={(props) => {
        const { dragging, ...rest } = props;
        const { index, value, min, max } = props;
        const calculatedOffset = ((value - min) * 100) / (max - min);

        return (
          <Handle {...rest} key={index} offset={calculatedOffset}>
            <Icon icon={FaGripLines} />
          </Handle>
        );
      }}
    />
  );
});
