import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';
import { breakpoints } from '../../variables/breakpoints';
import { AnimatedPrice } from '../optionlist/AnimatedPrice';
import { weight } from '../../variables/typography';

export const StyledPriceListFooter = styled.footer(
  ({ theme }) => css`
    flex: 0 0 auto;
    padding: ${spacing.s16};
    background: linear-gradient(90deg, ${theme.colors.secondary.default} 0%, ${theme.colors.secondary.xlight} 100%);
    color: ${theme.colors.secondary.xxxlight};
    line-height: 1.25;

    --plcp-offset: 0.25rem;
    clip-path: polygon(0 var(--plcp-offset), 100% 0, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 var(--plcp-offset), 100% 0, 100% 100%, 0% 100%);

    border-radius: 0 0 var(--brad, 0) var(--brad, 0);
    margin-top: calc(var(--plcp-offset) * -1);
    z-index: 1;

    @media screen and ${breakpoints.tablet} {
      --plcp-offset: 0.5rem;
    }

    > :not(:first-child) {
      margin-top: ${spacing.s8};
    }
  `
);

export const PriceListPrice = styled(AnimatedPrice)(
  ({ theme }) => css`
    font-size: 1.5rem;
    font-weight: ${weight.semibold};
  `
);
