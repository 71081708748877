import * as React from 'react';
import { FlyoutHeaderContent, StyledFlyoutHeader, StyledFlyout } from './FlyoutHeader.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { FlyoutLock } from './FlyoutLock';

interface FlyoutHeaderProps extends BaseProps, ChildrenProp {
  // Lock props. If locked == null it doesn't render.
  locked?: boolean;
  onLockChanged?: (locked: boolean) => void;
}

const FlyoutHeader = React.forwardRef<HTMLDivElement, FlyoutHeaderProps>(
  ({ children, locked, onLockChanged, ...rest }, ref) => (
    <StyledFlyoutHeader {...rest} ref={ref}>
      <FlyoutHeaderContent hasLock={locked != null}>
        {children}
        {locked != null && <FlyoutLock locked={locked} onClick={() => onLockChanged?.(!locked)} />}
      </FlyoutHeaderContent>
    </StyledFlyoutHeader>
  )
);

export { FlyoutHeader, StyledFlyout as FlyoutWithHeader, FlyoutHeaderProps };
