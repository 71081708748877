import { Heading, Stack, Svg404 } from '@innobrix/components';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Layout } from '../layout/Layout';

export const Error404Page = () => (
  <Layout>
    <Helmet>
      <title>Not found | Innobrix</title>
    </Helmet>

    <Stack justify={'center'} align={'center'} gutter={'large'} style={{ width: '100vw', height: '100%' }}>
      <Heading level={1}>What are you doing here?</Heading>
      <Svg404 />
    </Stack>
  </Layout>
);

export default Error404Page;
