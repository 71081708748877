import styled, { css } from 'styled-components';
import { KeybindLabelProps } from './KeybindLabel';

export const StyledKeybindLabel = styled.small<KeybindLabelProps>(
  ({ theme }) => css`
    box-sizing: content-box;
    padding: 0.125em ${theme.spacing.xsmall};
    min-width: 1em;

    border-radius: ${theme.borderRadius.small};
    background: ${theme.colors.neutral.dark};
    color: ${theme.colors.text.light};

    text-transform: capitalize;
    font-size: smaller;
    text-align: center;
  `
);
