import * as React from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '../Icon';
import { spacing } from '../../variables/spacing';
import { fontSize } from '../../variables/typography';

type Props = {
  $sorting?: boolean;
  $sortDirection: 'asc' | 'desc';
};

export const StyledSortableHeader = styled.th(
  ({ theme }) => css`
    cursor: pointer;
  `
);

export const SortIcon = styled(Icon)<Props>(
  ({ theme, $sorting, $sortDirection }) =>
    css`
      margin-left: ${spacing.s4};
      font-size: ${fontSize.s12};
      color: ${theme.colors.neutral.medium};

      transition: ${theme.transition.slow};
      transition-property: opacity, transform;

      transform: ${$sortDirection === 'desc' ? css`rotate(180deg)` : undefined};

      opacity: ${!$sorting ? 0 : undefined};

      ${StyledSortableHeader}:hover && {
        opacity: 1;

        ${$sorting && $sortDirection === 'desc'
          ? css`
              transform: rotate(155deg);
            `
          : css`
              transform: rotate(25deg);
            `}
      }
    `
);
