import * as React from 'react';
import { useEffect, useRef } from 'react';
import { ChatContainer, ChatInputContainer, DocumentsContainer, NoMessagesLabel, StyledChat } from './Chat.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { Span } from '../typo/Span';
import { FormattedMessage } from 'react-intl';
import { ChatInputProps } from './ChatInput';

interface ChatProps extends BaseProps, ChildrenProp, Pick<ChatInputProps, 'onSend'> {
  documentsList: React.ReactElement;
}

const Chat = React.forwardRef<HTMLDivElement, ChatProps>(({ documentsList, onSend, children, ...rest }, ref) => {
  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  // Scroll to bottom whenever children change
  useEffect(() => {
    if (chatContainerRef.current == null) return;

    const height = chatContainerRef.current.scrollHeight;
    chatContainerRef.current?.scrollTo({ behavior: 'smooth', top: height });
  }, [children]);

  return (
    <StyledChat {...rest} ref={ref}>
      <ChatContainer ref={chatContainerRef}>
        {children ?? (
          <NoMessagesLabel>
            <Span subtle>
              <FormattedMessage id={'chat.no-messages'} defaultMessage={'No messages yet, send your first message!'} />
            </Span>
          </NoMessagesLabel>
        )}
      </ChatContainer>

      <ChatInputContainer onSend={onSend} />

      <DocumentsContainer>{documentsList}</DocumentsContainer>
    </StyledChat>
  );
});

export { Chat, ChatProps };
