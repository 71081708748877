import * as React from 'react';
import { Modal, ModalFooter, ModalProps } from './Modal';
import { FlexBox } from '../layout/FlexBox';
import { Button, ButtonProps } from '../Button';
import { FormattedMessage } from 'react-intl';
import ReactModal from 'react-modal';

type Props = Omit<ModalProps, 'footer'> &
  Required<Pick<ModalProps, 'header'>> & {
    button: {
      variant: ButtonProps['variant'];
      onClick: ButtonProps['onClick'];
      label: ButtonProps['children'];
    };
  };

const Dialog = React.forwardRef<ReactModal, Props>(
  (
    { isOpen, onRequestClose, onAfterClose, button: { label, onClick, variant = 'primary' }, children, ...rest },
    ref
  ) => {
    return (
      <Modal
        {...rest}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        onAfterClose={onAfterClose}
        footer={
          <ModalFooter>
            <FlexBox gutter={'xsmall'}>
              <Button variant={variant} autoFocus onClick={onClick}>
                {label}
              </Button>

              <Button variant={'secondary'} onClick={onRequestClose}>
                <FormattedMessage id={'general.cancel'} />
              </Button>
            </FlexBox>
          </ModalFooter>
        }
        ref={ref}
      >
        {children}
      </Modal>
    );
  }
);

export { Dialog };
