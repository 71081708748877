import { css } from 'styled-components';

export const content = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol {
    margin-bottom: 1em;
  }

  ul,
  ol {
    margin-left: 2rem;
    list-style-type: initial;
  }

  ol {
    list-style-type: decimal;
  }

  a {
    font-weight: bold;
    color: currentColor;
    transition: color ${(p) => p.theme.transition.default};

    &:visited {
      color: currentColor;
    }

    &:hover,
    &:focus {
      color: ${(p) => p.theme.colors.primary.default};
    }
  }
`;
