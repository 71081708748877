import * as React from 'react';
import { FormattedNumber } from 'react-intl';

interface Props {
  value: number;
}

export const Percent = ({ value }: Props) => {
  return (
    <FormattedNumber
      value={value}
      // eslint-disable-next-line react/style-prop-object
      style={'percent'}
    />
  );
};
