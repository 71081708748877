import styled, { css } from 'styled-components';
import { Heading } from '../typo/Heading';

export const StyledOptionGroup = styled.fieldset<{ large?: boolean }>`
  min-inline-size: 100%;
  position: relative;

  ${(p) =>
    p.large &&
    css`
      --option-size: min(20vmin, 5rem);
    `};
`;

export const StyledOptionHeader = styled.header(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `
);

export const StyledOptionHeading = styled(Heading).attrs({ level: 5 })(({ theme }) => css``);
