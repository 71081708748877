import { BaseProps, Icon, SpinnerLabel } from '@innobrix/components';
import * as React from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { useTheme } from 'styled-components';
import { CompareLabelStyled, IconWrapper } from './CompareLabel.styled';

type Props = BaseProps & {
  growth: number;
  growthPercentage: number;
};

export const CompareLabel = ({ growth, growthPercentage, ...rest }: Props) => {
  const theme = useTheme();

  return (
    <CompareLabelStyled style={{ color: growth < 1 ? theme.colors.error.default : undefined }} {...rest}>
      <IconWrapper>
        <Icon icon={growth < 1 ? FaCaretDown : FaCaretUp} />
      </IconWrapper>
      {growth < 1 ? <span>-</span> : null}
      <SpinnerLabel stat={growthPercentage} />%
    </CompareLabelStyled>
  );
};
