import styled, { css } from 'styled-components';

export const StyledHamburger = styled.div<{ disable?: boolean }>(
  ({ theme, disable }) => css`
    display: inline-flex;
    padding: ${theme.spacing.small};
    font-size: 1.25rem;
    line-height: 1;
    color: ${theme.colors.primary.default};

    transition: ${theme.transition.default};
    transition-property: background, color;

    ${!disable &&
    css`
      cursor: pointer;

      :hover {
        background: ${theme.colors.primary.default};
        color: ${theme.colors.text.light};
      }
    `};
  `
);
