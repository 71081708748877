import styled, { css } from 'styled-components';
import { FormGridProps } from './FormGrid';
import { StyledLabel } from './Label.styled';
import { spacing } from '../../variables/spacing';

export const StyledFormGrid = styled.div<FormGridProps>(
  ({ theme, gutter }) => css`
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: ${theme.spacing[gutter ?? 'large']};
    margin-bottom: ${theme.spacing.large};

    ${StyledLabel} {
      padding-top: ${spacing.s8};
    }
  `
);
