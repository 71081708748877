import { TreeNodeProps } from './TreeNode';
import styled, { css } from 'styled-components';
import { TiArrowSortedDown } from 'react-icons/ti';
import { ThemedProps } from '../../interfaces/ThemedProps';
import { IconBaseProps } from 'react-icons';
import * as React from 'react';
import { FlexBox } from '../layout/FlexBox';
import { Span } from '../typo/Span';
import { Icon } from '../Icon';
import { textEllipsis } from '../../utilities/mixins/textOverflow';

export const StyledCaret = styled(({ isOpen, label, ...rest }) => <TiArrowSortedDown {...rest} />)<
  ThemedProps<IconBaseProps> & TreeNodeProps<any>
>(
  ({ theme, isOpen }) => css`
    margin-right: ${theme.spacing.small};
    transform: rotate(${!isOpen ? `-90deg` : `0`});
    transition: ${theme.transition.slow};
    transition-property: transform, fill;
  `
);

export const StyledHeader = styled(FlexBox)<
  ThemedProps<{
    active?: boolean;
    invisible?: boolean;
    dropzone?: boolean;
    onClick: React.MouseEventHandler;
    onMouseEnter: React.MouseEventHandler;
    onMouseLeave: React.MouseEventHandler;
    onContextMenu: React.MouseEventHandler;
  }>
>(
  ({ theme, active, invisible, dropzone }) => css`
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    background: ${active ? theme.colors.primary.default : 'none'};
    color: ${active ? theme.colors.primary.xxxlight : invisible ? theme.colors.neutral.light : 'currentColor'};
    padding: 0.15rem ${theme.spacing.small};
    border-radius: ${theme.borderRadius.small};
    position: relative;

    transition: ${theme.transition.default};
    transition-property: background, color;

    :hover {
      background: ${active ? theme.colors.primary.default : theme.colors.primary.xxxlight};
      color: ${active ? theme.colors.primary.xxxlight : theme.colors.primary.dark};
    }

    ${dropzone &&
    css`
      ::before {
        background: ${(p) => p.theme.colors.neutral.medium + 'CC'}; // CC -> 80% alpha
        border: 2px dashed ${(p) => p.theme.colors.neutral.xxdark};
        color: ${(p) => p.theme.colors.neutral.xxdark};
        content: 'Drop here';

        border-radius: ${(p) => p.theme.borderRadius.small};
        font-weight: bold;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        pointer-events: none;
      }
    `}
  `
);

export const StyledHeaderText = styled(Span)(
  ({ theme }) => css`
    flex: 1;
    ${textEllipsis};
  `
);

export const StyledVisToggle = styled(({ active, invisible, ...rest }) => <Icon {...rest} />)<
  ThemedProps<{ active?: boolean; invisible?: boolean }>
>(
  ({ theme, invisible, active }) => css`
    width: 1.25em;
    height: 1.25em;
    opacity: ${invisible ? 1 : 0};

    transition: ${theme.transition.default};
    transition-property: opacity, fill;

    ${StyledHeader}:hover & {
      opacity: 1;
    }
  `
);

export const StyledIcon = styled(Icon)`
  margin-right: ${(props) => props.theme.spacing.small};
`;

export const StyledTreeNode = styled.li(({ theme }) => css``);
