import * as React from 'react';
import { StyledChatNoSelection } from './ChatNoSelection.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { NoChatMessages } from '../graphics/Svgs';
import { FormattedMessage } from 'react-intl';
import { Heading } from '../typo/Heading';

interface ChatNoSelectionProps extends BaseProps, ChildrenProp {}

const ChatNoSelection = React.forwardRef<HTMLDivElement, ChatNoSelectionProps>(({ ...rest }, ref) => {
  return (
    <StyledChatNoSelection {...rest} ref={ref}>
      <NoChatMessages />

      <Heading level={6}>
        <FormattedMessage id={'chat.no-selection'} defaultMessage={'Select a chat from the list on the left'} />
      </Heading>
    </StyledChatNoSelection>
  );
});

export { ChatNoSelection, ChatNoSelectionProps };
