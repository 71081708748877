import * as React from 'react';
import styled, { css } from 'styled-components';
import { Modal } from './Modal';
import { spacing } from '../../variables/spacing';
import { StyledInnerModal, StyledModalContainer } from './Modal.styled';
import { HorizontalSteps } from '../list/HorizontalSteps';
import squarePattern from '../../../../../shared/assets/src/lib/images/square-pattern.svg';
import { FlexBox } from '../layout/FlexBox';

export const StyledGettingStartedModal = styled(Modal)(
  ({ theme }) => css`
    ${StyledInnerModal} {
      background-image: url(${squarePattern});
      background-repeat: repeat-x;
      background-position: center bottom;
    }

    ${StyledModalContainer} {
      padding-bottom: ${spacing.s12};
    }
  `
);

export const Footer = styled(FlexBox)(
  ({ theme }) => css`
    margin-top: ${spacing.s12};
  `
);

export const Steps = styled(HorizontalSteps)(
  ({ theme }) => css`
    margin-top: ${spacing.s24};
    grid-template-columns: 1fr 1fr 1fr;
  `
);

export const Step1Graphic = () => (
  <svg width="200" height="170" viewBox="0 0 200 170" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <rect y="100.388" width="152.941" height="69.6121" rx="2" fill="#F3F3F3" />
      <rect x="157.353" width="42.6471" height="170" rx="2" fill="#F3F3F3" />
      <rect y="13.1897" width="152.941" height="82.8017" rx="2" fill="#E0EBF1" />
      <path
        d="M59.6349 98.9415C60.0501 99.3056 60.6819 99.2642 61.0461 98.849C61.4102 98.4338 61.3688 97.802 60.9536 97.4378L59.6349 98.9415ZM51.103 74.375L52.103 74.375L52.103 74.375L51.103 74.375ZM62.8678 50.5603L51.7757 53.7695L60.1009 61.7709L62.8678 50.5603ZM60.2942 98.1897C60.9536 97.4378 60.9539 97.4381 60.9542 97.4383C60.9542 97.4384 60.9545 97.4386 60.9546 97.4387C60.9548 97.4389 60.9548 97.4389 60.9548 97.4389C60.9546 97.4387 60.9539 97.4381 60.9526 97.437C60.9501 97.4347 60.9455 97.4306 60.9387 97.4245C60.9252 97.4122 60.9033 97.3923 60.8736 97.3645C60.8142 97.309 60.7236 97.2226 60.6062 97.1052C60.3713 96.8705 60.0295 96.5125 59.6163 96.032C58.7894 95.0707 57.6784 93.6214 56.5649 91.6906C54.3399 87.8324 52.1031 82.0493 52.103 74.375L50.103 74.375C50.1031 82.455 52.4618 88.5792 54.8324 92.6898C56.0167 94.7434 57.2035 96.2938 58.1 97.3362C58.5484 97.8575 58.9246 98.2523 59.1924 98.5199C59.3263 98.6537 59.4332 98.7558 59.5084 98.8261C59.546 98.8612 59.5757 98.8884 59.597 98.9076C59.6076 98.9173 59.6162 98.9249 59.6225 98.9305C59.6257 98.9334 59.6283 98.9357 59.6304 98.9375C59.6314 98.9384 59.6323 98.9392 59.633 98.9399C59.6334 98.9402 59.6339 98.9406 59.6341 98.9408C59.6345 98.9412 59.6349 98.9415 60.2942 98.1897ZM52.103 74.375C52.103 67.0663 54.7194 61.4754 57.4472 57.6274L55.8156 56.4707C52.9062 60.575 50.103 66.5617 50.103 74.375L52.103 74.375Z"
        fill="#575F6A"
      />
      <path
        d="M0 102.388C0 101.283 0.895431 100.388 2 100.388H150.941C152.046 100.388 152.941 101.283 152.941 102.388V109.181H0V102.388Z"
        fill="#E5E5E5"
      />
      <rect width="152.941" height="8.7931" rx="2" fill="#F3F3F3" />
      <path
        d="M101.471 103.465V90.862C101.471 89.25 100.147 87.931 98.5294 87.931H95.5882C93.9706 87.931 92.6471 89.25 92.6471 90.862V95.5517L82.8529 86.7586C81.7353 85.762 80.0294 85.762 78.9118 86.7586L54.3235 108.829C53.3235 109.709 53.9412 111.379 55.2941 111.379H60.2941V131.897C60.2941 133.509 61.6176 134.828 63.2353 134.828H72.0588C73.6765 134.828 75 133.509 75 131.897V117.241H86.7647V131.897C86.7647 133.509 88.0882 134.828 89.7059 134.828H98.5294C100.147 134.828 101.471 133.509 101.471 131.897V111.379H106.471C107.824 111.379 108.471 109.709 107.441 108.829L101.471 103.465ZM75 105.517C75 102.293 77.647 99.6552 80.8823 99.6552C84.1176 99.6552 86.7647 102.293 86.7647 105.517H75Z"
        fill="#575F6A"
      />
    </g>
  </svg>
);

export const Step2Graphic = () => (
  <svg width="174" height="80" viewBox="0 0 174 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 2C0 0.895429 0.895431 0 2 0H172C173.105 0 174 0.895431 174 2V78C174 79.1046 173.105 80 172 80H2C0.895435 80 0 79.1046 0 78V2Z"
      fill="#F3F3F3"
    />
    <path d="M0 2C0 0.895432 0.895431 0 2 0H172C173.105 0 174 0.895431 174 2V10.1053H0V2Z" fill="#E5E5E5" />
    <rect x="22.5865" y="15.1579" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="22.5865" y="27.7895" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="22.5865" y="40.4211" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="22.5865" y="53.0526" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="22.5865" y="65.6842" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="35.1346" y="15.1579" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="35.1346" y="27.7895" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="35.1346" y="40.4211" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="35.1346" y="53.0526" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="35.1346" y="65.6842" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="47.6826" y="15.1579" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="47.6826" y="27.7895" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="47.6826" y="40.4211" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="47.6826" y="53.0526" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="47.6826" y="65.6842" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="60.2307" y="15.1579" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="60.7307" y="28.2895" width="9.03847" height="9.10526" rx="1.5" fill="#E5E5E5" stroke="#796A94" />
    <rect x="60.2307" y="40.4211" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="60.2307" y="53.0526" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="60.2307" y="65.6842" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="72.7788" y="15.1579" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="72.7788" y="27.7895" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="72.7788" y="40.4211" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="72.7788" y="53.0526" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="72.7788" y="65.6842" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="85.3269" y="15.1579" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="85.3269" y="27.7895" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="85.3269" y="40.4211" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="85.3269" y="53.0526" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="85.3269" y="65.6842" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="97.875" y="15.1579" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="97.875" y="27.7895" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="97.875" y="40.4211" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="97.875" y="53.0526" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="97.875" y="65.6842" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="110.423" y="15.1579" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="110.423" y="27.7895" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="110.423" y="40.4211" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="110.423" y="53.0526" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="110.423" y="65.6842" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="122.971" y="15.1579" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="122.971" y="27.7895" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="122.971" y="40.4211" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="122.971" y="53.0526" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="122.971" y="65.6842" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="135.519" y="15.1579" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="135.519" y="27.7895" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="135.519" y="40.4211" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="135.519" y="53.0526" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="135.519" y="65.6842" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="148.067" y="15.1579" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="148.067" y="27.7895" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="148.067" y="40.4211" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="148.067" y="53.0526" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="148.067" y="65.6842" width="10.0385" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="160.615" y="15.1579" width="10.0384" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="160.615" y="27.7895" width="10.0384" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="160.615" y="40.4211" width="10.0384" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="160.615" y="53.0526" width="10.0384" height="10.1053" rx="2" fill="#E5E5E5" />
    <rect x="160.615" y="65.6842" width="10.0384" height="10.1053" rx="2" fill="#E5E5E5" />
    <g>
      <path
        d="M70.7004 40.7122H70.3341L70.4676 41.0534L71.7812 44.4085L71.7814 44.4088C71.8265 44.5234 71.7709 44.6421 71.6862 44.6828L70.5312 45.2106L70.531 45.2107C70.4628 45.2419 70.3641 45.2169 70.3183 45.1055L69.0707 41.9212L68.9254 41.5504L68.6546 41.8425L66.6156 44.0416C66.5585 44.1032 66.4993 44.1053 66.4474 44.0808C66.3881 44.0529 66.3365 43.9868 66.3365 43.8944V33.2938C66.3365 33.1959 66.3902 33.1325 66.4512 33.1059C66.5112 33.0798 66.5675 33.0907 66.609 33.1392L66.6088 33.1393L66.6156 33.1466L73.3071 40.3639L73.3071 40.364L73.3118 40.3688C73.3666 40.4248 73.3808 40.5083 73.3486 40.5904C73.3159 40.674 73.2543 40.7122 73.1964 40.7122H70.7004Z"
        fill="white"
        stroke="black"
        strokeWidth="0.5"
      />
    </g>
  </svg>
);

export const Step3Graphic = () => (
  <svg width="198" height="170" viewBox="0 0 198 170" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <rect x="155.779" y="0.451019" width="42.2206" height="168.586" rx="2" fill="#F3F3F3" />
      <rect y="13.531" width="151.412" height="82.1131" rx="2" fill="#E0EBF1" />
      <rect y="100.004" width="151.412" height="69.0331" rx="2" fill="#F3F3F3" />
      <path
        d="M0 102.004C0 100.899 0.895431 100.004 2 100.004H149.412C150.516 100.004 151.412 100.899 151.412 102.004V108.724H0V102.004Z"
        fill="#E5E5E5"
      />
      <rect x="19.5334" y="113.096" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="19.5334" y="123.994" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="19.5334" y="134.891" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="19.5334" y="145.789" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="19.5334" y="156.686" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="30.3854" y="113.096" width="8.68154" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="30.3854" y="123.994" width="8.68154" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="30.3854" y="134.891" width="8.68154" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="30.3854" y="145.789" width="8.68154" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="30.3854" y="156.686" width="8.68154" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="41.2373" y="113.096" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="41.2373" y="123.994" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="41.2373" y="134.891" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="41.2373" y="145.789" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="41.2373" y="156.686" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="52.0892" y="113.096" width="8.68154" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect
        x="52.4642"
        y="124.369"
        width="7.93154"
        height="7.96812"
        rx="1.625"
        fill="#E5E5E5"
        stroke="#796A94"
        strokeWidth="0.75"
      />
      <rect x="52.0892" y="134.891" width="8.68154" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="52.0892" y="145.789" width="8.68154" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="52.0892" y="156.686" width="8.68154" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="62.9412" y="113.096" width="8.68155" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="62.9412" y="123.994" width="8.68155" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="62.9412" y="134.891" width="8.68155" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="62.9412" y="145.789" width="8.68155" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="62.9412" y="156.686" width="8.68155" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="73.793" y="113.096" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="73.793" y="123.994" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="73.793" y="134.891" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="73.793" y="145.789" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="73.793" y="156.686" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="84.645" y="113.096" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="84.645" y="123.994" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="84.645" y="134.891" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="84.645" y="145.789" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="84.645" y="156.686" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="95.4969" y="113.096" width="8.68155" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="95.4969" y="123.994" width="8.68155" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="95.4969" y="134.891" width="8.68155" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="95.4969" y="145.789" width="8.68155" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="95.4969" y="156.686" width="8.68155" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="106.349" y="113.096" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="106.349" y="123.994" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="106.349" y="134.891" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="106.349" y="145.789" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="106.349" y="156.686" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="117.201" y="113.096" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="117.201" y="123.994" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="117.201" y="134.891" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="117.201" y="145.789" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="117.201" y="156.686" width="8.68153" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="128.053" y="113.096" width="8.68155" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="128.053" y="123.994" width="8.68155" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="128.053" y="134.891" width="8.68155" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="128.053" y="145.789" width="8.68155" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="128.053" y="156.686" width="8.68155" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="138.905" y="113.096" width="8.68155" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="138.905" y="123.994" width="8.68155" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="138.905" y="134.891" width="8.68155" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="138.905" y="145.789" width="8.68155" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect x="138.905" y="156.686" width="8.68155" height="8.71812" rx="2" fill="#E5E5E5" />
      <rect y="0.451019" width="151.412" height="8.71997" rx="2" fill="#F3F3F3" />
      <g clipPath="url(#clip1)">
        <path
          d="M116.471 2.87324V2.63102C116.471 2.49779 116.361 2.38879 116.228 2.38879H113.316C113.183 2.38879 113.073 2.49779 113.073 2.63102V3.5999C113.073 3.73312 113.183 3.84212 113.316 3.84212H116.228C116.361 3.84212 116.471 3.73312 116.471 3.5999V3.35768H116.713V4.32657H114.287V6.991C114.287 7.12422 114.396 7.23322 114.529 7.23322H115.015C115.148 7.23322 115.257 7.12422 115.257 6.991V4.81101H117.198V2.87324H116.471Z"
          fill="#575F6A"
        />
      </g>
      <g clipPath="url(#clip2)">
        <path
          d="M120.838 6.991H121.324V6.50655H120.838V6.991ZM121.809 6.991H122.294V6.50655H121.809V6.991ZM121.324 3.5999H120.838V4.08434H121.324V3.5999ZM120.838 6.02211H121.324V5.53767H120.838V6.02211ZM122.294 2.63101H121.809V3.11545H122.294V2.63101ZM121.324 2.63101H120.838V3.11545H121.324V2.63101ZM124.235 2.63101H123.75V3.11545H124.235V2.63101ZM124.721 4.08434H125.206V3.5999H124.721V4.08434ZM124.721 2.63101V3.11545H125.206V2.63101H124.721ZM123.75 6.991H124.235V6.50655H123.75V6.991ZM123.022 2.63101C122.889 2.63101 122.779 2.74001 122.779 2.87323V4.56878H121.081C120.947 4.56878 120.838 4.67778 120.838 4.811C120.838 4.94423 120.947 5.05323 121.081 5.05323H122.779V6.74878C122.779 6.882 122.889 6.991 123.022 6.991C123.156 6.991 123.265 6.882 123.265 6.74878V5.05323H124.963C125.097 5.05323 125.206 4.94423 125.206 4.811C125.206 4.67778 125.097 4.56878 124.963 4.56878H123.265V2.87323C123.265 2.74001 123.156 2.63101 123.022 2.63101ZM124.721 6.991H125.206V6.50655H124.721V6.991ZM124.721 6.02211H125.206V5.53767H124.721V6.02211Z"
          fill="#575F6A"
        />
      </g>
      <rect x="112.103" y="8.44434" width="5.82352" height="0.726664" rx="0.363332" fill="#796A94" />
      <path
        d="M96.0882 46.3762V33.8776C96.0882 32.2789 94.7779 30.9709 93.1764 30.9709H90.2646C88.6632 30.9709 87.3529 32.2789 87.3529 33.8776V38.5282L77.6567 29.8083C76.5502 28.82 74.8614 28.82 73.7549 29.8083L49.4126 51.6954C48.4226 52.5674 49.034 54.2242 50.3735 54.2242H55.3235V74.5708C55.3235 76.1694 56.6337 77.4774 58.2352 77.4774H66.9705C68.572 77.4774 69.8823 76.1694 69.8823 74.5708V60.0375H81.5293V74.5708C81.5293 76.1694 82.8396 77.4774 84.4411 77.4774H93.1764C94.7779 77.4774 96.0882 76.1694 96.0882 74.5708V54.2242H101.038C102.378 54.2242 103.018 52.5674 101.999 51.6954L96.0882 46.3762ZM69.8823 48.4109C69.8823 45.2136 72.5029 42.5976 75.7058 42.5976C78.9088 42.5976 81.5293 45.2136 81.5293 48.4109H69.8823Z"
        fill="#9198A1"
      />
      <path
        d="M92.0238 44.6564C89.7078 44.6564 87.8382 46.5227 87.8382 48.8347C87.8382 50.6593 86.2197 51.6203 85.0477 51.6203C86.3313 53.3195 88.5218 54.4058 90.6286 54.4058C93.7121 54.4058 96.2095 51.9128 96.2095 48.8347C96.2095 46.5227 94.3399 44.6564 92.0238 44.6564ZM111.152 31.6061L109.283 29.7398C108.739 29.1966 107.86 29.1966 107.315 29.7398L94.8143 42.2191L98.6511 46.0492L111.152 33.5699C111.696 33.0268 111.696 32.1493 111.152 31.6061Z"
        fill="#575F6A"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="198" height="168.586" fill="white" transform="translate(0 0.451019)" />
      </clipPath>
      <clipPath id="clip1">
        <rect width="5.82352" height="5.81331" fill="white" transform="translate(112.103 1.90436)" />
      </clipPath>
      <clipPath id="clip2">
        <rect width="5.82353" height="5.81331" fill="white" transform="translate(120.11 1.90436)" />
      </clipPath>
    </defs>
  </svg>
);
