import * as React from 'react';
import { ReactNode } from 'react';
import { StyledParagraph } from './Paragraph.styled';
import Skeleton from 'react-loading-skeleton';
import { BaseProps } from '../../interfaces/BaseProps';

export interface ParagraphProps extends BaseProps {
  children?: ReactNode;
}

export const Paragraph = React.forwardRef((props: ParagraphProps, ref: React.Ref<HTMLParagraphElement>) => (
  <StyledParagraph {...props} ref={ref}>
    {props.children || <Skeleton count={3} />}
  </StyledParagraph>
));
