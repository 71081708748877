import * as React from 'react';
import { StyledOptionPillOrDivider, StyledSpan } from './OptionPillOrDivider.styled';
import { FormattedMessage } from 'react-intl';
import { BaseProps } from '../../interfaces/BaseProps';

type OptionPillOrDividerProps = BaseProps;
const OptionPillOrDivider = React.forwardRef<HTMLDivElement, OptionPillOrDividerProps>(({ ...rest }, ref) => {
  return (
    <StyledOptionPillOrDivider {...rest} ref={ref}>
      <StyledSpan>
        <FormattedMessage id={'editor.behavior.or'} />
      </StyledSpan>
    </StyledOptionPillOrDivider>
  );
});

export { OptionPillOrDivider, OptionPillOrDividerProps };
