import * as React from 'react';
import { StyledChatMessage } from './ChatMessage.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { HyperlinkParser } from './HyperlinkParser';

interface ChatMessageProps extends BaseProps, ChildrenProp {
  isMine?: boolean;
}

const ChatMessage = React.forwardRef<HTMLDivElement, ChatMessageProps>(({ isMine, children, ...rest }, ref) => {
  return (
    <StyledChatMessage {...rest} $isMine={isMine} ref={ref}>
      <HyperlinkParser>{children}</HyperlinkParser>
    </StyledChatMessage>
  );
});

export { ChatMessage, ChatMessageProps };
