import * as React from 'react';
import { StyledStatusBanner } from './StatusBanner.styled';
import { BaseProps } from '../../interfaces/BaseProps';
import { EstateStatus } from '@innobrix/definitions';
import { FormattedMessage } from 'react-intl';

interface StatusBannerProps extends BaseProps {
  status: EstateStatus;
}

const StatusBanner = React.forwardRef<HTMLDivElement, StatusBannerProps>(({ status, ...rest }, ref) => {
  return (
    <StyledStatusBanner {...rest} status={status} ref={ref}>
      {status === EstateStatus.Available ? (
        // Available
        <FormattedMessage id="estate.status.available" defaultMessage="Available" />
      ) : status === EstateStatus.UnderOption ? (
        // Under Option
        <FormattedMessage id="estate.status.under-option" defaultMessage="Under option" />
      ) : status === EstateStatus.Sold ? (
        // Sold
        <FormattedMessage id="estate.status.sold" defaultMessage="Sold" />
      ) : (
        // Not available
        <FormattedMessage id="estate.status.not-available" defaultMessage="Not available" />
      )}
    </StyledStatusBanner>
  );
});

export { StatusBanner, StatusBannerProps };
export { StatusBannerClip } from './StatusBanner.styled';
