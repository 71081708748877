import { BoxProps } from './Box';
import styled, { css } from 'styled-components';

export const StyledBox = styled.div<BoxProps>(
  ({ theme, pad }) => css`
    min-width: 0;

    & {
      ${pad && `padding: ${theme.spacing[pad]}`};
    }
  `
);
