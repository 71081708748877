import * as React from 'react';
import { StyledChatMessageGroup, Timestamp } from './ChatMessageGroup.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { FormattedTime } from 'react-intl';

interface ChatMessageGroupProps extends BaseProps, ChildrenProp {
  time?: Date;
  isMine?: boolean;
}

const ChatMessageGroup = React.forwardRef<HTMLDivElement, ChatMessageGroupProps>(
  ({ children, isMine, time, ...rest }, ref) => (
    <StyledChatMessageGroup {...rest} $isMine={isMine} ref={ref}>
      {children}

      <Timestamp $isMine={isMine}>
        <FormattedTime value={time} />
      </Timestamp>
    </StyledChatMessageGroup>
  )
);

export { ChatMessageGroup, ChatMessageGroupProps };
