export const primary = {
  xdark: '#494059',
  dark: '#615577',
  default: '#796a94',
  light: '#9488aa',
  xlight: '#afa6bf',
  xxlight: '#c9c4d4',
  xxxlight: '#e4e1ea',
};

export const secondary = {
  xdark: '#0E1F3D',
  dark: '#1D304D',
  default: '#354B6A',
  light: '#4D6887',
  xlight: '#6182A2',
  xxlight: '#A2C1D9',
  xxxlight: '#D8E7F5',
};

export const tertiary = {
  default: '#f0504a',
};

export const neutral = {
  xxdark: '#2f3237',
  xdark: '#464b53',
  dark: '#575f6a',
  medium: '#757e8a',
  light: '#acb1b9',
  xlight: '#d6d8dc',
  xxlight: '#f1f2f3',
  xxxlight: '#F9F9FA',
  white: '#fff',
};

export const text = {
  default: '#575f6a',
  light: `rgba(255, 255, 255, 0.8)`,
};

export const success = {
  xxdark: '#103C10',
  xdark: '#2B542B',
  dark: '#497645',
  default: '#6B9E63',
  light: '#88B980',
  xlight: '#B2CEAE',
  xxlight: '#EDF2EC',
};

export const warning = {
  xxdark: '#622300',
  xdark: '#85430A',
  dark: '#C2801D',
  default: '#E5B219',
  light: '#F0C03E',
  xlight: '#F0CF75',
  xxlight: '#FAF0D1',
};

export const error = {
  xxdark: '#600606',
  xdark: '#681616',
  dark: '#90221E',
  default: '#BE433F',
  light: '#D2706C',
  xlight: '#EDB7B6',
  xxlight: '#F7D8D7',
};
