import { css } from 'styled-components';

// Default Innobrix font style
export const klavika = css(
  ({ theme }) => css`
    font-family: ${theme.typography.headingFamily};
    font-weight: ${theme.typography.headings.h1.weight};
    line-height: ${theme.typography.headings.h1.size};
  `
);

export const openSans = css(
  ({ theme }) => css`
    font: normal normal ${theme.typography.text.size} / ${theme.typography.text.height} ${theme.typography.family};
  `
);
