import * as React from 'react';
import { StyledLogoIcon } from './LogoIcon.styled';
import { ChildrenProp, BaseProps } from '../../interfaces/BaseProps';

export interface LogoIconProps extends BaseProps, ChildrenProp {
  inverted?: boolean;
}

const LogoIcon = React.forwardRef((props: LogoIconProps, ref: React.Ref<SVGSVGElement>) => {
  const { className, inverted = false, ...rest } = props;
  return <StyledLogoIcon {...rest} inverted={inverted} className={className} ref={ref} />;
});

LogoIcon.defaultProps = {
  inverted: false,
};

export { LogoIcon };
