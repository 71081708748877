import { ActionCard } from './ActionCard';
import styled, { css } from 'styled-components';
import { Image } from '../Image';
import { Heading } from '../typo/Heading';
import { Span } from '../typo/Span';
import { textEllipsis } from '../../utilities/mixins/textOverflow';

export const StyledCompanyCard = styled(ActionCard)(
  ({ theme }) => css`
    position: relative;
    cursor: pointer;
  `
);

export const StyledIdLabel = styled(Span)(
  ({ theme }) => css`
    position: absolute;
    font-size: 0.75em;
    color: ${theme.colors.neutral.medium};

    // For the skeleton
    min-width: 1em;
  `
);

export const CompanyThumbnailWrapper = styled.div(
  ({ theme }) => css`
    --size: 4.5em;
    width: var(--size);
    height: var(--size);
    border-radius: 100%;
    margin-bottom: ${theme.spacing.small};
    background: ${theme.colors.neutral.xxlight};
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.25);
    display: flex;
    place-content: center;
  `
);

export const CompanyThumbnail = styled(Image)(
  ({ theme }) => css`
    max-width: 60%;
    object-fit: scale-down;
  `
);

export const CompanyHeadings = styled.div<{ isEmpty?: boolean }>(
  ({ theme, isEmpty }) => css`
    max-width: 100%;
    margin-bottom: ${theme.spacing.small};
    text-align: right;

    // For the skeleton
    width: ${isEmpty && '100%'};
  `
);

export const CompanyHeading = styled(Heading).attrs({ level: 4 })(
  ({ theme }) => css`
    margin-bottom: 0;
    ${textEllipsis};
  `
);

export const CompanySubheading = styled(Span)(
  ({ theme }) => css`
    display: block;
    font-size: 0.75em;
    line-height: 1.2;
    ${textEllipsis};
  `
);
