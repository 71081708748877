import { CardProps } from './Card';
import styled, { css } from 'styled-components';

export const StyledCard = styled.div<CardProps>(
  ({ theme, round, pad, hover, overflow }) => css`
    background: ${theme.colors.neutral.white};
    box-shadow: ${theme.elevation.light};

    ${round ? `border-radius: ${theme.borderRadius[round]}` : ''};
    ${pad && `padding: ${theme.spacing[pad]}`};

    ${overflow === true
      ? css`
          overflow: auto;
        `
      : overflow === 'x'
      ? css`
          overflow-x: auto;
          overflow-y: hidden;
        `
      : overflow === 'y'
      ? css`
          overflow-y: auto;
          overflow-x: hidden;
        `
      : undefined};

    ${hover
      ? css`
          transition: ${theme.transition.xSlow};
          transition-property: box-shadow, transform;

          :hover,
          :focus {
            box-shadow: ${theme.elevation.large};
            transform: translateY(-0.125em);
          }

          :active {
            box-shadow: ${theme.elevation.light};
            transform: none;
          }
        `
      : undefined};
  `
);
