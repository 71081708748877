import styled from 'styled-components';

export const DashboardLayout = styled.div`
  position: relative;
  height: 100%;
  max-height: calc(100vh - var(--header-height, 0));
  padding: 4.5rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-template-areas:
    'user     user      card1     card2     ibx'
    'user     user      card3     card4     ibx'
    'card5    card6     card7     card8     ibx'
    'bigcard1 bigcard1  bigcard2  bigcard2  active'
    'bigcard1 bigcard1  bigcard2  bigcard2  active';
`;
