import { fontSize } from '@innobrix/components';
import styled, { css } from 'styled-components';

export const CompareLabelStyled = styled.span(
  ({ theme }) => css`
    display: inline-flex;
    align-items: center;

    font-size: ${fontSize.s16};
    color: ${theme.colors.success.default};
  `
);

export const IconWrapper = styled.span`
  font-size: ${fontSize.s14};
`;
