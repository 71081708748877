import styled, { css } from 'styled-components';
import { Heading } from '../typo/Heading';
import { ThemedProps } from '../../interfaces/ThemedProps';
import { Icon } from '../Icon';
import { CloseButton } from '../navigation/CloseButton';
import { FlexBox } from '../layout/FlexBox';
import { math } from 'polished';
import { Severity } from '@innobrix/definitions';

type Props = {
  expanded: boolean;
};

export const StyledNotification = styled.div<Props>(
  ({ theme, expanded }) => css`
    --iconColor: ${theme.colors.neutral.medium};

    width: ${expanded ? '100%' : undefined};
    max-width: 100%;
    background: ${theme.colors.neutral.white};
    padding: ${theme.spacing.medium};
    border-radius: ${theme.borderRadius.small};
    box-shadow: ${expanded ? 'none' : theme.elevation.light};

    transition: ${theme.transition.default};
    transition-property: box-shadow;

    :hover {
      --iconColor: currentColor;
      box-shadow: ${theme.elevation.medium};
    }
  `
);

export const StyledNotificationHeading = styled(Heading).attrs({ level: 4 })<Props>(
  ({ theme, expanded }) => css`
    font-size: 1em;

    ${expanded
      ? css`
          margin-bottom: ${theme.spacing.xsmall};
        `
      : css`
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        `};
  `
);

export const StyledNotificationTimeLabel = styled.span(
  ({ theme }) => css`
    font-size: smaller;
    line-height: 1;
  `
);

export const StyledHeader = styled.div<Props>(({ theme, expanded }) => {
  return css`
    position: relative;
    display: flex;
    flex-flow: ${expanded ? 'column' : 'row'};

    ${!expanded
      ? css`
          align-items: center;
          margin: 0 ${math(`-1 * (${theme.spacing.small} / 2)`)};

          > * {
            margin: 0 ${math(`${theme.spacing.small} / 2`)};
          }

          ${StyledInnerHeader} {
            margin-bottom: 0;
          }

          ${StyledDot},
          ${StyledNotificationTimeLabel} {
            display: none;
          }

          ${StyledNotificationHeading} {
            line-height: 1.4;
          }

          > :nth-last-child(2) {
            margin-right: ${theme.spacing.large};
          }
        `
      : css`
          ${StyledInnerHeader} > :last-child {
            display: none;
          }
        `};
  `;
});

export const StyledInnerHeader = styled(FlexBox).attrs({ align: 'center' })(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.small};

    > :last-child {
      margin-right: 1.5em;
    }
  `
);

export const StyledIcon = styled(Icon)<ThemedProps<{ severity?: Severity }>>(
  ({ theme, severity }) => css`
    color: ${severity === 'error'
      ? theme.colors.error.default
      : severity === 'warning'
      ? theme.colors.warning.default
      : severity === 'success'
      ? theme.colors.success.default
      : 'currentColor'};
  `
);

export const StyledNotificationCloseButton = styled(CloseButton)<Props>(
  ({ theme, expanded }) => css`
    font-size: 1.25rem;
    color: var(--iconColor);
    right: 0;
    position: absolute;

    transition: ${theme.transition.default};
    transition-property: background-color, color;

    ${expanded
      ? `
          top: 0;
        `
      : css`
          top: 50%;
          transform: translateY(-50%);
        `}
  `
);

export const StyledNotificationContent = styled.div<Props>`
  font-size: 0.9em;

  ${(props) => !props.expanded && `display: none;`}
`;

export const StyledNotificationFooter = styled(FlexBox).attrs({ gutter: 'xsmall' })`
  margin-top: ${(p) => p.theme.spacing.medium};
`;

export const StyledDot = styled.span(
  ({ theme }) => css`
    display: inline-block;
    width: 0.25em;
    height: 0.25em;
    border-radius: 100%;
    background-color: currentColor;
    margin: 0 ${theme.spacing.small};
  `
);
