import * as React from 'react';
import { LogoOverlay, StyledLogoUpload } from './LogoUpload.styled';
import { HiddenInput } from './ImageUpload.styled';
import { LogoIcon } from '../logo/LogoIcon';
import { FaCloudUploadAlt, FaPen } from 'react-icons/fa';
import { useDropzone } from 'react-dropzone';
import { BaseProps } from '../../interfaces/BaseProps';
import { BaseInputProps } from '../../interfaces/InputProps';

interface LogoUploadProps extends BaseProps, Omit<BaseInputProps, 'onChange'> {
  value?: string;
  onChange?: (value: File) => void;
  onBlur?: React.FocusEventHandler;
}

const LogoUpload = React.forwardRef<HTMLDivElement, LogoUploadProps>(
  ({ className, name, value, onChange, onBlur, ...rest }, ref) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: 'image/png',
      multiple: false,
      onDropAccepted<T extends File>(files: T[]) {
        if (files.length <= 0) return;
        onChange?.(files[0]);
      },
    });

    const imageBlob = value;
    const rootProps = getRootProps();

    return (
      <StyledLogoUpload
        className={className}
        {...rootProps}
        ref={ref}
        onBlur={(event) => {
          onBlur?.(event);
          rootProps?.onBlur?.(event);
        }}
      >
        <HiddenInput {...getInputProps()} {...rest} />

        {imageBlob ? <img src={imageBlob} alt={'logo'} /> : <LogoIcon />}

        <LogoOverlay style={isDragActive ? { opacity: 1 } : undefined}>
          {isDragActive ? <FaCloudUploadAlt /> : <FaPen />}
        </LogoOverlay>
      </StyledLogoUpload>
    );
  }
);

export { LogoUpload, LogoUploadProps };
