import * as React from 'react';
import { StyledNumberInput, StyledNumberSliderInput, StyledSlider } from './NumberSliderInput.styled';
import { SliderProps } from './Slider';
import { ChildrenProp } from '../../interfaces/BaseProps';
import RCSlider from 'rc-slider';
import { NumberInputProps } from './number/NumberInput';

interface NumberSliderInputProps
  extends Omit<SliderProps, 'onChange'>,
    ChildrenProp,
    Pick<NumberInputProps, 'onChange'> {}

const NumberSliderInput = React.forwardRef<RCSlider, NumberSliderInputProps>(
  ({ name, id, min = 0, max = 100, step, onChange, value, disabled, children, ...rest }, ref) => {
    return (
      <StyledNumberSliderInput align={'center'}>
        {children}
        <StyledNumberInput value={value} name={name} min={min} max={max} disabled={disabled} onChange={onChange} />
        <StyledSlider
          id={id}
          name={name}
          step={step}
          min={min}
          max={max}
          value={value}
          onChange={onChange}
          disabled={disabled}
          {...rest}
          ref={ref}
        />
      </StyledNumberSliderInput>
    );
  }
);

export { NumberSliderInput, NumberSliderInputProps };
