import { AddProjectCardProps } from './AddCard';
import styled, { css } from 'styled-components';
import { Stack } from '../layout/Stack';
import { Span } from '../typo/Span';

const HighStack = styled(Stack)`
  height: 100%;
  min-height: 175px;
`;

const StyledAddProjectCard = styled.div<AddProjectCardProps>(
  ({ theme }) => css`
    color: ${theme.colors.primary.default};
    border: 2px dashed ${theme.colors.primary.default};
    border-radius: ${theme.borderRadius.medium};

    transition: ${theme.transition.slow};
    transition-property: border-width, background, color, stroke, box-shadow;

    svg {
      fill: none;
      width: 3rem;
      height: 3rem;
    }

    path {
      stroke: currentColor;
      stroke-width: 2px;
    }

    :hover,
    :focus {
      color: ${theme.colors.primary.xxxlight};
      background: ${theme.colors.primary.default};
      box-shadow: ${theme.elevation.large};
      mix-blend-mode: normal;
      cursor: pointer;
    }

    :active {
      box-shadow: none;
    }
  `
);

const StyledText = styled(Span)`
  transform: translateY(250%);
  position: absolute;
  pointer-events: none;
`;

export { StyledAddProjectCard, HighStack, StyledText };
