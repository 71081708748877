import { css, DefaultTheme } from 'styled-components';
import { FormikInputProps } from '../../interfaces/InputProps';
import { ThemedProps } from '../../interfaces/ThemedProps';

type Props = Omit<FormikInputProps, 'name'> & { disabled?: boolean };

// Default Innobrix styling for input box
export const inputBox = ({ theme, disabled, error, touched }: ThemedProps<Props>) => css`
  background: ${disabled ? theme.colors.neutral.xlight : theme.colors.neutral.xxlight};
  color: ${disabled ? theme.colors.neutral.light : 'currentColor'};
  border-bottom: ${error && touched && `2px solid ${theme.colors.error.default}`};

  border-radius: ${theme.borderRadius.small};
  box-shadow: ${theme.elevation.xlight};

  transition: ${theme.transition.slow};
  transition-property: box-shadow, border, color, background-color;
`;

// Box shadow for focused input box
export const inputBoxFocus = ({ theme, disabled }: ThemedProps<Props>) => css`
  box-shadow: ${!disabled ? `${theme.elevation.xlight}, ${theme.elevation.medium}` : undefined};
`;

// Reset default input styling
export const inputStyle = (theme: DefaultTheme) => css`
  border: none;
  font: inherit;
  background: none;
  outline: none;
  padding: ${theme.spacing.small};
  color: currentColor;
  caret-color: ${theme.colors.primary.default};

  ${removeInputNumberSpinners};

  :disabled {
    cursor: not-allowed;
  }

  ::placeholder {
    color: ${theme.colors.neutral.light};
  }
`;

// Removes number spinners (up/down arrows) from input
export const removeInputNumberSpinners = css`
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

// Resets default auto fil color (yellow/blue, etc...)
export const autofillMixin = () => css`
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px var(--bg) inset;
  }
`;
