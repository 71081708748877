import * as React from 'react';
import { Container, IconWrapper, StyledInputWrapper, StyledTextInput } from './TextInput.styled';
import { ErrorMessage } from './ErrorMessage';
import { IconProps } from '../Icon';
import { BaseProps } from '../../interfaces/BaseProps';
import { BaseInputProps } from '../../interfaces/InputProps';

export interface TextInputProps extends BaseProps, BaseInputProps {
  iconBefore?: React.ReactElement<IconProps> | React.ReactText;
  iconAfter?: React.ReactElement<IconProps> | React.ReactText;
  iconAfterWrapperEvent?: React.MouseEventHandler;

  noIconBackdrop?: boolean;
}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      type = 'text',
      error,
      disabled,
      touched = false,
      iconAfter,
      iconAfterWrapperEvent,
      iconBefore,
      className,
      noIconBackdrop,
      ...rest
    },
    ref
  ) => (
    <Container inline={true}>
      <StyledInputWrapper error={error} touched={touched} disabled={disabled} className={className}>
        {iconBefore != null ? <IconWrapper $noIconBackdrop={noIconBackdrop}>{iconBefore}</IconWrapper> : undefined}
        <StyledTextInput type={type} error={error} disabled={disabled} touched={touched} {...rest} ref={ref} />
        {iconAfter != null ? (
          <IconWrapper onClick={iconAfterWrapperEvent} $noIconBackdrop={noIconBackdrop}>
            {typeof iconAfter === 'string' ? <span>{iconAfter}</span> : iconAfter}
          </IconWrapper>
        ) : undefined}
      </StyledInputWrapper>

      {error && touched && <ErrorMessage error={error} />}
    </Container>
  )
);
