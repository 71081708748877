import * as React from 'react';
import { AuthGuard } from './components/AuthGuard';
import { Providers } from './providers/providers';
import Router from './router';

const MemoedChildren = React.memo(() => <Router />);

export function App() {
  return (
    <Providers>
      <AuthGuard>
        <MemoedChildren />
      </AuthGuard>
    </Providers>
  );
}

export default App;
