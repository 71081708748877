import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { LabelHTMLAttributes } from 'react';

export interface LabelProps
  extends BaseProps,
    Required<ChildrenProp>,
    Pick<LabelHTMLAttributes<HTMLLabelElement>, 'htmlFor'> {
  disabled?: boolean;
}
export { StyledLabel as Label } from './Label.styled';
