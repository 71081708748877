import * as React from 'react';
import { Control, ControlsContainer, StyledInlineControls } from './InlineControls.styled';
import { FaEllipsisH } from 'react-icons/fa';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { FlexBox } from '../layout/FlexBox';
import { CloseButton } from '../navigation/CloseButton';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useClickAway, usePrevious } from 'react-use';
import mergeRefs from 'react-merge-refs';

interface InlineControlsProps extends BaseProps, ChildrenProp {
  isOpen?: boolean;
  onChange?: (isOpen: boolean) => void;
}

const InlineControls = React.forwardRef<HTMLDivElement, InlineControlsProps>(
  ({ isOpen = false, onChange, children, ...rest }, ref) => {
    const previous = usePrevious(isOpen);
    const [_isOpen, setIsOpen] = useState(isOpen);

    useEffect(() => {
      if (previous !== isOpen) setIsOpen(isOpen);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
      onChange?.(isOpen);
    }, [isOpen, onChange]);

    const closeControls = useCallback((e?: React.MouseEvent) => {
      e?.preventDefault();
      setIsOpen(false);
      e?.stopPropagation();
    }, []);

    const openControls = useCallback((e: React.MouseEvent) => {
      e.preventDefault();
      setIsOpen(true);
      e.stopPropagation();
    }, []);

    const clickAwayRef = useRef<HTMLDivElement>(null);
    useClickAway(clickAwayRef, () => closeControls());

    return (
      <StyledInlineControls {...rest} ref={mergeRefs([ref, clickAwayRef])} onClick={closeControls}>
        <Control onClick={openControls}>
          <FaEllipsisH />
        </Control>

        <ControlsContainer $isOpen={_isOpen}>
          <FlexBox>
            {children}

            <Control>
              <CloseButton onClick={closeControls} />
            </Control>
          </FlexBox>
        </ControlsContainer>
      </StyledInlineControls>
    );
  }
);

export { Control } from './InlineControls.styled';
export { InlineControls, InlineControlsProps };
