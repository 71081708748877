import * as React from 'react';
import { Handle, HandleBackdrop, StyledHiddenInput, StyledToggle } from './Toggle.styled';
import { CheckboxProps } from './Checkbox';

type ToggleProps = CheckboxProps;

const Toggle = React.forwardRef<HTMLLabelElement, ToggleProps>(({ className, children, ...rest }, ref) => {
  return (
    <StyledToggle className={className} ref={ref}>
      {children}

      <StyledHiddenInput {...rest} />
      <HandleBackdrop>
        <Handle />
      </HandleBackdrop>
    </StyledToggle>
  );
});

export { Toggle, ToggleProps };
