/**
 * Use this method before an action invocation to prevent it from execution if the event.target is one of the following
 * tags:
 * - <input ...>
 * - <textarea ...>
 * @param e {Event} The instigated event
 * @param elseEvent Function to execute as normal (you're able to reuse the event)
 */
export const PreventExecutionOnInputFocus = (e?: KeyboardEvent, elseEvent?: () => void): void => {
  if (e == null) {
    elseEvent?.();
    return;
  }
  switch ((e.target as HTMLElement)?.tagName.toLowerCase()) {
    case 'input':
    case 'textarea':
      break;
    default:
      elseEvent?.();
      break;
  }
};
