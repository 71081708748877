import { useEffectOnce } from 'react-use';
import ReactGA from 'react-ga';
import { environment } from '../../../../environments/environment';

export function useAnalytics() {
  useEffectOnce(() => {
    if (environment.googleAnalyticsViewer != null) {
      ReactGA.initialize(environment.googleAnalyticsViewer, { debug: process.env.NODE_ENV === 'development' });
      ReactGA.ga('set', 'anonymizeIp', true);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  });
}
