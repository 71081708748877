import { BaseProps, FlexBox } from '@innobrix/components';
import { PropsWithChildren } from 'react';
import * as React from 'react';
import { CompareLabel } from './CompareLabel';
import { MonthStatLabelStyled, StatLabel } from './MonthStatLabel.styled';

function calculateGrowth(thisMonth: number, lastMonth: number) {
  const growth = thisMonth / lastMonth;
  const growthPercent = growth >= 1 ? Math.round((growth - 1) * 100) : 100 - Math.round(growth * 100);

  return { growth, growthPercent };
}

type Props = BaseProps & {
  stat: number;
  compareTo?: number;
};

export const MonthStatLabel = ({ stat, compareTo, children, ...rest }: PropsWithChildren<Props>) => {
  const { growth, growthPercent } = calculateGrowth(stat, compareTo ?? 0);

  return (
    <MonthStatLabelStyled {...rest}>
      {children}
      <FlexBox align={'center'} gutter={'xsmall'}>
        <StatLabel stat={stat} />
        {compareTo != null && <CompareLabel growth={growth} growthPercentage={growthPercent} />}
      </FlexBox>
    </MonthStatLabelStyled>
  );
};
