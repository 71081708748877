import * as React from 'react';
import { withTheme } from 'styled-components';
import { SkeletonTheme } from 'react-loading-skeleton';
import { ThemedProps } from '../../interfaces/ThemedProps';

class MyStyledSkeletonTheme extends React.PureComponent<ThemedProps<any>> {
  render() {
    const { children, theme } = this.props;

    return (
      <SkeletonTheme color={theme.colors.neutral.xlight} highlightColor={theme.colors.neutral.xxlight}>
        {children}
      </SkeletonTheme>
    );
  }
}

export const StyledSkeletonTheme = withTheme(MyStyledSkeletonTheme);
