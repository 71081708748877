/**
 * Returns the first item in an object
 * @param obj
 */
export function first<T = unknown>(obj: Record<string, T>): T | undefined {
  const keys = Object.keys(obj);
  if (keys.length <= 0) return undefined;

  return obj[keys[0]];
}
