import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';

export const StyledColorPicker = styled.div<{ hideAlpha?: boolean }>(
  ({ theme, hideAlpha }) => css`
    .--mb--color-picker {
      position: static;
      top: unset;
      left: unset;
      width: unset;
      display: inline-block;

      padding-top: ${spacing.s8};
      background: ${theme.colors.neutral.xxlight};
      box-shadow: ${theme.elevation.medium};
      font-size: 1rem;
      z-index: 1;

      .color-picker-header {
        display: none;
      }

      .color-picker-body {
      }

      .color-input {
        width: 5ch;
        font-size: 0.75rem;
        text-align: center;

        :not(:last-child) {
          margin-right: ${theme.spacing.small};
        }

        > input {
          background: ${theme.colors.neutral.xlight};
          border-radius: ${theme.borderRadius.small};
          font-size: inherit;
          border: none;
          color: ${theme.colors.text.default};

          :hover,
          :focus {
            box-shadow: ${theme.elevation.light};
          }
        }
      }
      .hex-input {
        width: 9ch !important;
      }

      .s-v-plane {
        border-radius: ${theme.borderRadius.small};
        border: none;
        backface-visibility: hidden;

        .base-hue-layer {
          background-image: linear-gradient(transparent 0%, black 100%),
            linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%) !important;
        }

        .s-layer,
        .v-layer {
          display: none;
        }
      }

      .h-band,
      .a-band,
      .preview-container {
        border: none;
        border-radius: ${theme.borderRadius.xsmall};

        > :first-child {
          border-radius: ${theme.borderRadius.xsmall};
        }
      }

      .slider.slider {
        height: auto;
        top: -2px;
        bottom: -2px;
      }

      ${hideAlpha &&
      css`
        .h-band {
          margin-bottom: 0;
          height: 1.5rem;
        }

        .a-band {
          display: none;
        }

        .input-section {
          .color-input:nth-of-type(4) {
            margin-right: 0;
          }

          .color-input:nth-of-type(5) {
            display: none;
          }
        }
      `};
    }
  `
);
