import styled from 'styled-components';
import { spacing } from '../../../variables/spacing';
import { Icon } from '../../Icon';
import { borderRadius } from '../../../variables/borderRadius';
import { fontSize } from '../../../variables/typography';

export const StyledDocumentListItem = styled.li`
  padding: ${spacing.s4};
  border-radius: ${borderRadius.r4};

  transition: ${(p) => p.theme.transition.default};
  transition-property: background-color;

  :hover,
  :focus.focus-visible {
    background-color: ${(p) => p.theme.colors.neutral.xxlight};
  }
`;

export const DocumentIcon = styled(Icon)`
  font-size: ${fontSize.s24};
  margin-left: ${spacing.s4};
`;
