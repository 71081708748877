import styled, { css, keyframes } from 'styled-components';
import { ThemedProps } from '../../interfaces/ThemedProps';
import { StyledErrorMessage } from './ErrorMessage.styled';

const rcSliderTooltipZoomDownIn = keyframes`
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
`;
const rcSliderTooltipZoomDownOut = keyframes`
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
`;

const selector = '.rc-slider';
export const StyledSliderWrapper = styled.div<ThemedProps<{ error?: string; touched?: boolean; hasMarks?: boolean }>>(
  ({ theme, error, touched, hasMarks }) =>
    css`
      font-size: 1.25rem;
      margin: 0 0.25em ${hasMarks && (!touched || error == null) && '1.3125em'};

      display: flex;
      flex-flow: column;
      justify-content: center;

      ${StyledErrorMessage} {
        padding: 0;
        margin: ${hasMarks ? '2em' : '1em'} -0.25em 0;
        font-size: 1rem;
      }

      ${selector} {
        --bg: ${touched && error ? theme.colors.neutral.xlight : theme.colors.primary.xxxlight};
        --fg: ${touched && error ? theme.colors.error.default : theme.colors.primary.default};
        --highlight: ${touched && error ? theme.colors.error.xlight : theme.colors.primary.xlight};
        --height: 0.25em;

        position: relative;
        height: var(--height);
        box-sizing: content-box;
        padding: calc((1em - var(--height)) * 0.5) 0;
        width: 100%;
        border-radius: ${theme.borderRadius.medium};
        touch-action: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        cursor: pointer;

        transition: ${theme.transition.default};
        transition-property: background, color, border, box-shadow;

        &-rail {
          position: absolute;
          width: 100%;
          height: var(--height);
          border-radius: inherit;
          background-color: var(--bg);
          transition: inherit;
        }

        &-track {
          position: absolute;
          left: 0;
          height: var(--height);
          border-radius: inherit;
          background-color: ${touched && error ? theme.colors.error.light : theme.colors.primary.light};
          transition: inherit;
        }

        &-handle {
          font-size: 1em;
          position: absolute;
          width: 1em;
          height: 1em;
          cursor: grab;
          margin-top: -0.375em;
          border-radius: 50%;
          touch-action: pan-x;
          background-color: var(--fg);
          transition: inherit;
          outline: none;

          :hover,
          :focus,
          :active,
          &-click-focused:focus {
            box-shadow: 0 0 0 0.2em var(--highlight);
          }

          :active {
            //box-shadow: 0 0 0.3em var(--highlight);
            cursor: grabbing;
          }
        }

        &-step {
          position: absolute;
          width: 100%;
          height: var(--height);
          background: transparent;
          transition: inherit;
        }

        &-dot {
          position: absolute;
          bottom: -50%;
          margin-left: -0.25em;
          width: 0.5em;
          height: 0.5em;
          cursor: pointer;
          border-radius: 50%;

          color: ${theme.colors.primary.xxlight};
          background: currentColor;
          border-color: currentColor;
          transition: inherit;

          :hover,
          &-active {
            color: var(--fg);
          }

          &-reverse {
            margin-left: 0;
            margin-right: -4px;
          }
        }

        &-mark {
          position: absolute;
          top: 2em;
          left: 0;
          width: 100%;
          font-size: 0.75em;
          transition: inherit;

          &-text {
            position: absolute;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            cursor: pointer;
            color: ${theme.colors.neutral.light};
            transition: inherit;
            line-height: 1;

            :hover,
            &-active {
              color: currentColor;
            }
          }
        }

        &-disabled {
          --bg: ${theme.colors.neutral.xxlight};
          color: ${theme.colors.neutral.xlight};
          background: transparent;

          ${selector}-track {
            background-color: ${theme.colors.neutral.xlight};
          }

          ${selector}-handle,
          ${selector}-dot {
            border-color: ${theme.colors.neutral.xlight};
            box-shadow: none;
            background-color: ${theme.colors.neutral.light};
            cursor: not-allowed;
          }

          ${selector}-mark-text,
          ${selector}-dot {
            cursor: not-allowed !important;
          }
        }

        &-vertical {
          width: 1em;
          height: 100%;
          padding: 0 0.25em;

          ${selector}-rail {
            height: 100%;
            width: var(--height);
          }

          ${selector}-track {
            left: 5px;
            bottom: 0;
            width: var(--height);
          }

          ${selector}-handle {
            margin-left: -0.375em;
            touch-action: pan-y;
          }

          ${selector}-mark {
            top: 0;
            left: 1.5em;
            height: 100%;

            &-text {
              line-height: 2;
            }
          }
          ${selector}-step {
            height: 100%;
            width: var(--height);
          }
          ${selector}-dot {
            left: 0.125em;
            margin-bottom: -0.25em;
          }
          ${selector}-dot:first-child {
            margin-bottom: -0.25em;
          }
          ${selector}-dot:last-child {
            margin-bottom: -0.25em;
          }
        }

        &-tooltip {
          position: absolute;
          left: -9999px;
          top: -9999px;
          visibility: visible;
          box-sizing: border-box;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

          &-hidden {
            display: none;
          }

          &-placement-top {
            padding: 4px 0 8px 0;
          }

          &-inner {
            padding: 6px 2px;
            min-width: 24px;
            height: 24px;
            font-size: 12px;
            line-height: 1;
            color: #fff;
            text-align: center;
            text-decoration: none;
            background-color: #6c6c6c;
            border-radius: inherit;
            box-shadow: 0 0 4px #d9d9d9;
          }

          &-arrow {
            position: absolute;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
          }

          &-placement-top &-arrow {
            bottom: 4px;
            left: 50%;
            margin-left: -4px;
            border-width: 4px 4px 0;
            border-top-color: #6c6c6c;
          }

          &-zoom-down {
            &-enter,
            &-appear {
              animation-duration: 0.3s;
              animation-fill-mode: both;
              display: block !important;
              animation-play-state: paused;

              transform: scale(0, 0);
              animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
            }

            &-enter&-enter-active,
            &-appear&-appear-active {
              animation-name: ${rcSliderTooltipZoomDownIn};
              animation-play-state: running;
            }

            &-leave {
              animation-duration: 0.3s;
              animation-fill-mode: both;
              display: block !important;
              animation-play-state: paused;

              animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            }

            &-leave&-leave-active {
              animation-name: ${rcSliderTooltipZoomDownOut};
              animation-play-state: running;
            }
          }
        }
      }
    `
);
