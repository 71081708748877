import * as React from 'react';
import { StyledAccountPanel, StyledFlexBox, StyledLogout, StyledName, StyledSmall } from './AccountPanel.styled';
import { BaseProps } from '../../interfaces/BaseProps';
import { FormattedMessage } from 'react-intl';

type AccountPanelProps = BaseProps & {
  name: string;
  company: string;
  onLogOut?: () => void;
};

const AccountPanel = React.forwardRef((props: AccountPanelProps, ref: React.Ref<HTMLDivElement>) => {
  const { name, company, onLogOut, ...rest } = props;
  return (
    <StyledAccountPanel {...rest} ref={ref}>
      <StyledFlexBox>
        <StyledName>{name}</StyledName>
        <StyledLogout onClick={onLogOut}>
          <FormattedMessage id={'dashboard.title.sign-out'} defaultMessage={'Sign out'} />
        </StyledLogout>
      </StyledFlexBox>
      <StyledSmall>{company}</StyledSmall>
    </StyledAccountPanel>
  );
});

export { AccountPanel, AccountPanelProps };
