import * as React from 'react';
import { fontSize, klavika, SpinnerLabel, weight } from '@innobrix/components';
import { SVGProps } from 'react';
import styled, { css } from 'styled-components';

export const MilestoneGaugeStyled = styled.div`
  ${klavika};
  line-height: 1;
  font-weight: ${weight.regular};
  position: relative;
  width: min(100%, 225px);
`;

export const StatLabel = styled(SpinnerLabel)`
  font-size: ${fontSize.s36};
  font-weight: ${weight.medium};
`;

export const TextWrapper = styled.div`
  position: absolute;
  inset: auto 15% 50%;
  transform: translateY(90%);

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

/*<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 292 243">
  <path
    d="
      M 46, 223
      a 126,126 0 1,1 200,0
    "
    stroke="white"
    stroke-width="40"
    stroke-linecap="round"
  />
</svg>*/

export const ConicGradient = styled.div(
  ({ theme }) => css`
    width: 100%;
    aspect-ratio: 292/243;
    background-image: conic-gradient(from 180deg, #796a94 15%, #489fd1 50%, #69be5d 85%);
  `
);

const GaugeSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 292 243" {...props}>
    <path d="M46 223 a126 126 0 1 1 200 0" />

    <mask id="mask">
      <path className="progress" d="M46 223 a126 126 0 1 1 200 0" stroke="#fff" />
    </mask>
    <foreignObject x="0" y="0" width="100%" height="100%" mask="url(#mask)">
      <ConicGradient />
    </foreignObject>
  </svg>
);

export const Gauge = styled(GaugeSvg)(
  ({ theme }) => css`
    width: 100%;

    path {
      stroke-width: 32;
      stroke-linecap: round;

      &:not(.progress) {
        stroke: ${theme.colors.neutral.xxlight};
      }

      &.progress {
        stroke-dasharray: 560;
        stroke-dashoffset: calc(560 - var(--progress, 0) * 560);

        transition: stroke-dashoffset ${theme.transition.xSlow};
      }
    }
  `
);
