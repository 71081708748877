import styled from 'styled-components';
import { fontSize } from '../../variables/typography';
import { klavika } from '../../utilities/mixins/typography';
import { Badge } from '../atoms/Badge';

export const StyledNotificationBadge = styled(Badge)`
  ${klavika};
  line-height: 1;
  padding: 0;
  font-size: ${fontSize.s12};

  width: 1.25rem;
  height: 1.25rem;
  border-radius: 100%;

  display: flex;
  place-content: center;
  align-items: center;
`;
