import * as React from 'react';
import { FormattedNumber } from 'react-intl';
import { useCurrency } from '@innobrix/utils';
import { StyledPrice } from './Price.styled';
import { BaseProps } from '../interfaces/BaseProps';

interface Props extends BaseProps {
  value: number;
  hideFractions?: boolean;
}

const Price = ({ value, hideFractions, ...rest }: Props) => {
  const { code, digits } = useCurrency();

  // Hide fraction digits or show based on currency
  const factionDigits = hideFractions || value % 1 === 0 ? 0 : digits;

  return (
    <StyledPrice {...rest}>
      <FormattedNumber
        value={value}
        currency={code}
        // eslint-disable-next-line react/style-prop-object
        style={'currency'}
        minimumFractionDigits={factionDigits}
        maximumFractionDigits={factionDigits}
      />
    </StyledPrice>
  );
};

export { Price, StyledPrice };
