import * as React from 'react';
import { ReactNode } from 'react';
import { StyledIcon, StyledLabel, StyledNavbarItem } from './NavbarItem.styled';
import { IconType } from 'react-icons';
import { BaseProps } from '../../interfaces/BaseProps';

export interface NavbarItemProps extends BaseProps {
  icon: IconType;
  label: ReactNode;
  onClick?: () => void;
}
export const NavbarItem = React.forwardRef((props: NavbarItemProps, ref: React.Ref<HTMLLIElement>) => {
  const { icon, label, ...rest } = props;
  return (
    <StyledNavbarItem {...rest} ref={ref}>
      <StyledIcon as={icon} />
      <StyledLabel>{label}</StyledLabel>
    </StyledNavbarItem>
  );
});
