import * as React from 'react';
import { SyntheticEvent } from 'react';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import {
  StyledBox,
  StyledCheckbox,
  StyledCheckboxWrapper,
  StyledCheckmark,
  StyledErrorMessage,
  StyledLabel,
} from './Checkbox.styled';
import { getRandomId } from '../../utilities/Crypto';

export interface CheckboxProps extends BaseProps, ChildrenProp {
  name: string;
  value: string;
  checked?: boolean;
  defaultChecked?: boolean;
  id?: string;
  error?: string;
  touched?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler;
  tabIndex?: number;
  disabled?: boolean;
  checkmark?: (props: any) => React.ReactElement;
}
const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      children,
      name,
      value,
      checked,
      defaultChecked,
      onChange,
      onBlur,
      id,
      tabIndex = 0,
      error,
      touched,
      disabled,
      checkmark,
      ...rest
    },
    ref
  ) => {
    const [htmlId] = React.useState(() => id ?? getRandomId());

    const handleClick = (e: SyntheticEvent) => {
      // In Safari checkboxes don't blur or focus so do it onClick

      // Continue bubbling
      if (!onBlur) return true;

      // use shared variables and fill the rest
      const event = {
        ...e,
        type: 'blur',
        target: e.currentTarget,
        // TODO Type error, leaving it undefined. Fix it when we need it.
        // relatedTarget: synthEvent.currentTarget,
      } as React.FocusEvent<HTMLInputElement>;

      onBlur(event);
    };

    return (
      <StyledBox {...rest}>
        <StyledCheckboxWrapper>
          <StyledCheckbox
            tabIndex={tabIndex}
            id={htmlId}
            name={name}
            value={value}
            defaultChecked={defaultChecked}
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            type={'checkbox'}
            onClick={handleClick}
            // Don't blind blur as we fire it in handleClick
            ref={ref}
          />
          <StyledLabel htmlFor={htmlId} disabled={disabled}>
            {checkmark?.({})}
            {children}
          </StyledLabel>
        </StyledCheckboxWrapper>

        {error && touched && <StyledErrorMessage error={error} />}
      </StyledBox>
    );
  }
);

Checkbox.defaultProps = {
  checkmark: (props) => <StyledCheckmark {...props} />,
};

export { Checkbox };
