export const DragType = {
  // Content
  texture: 'texture',
  material: 'material',
  decoration: 'decoration',
  massVolume: 'mass-volume',
  textureVolume: 'texture-volume',
  configurable: 'configurable',
  parcelMapModel: 'parcelMapModel',
  mass: 'mass',
  estate: 'estate',
  optionList: 'optionList',
  materialSwitch: 'materialSwitch',

  // Nodes
  parcelMapLayer: 'parcelMapLayer',
  pdfTransform: 'pdfTransform',
  option: 'option',
  header: 'header',
  estateNode: 'estateNode',
  massNode: 'massNode',

  // Misc
  none: 'none',
};
