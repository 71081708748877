import { BaseProps } from '../../interfaces/BaseProps';
import * as React from 'react';
import { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { useSpring, animated, useTransition } from '@react-spring/web';
import { percentFormatter } from '../../utilities/NumberFormatting';
import { AnimatedIcon, StyledProgressCircle, StyledProgressCircleWrapper } from './ProgressCircle.styled';

export type ProgressCircleProps = BaseProps & {
  progress: number;
};
export const ProgressCircle = (props: ProgressCircleProps) => {
  const { progress, ...rest } = props;

  const circleSize = 308;
  const [done, setDone] = useState(false);

  const animNum = useSpring({
    to: { percent: progress, strokeDashoffset: circleSize - progress * 0.01 * circleSize },
    from: { percent: 0, strokeDashoffset: circleSize },
    onRest: () => {
      if (progress === 100) setDone(true);
    },
  });

  const transition = useTransition(done, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <StyledProgressCircleWrapper {...rest}>
      <StyledProgressCircle style={{ strokeDashoffset: animNum.strokeDashoffset }} />
      {transition((props, item) =>
        item ? (
          <AnimatedIcon icon={FaCheck} style={props} />
        ) : (
          <animated.span style={props}>
            {animNum.percent?.to((val) => percentFormatter().format(val * 0.01))}
          </animated.span>
        )
      )}
    </StyledProgressCircleWrapper>
  );
};
