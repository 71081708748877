import * as React from 'react';
import {
  CompanyHeading,
  CompanyHeadings,
  CompanySubheading,
  CompanyThumbnail,
  CompanyThumbnailWrapper,
  StyledCompanyCard,
  StyledIdLabel,
} from './CompanyCard.styled';
import { ActionCardProps } from './ActionCard';
import { Stack } from '../layout/Stack';
import placeholder from '../../../../../shared/assets/src/lib/images/company-placeholder.svg';
import Skeleton from 'react-loading-skeleton';

type CompanyCardProps = Omit<ActionCardProps, 'children'> & {
  id?: number;
  thumbnail?: string;
  url?: string;
  children?: React.ReactText | string;
};
const CompanyCard = React.forwardRef((props: CompanyCardProps, ref: React.Ref<HTMLDivElement>) => {
  const { children, id, url, thumbnail, ...rest } = props;

  return (
    <StyledCompanyCard {...rest} pad={'medium'} ref={ref}>
      <StyledIdLabel>{id != null && `#${id}`}</StyledIdLabel>
      <Stack align={'center'}>
        <CompanyThumbnailWrapper>
          <CompanyThumbnail src={thumbnail ?? placeholder} crossOrigin={'anonymous'} />
        </CompanyThumbnailWrapper>
        <CompanyHeadings title={children?.toString()} isEmpty={children == null}>
          <CompanyHeading>{children}</CompanyHeading>
          <CompanySubheading>{url}</CompanySubheading>
        </CompanyHeadings>
      </Stack>
    </StyledCompanyCard>
  );
});

CompanyCard.defaultProps = {
  actions: [<Skeleton />, <Skeleton />],
};

export { CompanyCard, CompanyCardProps };
