import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { Wrapper } from './ImageBox.styled';

export const StyledImageInputImage = styled.div(
  ({ theme }) => css`
    cursor: pointer;
    outline: none;
    border-radius: ${theme.borderRadius.small};
  `
);

export const StyledImageInput = styled.input``;

const overlayActive = css`
  opacity: 1;
  pointer-events: auto;
`;

export const DropOverlay = styled.div<{ rejected?: boolean; isDragActive?: boolean }>(
  ({ theme, rejected, isDragActive }) => css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: rgba(0, 0, 0, 0.25);
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: ${theme.colors.text.light};
    border: 0.125em dashed ${theme.colors.neutral.medium};
    border-radius: inherit;

    opacity: 0;
    pointer-events: none;

    transition: ${theme.transition.slow};
    transition-property: opacity, border, background;

    ${
      rejected &&
      css`
        border-color: ${theme.colors.error.light};
        background: ${rgba(theme.colors.error.light, 0.25)};
      `
    }

    ${Wrapper}:focus-within &,
    ${Wrapper}:hover & {
      ${overlayActive};
    }

    ${isDragActive && overlayActive};
  `
);
