import { animated } from '@react-spring/web';
import styled, { css } from 'styled-components';

export const StyledDropzoneWrapper = styled.div``;

export const StyledDropzone = styled(animated.div)(
  ({ theme }) => css`
    pointer-events: none;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    font-size: 1.5rem;
    color: ${theme.colors.neutral.white};

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      font-size: 6em;
    }
  `
);
