import * as React from 'react';
import { StyledFlexBox } from './FlexBox.styled';
import { BoxProps } from './Box';
import { Spacing } from '../../interfaces/Spacing';

export type FlexBoxProps = BoxProps & {
  gutter?: Spacing;
  reverse?: boolean;
  wrapping?: boolean;
  justify?: 'start' | 'end' | 'center' | 'stretch' | 'space-around' | 'space-between' | 'space-evenly';
  align?: 'start' | 'end' | 'center' | 'stretch';
  inline?: boolean;
};

export const FlexBox = React.forwardRef<HTMLDivElement, FlexBoxProps>(({ children, ...rest }, ref) => (
  <StyledFlexBox {...rest} ref={ref}>
    {children}
  </StyledFlexBox>
));
