import * as React from 'react';
import {
  ModalContent,
  ModalHeader,
  ModalHeaderContent,
  StyledCloseButton,
  StyledOptionDescriptionModal,
  StyledThumbnail,
} from './OptionDescriptionModal.styled';
import ReactModal from 'react-modal';
import { StyledOverlay } from '../modal/Modal.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { Price } from '../Price';

interface OptionDescriptionModalProps extends Omit<BaseProps, 'className' | 'style'>, ReactModal.Props, ChildrenProp {
  price: number;
  header: React.ReactNode;
  thumbnail?: string;
}

const OptionDescriptionModal = React.forwardRef<ReactModal, OptionDescriptionModalProps>(
  ({ children, thumbnail, price, header, onRequestClose, ...rest }, ref) => (
    <ReactModal
      {...rest}
      ref={ref}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onRequestClose}
      closeTimeoutMS={200}
    >
      <StyledOverlay overlay={true} onClick={onRequestClose} />

      <StyledOptionDescriptionModal>
        <ModalHeader>
          {!!thumbnail && <StyledThumbnail src={thumbnail} />}

          <ModalHeaderContent>
            <span>{header}</span>
            <Price value={price} />
          </ModalHeaderContent>
        </ModalHeader>

        <ModalContent>{children}</ModalContent>
      </StyledOptionDescriptionModal>

      <StyledCloseButton onClick={onRequestClose} />
    </ReactModal>
  )
);

export { OptionDescriptionModal, OptionDescriptionModalProps };
