import { code, data } from 'currency-codes';

// A blacklist for items we're not using @see https://en.wikipedia.org/wiki/ISO_4217#Active_codes
const blacklist = [
  '927',
  '940',
  '947',
  '948',
  '955',
  '956',
  '957',
  '958',
  '959',
  '960',
  '961',
  '962',
  '963',
  '964',
  '965',
  '970',
  '979',
  '984',
  '990',
  '994',
  '997',
  '999',
];

export const currencies = data.filter((c) => !blacklist.includes(c.number));

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const currencyFallback = code('EUR')!;
