import { css } from 'styled-components';

// Disables outline
export const hideOutline = css`
  outline: none;

  // Disable Firefox inner dotted outline
  ::-moz-focus-inner {
    border: 0;
  }
`;
