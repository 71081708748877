import * as React from 'react';
import { ReactNode } from 'react';
import { StyledFlyout } from './Flyout.styled';
import { BaseProps } from '../../interfaces/BaseProps';
import { TooltipProps as TProps } from 'rc-tooltip/lib/Tooltip';
import ReactTooltip from 'rc-tooltip';

export interface FlyoutProps
  extends BaseProps,
    Pick<TProps, 'getTooltipContainer' | 'visible' | 'onVisibleChange' | 'align' | 'trigger'> {
  children: React.ReactElement;
  flyout: ReactNode;
  placement?: 'bottom' | 'bottomRight' | 'bottomLeft';
}

export const Flyout = React.forwardRef<typeof ReactTooltip, FlyoutProps>(
  ({ placement = 'bottom', flyout, children, ...rest }, ref) => (
    <StyledFlyout trigger={'click'} placement={placement} overlay={flyout} animation={'flyout'} {...rest} ref={ref}>
      {children}
    </StyledFlyout>
  )
);
