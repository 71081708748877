import { ErrorMessageProps } from './ErrorMessage';
import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';

export const StyledErrorMessage = styled.div<ErrorMessageProps>(
  ({ theme }) => css`
    color: ${theme.colors.error.default};
    padding: ${spacing.s4} ${spacing.s8};
  `
);
