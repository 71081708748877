import * as React from 'react';
import { BaseProps } from '../../interfaces/BaseProps';
import { StyledBreadcrumb } from './Breadcrumb.styled';
import { Icon } from '../Icon';
import { FaChevronRight } from 'react-icons/fa';

export interface BreadcrumbProps extends BaseProps {
  children: Array<React.ReactElement | undefined>;
}

export const Breadcrumb = (props: BreadcrumbProps) => {
  const { children, ...rest } = props;

  return (
    <StyledBreadcrumb {...rest}>
      {React.Children.map(children, (child, index) => {
        if (child == null) return;
        return index > 0 ? (
          <>
            {/*Add chevron*/}
            <Icon icon={FaChevronRight} />
            {child}
          </>
        ) : (
          child
        );
      })}
    </StyledBreadcrumb>
  );
};
