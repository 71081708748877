import * as React from 'react';
import { ReactNode } from 'react';
import ReactModal from 'react-modal';
import {
  StyledContent,
  StyledHeader,
  StyledIconWrapper,
  StyledInnerModal,
  StyledModalContainer,
  StyledModalFooter,
  StyledOverlay,
} from './Modal.styled';
import { CloseButton } from '../navigation/CloseButton';
import { Heading } from '../typo/Heading';
import { Icon } from '../Icon';
import { IconType } from 'react-icons';
import { Severity, severityIconMap } from '@innobrix/definitions';
import { Stack } from '../layout/Stack';

ReactModal.defaultStyles = {};

export interface ModalProps extends ReactModal.Props {
  children?: ReactNode;
  header: ReactNode;
  footer?: ReactNode;
  overlay?: boolean;
  severity?: Severity;
  closingDisabled?: boolean;
  // overrides severity based icon. If both are null, there is no Icon.
  icon?: IconType;
}

const Modal = React.forwardRef<ReactModal, ModalProps>(
  (
    {
      isOpen,
      closingDisabled,
      onRequestClose,
      shouldCloseOnOverlayClick = true,
      header,
      footer,
      icon,
      severity,
      overlay = true,
      children,
      ...rest
    },
    ref
  ) => {
    const onClose = !closingDisabled ? onRequestClose : undefined;
    const hasFooter = footer != null;

    return (
      <ReactModal
        {...rest}
        ref={ref}
        isOpen={isOpen}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        onRequestClose={onClose}
        ariaHideApp={false}
      >
        <StyledOverlay overlay={overlay} onClick={shouldCloseOnOverlayClick ? onClose : undefined} />
        <StyledInnerModal>
          <StyledModalContainer hasFooter={hasFooter}>
            {(severity != null || icon != null) ? (
              <StyledIconWrapper severity={severity}>
                <Icon icon={icon != null ? icon : severityIconMap[severity ?? 'message']} />
              </StyledIconWrapper>
             ) : undefined}
            <Stack>
              <StyledHeader hasFooter={hasFooter}>
                <Heading level={1}>{header}</Heading>
                <CloseButton onClick={onClose} disabled={closingDisabled} />
              </StyledHeader>
              <StyledContent hasFooter={hasFooter}>{children}</StyledContent>
            </Stack>
          </StyledModalContainer>

          {footer}
        </StyledInnerModal>
      </ReactModal>
    );
  }
);

export { Modal, StyledModalFooter as ModalFooter };
export { ModalFormWrapper } from './Modal.styled';
