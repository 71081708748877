import { DefaultTheme } from 'styled-components';
import { weight } from '../variables/typography';
import * as colors from '../variables/colors';
import { spacing } from '../variables/spacing';
import { borderRadius } from '../variables/borderRadius';

const lightTheme: DefaultTheme = {
  colors: {
    primary: colors.primary,
    secondary: colors.secondary,
    neutral: colors.neutral,
    text: colors.text,
    success: colors.success,
    warning: colors.warning,
    error: colors.error,
    gradients: {
      primary: `linear-gradient(45deg, ${colors.primary.default} 0%, ${colors.secondary.light} 100%)`,
      secondary: `linear-gradient(45deg, ${colors.secondary.default} 0%, ${colors.primary.default} 41%, ${colors.primary.xlight} 100%)`,
      tertiary: `linear-gradient(45deg, ${colors.secondary.default} 0%, ${colors.tertiary.default} 142%)`,
    },
  },

  typography: {
    family: '"Open Sans", sans-serif',
    headingFamily: '"Klavika", sans-serif',

    headings: {
      h1: {
        size: '2.5rem',
        height: 1.2,
        weight: weight.medium,
      },
      h2: {
        size: '2rem',
        height: 1.2,
        weight: weight.medium,
      },
      h3: {
        size: '1.75rem',
        height: 1.2,
        weight: weight.medium,
      },
      h4: {
        size: '1.5rem',
        height: 1.2,
        weight: weight.medium,
      },
      h5: {
        size: '1.25rem',
        height: 1.2,
        weight: weight.medium,
      },
      h6: {
        size: '1rem',
        height: 1.2,
        weight: weight.medium,
      },
    },

    text: {
      size: '1em',
      height: 1.5,
      weight: 'normal',
    },

    code: {
      family: '"Consolas", monospace',
      size: '1em',
      height: 1.2,
      weight: weight.light,
    },
  },

  spacing: {
    large: spacing.s32,
    medium: spacing.s16,
    small: spacing.s8,
    xsmall: spacing.s4,
    xxsmall: spacing.s2,
  },

  transition: {
    default: '0.1s ease-out',
    slow: '0.2s ease-out',
    xSlow: '0.33s ease-out',
  },

  borderRadius: {
    xsmall: borderRadius.r2,
    small: borderRadius.r4,
    medium: borderRadius.r8,
    large: borderRadius.r16,
  },

  elevation: {
    xlight: '0 1px 1px 0 rgba(0, 0, 0, 0.15)',
    light: '0 1px 2px 0 rgba(0, 0, 0, 0.25)',
    medium: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
    large: '0 4px 8px 0 rgba(0, 0, 0, 0.25)',
  },
};

export { lightTheme };
