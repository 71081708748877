import { useEffect, useState } from 'react';

/**
 * Get the Cursor Position on render
 */
export function useGetCursorPositionOnMount() {
  const [mousePos, setMousePos] = useState<{ x: number; y: number }>();

  // Grab cursor position on render
  useEffect(() => {
    function handleMouseOver(e: MouseEvent) {
      setMousePos({ x: e.clientX, y: e.clientY });
      window.removeEventListener('mouseover', handleMouseOver);
    }
    window.addEventListener('mouseover', handleMouseOver);

    return () => {
      window.removeEventListener('mouseover', handleMouseOver);
    };
  }, []);

  return mousePos;
}
