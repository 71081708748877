import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { DonutStatCard } from '../components/dashboard/DonutStatCard';
import { DuoStatCard } from '../components/dashboard/DuoStatCard';
import { FetchErrorBubble } from '../components/dashboard/FetchErrorBubble';
import { PlaceholderCard } from '../components/dashboard/PlaceholderCard.styled';
import { StatCard } from '../components/dashboard/StatCard';
import { UserGrowthCard } from '../components/dashboard/UserGrowthCard';
import { DashboardLayout } from '../layout/DashboardLayout.styled';
import { Layout } from '../layout/Layout';
import { dashboardEndpoint, HomeStats } from '../store/dashboard/dashboard';
import { useAuthenticatedSWR } from '../utils/swr';

// Milestones TODO how do we fill these externally?
const signupMilestone = 150;
const activityMilestone = 100;

export const Home = () => {
  const { data, error } = useAuthenticatedSWR<HomeStats>(dashboardEndpoint);

  if (data == null) return <div>loading...</div>;

  return (
    <Layout>
      <Helmet>
        <title>Dashboard | Innobrix</title>
      </Helmet>

      <DashboardLayout>
        {error != null && <FetchErrorBubble>Failed to fetch data</FetchErrorBubble>}

        <UserGrowthCard
          style={{ gridArea: 'user' }}
          datasets={[
            { label: 'Active users', data: data.userGrowth.activity, milestone: activityMilestone },
            { label: 'Signups', data: data.userGrowth.signups, milestone: signupMilestone },
          ]}
          total={data.userGrowth.totalUsers}
        />

        <StatCard stat={data.projects.total} style={{ gridArea: 'card1' }}>
          <b>Projects</b> created
        </StatCard>

        <StatCard stat={data.estates.total} style={{ gridArea: 'card2' }}>
          <b>Estates</b> placed
        </StatCard>

        <StatCard stat={data.configurables.total} style={{ gridArea: 'card3' }}>
          <b>Configurables</b> created
        </StatCard>

        <DonutStatCard
          total={data.decorations.decorations + data.decorations.furniture}
          dataset={data.decorations}
          style={{ gridArea: 'card4' }}
        >
          <b>Decorations</b> placed
        </DonutStatCard>

        <PlaceholderCard style={{ gridArea: 'ibx' }} />

        <DuoStatCard {...data.configurations} style={{ gridArea: 'card5' }}>
          <b>Configurations</b> created this month
        </DuoStatCard>

        <DonutStatCard
          total={data.companies.lite + data.companies.pro + data.companies.enterprise}
          dataset={data.companies}
          style={{ gridArea: 'card6' }}
        >
          <b>Companies</b> created
        </DonutStatCard>

        <DuoStatCard {...data.chat} style={{ gridArea: 'card7' }}>
          <b>Chat messages</b> sent this month
        </DuoStatCard>

        <DuoStatCard {...data.documents} style={{ gridArea: 'card8' }}>
          <b>Documents</b> uploaded this month
        </DuoStatCard>

        <PlaceholderCard style={{ gridArea: 'bigcard1' }} />
        <PlaceholderCard style={{ gridArea: 'bigcard2' }} />
        <PlaceholderCard style={{ gridArea: 'active' }} />
      </DashboardLayout>
    </Layout>
  );
};

export default Home;
