import styled, { css } from 'styled-components';
import { FlexBox, FlexBoxProps } from './FlexBox';

export const StyledStack = styled(FlexBox)<FlexBoxProps>(
  ({ reverse }) => css`
    & {
      flex-direction: ${`column${reverse ? '-reverse' : ''}`};
    }
  `
);
