import styled, { css } from 'styled-components';

export const StyledPillStack = styled.div(
  ({ theme }) => css`
    position: relative;

    > :nth-child(1),
    > :nth-child(2),
    > :nth-child(3) {
      position: absolute;
      box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.15);
      box-sizing: content-box;
      min-height: 1.33em;
      min-width: 8ch;
    }

    > :nth-child(1) {
      top: 0;
      left: 0;
      z-index: 1;
      background-color: #c2c6cb;
    }

    > :nth-child(2) {
      top: -1px;
      left: 0.25em;
      z-index: 2;
      background-color: #dbdde1;
    }

    > :nth-child(3) {
      top: -2px;
      left: 0.58em;
      z-index: 3;
      background-color: ${theme.colors.neutral.xxlight};
    }
  `
);
