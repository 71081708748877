import styled, { css } from 'styled-components';
import { borderRadius } from '../../../variables/borderRadius';
import { spacing } from '../../../variables/spacing';
import { hideVisually, transparentize } from 'polished';
import { frostedGlass } from '../../../utilities/mixins/frostedGlass';
import { animated } from '@react-spring/web';

export const StyledDocumentUpload = styled.div(
  ({ theme }) => css`
    position: relative;
    overflow: hidden;
    cursor: pointer;

    border-radius: ${borderRadius.r16};
    background-color: ${theme.colors.neutral.xxlight};
    padding: 1.5rem 2rem;
  `
);

export const StyledDocumentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  place-content: center;
  align-items: center;
  text-align: center;

  > :not(:last-child) {
    margin-bottom: ${spacing.s8};
  }
`;

export const HiddenInput = styled.input`
  ${hideVisually()};
`;

export const DropOverlay = styled(animated.div)(
  ({ theme }) => css`
    ${frostedGlass};

    // Create new scope so background-color can overwrite that of frostedGlass
    & {
      position: absolute;
      inset: 0;
      z-index: 200;

      display: flex;
      place-content: center;
      align-items: center;

      color: ${theme.colors.primary.xdark};
      background-color: ${transparentize(0.66, theme.colors.primary.xxxlight)};
      border: 2px dashed ${theme.colors.primary.light};
      border-radius: inherit;
      box-sizing: border-box;
    }
  `
);
