import * as React from 'react';
import { BaseProps } from '../../interfaces/BaseProps';
import ColorPickr, { ColorPickerProps as CProps } from 'mb-react-color-picker';
import { StyledColorPicker } from './ColorPicker.styled';

interface ColorPickerProps extends BaseProps, CProps {
  hideAlpha?: boolean;
}

const ColorPicker = React.forwardRef<ColorPickr, ColorPickerProps>(
  ({ className, headerText = '', color, hideAlpha, ...rest }, ref) => {
    return (
      <StyledColorPicker className={className} hideAlpha={hideAlpha}>
        <ColorPickr {...rest} color={color ?? '#fff'} headerText={headerText} ref={ref} />
      </StyledColorPicker>
    );
  }
);

export { ColorPicker, ColorPickerProps };
