import { createGlobalStyle, css } from 'styled-components';
import { reset } from './Reset';
import { openSans } from '../../utilities/mixins/typography';

export const GlobalStyle = createGlobalStyle(
  ({ theme }) => css`
    ${reset};

    :root {
      --scrollbar-width: 17px;
    }

    body {
      overscroll-behavior: none;
      color: ${theme.colors.neutral.dark};
      ${openSans};
    }

    body.ReactModal__Body--open {
      position: fixed;
      width: 100%;
      height: 100%;
    }

    ::selection {
      background: ${theme.colors.primary.default};
      color: ${theme.colors.primary.xxxlight};
    }

    [hidden] {
      display: none !important;
    }

    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }

    code,
    samp,
    pre {
      font-family: ${theme.typography.code.family};
    }

    var,
    cite,
    q {
      font-style: italic;
    }

    // Custom scrollbar styling
    @supports (-webkit-tap-highlight-color: #fff) {
      // only set width in webkit browsers (which support custom scrollbars)
      :root {
        --scrollbar-width: 6px;
      }
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ::-webkit-scrollbar {
      width: var(--scrollbar-width);
      height: var(--scrollbar-width);
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 0.5rem;
    }
  `
);
