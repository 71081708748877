import styled, { css } from 'styled-components';

export const StyledOptionTab = styled.div<{ selected?: boolean; disabled?: boolean }>(
  ({ theme, selected, disabled }) => css`
    color: ${selected ? theme.colors.primary.default : theme.colors.neutral.light};
    font-size: 1.125rem;
    font-family: ${theme.typography.headingFamily};
    font-weight: ${theme.typography.headings.h1.weight};
    user-select: none;

    transition: ${theme.transition.slow};
    transition-property: color;

    :focus,
    :hover {
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      color: ${disabled ? theme.colors.primary.xxlight : theme.colors.primary.default};
    }
  `
);
