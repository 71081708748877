import * as React from 'react';
import { PopoverStatValue, StyledPopoverStat } from './PopoverStat.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';

interface PopoverStatProps extends BaseProps, Required<ChildrenProp> {
  description?: React.ReactNode;
}

const PopoverStat = React.forwardRef<HTMLDivElement, PopoverStatProps>(({ children, description, ...rest }, ref) => (
  <StyledPopoverStat {...rest} ref={ref}>
    <PopoverStatValue>{children}</PopoverStatValue>
    {description && <small>{description}</small>}
  </StyledPopoverStat>
));

export { PopoverStat, PopoverStatProps };
