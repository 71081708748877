import styled, { css } from 'styled-components';
import { Stack } from '../layout/Stack';

export const StyledNotificationPanel = styled(Stack).attrs({ align: 'center' })(({ theme }) => css``);

export const Header = styled.header(
  ({ theme }) => css`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: ${theme.spacing.small};

    font-family: ${theme.typography.headingFamily};
    font-weight: ${theme.typography.headings.h1.weight};
    color: ${theme.colors.neutral.medium};
  `
);

export const HeaderButton = styled.button(
  ({ theme }) => css`
    border: none;
    font: inherit;
    background: none;
    color: ${theme.colors.text.default};
    cursor: pointer;
    border-radius: ${theme.borderRadius.small};

    :hover:not(:disabled),
    :focus:not(:disabled),
    :active:not(:disabled) {
      color: ${theme.colors.primary.default};
    }

    :disabled {
      color: ${theme.colors.neutral.light};
      cursor: not-allowed;
    }
  `
);

export const NotificationStack = styled(Stack).attrs({ gutter: 'small', align: 'center' })<{ isEmpty: boolean }>(
  ({ theme, isEmpty }) => css`
    padding-top: ${isEmpty ? theme.spacing.medium : undefined};
    width: 100%;
    min-width: 300px;
  `
);
