import styled, { css } from 'styled-components';
import { NumberInput } from './NumberInput';
import { IconWrapper } from '../TextInput.styled';

export const StyledPriceInput = styled(NumberInput)(
  ({ theme }) => css`
    ${IconWrapper} {
      font-weight: bold;
      line-height: 1;
    }

    input {
      text-align: left;
    }
  `
);
