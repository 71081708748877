import * as React from 'react';
import { StyledChatInput } from './ChatInput.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { TextInput } from '../form/TextInput';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { FaPaperPlane } from 'react-icons/fa';
import { Span } from '../typo/Span';
import { stringIsNullorEmpty } from '@innobrix/utils';
import { BaseInputProps } from '../../interfaces/InputProps';
import { FormattedMessage, useIntl } from 'react-intl';

interface ChatInputProps extends BaseProps, ChildrenProp, Pick<BaseInputProps, 'disabled' | 'maxLength'> {
  onSend?: (message: string) => void;
}

const ChatInput = React.forwardRef<HTMLFormElement, ChatInputProps>(
  ({ disabled, onSend, maxLength = 5000, ...rest }, ref) => {
    const { formatMessage } = useIntl();

    const [messageValue, setMessageValue] = React.useState<string>('');

    const canSend = !stringIsNullorEmpty(messageValue);

    const handleSubmit = React.useCallback(
      (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (messageValue == null) return;

        onSend?.(messageValue);
        // Clear message
        setMessageValue('');
      },
      [messageValue, onSend]
    );

    return (
      <StyledChatInput {...rest} ref={ref} onSubmit={handleSubmit}>
        <TextInput
          placeholder={formatMessage({ id: 'chat.input-placeholder' })}
          value={messageValue}
          maxLength={maxLength}
          onChange={(e) => setMessageValue(e.target.value)}
        />
        <Button type={'submit'} disabled={!canSend || disabled}>
          <Icon icon={FaPaperPlane} />
          <Span>
            <FormattedMessage id={'chat.input-label'} />
          </Span>
        </Button>
      </StyledChatInput>
    );
  }
);

export { ChatInput, ChatInputProps };
