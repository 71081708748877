import * as React from 'react';
import { BaseProps } from '../../interfaces/BaseProps';
import { StyledBadge } from './Badge.styled';

type Props = React.PropsWithChildren<
  BaseProps & {
    variant: 'default' | 'primary' | 'secondary' | 'error' | 'warning' | 'success';
  }
>;

const Badge = React.forwardRef<HTMLDivElement, Props>(({ ...rest }, ref) => {
  return <StyledBadge {...rest} ref={ref} />;
});

export { Badge };
