import styled, { css } from 'styled-components';
import * as React from 'react';

const LogoImg = React.forwardRef((props: any, ref: React.Ref<SVGElement>) => (
  <svg viewBox="0 0 544 118" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
    <path
      d="M535.226 55.5567C539.52 55.5567 543 52.0749 543 47.7798C543 43.4848 539.52 40.003 535.226 40.003C530.933 40.003 527.453 43.4848 527.453 47.7798C527.453 52.0749 530.933 55.5567 535.226 55.5567Z"
      className="logo-sp"
      strokeMiterlimit="10"
    />
    <path
      d="M533.366 49.2056V51.1683H532.952V44.7047H535.507C536.325 44.7047 536.769 44.8724 537.011 45.0914C537.241 45.2993 537.382 45.6392 537.382 46.2245V47.1966C537.382 47.5864 537.309 47.8697 537.188 48.0722C537.072 48.2658 536.889 48.4234 536.592 48.5294L535.977 48.7484L536.348 49.2849L537.652 51.1683H537.193L535.626 48.9197L535.476 48.7056H535.216H533.866H533.366V49.2056ZM533.877 44.9468H533.377V45.4468V47.9851V48.4851H533.877H535.464C535.889 48.4851 536.308 48.4131 536.607 48.1291C536.915 47.837 536.979 47.4349 536.979 47.0778V46.3366C536.985 45.9657 536.911 45.566 536.591 45.2814C536.289 45.0121 535.875 44.9468 535.464 44.9468H533.877Z"
      className="logo-sp"
    />
    <path
      d="M518.535 59.8879H503.484L495.376 71.4452L487.267 59.8879H472.217L487.85 82.1707L469.723 108.007H484.773L495.376 92.8855L505.978 108.007H521.029L502.901 82.1707L518.535 59.8879Z"
      className="logo-fp"
    />
    <path d="M442.817 59.8879V72.1581H450.731V108.007H463.039V59.8879H442.817Z" className="logo-fp" />
    <path d="M463.039 39.2361H450.72V51.5602H463.039V39.2361Z" className="logo-fp" />
    <path
      d="M425.672 59.8879L415.016 65.969V59.8879H402.697V108.007H415.016V80.1401L429.084 72.1581H433.985V59.8879H425.672Z"
      className="logo-fp"
    />
    <path
      d="M353.399 59.8879V39.4845H341.08V59.8879V108.007H387.981V59.8879H353.399ZM375.726 95.737H353.345V72.1472H375.726V95.737Z"
      className="logo-fp"
    />
    <path
      d="M326.223 108.007H279.322V59.8879H326.223V108.007ZM291.577 95.737H313.958V72.1473H291.577V95.737Z"
      className="logo-fb"
    />
    <path
      d="M244.513 59.8879L230.089 67.654V59.8879H217.824V108.007H230.089V81.6415L247.569 72.1581H253.432V108.007H265.686V59.8879H244.513Z"
      className="logo-fb"
    />
    <path
      d="M184.052 59.8879L169.627 67.654V59.8879H157.362V108.007H169.627V81.6415L187.107 72.1581H192.97V108.007H205.224V59.8879H184.052Z"
      className="logo-fb"
    />
    <path d="M120.848 59.8879V72.1581H128.762V108.007H141.07V59.8879H120.848Z" className="logo-fb" />
    <path d="M141.07 39.2361H128.751V51.5602H141.07V39.2361Z" className="logo-fb" />
    <g clipPath="url(#clip0)">
      <path d="M14.068 58.3641L0 66.5135L14.068 74.6629V58.3641Z" className="logo-fp" />
      <path d="M28.8531 81.4342L43.0407 73.5244V57.9445L28.8531 49.8251V81.4342Z" className="logo-fp" />
      <path
        d="M28.853 0.629211V18.8156L13.5304 27.8638L28.853 35.9532L43.0406 26.935V8.74861L28.853 0.629211Z"
        className="logo-fp"
      />
      <path
        d="M71.3859 43.4434V8.05942L57.1983 0V84.1907L14.0979 109.328L28.4945 117.417L57.3179 100.549L85.8722 117.118L100 109.238V59.8921L71.3859 43.4434ZM85.8423 84.6999L71.7444 76.5505L85.8423 68.3711V84.6999Z"
        className="logo-fp"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="100" height="117.417" fill="white" />
      </clipPath>
    </defs>
  </svg>
));

export const StyledLogo = styled<any>(
  React.forwardRef(({ inverted, ...props }: any, ref: React.Ref<SVGSVGElement>) => <LogoImg {...props} ref={ref} />)
)(
  ({ theme, inverted = false }) => css`
    fill: none;
    min-width: 100px;

    .logo-fp {
      fill: ${inverted ? theme.colors.neutral.white : theme.colors.primary.default};
    }

    .logo-sp {
      stroke: ${inverted ? theme.colors.neutral.white : theme.colors.primary.default};
    }

    .logo-fb {
      fill: ${inverted ? theme.colors.neutral.white : theme.colors.secondary.default};
    }
  `
);
