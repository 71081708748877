/**
 * <pre>
 * mobile portrait:    0 -  767px
 * ipad portrait:    768 - 1023px
 * ipad landscape:  1024 - 1366px
 * laptop:          1367 - 1599px
 * desktop:         1600 +
 * </pre>
 */
export const breakpoints = {
  mobileMax: `(max-width: 767px)`,
  tablet: `(min-width: 768px)`,
  tabletPortraitMax: `(max-width: 768px)`,
  tabletLandscape: `(min-width: 1024px)`,
  tabletMax: `(max-width: 1366px)`,
  laptop: `(min-width: 1367px)`,
  laptopMax: `(max-width: 1599px)`,
  desktop: `(min-width: 1600px)`,
};
