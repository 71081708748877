import styled, { css } from 'styled-components';
import { visuallyHidden } from '../../utilities/mixins/visuallyHidden';
import material from '../../../../../shared/assets/src/lib/images/material-placeholder.svg';
import content from '../../../../../shared/assets/src/lib/images/content-placeholder.svg';

export const StyledContainer = styled.div(
  ({ theme }) => css`
    cursor: pointer;
  `
);

export const StyledMaterialPlaceholder = styled.div(
  ({ theme }) => css`
    position: relative;
    background: ${(p) => p.theme.colors.neutral.xlight};
    border-radius: ${(p) => p.theme.borderRadius.small};

    width: 4rem;
    height: 4rem;
    object-fit: contain;

    background: ${theme.colors.neutral.xlight} url(${material}) no-repeat center;
  `
);

export const StyledContentPlaceholder = styled.div(
  ({ theme }) => css`
    position: relative;
    background: ${(p) => p.theme.colors.neutral.xlight};
    border-radius: ${(p) => p.theme.borderRadius.small};

    width: 4rem;
    height: 4rem;
    object-fit: contain;

    background: ${theme.colors.neutral.xlight} url(${content}) no-repeat center;
  `
);

export const StyledAssetInput = styled.input(
  ({ theme }) => css`
    ${visuallyHidden};
  `
);
