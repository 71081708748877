import * as React from 'react';
import { useContext } from 'react';
import { StyledOptionGroup, StyledOptionHeader, StyledOptionHeading } from './OptionGroup.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { useOptionList } from './OptionList';
import { IToggleVisibility, VisibilityToggle } from './VisibilityToggle';
import { AnimatedPrice } from './AnimatedPrice';

interface OptionGroupProps extends BaseProps, ChildrenProp, Partial<IToggleVisibility> {
  header?: React.ReactNode;
  large?: boolean;
  price?: number;
  guid?: string;
  disabled?: boolean;
}

export const OptionGroupContext = React.createContext<{ large?: boolean }>({});
export const useOptionGroup = () => useContext(OptionGroupContext);

const OptionGroup = React.forwardRef<HTMLFieldSetElement, OptionGroupProps>(
  ({ children, header, visible, onVisibilityChange, price, guid, large, ...rest }, ref) => {
    const { editVisibility } = useOptionList();

    const _header = typeof header !== 'string' ? header : <StyledOptionHeading>{header}</StyledOptionHeading>;

    return (
      <StyledOptionGroup {...rest} large={large} ref={ref} key={guid}>
        <StyledOptionHeader>
          {editVisibility ? (
            <VisibilityToggle size={'small'} checked={!visible} onClick={() => onVisibilityChange?.(!visible)}>
              {_header}
            </VisibilityToggle>
          ) : (
            _header
          )}

          {price != null && <AnimatedPrice price={price} guid={guid} />}
        </StyledOptionHeader>

        <OptionGroupContext.Provider value={{ large }}>
          <ul>
            {React.Children.map(children, (child, index) => (
              <li key={index}>{child}</li>
            ))}
          </ul>
        </OptionGroupContext.Provider>
      </StyledOptionGroup>
    );
  }
);

export { OptionGroup, OptionGroupProps };
