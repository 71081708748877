import * as React from 'react';
import RCSelect, { ActionMeta, GroupType, Props, ValueType } from 'react-select';
import { StyledGroup, StyledSelect, StyledSelectMenu } from './Select.styled';
import { ErrorMessage } from './ErrorMessage';
import { Stack } from '../layout/Stack';
import { BaseProps } from '../../interfaces/BaseProps';
import { useCallback } from 'react';
import { borderRadius } from '../../variables/borderRadius';
import { useTheme } from 'styled-components';

export interface SelectOptionProps<T = string> {
  label: string;
  value: T;
  __isNew__?: boolean;
}

export const SelectOptionGroup = (props: GroupType<SelectOptionProps>) => {
  return (
    <StyledGroup>
      <span>{props.label}</span>
      <span>{props.options.length}</span>
    </StyledGroup>
  );
};

export function useSelectTheme(): Props['theme'] {
  const ibxTheme = useTheme();

  return (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      // Primary
      primary: ibxTheme.colors.primary.default,
      primary25: ibxTheme.colors.primary.xxxlight,
      primary50: ibxTheme.colors.primary.xxlight,
      primary75: ibxTheme.colors.primary.light,
      // Error
      danger: ibxTheme.colors.error.default,
      dangerLight: ibxTheme.colors.error.xlight,
      // Neutral
      neutral0: ibxTheme.colors.neutral.white,
      neutral5: ibxTheme.colors.neutral.xxxlight,
      neutral10: ibxTheme.colors.neutral.xxlight,
      neutral20: ibxTheme.colors.neutral.xlight,
      neutral30: ibxTheme.colors.neutral.light,
      neutral40: ibxTheme.colors.neutral.medium,
      neutral50: ibxTheme.colors.neutral.dark,
      neutral60: ibxTheme.colors.neutral.xdark,
      neutral70: ibxTheme.colors.neutral.xxdark,
      neutral80: ibxTheme.colors.neutral.xxdark,
      neutral90: ibxTheme.colors.neutral.xxdark,
    },
  });
}

// TODO Name is required but not enforced, typings need to be adjusted
interface SelectProps extends Omit<Props, 'isDisabled' | 'theme'>, BaseProps {
  error?: string;
  touched?: boolean;
  disabled?: boolean;
}
const Select = React.forwardRef<RCSelect, SelectProps>(
  ({ components, error, touched, isMulti, disabled, className, onChange, ...rest }, ref) => {
    const handleChange = useCallback(
      (value: ValueType<SelectOptionProps>, action: ActionMeta) => {
        if (isMulti) {
          // Handle null value
          const val = value ?? [];
          onChange?.(val, action);
        } else {
          onChange?.(value, action);
        }
      },
      [isMulti, onChange]
    );

    const rsTheme = useSelectTheme();

    return (
      <Stack className={className}>
        <StyledSelect
          classNamePrefix={'rs'}
          error={error}
          touched={touched}
          isDisabled={disabled}
          isMulti={isMulti}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - This is passed down to the react-select
          theme={rsTheme}
          components={{ Menu: StyledSelectMenu, ...components }}
          {...rest}
          onChange={handleChange}
          ref={ref}
        />
        {!disabled && error && touched && <ErrorMessage error={error} />}
      </Stack>
    );
  }
);

Select.defaultProps = {
  isSearchable: false,
  placeholder: 'Select...',
  menuPlacement: 'auto',
  formatGroupLabel: SelectOptionGroup,
};

export { Select, SelectProps };
