import * as React from 'react';
import { StyledNumberInput } from './NumberInput.styled';
import { TextInputProps } from '../TextInput';
import { IMaskMixin, IMaskProps, Masked } from 'react-imask';
import { useCurrency } from '@innobrix/utils';
import { BaseProps } from '../../../interfaces/BaseProps';
import { BaseInputProps } from '../../../interfaces/InputProps';
import { round } from 'lodash-es';

// Props for this component
interface NumberInputProps
  extends Pick<React.InputHTMLAttributes<HTMLInputElement>, 'onKeyDown'>,
    BaseProps,
    Omit<BaseInputProps<number>, 'onChange'>,
    Pick<TextInputProps, 'iconBefore' | 'iconAfter'>,
    Omit<IMaskProps, 'radix' | 'onAccept' | 'value' | 'min' | 'max'> {
  inputMode?: 'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined;
  onChange?: (value?: number) => void;
  min?: number;
  max?: number;

  prefix?: string;
  postfix?: string;
}

// Underlying TextInput props
interface BaseTextInputProps extends Omit<NumberInputProps, 'value' | 'onChange'>, BaseInputProps {}

// Higher Order Component
const MaskedNumberInput = IMaskMixin<BaseTextInputProps>(({ inputRef, onChange, ...rest }) => (
  <StyledNumberInput ref={inputRef} {...rest} />
));

// Actual component
const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  (
    {
      inputMode = 'decimal',
      type = 'text',
      mask = Number,
      scale = 2,
      mapToRadix = [',', '.'],
      thousandsSeparator = ' ',
      signed = true,
      onChange,
      value,
      prefix,
      postfix,
      ...rest
    },
    ref
  ) => {
    const { radix } = useCurrency();

    let numberProps: any = {
      radix,
      mask,
      scale,
      mapToRadix,
      thousandsSeparator,
      signed,
    };

    if (prefix || postfix) {
      numberProps = {
        mask: `${prefix ?? ''}num${postfix ?? ''}`,
        lazy: false,
        blocks: {
          num: numberProps,
        },
      };
    }

    const handleChange = React.useCallback(
      (value: string, masked: Masked) => {
        // Parse string value
        let numberValue: number | undefined = parseFloat(masked.unmaskedValue);
        if (isNaN(numberValue)) numberValue = undefined;

        onChange?.(numberValue);
      },
      [onChange]
    );

    return (
      <MaskedNumberInput
        {...numberProps}
        value={value != null ? round(value, scale)?.toString(10) : undefined}
        inputMode={inputMode}
        type={type}
        ref={ref}
        {...rest}
        onAccept={handleChange}
      />
    );
  }
);

export { NumberInput, NumberInputProps };
