import * as React from 'react';
import { StyledDropdownFlyout } from './DropdownFlyout.styled';
import { BaseProps } from '../../interfaces/BaseProps';
import ReactTooltip from 'rc-tooltip';
import { FlyoutProps } from '../flyout/Flyout';

interface DropdownFlyoutProps extends BaseProps, Pick<FlyoutProps, 'flyout' | 'children'> {
  placement?: 'bottomLeft' | 'bottomRight';
}

const DropdownFlyout = React.forwardRef<typeof ReactTooltip, DropdownFlyoutProps>(
  ({ placement = 'bottomLeft', children, flyout, ...rest }, ref) => {
    return (
      <StyledDropdownFlyout
        {...rest}
        ref={ref}
        flyout={flyout}
        placement={placement}
        align={{
          overflow: {
            adjustX: true,
            adjustY: false,
          },
        }}
      >
        {children}
      </StyledDropdownFlyout>
    );
  }
);

export { DropdownFlyout, DropdownFlyoutProps };
