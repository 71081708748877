import * as React from 'react';
import { StyledChatPanel } from './ChatPanel.styled';
import { ChatProps } from '../Chat';

type Props = ChatProps;

const ChatPanel = React.forwardRef<HTMLDivElement, Props>(({ ...rest }, ref) => {
  return <StyledChatPanel {...rest} ref={ref} />;
});

export { ChatPanel };
