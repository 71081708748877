import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';

export const StyledEditorHelpItem = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-auto-flow: column dense;
    justify-content: space-between;
    grid-gap: ${spacing.s4};
  `
);

export const StyledFlexbox = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    & > :not(:last-child) {
      margin-right: ${spacing.s4};
    }
  `
);
