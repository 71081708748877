import * as React from 'react';
import { DropOverlay, StyledImageInputImage, StyledImageInput } from './ImageInput.styled';
import { DropEvent, useDropzone } from 'react-dropzone';
import { FaFileUpload } from 'react-icons/fa';
import { ImageBox, ImageBoxPlaceholder } from './ImageBox';
import { BaseProps } from '../../interfaces/BaseProps';
import { BaseInputProps } from '../../interfaces/InputProps';

interface ImageInputProps extends BaseProps, Omit<BaseInputProps, 'onChange'> {
  onChange: (e: DropEvent, file: File) => void;
  disabled?: boolean;
  value?: string;
}

const ImageInput = React.forwardRef<HTMLDivElement, ImageInputProps>(
  ({ onChange, value, onBlur, name, disabled, ...rest }, ref) => {
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
      accept: 'image/jpeg, image/png',
      multiple: false,
      preventDropOnDocument: true,
      disabled,
      onDropAccepted<T extends File>(files: T[], event: DropEvent) {
        if (files.length <= 0) return;

        onChange(event, files[0]);
      },
    });

    const content = (
      <>
        <StyledImageInput {...getInputProps()} name={name} onBlur={onBlur} />

        <DropOverlay rejected={isDragReject} isDragActive={isDragActive}>
          <FaFileUpload />
        </DropOverlay>
      </>
    );

    return (
      <StyledImageInputImage {...rest} ref={ref} {...getRootProps()}>
        {!value ? <ImageBoxPlaceholder>{content}</ImageBoxPlaceholder> : content}
      </StyledImageInputImage>
    );
  }
);

export { ImageInput, ImageInputProps };
