import * as React from 'react';
import { useTransition } from '@react-spring/web';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { StyledDropzone, StyledDropzoneWrapper } from './FileDropZone.styled';

interface FileDropZoneProps extends BaseProps, ChildrenProp {
  options?: DropzoneOptions;
  overlay?: React.ReactNode;
}

const FileDropZone = React.forwardRef<HTMLDivElement, FileDropZoneProps>(
  ({ options, overlay, children, ...rest }, ref) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone(options);

    const transitions = useTransition(isDragActive, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: {
        tension: 400,
      },
    });

    return (
      <StyledDropzoneWrapper {...rest} {...getRootProps()} tabIndex={undefined} ref={ref}>
        {children}
        {transitions(
          (animProps, item) =>
            item && (
              <StyledDropzone
                style={{
                  opacity: animProps.opacity,
                  backgroundColor: animProps.opacity?.to((v) => `rgba(255, 255, 255, ${(v as number) * 0.5})`),
                  backdropFilter: animProps.opacity?.to((v) => `blur(${(v as number) * 5}px)`),
                }}
              >
                <input {...getInputProps()} />
                {overlay}
              </StyledDropzone>
            )
        )}
      </StyledDropzoneWrapper>
    );
  }
);

export { FileDropZone, FileDropZoneProps };
