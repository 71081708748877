import * as React from 'react';
import { useEffect } from 'react';
import { StyledCarousel } from './Carousel.styled';
import { BaseProps } from '../../interfaces/BaseProps';
import { CarouselContextProps, useCarousel } from './CarouselProvider';

interface CarouselProps extends BaseProps, Partial<Pick<CarouselContextProps, 'visibleItems'>> {
  children: React.ReactElement[] | React.ReactFragment;
}
const Carousel = React.forwardRef<HTMLDivElement, CarouselProps>(({ visibleItems = 1, children, ...rest }, ref) => {
  const { setCount, setVisibleItems, carouselRef } = useCarousel();

  // Update count when children change
  useEffect(() => {
    setCount(React.Children.count(children));
  }, [children, setCount]);

  useEffect(() => {
    setVisibleItems(visibleItems);
  }, [setVisibleItems, visibleItems]);

  return (
    <StyledCarousel {...rest} ref={carouselRef} visibleItems={visibleItems}>
      {children}
    </StyledCarousel>
  );
});

export { Carousel, CarouselProps };
