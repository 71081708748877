import * as React from 'react';
import { IconBaseProps } from 'react-icons';
import { animated, useSpring } from '@react-spring/web';
import { interpolate } from 'polymorph-js';
import { StyledFlyoutLock } from './FlyoutLock.styled';

interface FlyoutLockProps extends IconBaseProps {
  locked?: boolean;
}

const lockedPath =
  'M400 256H152V152.9c0-39.6 31.7-72.5 71.3-72.9 40-.4 72.7 32.1 72.7 72v104c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24V152C376 68 307.5-.3 223.5 0 139.5.3 72 69.5 72 153.5V256H48c-26.5 0-48 21.5-48 48v160c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z';
const unlockedPath =
  'M400 256H152V152.9c0-39.6 31.7-72.5 71.3-72.9 40-.4 72.7 32.1 72.7 72v16c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24v-16C376 68 307.5-.3 223.5 0 139.5.3 72 69.5 72 153.5V256H48c-26.5 0-48 21.5-48 48v160c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z';

const FlyoutLock = React.forwardRef<SVGSVGElement, FlyoutLockProps>(({ locked, ...rest }, ref) => {
  const interpolator = interpolate([lockedPath, unlockedPath]);

  const { t } = useSpring({
    t: locked ? 0 : 1,
  });

  return (
    <StyledFlyoutLock viewBox="0 0 448 512" width="1em" {...rest} ref={ref}>
      <animated.path d={t.to(interpolator)} />
    </StyledFlyoutLock>
  );
});

export { FlyoutLock, FlyoutLockProps };
