import * as React from 'react';
import { SortIcon, StyledSortableHeader } from './SortableHeader.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { MouseEventProps } from '../../interfaces/EventProps';
import { FaArrowDown } from 'react-icons/fa';

type SortDirection = 'asc' | 'desc';
interface SortableHeaderProps extends BaseProps, ChildrenProp, MouseEventProps {
  sorting?: boolean;
  sortDirection?: SortDirection;
}

const SortableHeader = React.forwardRef<HTMLTableCellElement, SortableHeaderProps>(
  ({ children, sorting, sortDirection = 'asc', ...rest }, ref) => {
    return (
      <StyledSortableHeader {...rest} ref={ref}>
        {children}
        <SortIcon $sorting={sorting} $sortDirection={sortDirection} icon={FaArrowDown} />
      </StyledSortableHeader>
    );
  }
);

export { SortableHeader, SortableHeaderProps, SortDirection };
