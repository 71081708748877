import * as React from 'react';
import { StyledVisibilityToggle } from './VisibilityToggle.styled';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { IconButtonProps } from '../IconButton';
import { FlexBox } from '../layout/FlexBox';

interface VisibilityToggleProps extends IconButtonProps {
  checked?: boolean;
}

type IToggleVisibility = {
  onVisibilityChange: (visible: boolean) => void;
  visible: boolean;
};

const Icon = React.memo(() => <FaEye />);
const CrossedIcon = React.memo(() => <FaEyeSlash />);

const VisibilityToggle = React.forwardRef<HTMLButtonElement, VisibilityToggleProps>(
  ({ className, children, checked, ...rest }, ref) => {
    const toggle = (
      <StyledVisibilityToggle className={className} checked={checked} {...rest} ref={ref}>
        {checked ? <CrossedIcon /> : <Icon />}
      </StyledVisibilityToggle>
    );

    return children != null ? (
      <FlexBox className={className} align={'center'}>
        {toggle}

        {children}
      </FlexBox>
    ) : (
      toggle
    );
  }
);

export { VisibilityToggle, VisibilityToggleProps, IToggleVisibility };
