import { useMedia } from 'react-use';
import { breakpoints } from '../variables/breakpoints';

/**
 * Check whether the device is a phone
 */
export function useIsPhone() {
  return useMedia(breakpoints.mobileMax);
}

/**
 * Check whether the device is a tablet
 */
export function useIsIpad() {
  return useMedia(`${breakpoints.tablet} and ${breakpoints.tabletMax} and (min-resolution: 1.5dppx)`);
}

/**
 * Check whether the device is a tablet
 */
export function useIsTablet() {
  return useMedia(`${breakpoints.tablet} and ${breakpoints.tabletMax}`);
}

/**
 * Check whether the device is tablet in portrait mode
 */
export function useIsTabletPortrait() {
  return useMedia(
    `${breakpoints.tablet} and ${breakpoints.tabletMax} and (orientation: portrait) and (min-resolution: 1.5dppx)`
  );
}

/**
 * Check whether the device is smaller than a landscape tablet
 */
export function useIsMaxTabletPortait() {
  return useMedia(`${breakpoints.tabletPortraitMax}`);
}

/**
 * Check whether the device is a computer
 */
export function useIsComputer() {
  return useMedia(`${breakpoints.laptop}`);
}

/**
 * Check whether the device is a touch device
 */
export function useIsTouch() {
  return useMedia('(pointer: coarse)');
}

/**
 * Check whether the device is an iOS device
 */
export function useIsIOS() {
  const isMac = /iPad|MacIntel|iPhone|iPod/.test(navigator.platform);
  return useIsTouch() && isMac;
}

/**
 *
 * @param breakpoint what breakpoint to use
 */
export function useBreakpoint(breakpoint: keyof typeof breakpoints) {
  return useMedia(breakpoints[breakpoint]);
}
