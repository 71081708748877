import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  ImageContainer,
  StyledCardDate,
  StyledCardHeader,
  StyledCardHeading,
  StyledImage,
  StyledProjectCard,
  StyledSkeletonWrapper,
} from './ProjectCard.styled';
import { ActionCardProps } from './ActionCard';
import placeholder from '../../../../../shared/assets/src/lib/images/thumbnail-placeholder.jpg';

interface ProjectCardProps extends ActionCardProps {
  thumbnail?: string;
  heading?: string;
  date?: React.ReactNode;
  pending?: boolean;
}

const ProjectCard = React.forwardRef((props: ProjectCardProps, ref: React.Ref<HTMLDivElement>) => {
  const { pending, heading, date, thumbnail, ...rest } = props;

  return (
    <StyledProjectCard {...rest} ref={ref}>
      <StyledCardHeader gutter={'xsmall'} pad={'small'} justify={'space-between'} align={'center'}>
        <StyledCardHeading title={heading}>{heading}</StyledCardHeading>
        <StyledCardDate>{date}</StyledCardDate>
      </StyledCardHeader>
      <ImageContainer>
        {thumbnail && !pending ? (
          <StyledImage
            src={thumbnail || placeholder}
            crossOrigin={'anonymous'}
            onError={(e) => {
              e.preventDefault();
              (e.target as HTMLImageElement).src = placeholder;
            }}
          />
        ) : (
          <Skeleton wrapper={StyledSkeletonWrapper} height={'7rem'} />
        )}
      </ImageContainer>
    </StyledProjectCard>
  );
});

ProjectCard.defaultProps = {
  actions: [<Skeleton />, <Skeleton />, <Skeleton />],
};

export { ProjectCard, ProjectCardProps };
