import styled from 'styled-components';
import { Heading } from '../typo/Heading';

export const StyledHeader = styled.div`
  margin-bottom: 4rem;
  text-align: center;
`;

export const StyledHeading = styled(Heading).attrs({ level: 1 })`
  margin-bottom: 0;
`;

export const StyledError = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;
