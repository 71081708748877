/**
 * Calculate total scroll offset for an element
 * @param el
 */
export function calcScrollOffset(el: HTMLElement) {
  let offset = 0;
  while (el != null) {
    offset += el.offsetTop;
    el = el.offsetParent as HTMLElement;
  }
  return offset;
}
