import * as React from 'react';
import { ReactElement, useState } from 'react';
import { BaseProps } from '../interfaces/BaseProps';
import { StyledIconRadioGroup } from './IconRadioGroup.styled';
import { IconButtonProps } from './IconButton';
import { IconButtonRadio } from './IconButtonRadio';

export interface IconRadioGroupProps extends BaseProps {
  children?: ReactElement<IconButtonProps> | ReactElement<IconButtonProps>[];
  defaultValue?: number;
  // controlled states
  value?: number;
  onChange?: (value: number) => void;
}
export const IconRadioGroup = React.forwardRef((props: IconRadioGroupProps, ref: React.Ref<HTMLDivElement>) => {
  const { children, defaultValue = 0, value, onChange, ...rest } = props;
  const isControlled: boolean = value != null;

  const [activeIndex, setActiveIndex] = useState(Math.min(React.Children.count(children) - 1, defaultValue));

  if (isControlled && onChange == null) {
    console.error('Controlled Component has `value` but is missing an `onChange` handler.');
  }

  return (
    <StyledIconRadioGroup {...rest} ref={ref} value={value ?? activeIndex}>
      {children &&
        React.Children.map(children, (child, index) => {
          const newChild = React.cloneElement(child, {
            ...child.props,
            size: 'xlarge',
            onClick: () => {
              if (isControlled) {
                onChange && onChange(index);
              } else {
                setActiveIndex(index);
              }
            },
          });
          return (
            <IconButtonRadio
              onChange={(newIndex: number) => {
                isControlled ? onChange && onChange(newIndex) : setActiveIndex(newIndex);
              }}
              index={index}
              active={isControlled ? value === index : activeIndex === index}
            >
              {newChild}
            </IconButtonRadio>
          );
        })}
    </StyledIconRadioGroup>
  );
});
