import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';
import { Icon } from '../Icon';
import { animated } from '@react-spring/web';

const gradient = (rotation: string, opacity: number) => `
    linear-gradient(
      ${rotation},
      rgba(70, 75, 83, 0) 0%,
      rgba(70, 75, 83, ${opacity}) 15%,
      rgba(70, 75, 83, ${opacity}) 85%,
      rgba(70, 75, 83, 0) 100%
    )
`;

export const StyledTutorialHint = styled(animated.div)(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    padding: ${spacing.s8} ${spacing.s32};

    color: ${theme.colors.text.light};
    background: ${gradient('270deg', 0.5)};
    backdrop-filter: blur(4px);

    border-top: 1px solid;
    border-bottom: 1px solid;
    border-image: ${gradient('270deg', 0.25)} 1;

    @media screen and (pointer: fine) {
      padding: ${spacing.s8} ${spacing.s48};
    }
  `
);

export const StyledHintIcon = styled(Icon)(
  ({ theme }) => css`
    flex: 0 0 auto;
    box-sizing: content-box;
    font-size: 2.25em;
    width: auto;
    height: 1em;
    padding-right: ${spacing.s16};
    border-right: 1px solid transparent;
    border-image: ${gradient('0deg', 0.33)} 1;
    margin-right: ${spacing.s16};

    @media screen and (pointer: fine) {
      font-size: 3em;
    }
  `
);

export const StyledContent = styled.span(
  ({ theme }) => css`
    flex: 1;
  `
);
