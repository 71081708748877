import styled, { css } from 'styled-components';
import { FlexBox } from '../../layout/FlexBox';
import { fontSize } from '../../../variables/typography';

export const StyledNumberWithIncDecInput = styled(FlexBox).attrs({ inline: true })(
  ({ theme }) => css`
    align-items: center;
    position: relative;

    > :first-child {
      width: 100%;

      input {
        text-align: left;
        padding-right: 3.5rem;
      }
    }
  `
);

export const StyledZoomButtons = styled(FlexBox)`
  position: absolute;
  right: 0;
  height: 100%;
  align-items: stretch;

  svg {
    font-size: ${fontSize.s14};
  }
`;
