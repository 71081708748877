import styled, { css } from 'styled-components';
import { absoluteCenter } from '../../utilities/mixins/layout';
import { spacing } from '../../variables/spacing';
import { klavika } from '../../utilities/mixins/typography';
import { CloseButton } from '../navigation/CloseButton';
import { StyledModalFooter } from './Modal.styled';
import { Form } from 'formik';

export const StyledEditProjectModal = styled.div(
  ({ theme }) => css`
    --modal-logo-size: 5rem;

    ${absoluteCenter};
    position: fixed;
    z-index: 5001;
    max-height: 90vh;

    border-radius: ${theme.borderRadius.medium};
    background: ${theme.colors.neutral.white};
    box-shadow: ${theme.elevation.medium};

    display: flex;
    flex-flow: column;
  `
);

export const ModalForm = styled(Form)(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    overflow: auto;
  `
);

export const ModalContent = styled.div(
  ({ theme }) => css`
    overflow: auto;

    margin: ${spacing.s4};
    padding: 1.25rem;
    // pad right for the logo
    padding-right: calc(var(--modal-logo-size) + 1.25rem + 1rem);
  `
);

export const Header = styled.header(
  ({ theme }) => css`
    position: relative;
    background: ${theme.colors.neutral.xxlight};
    border-radius: ${theme.borderRadius.medium} ${theme.borderRadius.medium} 0 0;

    &::before {
      display: block;
      content: '';
      width: 100%;
      // Set box to aspect ratio of the image
      padding-top: ${(370 / 700) * 100}%;
    }
  `
);

export const ModalFooter = styled(StyledModalFooter)(
  ({ theme }) => css`
    flex: 0 0 auto;
    border-radius: 0 0 ${theme.borderRadius.medium} ${theme.borderRadius.medium};
  `
);

export const ModalHeading = styled.span<{ light?: boolean }>(
  ({ theme, light }) => css`
    ${klavika};
    font-size: 1.5rem;
    position: absolute;
    top: ${spacing.s16};
    left: ${spacing.s16};
    z-index: 300;
    transition: color ${theme.transition.slow};

    ${light &&
    css`
      color: ${theme.colors.neutral.white};
      text-shadow: 0 0 0.75rem rgba(0, 0, 0, 1);
    `}
  `
);

export const StyledCloseButton = styled(CloseButton)<{ light?: boolean }>(
  ({ theme, light }) => css`
    font-size: 2rem;
    position: absolute;
    top: ${spacing.s16};
    right: ${spacing.s16};
    z-index: 220;
    transition-property: background-color, color;

    ${light &&
    css`
      color: ${theme.colors.neutral.white};
      text-shadow: 0 0 0.75rem rgba(0, 0, 0, 1);

      :hover {
        color: currentColor;
      }
    `}
  `
);
