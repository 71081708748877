import * as React from 'react';
import { StyledOverlay } from './Overlay.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';

export interface OverlayProps extends BaseProps, ChildrenProp {}

export const Overlay = React.forwardRef<HTMLDivElement, OverlayProps>(({ children, ...rest }, ref) => (
  <StyledOverlay {...rest} ref={ref}>
    {children}
  </StyledOverlay>
));
