import * as React from 'react';
import { PagerBubble, StyledCarouselPager } from './CarouselPager.styled';
import { useCarousel } from './CarouselProvider';
import { BaseProps } from '../../interfaces/BaseProps';
import { useCallback, useMemo } from 'react';

interface PagerProps extends BaseProps {
  index: number;
}

export const Pager = ({ index, ...rest }: PagerProps) => {
  const { currentIndex, visibleItems, goToIndex } = useCarousel();

  const handleClick = useCallback(() => {
    goToIndex(index * visibleItems);
  }, [goToIndex, index, visibleItems]);

  const isActive = useMemo(() => Math.round(currentIndex / visibleItems) === index, [
    currentIndex,
    index,
    visibleItems,
  ]);

  return <PagerBubble {...rest} active={isActive} onClick={handleClick} />;
};

type CarouselPagerProps = BaseProps;

const CarouselPager = React.forwardRef<HTMLDivElement, CarouselPagerProps>(({ ...rest }, ref) => {
  const { count, visibleItems } = useCarousel();

  const bubbles = useMemo(() => {
    const items = [];
    for (let i = 0; i < count / visibleItems; i++) {
      items.push(<Pager index={i} key={`item-${i}`} />);
    }
    return items;
  }, [count, visibleItems]);

  return (
    <StyledCarouselPager {...rest} ref={ref}>
      {bubbles}
    </StyledCarouselPager>
  );
});

export { CarouselPager, CarouselPagerProps };
