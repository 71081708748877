import * as React from 'react';
import { StyledOptionTab } from './OptionTab.styled';
import { useTabs } from './OptionTabs';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';

interface OptionTabProps extends BaseProps, ChildrenProp {
  index?: number;
}

const OptionTab = React.forwardRef<HTMLDivElement, OptionTabProps>(({ children, index, ...rest }, ref) => {
  const { selectedIndex, onSelect } = useTabs();

  return (
    <StyledOptionTab
      {...rest}
      ref={ref}
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        onSelect?.(index ?? 0, event);
      }}
      selected={index === selectedIndex}
    >
      {children}
    </StyledOptionTab>
  );
});

export { OptionTab, OptionTabProps };
