import styled, { css } from 'styled-components';
import { TabList } from 'react-tabs';

export const StyledTabList = styled(TabList)(
  ({ theme }) => css`
    display: flex;
    flex-flow: row wrap;
    border-bottom: 2px solid ${theme.colors.primary.xxxlight};
  `
);
