import * as React from 'react';
import styled, { css } from 'styled-components';
import { klavika } from '../../utilities/mixins/typography';
import { spacing } from '../../variables/spacing';

export const StyledHorizontalSteps = styled.ol(
  ({ theme }) => css`
    display: grid;
    grid-gap: ${spacing.s16};
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

    counter-reset: orderedListCounter;
  `
);

export const StepHeader = styled(({ children, ...props }) => (
  <div {...props}>
    <span>{children}</span>
  </div>
))(
  ({ theme }) => css`
    display: flex;
    font-size: 1rem;
    margin-bottom: ${spacing.s8};

    > span {
      padding-top: 0.15em;
      line-height: 1.2;
    }

    ::before {
      flex: 0 0 auto;
      --cnt-size: 1.5rem;
      content: counter(orderedListCounter);

      display: inline-flex;
      text-align: center;
      place-content: center;
      align-items: center;
      background: ${theme.colors.gradients.primary};
      color: ${theme.colors.text.light};

      width: var(--cnt-size, 1.5rem);
      height: var(--cnt-size, 1.5rem);
      border-radius: 50%;
      margin-right: ${spacing.s8};
    }
  `
);

export const StepContent = styled.div(
  ({ theme }) => css`
    display: flex;
    flex: 0 0 auto;
  `
);

export const StyledHorizontalStep = styled(({ children, header, ...props }) => (
  <li {...props}>
    <StepHeader>{header}</StepHeader>
    <StepContent>{children}</StepContent>
  </li>
))(
  ({ theme }) => css`
    counter-increment: orderedListCounter;
    ${klavika};
  `
);
