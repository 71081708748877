import * as React from 'react';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { StyledMessage } from './Message.styled';
import { Icon } from '../Icon';
import { Severity, severityIconMap } from '@innobrix/definitions';

interface MessageProps extends BaseProps, ChildrenProp {
  severity: Severity;
}

const Message = React.forwardRef<HTMLDivElement, MessageProps>(({ children, severity = 'message', ...rest }, ref) => (
  <StyledMessage severity={severity} {...rest} ref={ref}>
    <Icon icon={severityIconMap[severity]} />
    {children}
  </StyledMessage>
));

export { Message, MessageProps };
