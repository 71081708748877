import * as React from 'react';
import { StyledColorBox, StyledColorPreview, StyledColorRadio, StyledOptionCheckmark } from './ColorRadio.styled';
import { ImageRadioProps } from './ImageRadio';
import { StyledHiddenInput, StyledStack, StyledVisibilityToggle } from './ImageRadio.styled';
import { useOptionList } from './OptionList';
import { IToggleVisibility } from './VisibilityToggle';

interface ColorRadioProps extends Omit<ImageRadioProps, 'src'>, Partial<IToggleVisibility> {
  color?: string;
}

const ColorRadio = React.forwardRef<HTMLLabelElement, ColorRadioProps>(
  ({ className, children, visible, onVisibilityChange, color, ...rest }, ref) => {
    const { editVisibility } = useOptionList();

    return (
      <StyledColorRadio ref={ref} className={className}>
        <StyledStack align={'center'}>
          <StyledHiddenInput {...rest} />

          {children}

          <StyledColorBox>
            {!editVisibility ? (
              <StyledOptionCheckmark />
            ) : (
              <StyledOptionCheckmark
                as={StyledVisibilityToggle}
                checked={!visible}
                onClick={() => onVisibilityChange?.(!visible)}
              />
            )}
            <StyledColorPreview color={color} />
          </StyledColorBox>
        </StyledStack>
      </StyledColorRadio>
    );
  }
);

export { ColorRadio, ColorRadioProps };
