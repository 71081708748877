import { IconType } from 'react-icons';
import { FaFile, FaFileExcel, FaFileImage, FaFilePdf, FaFileVideo, FaFileWord } from 'react-icons/fa';

export type FileType = 'image' | 'pdf' | 'video' | 'docx' | 'xlsx' | 'file';
const extensionToFileType: Record<string, FileType> = {
  docx: 'docx',
  jpeg: 'image',
  jpg: 'image',
  mov: 'video',
  mp4: 'video',
  pdf: 'pdf',
  png: 'image',
  xlsx: 'xlsx',
};

/**
 * Maps file extensions generic file types for generalisation in icons,
 */
export function getFileTypeForExtension(extension: string | undefined): FileType {
  if (extension == null) {
    return 'file';
  }
  const e = extension.trim();
  if (!(e in extensionToFileType)) {
    return 'file';
  }
  return extensionToFileType[e];
}

/**
 * Maps {@see getFileTypeForExtension} output to Font Awesome icons
 * @package react-icons/fa
 */
export const fileTypeIconMap: Record<FileType, IconType> = {
  file: FaFile,
  image: FaFileImage,
  pdf: FaFilePdf,
  video: FaFileVideo,
  docx: FaFileWord,
  xlsx: FaFileExcel,
};
