import styled from 'styled-components';

export const StyledPopoverStat = styled.div`
  line-height: 1;
  font-size: large;

  > small {
    color: ${(p) => p.theme.colors.neutral.medium};
  }
`;

export const PopoverStatValue = styled.p``;
