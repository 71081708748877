import * as React from 'react';
import { StyledToolbar, ToolbarDivider, ToolbarDividerVertical, ToolbarPanel } from './Toolbar.styled';
import { Actionbar, ActionBarProps } from './Actionbar';
import { IconButtonProps } from '../IconButton';
import { BaseProps } from '../../interfaces/BaseProps';
import { useMedia } from 'react-use';

interface ToolbarProps
  extends BaseProps,
    Pick<ActionBarProps, 'vertical'>,
    Required<Pick<ActionBarProps, 'value' | 'onChange'>> {
  // The following lists should be the same length
  // List of ActionBarItems
  actions: React.ReactElement<IconButtonProps>[];
  // List of Panels
  panels?: React.ReactElement[];

  renderPanel?: (index: number, vertical?: boolean) => React.ReactElement | null;
}

const Toolbar = React.forwardRef<HTMLDivElement, ToolbarProps>(
  ({ actions, panels, vertical, value, onChange, renderPanel, ...rest }, ref) => {
    if (panels && actions.length !== panels.length)
      throw new Error('Toolbar: There are not the same amount of Actions and Panels');

    const isPhone = useMedia('screen and (max-width: 375px)');
    // Action button + margin
    const actionOffset = isPhone ? 48 : 52;

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore Error on the ref typing, but it's correct.
      <StyledToolbar {...rest} ref={ref} vertical={vertical}>
        {/* TODO animate a div based on the activated panel' size */}

        {/*{panels.map((panel, i) =>
          React.cloneElement(panel, { ...panel.props, key: `toolbarPanel-${i}`, hidden: i !== value, vertical })
        )}*/}

        {renderPanel?.(value, vertical)}

        {vertical ? (
          <ToolbarDividerVertical
            hidden={value === -1}
            style={{ transform: `translateY(${actionOffset * (value < 0 ? 0 : value)}px)` }}
          />
        ) : (
          <ToolbarDivider
            hidden={value === -1}
            style={{
              transform: `translateX(${actionOffset * (value < 0 ? 0 : value)}px)`,
            }}
          />
        )}

        <Actionbar value={value} onChange={(val) => onChange(val === value ? -1 : val)} vertical={vertical}>
          {actions}
        </Actionbar>
      </StyledToolbar>
    );
  }
);

export { Toolbar, ToolbarProps, ToolbarPanel };
