import styled, { css } from 'styled-components';
import { borderRadius } from '../../variables/borderRadius';
import { spacing } from '../../variables/spacing';

type Props = {
  $isMine?: boolean;
};

export const StyledChatMessage = styled.div<Props>(
  ({ $isMine, theme }) => css`
    word-break: break-word;
    hyphens: auto;

    padding: ${spacing.s12} ${spacing.s16};
    background-color: ${theme.colors.neutral.xxxlight};
    border-radius: ${borderRadius.r2} 0.75rem 0.75rem 0.75rem;
    box-shadow: ${theme.elevation.light};

    transition: ${theme.transition.default};
    transition-property: background-color, color;

    ${$isMine
      ? css`
          background-color: #f2eef8;
          color: ${theme.colors.primary.xdark};
          border-radius: 0.75rem ${borderRadius.r2} 0.75rem 0.75rem;
        `
      : undefined};
  `
);
