import styled, { css } from 'styled-components';
import { Select } from './Select';
import { Icon } from '../Icon';

export const StyledOptionIcon = styled(Icon)(
  ({ theme }) => css`
    flex: 0 0 auto;
    margin-right: ${theme.spacing.small};
  `
);

export const StyledOptionLabel = styled.span(
  ({ theme }) => css`
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2;
  `
);

export const StyledSortingPicker = styled(Select)(({ theme }) => css``);
