import * as React from 'react';
import { StyledKeybindLabel } from './KeybindLabel.styled';
import { FlexBox } from './layout/FlexBox';

type KeybindLabelProps = { keybind?: string[] };
const KeybindLabel = React.forwardRef((props: KeybindLabelProps, ref: React.Ref<HTMLDivElement>) => {
  const { keybind, ...rest } = props;

  return (
    <FlexBox inline align={'center'} gutter={'xxsmall'} {...rest} ref={ref}>
      {keybind?.map((s, i) => {
        if (s === ',') {
          return <span key={i}>{s}</span>;
        }
        return <StyledKeybindLabel key={`keybind-${i}`}>{s}</StyledKeybindLabel>;
      })}
    </FlexBox>
  );
});

export { KeybindLabel, KeybindLabelProps };
