import styled, { css } from 'styled-components';
import { Flyout } from '../flyout/Flyout';
import { spacing } from '../../variables/spacing';
import { ListItem } from '../list/ListItem';
import { borderRadius } from '../../variables/borderRadius';
import { hideOutline } from '../../utilities/mixins/hideOutline';
import { Button } from '../Button';

export const StyledActionsDropdown = styled(Flyout)(
  ({ theme }) => css`
    && {
      --bg: ${theme.colors.neutral.xxxlight};
    }

    &.tip {
      --arrowOffset: -${spacing.s4};

      .tip-inner {
        padding: 0;
        border-radius: ${borderRadius.r4};
      }
    }

    .tip-arrow {
      display: none;
    }
  `
);

export const StyledActionsButton = styled(Button)`
  background-color: white;
  // Remove from document flow
  position: absolute;
  right: 0;
`;

export const StyledFlyoutWrapper = styled.div`
  padding: ${spacing.s4} 0;
  min-width: 100%;
`;

export const ActionsDropdownItem = styled(ListItem).attrs({ forwardedAs: 'li', tabIndex: 0 })(
  ({ theme }) => css`
    ${hideOutline};
    padding: ${spacing.s8} ${spacing.s12};
    cursor: pointer;

    transition: ${theme.transition.default};
    transition-property: background-color, color;

    :hover,
    :focus.focus-visible {
      background-color: ${theme.colors.primary.default};
      color: ${theme.colors.text.light};
    }
  `
);
