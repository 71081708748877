import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import * as React from 'react';
import { StyledBox } from './Box.styled';
import { Spacing } from '../../interfaces/Spacing';

export interface BoxProps extends BaseProps, ChildrenProp {
  pad?: Spacing;
}

const Box = React.forwardRef((props: BoxProps, ref: React.Ref<HTMLDivElement>) => {
  const { children, ...rest } = props;

  return (
    <StyledBox {...rest} ref={ref}>
      {children}
    </StyledBox>
  );
});

export { Box };
