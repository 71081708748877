import * as React from 'react';
import {
  StyledOptionTabList,
  StyledOptionTabListWrapper,
  LeftArrow,
  RightArrow,
  StyledTabListItems,
} from './OptionTabList.styled';
import { BaseProps } from '../../interfaces/BaseProps';
import { useTabs } from './OptionTabs';
import { useCallback, useEffect } from 'react';

interface OptionTabListProps extends BaseProps {
  children: (React.ReactElement | false | undefined)[];
}

const OptionTabList = React.forwardRef<HTMLDivElement, OptionTabListProps>(
  ({ className, hidden, children, ...rest }, ref) => {
    const { selectedIndex, onSelect, setLastIndex } = useTabs();

    useEffect(() => {
      setLastIndex(Math.max(React.Children.count(children) - 1, 0));
    }, [children, setLastIndex]);

    const onLeftClick = useCallback((event) => onSelect(selectedIndex - 1, event), [onSelect, selectedIndex]);
    const onRightClick = useCallback((event) => onSelect(selectedIndex + 1, event), [onSelect, selectedIndex]);

    return (
      <StyledOptionTabListWrapper className={className} hidden={hidden} ref={ref}>
        <LeftArrow onClick={onLeftClick} />
        <StyledOptionTabList>
          <StyledTabListItems {...rest}>
            {React.Children.map(
              children,
              (child, index) => child && React.cloneElement(child, { ...child.props, index })
            )}
          </StyledTabListItems>
        </StyledOptionTabList>
        <RightArrow onClick={onRightClick} />
      </StyledOptionTabListWrapper>
    );
  }
);

export { OptionTabList, OptionTabListProps };
