import { useAuth0 } from '@auth0/auth0-react';
import { Navigation, NavigationItem } from '@innobrix/components';
import * as React from 'react';
import { PropsWithChildren } from 'react';
import { FaDatabase, FaEuroSign, FaHome, FaLifeRing } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

export function Layout({ children }: PropsWithChildren<unknown>) {
  const { user } = useAuth0();

  return (
    <>
      <header>
        <Navigation avatarSrc={user?.picture}>
          <NavLink exact to={'/'}>
            <NavigationItem icon={FaHome}>Home</NavigationItem>
          </NavLink>
          <NavLink to={'/sales'}>
            <NavigationItem icon={FaEuroSign}>Sales</NavigationItem>
          </NavLink>
          <NavLink to={'/support'}>
            <NavigationItem icon={FaLifeRing}>Support</NavigationItem>
          </NavLink>
          <NavLink to={'/dev'}>
            <NavigationItem icon={FaDatabase}>Dev</NavigationItem>
          </NavLink>
        </Navigation>
      </header>

      <main>{children}</main>
    </>
  );
}
