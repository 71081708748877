import * as React from 'react';
import { StyledNotificationBadge } from './NotificationBadge.styled';
import { Badge } from '../atoms/Badge';

type BadgeProps = React.ComponentProps<typeof Badge>;
interface NotificationBadgeProps extends BadgeProps {
  count?: number;
}

const NotificationBadge = React.forwardRef<HTMLDivElement, NotificationBadgeProps>(({ count, ...rest }, ref) => {
  if (count == null || count <= 0) return null;

  return (
    <StyledNotificationBadge {...rest} ref={ref}>
      {count > 99 ? '99' : count}
    </StyledNotificationBadge>
  );
});

export { NotificationBadge, NotificationBadgeProps };
