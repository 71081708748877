import * as React from 'react';
import { CheckGroupProps } from './CheckGroup';
import { StyledRadioOptionGrid, StyledErrorMessage } from './RadioOptionGroup.styled';
import { Stack } from '../layout/Stack';

export type RadioOptionGroupProps = Omit<CheckGroupProps, 'inline'>;
export const RadioOptionGroup = React.forwardRef((props: RadioOptionGroupProps, ref: React.Ref<HTMLDivElement>) => {
  const { children, className, error, touched, name, ...rest } = props;
  return (
    <Stack className={className}>
      <StyledRadioOptionGrid {...rest} ref={ref}>
        {children({ name })}
      </StyledRadioOptionGrid>

      {/* TODO animate error in */}
      {error && touched && <StyledErrorMessage error={error} />}
    </Stack>
  );
});
