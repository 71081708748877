import * as React from 'react';
import { StyledTextContainer } from './TextContainer.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';

interface Props extends BaseProps, ChildrenProp {
  size: 'S' | 'M' | 'L';
  center?: boolean;
}

const TextContainer = React.forwardRef<HTMLDivElement, Props>(({ ...rest }, ref) => {
  return <StyledTextContainer {...rest} ref={ref} />;
});

export { TextContainer };
