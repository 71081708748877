import * as React from 'react';
import { Range as RCRange, RangeProps as RProps } from 'rc-slider';
import { StyledRange, StyledRangeWrapper } from './Range.styled';
import { BaseInputProps } from '../../interfaces/InputProps';
import { ErrorMessage } from './ErrorMessage';

export interface RangeProps extends RProps, Omit<BaseInputProps<number[]>, 'onChange'> {
  id?: string;
}
export const Range = React.forwardRef((props: RangeProps, ref: React.Ref<RCRange>) => {
  const { className, error, touched, marks, ...rest } = props;

  return (
    <StyledRangeWrapper className={className} error={error} touched={touched} hasMarks={marks != null}>
      <StyledRange ref={ref} {...rest} marks={marks} />

      {touched && error && <ErrorMessage error={error} />}
    </StyledRangeWrapper>
  );
});
