import styled from 'styled-components';
import { Page } from './Page';
import { StyledPageContent } from './Page.styled';
import { breakpoints } from '../../variables/breakpoints';

export const StyledListDetailsPage = styled(Page)`
  main {
    height: var(--viewheight, 100vh);
    min-height: unset;
  }

  ${StyledPageContent} {
    height: calc(var(--viewheight, 100vh) - 4rem);
    display: grid;
    grid-template-columns: 33% 1fr;
    grid-template-rows: auto 1fr;
    gap: 1rem;

    @media screen and ${breakpoints.tabletPortraitMax} {
      height: calc(var(--viewheight, 100vh) - 4.75rem);
      // Hide the list
      grid-template-columns: 1fr;
      gap: 1rem 0;
    }

    @media screen and ${breakpoints.laptop} {
      grid-template-columns: min(25%, 400px) 1fr;
    }
  }
`;
