import * as React from 'react';
import {
  PriceListDescription,
  PriceListHeaderContainer,
  PriceListHeading,
  PriceListStat,
  PriceListStats,
  PriceListStatsContainer,
  PriceListStatUnit,
  StyledPriceListHeader,
} from './PriceListHeader.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { PriceListProps } from './PriceList';

interface PriceListHeaderProps
  extends BaseProps,
    ChildrenProp,
    Pick<PriceListProps, 'stats' | 'description' | 'heading'> {}

const PriceListHeader = React.forwardRef<HTMLDivElement, PriceListHeaderProps>(
  ({ heading, description, stats, ...rest }, ref) => {
    return (
      <PriceListHeaderContainer>
        <StyledPriceListHeader {...rest} ref={ref}>
          <PriceListHeading>{heading}</PriceListHeading>
          <PriceListDescription dangerouslySetInnerHTML={{ __html: description ?? '' }} />
        </StyledPriceListHeader>

        {stats?.length && (
          <PriceListStatsContainer>
            <PriceListStats>
              {stats.map((stat, i) => (
                <PriceListStat description={stat.description} key={i}>
                  {stat.value}
                  {stat.unit && <PriceListStatUnit>{stat.unit}</PriceListStatUnit>}
                </PriceListStat>
              ))}
            </PriceListStats>
          </PriceListStatsContainer>
        )}
      </PriceListHeaderContainer>
    );
  }
);

export { PriceListHeader, PriceListHeaderProps };
