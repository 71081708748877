/**
 * Check if the browser supports native share API
 */
export function hasNativeShare(): boolean {
  return 'share' in navigator;
}

/**
 * Native share API
 * @param shareData {ShareData} Object that holds data to share
 */
export function nativeShare(shareData: ShareData): Promise<void> {
  return new Promise((resolve, reject) => {
    if (!hasNativeShare()) reject();

    return navigator.share(shareData).then(resolve);
  });
}
