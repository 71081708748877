import * as React from 'react';
import RCTooltip from 'rc-tooltip';
import { TooltipProps } from './Tooltip';
import { KeybindLabel, KeybindLabelProps } from '../KeybindLabel';
import { FlexBox } from '../layout/FlexBox';
import { StyledKeybindTooltip } from './KeybindTooltip.styled';

type KeybindTooltipProps = TooltipProps & KeybindLabelProps;
const KeybindTooltip = React.forwardRef((props: KeybindTooltipProps, ref: React.Ref<typeof RCTooltip>) => {
  const { keybind, overlay, children, ...rest } = props;
  return (
    <StyledKeybindTooltip
      overlay={
        <FlexBox align={'center'} gutter={'xsmall'}>
          <div>{overlay}</div>
          <FlexBox>
            <KeybindLabel keybind={keybind} />
          </FlexBox>
        </FlexBox>
      }
      {...rest}
      ref={ref}
    >
      {children}
    </StyledKeybindTooltip>
  );
});

export { KeybindTooltip, KeybindTooltipProps };
