import styled from 'styled-components';
import { klavika } from '../../utilities/mixins/typography';
import { fontSize } from '../../variables/typography';

export const StyledNavigationItem = styled.li`
  padding: 1.3125rem 1.5rem;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 0.33rem;
  color: ${(p) => p.theme.colors.neutral.light};

  transition: color ${(p) => p.theme.transition.default};

  > span {
    ${klavika};
    font-size: ${fontSize.s20};
    line-height: 1;
  }

  :hover {
    color: currentColor;
  }

  .active > & {
    font-weight: bold;
    color: currentColor;
  }
`;
