import styled, { css } from 'styled-components';
import { Label } from './Label';
import { visuallyHidden } from '../../utilities/mixins/visuallyHidden';
import { checkStyle } from '../../utilities/mixins/checkStyle';
import { absoluteCenter } from '../../utilities/mixins/layout';

export const StyledRadio = styled.input.attrs({ type: 'radio' })`
  ${visuallyHidden};
`;

export const Checkdot = styled.span(
  ({ theme }) => css`
    --size: 0.75em;
    ${checkStyle(theme)};
    border-radius: 100%;
    position: relative;

    transition: ${theme.transition.slow};
    transition-property: border, box-shadow;

    ::before {
      content: '';
      display: block;
      width: var(--size);
      height: var(--size);
      border-radius: 100%;

      ${absoluteCenter};
      transform-origin: 0 0;
      transform: scale(0) translate(-50%, -50%);

      transition: ${theme.transition.slow};
      transition-property: background, box-shadow, transform;
    }
  `
);

export const StyledLabel = styled(Label)(
  ({ theme }) => css`
    display: inline-flex;
    align-items: center;
    flex-flow: row nowrap;

    input:not(:disabled):hover + & ${Checkdot}, input:not(:disabled):focus.focus-visible + & ${Checkdot} {
      box-shadow: 0 0 0 ${theme.spacing.xsmall} ${theme.colors.primary.xxlight};

      ::before {
        background: ${theme.colors.primary.xlight};
        box-shadow: ${theme.elevation.xlight};
        transform: scale(1) translate(-50%, -50%);
      }
    }

    input:checked + && ${Checkdot} {
      background: ${theme.colors.primary.default};
      border-color: transparent;

      ::before {
        background: ${theme.colors.neutral.xxlight};
        box-shadow: ${theme.elevation.xlight};
        transform: scale(1) translate(-50%, -50%);
      }
    }

    input:disabled + & {
      color: ${theme.colors.neutral.light};
      cursor: not-allowed;

      ${Checkdot} {
        background: ${theme.colors.neutral.xxlight};
        border-color: currentColor;
        cursor: not-allowed;
      }
    }

    input:disabled:checked + & ${Checkdot} {
      background: ${theme.colors.neutral.light};

      ::before {
        background: ${theme.colors.neutral.xlight};
      }
    }
  `
);
