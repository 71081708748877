import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';
import { klavika } from '../../utilities/mixins/typography';
import { borderRadius } from '../../variables/borderRadius';

export const StyledTable = styled.table(
  ({ theme }) => css`
    width: 100%;
    table-layout: fixed;
    text-align: left;

    // Headers
    thead {
      tr {
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.04);

        th {
          position: sticky;
          top: 0;

          background: white;
          box-shadow: inset 0 -2px ${theme.colors.neutral.xxlight};

          ${klavika};
          font-size: 1.125rem;
          line-height: 1.2;
          padding: ${spacing.s12} ${spacing.s16};

          &:first-child {
            border-radius: ${borderRadius.r4} 0 0 0;
          }
          &:last-child {
            border-radius: 0 ${borderRadius.r4} 0 0;
          }
        }
      }
    }

    // Body
    tbody {
      tr {
        background: white;

        // Cells
        td {
          padding: ${spacing.s8} ${spacing.s16};
          vertical-align: middle;
        }

        :nth-child(2n) {
          background: ${theme.colors.neutral.xxxlight};
        }

        :not(:last-child) {
          border-bottom: 1px solid ${theme.colors.neutral.xxlight};
        }

        :last-child {
          td:last-child {
            border-radius: 0 0 ${borderRadius.r4} 0;
          }
          td:first-child {
            border-radius: 0 0 0 ${borderRadius.r4};
          }
        }
      }
    }
  `
);
