import * as React from 'react';
import { CSSProperties, useLayoutEffect } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { Price } from '../Price';

type AnimatedPriceProps = {
  price: number;
  guid?: string;
};

const AnimatedPrice = React.memo<AnimatedPriceProps>(
  React.forwardRef<HTMLDivElement, AnimatedPriceProps>(({ price, guid = 'total', ...rest }, ref) => {
    const [init, setInit] = React.useState(false);

    useLayoutEffect(() => {
      setInit(true);
    }, []);

    const props = useSpring({
      to: async (next: (props: CSSProperties) => void) => {
        await next({ transform: 'translateX(-12.5%) scale(1.25)' });
        await next({ transform: 'translateX(0%) scale(1)' });
      },
      config: {
        friction: 33,
        tension: 300,
      },
    });

    return (
      <animated.span {...rest} ref={ref} key={`price-${guid}`} style={init ? props : {}}>
        <Price value={price} />
      </animated.span>
    );
  })
);

export { AnimatedPrice, AnimatedPriceProps };
