import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';
import { CloseButton } from '../navigation/CloseButton';
import { fadeIn } from '../../utilities/mixins/animations';

export const StyledCloseButton = styled(CloseButton)(
  ({ theme }) => css`
    font-size: 1.5em;
    color: ${theme.colors.neutral.light};
    position: absolute;
    right: ${spacing.s8};
    top: 50%;
    transform: translateY(-50%);

    transition-property: background-color, color;

    :hover {
      color: currentColor;
    }
  `
);

export const StyledAlert = styled.div(
  ({ theme }) => css`
    // layout
    display: inline-flex;
    align-items: center;
    position: relative;
    // shape
    border-radius: 2.5em;
    background-color: ${theme.colors.neutral.white};
    box-shadow: ${theme.elevation.light};
    padding: ${spacing.s8} ${spacing.s16};

    animation: ${fadeIn} ${theme.transition.slow} forwards;

    > :not(:last-child) {
      margin-right: ${spacing.s8};
    }
  `
);

export const Content = styled.div(
  () => css`
    &&:not(:last-child) {
      margin-right: 1.5rem;
    }
  `
);
