import * as React from 'react';
import { FlexBox } from './layout/FlexBox';
import { StyledFileExtensionLabel } from './FileExtensionLabel.styled';

type FileExtensionLabelProps = { extensions?: string[] };
const FileExtensionLabel = React.forwardRef((props: FileExtensionLabelProps, ref: React.Ref<HTMLDivElement>) => {
  const { extensions, ...rest } = props;

  return (
    <FlexBox inline gutter={'xxsmall'} {...rest} ref={ref}>
      {extensions?.map((s, i) => (
        <StyledFileExtensionLabel key={`extension-${i}`}>{s}</StyledFileExtensionLabel>
      ))}
    </FlexBox>
  );
});

export { FileExtensionLabel, FileExtensionLabelProps };
