import styled, { css } from 'styled-components';
import { CloseButton } from '../navigation/CloseButton';
import { Tab } from '../tabs/Tab';
import { TabList } from '../tabs/TabList';
import { spacing } from '../../variables/spacing';
import { borderRadius } from '../../variables/borderRadius';

export const StyledEditorHelp = styled.div(
  ({ theme }) => css`
    position: fixed;
    z-index: 200;
    bottom: 0;
    left: 0;
    right: 0;
    height: 16rem;
    overflow: hidden;

    display: flex;
    flex-flow: column;
    justify-content: space-between;

    background: ${theme.colors.neutral.xdark};
    color: ${theme.colors.text.light};

    // open transition
    opacity: 0;
    transform: translateY(100%);

    transition: ${(p) => p.theme.transition.slow};
    transition-property: opacity, transform;

    .ReactModal__Overlay--after-open & {
      opacity: 1;
      transform: translateY(0);
    }
    .ReactModal__Content--before-close & {
      opacity: 0;
      transform: translateY(100%);
    }
  `
);

export const StyledCloseButton = styled(CloseButton)(
  ({ theme }) =>
    css`
      font-size: 1.5em;
      transition-property: background-color, color;

      position: absolute;
      right: 1rem;
      top: 1rem;

      :hover {
        color: ${theme.colors.text.default};
      }
    `
);

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1024px;
  padding: ${spacing.s8} ${spacing.s16};
  flex: 1;
  overflow: hidden;
`;

export const StyledTab = styled(Tab)(
  ({ theme }) => css`
    border-radius: ${borderRadius.r4};
    border: none;
    font-size: 1rem;
    margin-bottom: 0;
    color: ${theme.colors.neutral.light};

    transition-property: color, background;

    &.react-tabs__tab--selected {
      background: ${theme.colors.primary.default};
      color: ${theme.colors.text.light};
    }

    &:not(:last-child) {
      margin-right: ${spacing.s8};
    }

    :focus:not(:disabled),
    :active:not(:disabled),
    :hover:not(:disabled) {
      background: ${theme.colors.primary.dark};
      color: ${theme.colors.text.light};
      border: none;
    }
  `
);

export const StyledTabList = styled(TabList)(
  ({ theme }) => css`
    border: none;
    margin-bottom: ${theme.spacing.large};
    display: flex;
    justify-content: center;
  `
);

export const EditorHelpGrid = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${theme.spacing.large};
  `
);

export const EditorHelpLinks = styled.div(
  ({ theme }) => css`
    text-align: center;
    padding: ${spacing.s8} 0;
    flex: 0 0 auto;

    > :not(:last-child) {
      margin-right: ${spacing.s8};
    }
  `
);

export const EditorHelpLink = styled.a(
  ({ theme }) => css`
    color: ${theme.colors.neutral.light};
    text-decoration: underline;
    cursor: pointer;

    transition: color ${theme.transition.slow};

    &:hover,
    &:focus {
      color: ${theme.colors.text.light};
    }
  `
);
