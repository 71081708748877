import * as React from 'react';
import { useCallback, useState } from 'react';
import { HiddenRadio, RadioGroupItemLabel, StyledRadioGroup } from './RadioGroup.styled';
import { BaseProps } from '../../interfaces/BaseProps';
import { useIsTouch } from '../../utilities/useDevices';
import { CSSProperties } from 'styled-components';

interface RadioGroupProps extends BaseProps {
  children: React.ReactElement[];
  // controlled states
  value?: number;
  onChange?: (value: number) => void;
}

const RadioGroup = React.forwardRef<HTMLDivElement, RadioGroupProps>(
  ({ value = 0, onChange, children, ...rest }, ref) => {
    const [activeElement, setActiveElement] = useState<HTMLElement | null>();
    const [hoverOffset, setHoverOffset] = useState<number>(0);

    const isTouch = useIsTouch();
    const handleMouseEnter = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        if (isTouch) return;

        const targetOffset = event.currentTarget.offsetLeft;
        if (targetOffset === hoverOffset) return;

        setHoverOffset(targetOffset);
      },
      [hoverOffset, isTouch]
    );

    const handleClick = useCallback(
      (event: React.MouseEvent, index: number) => {
        event.preventDefault();
        onChange?.(index);
      },
      [onChange]
    );

    // Create css props
    const offset = activeElement?.offsetLeft ?? value * 100;
    const styles = {
      '--rg-item-width': `${activeElement?.offsetWidth ?? 100}px`,
      '--rg-offset': `${offset}px`,
      '--rg-hover-offset':
        hoverOffset === 0 || hoverOffset === offset ? '0px' : hoverOffset > offset ? `.3125rem` : `-.3125rem`,
    } as CSSProperties;

    return (
      <StyledRadioGroup {...rest} ref={ref} onMouseLeave={() => setHoverOffset(0)} style={styles}>
        {React.Children.map(children, (child, index) => (
          <RadioGroupItemLabel
            ref={index === value ? setActiveElement : undefined}
            onClick={(event: React.MouseEvent) => handleClick(event, index)}
            onMouseEnter={handleMouseEnter}
          >
            <HiddenRadio name={'radio-group'} value={index} checked={index === value} readOnly />
            {child}
          </RadioGroupItemLabel>
        ))}
      </StyledRadioGroup>
    );
  }
);

export { RadioGroup, RadioGroupProps };
