import * as React from 'react';
import { StyledErrorMessage } from './ErrorMessage.styled';
import { BaseProps } from '../../interfaces/BaseProps';
import { FaExclamationCircle } from 'react-icons/fa';
import { FlexBox } from '../layout/FlexBox';
import { Stack } from '../layout/Stack';

export interface ErrorMessageProps extends BaseProps {
  error?: string | string[];
}

export const ErrorMessage = React.forwardRef<HTMLDivElement, ErrorMessageProps>(({ error, ...rest }, ref) => (
  <StyledErrorMessage ref={ref} {...rest}>
    <FlexBox gutter={'xxsmall'} align={'start'}>
      <FlexBox pad={'xsmall'} align={'center'}>
        <FaExclamationCircle />
      </FlexBox>
      <Stack>{Array.isArray(error) ? error.map((e, i) => <div key={i}>{e}</div>) : error}</Stack>
    </FlexBox>
  </StyledErrorMessage>
));
