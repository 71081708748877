import styled, { css } from 'styled-components';
import { ParagraphProps } from './Paragraph';
import { ThemedProps } from '../../interfaces/ThemedProps';

const sizeStyle = (props: ThemedProps<ParagraphProps>) => {
  const {
    theme: { typography },
  } = props;

  return css`
    font-family: ${typography.family};
    font-size: ${typography.text.size};
    line-height: ${typography.text.height};
    font-weight: ${typography.text.weight};
  `;
};

const marginStyle = () => {
  return css`
    margin-bottom: 1em;
  `;
};

const StyledParagraph = styled.p<ParagraphProps>`
  ${(props) => sizeStyle(props)}
  ${() => marginStyle()}
`;

export { StyledParagraph };
