import styled, { css } from 'styled-components';
import { ErrorMessage } from './ErrorMessage';

export const StyledCheckGroup = styled.fieldset(
  ({ theme }) => css`
    min-inline-size: 100%;
  `
);

export const StyledErrorMessage = styled(ErrorMessage)`
  padding: 0;
`;
