import { BaseProps, FlexBox, StatLabel } from '@innobrix/components';
import * as React from 'react';
import { PropsWithChildren } from 'react';
import { Stats } from '../../store/dashboard/dashboard';
import { SecondaryStatLabel, StyledDuoStatCard } from './DuoStatCard.styled';

type Props = BaseProps & Required<Stats>;

export const DuoStatCard = ({ children, total, thisMonth, lastMonth, ...rest }: PropsWithChildren<Props>) => {
  return (
    <StyledDuoStatCard {...rest}>
      <FlexBox align={'center'} gutter={'xsmall'}>
        <StatLabel stat={thisMonth} />
        <span>{children}</span>
      </FlexBox>
      <FlexBox align={'start'} gutter={'xsmall'}>
        of <SecondaryStatLabel stat={total} /> total
      </FlexBox>
    </StyledDuoStatCard>
  );
};
