import * as React from 'react';
import { StyledHorizontalScrollBox } from './HorizontalScrollBox.styled';
import { useCallback, WheelEventHandler } from 'react';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';

interface HorizontalScrollBoxProps extends BaseProps, ChildrenProp {}

const HorizontalScrollBox = ({ children, ...rest }: HorizontalScrollBoxProps) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const handleScroll: WheelEventHandler = useCallback((event) => {
    if (ref.current == null || event.deltaY === 0) return;

    const scrollDelta = ref.current.scrollLeft + (event.deltaY > 0 ? 100 : -100);
    ref.current.scrollTo({ left: scrollDelta });
  }, []);

  return (
    <StyledHorizontalScrollBox {...rest} ref={ref} onWheel={handleScroll}>
      {children}
    </StyledHorizontalScrollBox>
  );
};

export { HorizontalScrollBox, HorizontalScrollBoxProps };
