import * as React from 'react';
import { ReactNode } from 'react';
import { StyledFormGrid } from './FormGrid.styled';
import { BaseProps } from '../../interfaces/BaseProps';
import { Spacing } from '../../interfaces/Spacing';

export interface FormGridProps extends BaseProps {
  children?: ReactNode;
  gutter?: Spacing;
}

export const FormGrid = React.forwardRef<HTMLDivElement, FormGridProps>(({ children, ...rest }, ref) => (
  <StyledFormGrid ref={ref} {...rest}>
    {children}
  </StyledFormGrid>
));
