import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';
import { IconButton } from '../IconButton';

export const StyledInlineControls = styled.div`
  position: relative;
  display: inline-block;
  width: auto;
`;

export const Control = styled(IconButton)`
  display: flex;
  padding: 0.625rem;
`;

export const ControlsContainer = styled.div<{ $isOpen: boolean }>(
  ({ theme, $isOpen }) => css`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    background: ${theme.colors.neutral.xxlight};
    color: ${theme.colors.text.default};
    box-shadow: ${theme.elevation.light};
    border-radius: 44px;
    padding: 0 ${spacing.s4};

    visibility: ${$isOpen ? 'visible' : 'hidden'};
    opacity: ${$isOpen ? 1 : 0};
    transition: ${theme.transition.default};
    transition-property: opacity, visibility;
  `
);
