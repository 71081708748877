import styled, { css } from 'styled-components';
import { SpanProps } from './Span';
import { textEllipsis } from '../../utilities/mixins/textOverflow';

const StyledSpan = styled.span<SpanProps>(
  ({ theme: { typography, colors }, size, subtle, light, forceTypo, truncate }) => css`
    font-size: ${size === 'small' ? `.75em` : forceTypo ? typography.text.size : undefined};
    color: ${subtle ? (light ? colors.neutral.xlight : colors.neutral.medium) : undefined};

    ${forceTypo
      ? css`
          font-family: ${typography.family};
          line-height: ${typography.text.height};
          font-weight: ${typography.text.weight};
        `
      : undefined}

    ${truncate ? textEllipsis : undefined};
  `
);

export { StyledSpan };
