import * as React from 'react';
import { StyledHeading } from './Heading.styled';
import Skeleton from 'react-loading-skeleton';
import { ChildrenProp, BaseProps } from '../../interfaces/BaseProps';

export interface HeadingProps extends BaseProps, ChildrenProp {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  span?: boolean;
}

export const Heading = React.forwardRef<HTMLHeadingElement, HeadingProps>(({ level, span, children, ...rest }, ref) => {
  // This is a hack to make typescript stop whining about the `as` prop.
  const asProp = React.useMemo<unknown>(
    () => ({
      as: span ? 'span' : `h${level}`,
    }),
    [span, level]
  );

  return (
    <StyledHeading {...rest} ref={ref} level={level} {...asProp}>
      {children ?? <Skeleton />}
    </StyledHeading>
  );
});
