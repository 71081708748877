import * as React from 'react';
import { StyledUserLabel } from './UserLabel.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { Avatar } from '../atoms/Avatar';
import { Stack } from '../layout/Stack';
import { Span } from '../typo/Span';
import { Pill } from '../Pill';
import { FlexBox } from '../layout/FlexBox';

interface Props extends BaseProps, ChildrenProp {
  picture: string;
  name: string;
  email: string;
  verified?: boolean;
}

const UserLabel = React.forwardRef<HTMLDivElement, Props>(({ name, email, picture, verified = true, ...rest }, ref) => {
  return (
    <StyledUserLabel {...rest} ref={ref}>
      <Avatar size={'small'} src={picture} />
      <FlexBox align={'center'} gutter={'xsmall'}>
        <Stack>
          <Span subtle={!verified}>{name}</Span>
          <Span size={'small'} subtle>
            {email}
          </Span>
        </Stack>
        {!verified && <Pill small>invited</Pill>}
      </FlexBox>
    </StyledUserLabel>
  );
});

export { UserLabel };
