import styled, { css } from 'styled-components';
import { FlexBox } from '../layout/FlexBox';
import { spacing } from '../../variables/spacing';

export const StyledVectorInput = styled(FlexBox)(
  ({ theme }) => css`
    column-gap: ${theme.spacing.small};
  `
);

export const StyledInputWrapper = styled(FlexBox)(
  ({ theme }) => css`
    flex: 1 1 0;
    align-items: center;

    > div {
      flex: 1;
    }

    > :not(:last-child) {
      margin-right: ${spacing.s4};
    }
  `
);

export const StyledSpanX = styled.span`
  color: ${(p) => p.theme.colors.error.default};
`;
export const StyledSpanY = styled.span`
  color: ${(p) => p.theme.colors.success.default};
`;
export const StyledSpanZ = styled.span`
  color: #5f8eb9;
`;
