import * as React from 'react';
import styled, { css } from 'styled-components';
import headergraphic from '../../../../../shared/assets/src/lib/images/header-graphic.svg';
import { breakpoints } from '../../variables/breakpoints';

const HeaderSvg = React.forwardRef((props: any, ref: React.Ref<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2039 294.4" {...props} ref={ref}>
    <path d="M0 0h2039v59l-101.33 58.52L1887 88.3v58.9l-51-29.48-102 58.9-51-29.42V206l-51-29.39-102 58.91v-58.93L1479 206l-51-29.38-102 58.9v-58.93L1275 206l-51-29.38v58.88l-102-58.9v58.9l-51-29.5v-58.83l-51.06 29.4L918 118v58.65l-102-58.93-51 29.48 51 29.4-51 29.4v-58.8l-51 29.41-51-29.41-51 29.41v-58.87l-51 29.48-51-29.53-51 29.53-51-29.48v58.86l-51-29.4-51 29.4v58.92L255 206l-102 58.9V206l-51 29.5L51 206v58.9L0 294.4zm459 206v58.9l51-29.4v58.85L561 265l-51-29.5v-58.9z" />
    <defs>
      <linearGradient x1="0" y1="100%" x2="100%" y2="0" gradientTransform="rotate(9)" id="a">
        <stop className="grad-s1" offset="0" />
        <stop className="grad-s2" offset="100%" />
      </linearGradient>
    </defs>
  </svg>
));

export const StyledHeader = styled(HeaderSvg)(
  ({ theme }) => css`
    fill: url(#a);

    .grad-s1 {
      stop-color: ${theme.colors.primary.default};
    }
    .grad-s2 {
      stop-color: #5a7bac;
    }
  `
);

export const StyledHeaderGraphic = styled.div<{ $gray?: boolean }>(
  ({ theme, $gray }) => css`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background: linear-gradient(
      45deg,
      ${!$gray ? theme.colors.primary.default : theme.colors.neutral.medium} 0%,
      ${!$gray ? theme.colors.secondary.xlight : theme.colors.neutral.xxlight} 100%
    );
    -webkit-mask-image: url(${headergraphic});
    -webkit-mask-repeat: repeat-x;
    -webkit-mask-position: 0 3rem;

    height: 200px;
    -webkit-mask-size: 1011px 100%;

    @media screen and ${breakpoints.tablet} {
      height: 232px;
      -webkit-mask-size: 1211px 100%;
    }

    @media screen and ${breakpoints.laptop} {
      height: 292px;
      -webkit-mask-size: 2022px 100%;
    }
  `
);
