export type SupportedFileUploadMimeTypes = 'image/png,image/jpeg,image/webp' | 'model/gltf-binary' | 'application/pdf';

/**
 * Validate a MimeType against a supported Preset {@link SupportedFileUploadMimeTypes}
 * @param mimeType - MimeType to validate
 * @param acceptedMimeType - MimeType that is validated against
 */
export function validateMimeType(mimeType: string, acceptedMimeType: SupportedFileUploadMimeTypes): boolean {
  // Validate file type
  let pattern = '';
  switch (acceptedMimeType) {
    case 'image/png,image/jpeg,image/webp':
      pattern = '^image\\/(png|jpeg|webp)$';
      break;

    case 'application/pdf':
      pattern = 'application\\/(pdf)$';
      break;

    case 'model/gltf-binary':
      pattern = 'model\\/gltf-binary';
      break;
  }
  const regex = new RegExp(pattern);

  return !mimeType ? false : regex.test(mimeType);
}

/**
 * Get the file extension from a File's name
 * @param filename {string} The filename
 */
export function getFileExtension(filename: string): string | undefined {
  return filename.split('.').pop();
}
