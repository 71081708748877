import styled from 'styled-components';

export const StyledNavigation = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  background-color: white;
  box-shadow: ${(p) => p.theme.elevation.light};

  // Logo, user avatar
  > :first-child,
  > :last-child {
    flex: 0 0 auto;
  }

  // Logo
  > :first-child {
    height: 1.875rem;
  }

  nav {
    flex: 1;
    display: flex;
    margin: 0 6rem;
    list-style: none;

    > a {
      color: currentColor;
      text-decoration: none;
    }
  }
`;
