import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';

export const StyledSubtotalDivider = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    height: 1px;

    font-size: 0.5rem;
    width: calc(100% + 0.75em);
    margin-right: -0.75em;

    color: ${theme.colors.neutral.xlight};

    > :not(:last-child) {
      margin-right: ${spacing.s2};
    }
  `
);

export const Divider = styled.div(
  ({ theme }) => css`
    height: 1px;
    border-radius: 50%;
    flex: 1 1 100%;
    background: currentColor;
  `
);
