import styled from 'styled-components';
import { Button } from './Button';
import { MathUtils } from 'three';
import { ProgressButtonProps } from './ProgressButton';

export const StyledProgressButton = styled(Button).attrs<ProgressButtonProps>((props) => ({
  disabled: props.progress != null,
  variant: 'primary',
}))<ProgressButtonProps>`
  position: relative;
  overflow: hidden;

  :disabled {
    cursor: wait;
    color: ${(p) => p.theme.colors.text.light};
    background: ${(p) => p.theme.colors.primary.default};
  }

  ::before {
    content: '';
    display: ${(p) => (p.progress != null ? 'block' : 'none')};
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: ${(p) => MathUtils.clamp(p.progress ?? 0, 0, 100)}%;
    background: ${(p) => p.theme.colors.neutral.xdark};
    mix-blend-mode: screen;

    transition: left ${(p) => p.theme.transition.default};
  }
`;
