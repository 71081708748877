import * as React from 'react';
import { StyledHorizontalSteps } from './HorizontalSteps.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';

interface HorizontalStepsProps extends BaseProps, ChildrenProp {}

const HorizontalSteps = React.forwardRef<HTMLOListElement, HorizontalStepsProps>(({ children, ...rest }, ref) => {
  return (
    <StyledHorizontalSteps {...rest} ref={ref}>
      {children}
    </StyledHorizontalSteps>
  );
});

export { HorizontalSteps, HorizontalStepsProps };
export { StyledHorizontalStep as HorizontalStep } from './HorizontalSteps.styled';
