import * as React from 'react';
import styled, { css } from 'styled-components';
import { Overlay } from './Overlay';
import { FlexBox } from '../layout/FlexBox';
import { CloseButton } from '../navigation/CloseButton';
import { breakpoints } from '../../variables/breakpoints';
import { HelpTabList, HelpTab } from './EditorHelpOverlay';

export const StyledHelpOverlay = styled((props) => <Overlay {...props} />)(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    align-items: center;

    padding: ${theme.spacing.large} ${theme.spacing.medium};
    height: 100%;
    z-index: 1100;

    // open transition
    opacity: 0;
    transition: opacity ${theme.transition.slow};

    .ReactModal__Overlay--after-open & {
      opacity: 1;
    }
    .ReactModal__Content--before-close & {
      opacity: 0;
    }

    @media screen and ${breakpoints.tablet} {
      padding: 4rem;
    }
  `
);

export const InnerContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  align-items: center;
`;

export const ActionsContainer = styled(FlexBox)`
  flex: 0 1 auto;
`;

export const StyledCloseButton = styled(CloseButton)`
  font-size: 3em;
  position: fixed;
  top: 1.75rem;
  right: 2rem;
  z-index: 300;

  transition-property: background-color, color;
`;

export const StyledTabList = styled(HelpTabList)`
  margin-bottom: 5rem;
`;

export const StyledTab = styled(HelpTab)`
  @media screen and ${breakpoints.tablet} {
    font-size: 1.25em;
  }
`;
