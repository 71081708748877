import * as React from 'react';
import { DocumentsListHeader, DocumentsListHeaderUser, StyledDocumentsList } from './DocumentsList.styled';
import { BaseProps, ChildrenProp } from '../../../interfaces/BaseProps';
import { Stack } from '../../layout/Stack';
import { Span } from '../../typo/Span';
import { Heading } from '../../typo/Heading';
import { FlexBox } from '../../layout/FlexBox';
import { FaFolderOpen } from 'react-icons/fa';
import { DocumentUpload } from './DocumentUpload';
import { Avatar } from '../../atoms';
import Skeleton from 'react-loading-skeleton';
import { FormattedMessage } from 'react-intl';

type UploadProps = React.ComponentProps<typeof DocumentUpload>;

interface DocumentsListProps extends BaseProps, ChildrenProp, Pick<UploadProps, 'onChange' | 'accept'> {
  userName?: string;
  userEmail?: string;
  avatarSrc?: string;
}

const DocumentsList = React.forwardRef<HTMLDivElement, DocumentsListProps>(
  ({ onChange, children, accept, userName, userEmail, avatarSrc, ...rest }, ref) => {
    return (
      <StyledDocumentsList {...rest} ref={ref}>
        <DocumentsListHeader>
          <DocumentsListHeaderUser align={'center'}>
            {avatarSrc != null ? (
              <Avatar src={avatarSrc} size={'large'} />
            ) : (
              <Skeleton circle width={144} height={144} />
            )}
            <Span>{userName != null ? <b>{userName}</b> : <Skeleton width={169} />}</Span>
            <Span size={'small'}>{userEmail != null ? userEmail : <Skeleton width={144} />}</Span>
          </DocumentsListHeaderUser>

          <Heading level={5}>
            <FlexBox align={'stretch'} gutter={'xsmall'}>
              <FaFolderOpen />{' '}
              <span>
                <FormattedMessage id={'chat.documents'} />
              </span>
            </FlexBox>
          </Heading>
        </DocumentsListHeader>

        <Stack gutter={'small'}>
          <DocumentUpload accept={accept} onChange={onChange} />

          <ul>{children}</ul>
        </Stack>
      </StyledDocumentsList>
    );
  }
);

export { DocumentsList, DocumentsListProps };
