import * as React from 'react';
import { Select, SelectProps } from '../form/Select';
import ReactSelect from 'react-select';
import { currencies } from '@innobrix/definitions';
import getSymbolFromCurrency from 'currency-symbol-map';
import { components } from 'react-select';
import { StyledCurrencySelect, StyledIconOption } from './CurrencySelect.styled';
import { Span } from '../typo/Span';

// Create options for the select
const options = currencies.map((c) => ({
  value: c.code,
  label: c.currency,
}));

// Option with a Icon
const IconOption = (props: React.ComponentProps<typeof components.Option>) => {
  const symbol = getSymbolFromCurrency(props.data.value);

  return (
    <StyledIconOption {...props}>
      <Span subtle>{symbol}</Span>
      <span>{props.data.label}</span>
    </StyledIconOption>
  );
};

// Reuse IconOption for the value
const IconValue = (props: React.ComponentProps<typeof components.SingleValue>) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore this is a styled component so there's an as prop
  return <IconOption {...props} children={props.children} as={components.SingleValue} />;
};

type CurrencySelectProps = Omit<SelectProps, 'options'>;

const CurrencySelect = React.forwardRef<ReactSelect, CurrencySelectProps>(({ ...rest }, ref) => (
  <StyledCurrencySelect>
    <Select
      {...rest}
      ref={ref}
      options={options}
      components={{ Option: IconOption, SingleValue: IconValue }}
      isSearchable
    />
  </StyledCurrencySelect>
));

export { CurrencySelect, CurrencySelectProps };
