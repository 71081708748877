import * as React from 'react';
import { TextInputProps } from './TextInput';
import styled, { css } from 'styled-components';
import { autofillMixin, inputBox, inputBoxFocus, inputStyle } from '../../utilities/mixins/inputStyle';
import { Stack } from '../layout/Stack';

/*
TODO style browser autofill see: https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
*/
export const StyledInputWrapper = styled.div<{ error?: string; touched?: boolean; disabled?: boolean }>(
  ({ theme, error, touched, disabled }) => css`
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;

    ${inputBox({ theme, disabled, error, touched })};

    padding: 0 ${theme.spacing.small};
    overflow: hidden;

    ${autofillMixin()};

    :focus-within,
    :hover {
      ${inputBoxFocus({ theme, disabled })};
    }
  `
);

export const Container = styled(Stack)(
  ({ theme }) => css`
    min-width: 0;
  `
);

export const StyledTextInput = styled.input<TextInputProps>(
  ({ theme }) => css`
    flex: 1 0 auto;

    ${inputStyle(theme)};
  `
);

export const IconWrapper = styled.div<React.HTMLAttributes<HTMLDivElement> & { $noIconBackdrop?: boolean }>(
  ({ theme, onClick, $noIconBackdrop }) => css`
    display: flex;
    align-items: center;
    place-content: center;

    width: 1em;
    //height: 1em;

    background-color: ${!$noIconBackdrop ? theme.colors.neutral.xlight : 'transparent'};
    padding: 0.75em;
    box-sizing: content-box;

    ${onClick &&
    css`
      cursor: pointer;
    `};

    :first-child {
      margin-left: -0.5em;
    }

    :last-child {
      margin-right: -0.5em;
    }

    > svg,
    > span {
      line-height: 1;
      display: inline-block;
      user-select: none;

      :empty {
        display: none;
      }
    }
  `
);
