import styled, { css } from 'styled-components';
import { inputBox, inputBoxFocus } from '../../utilities/mixins/inputStyle';
import { borderRadius } from '../../variables/borderRadius';
import { spacing } from '../../variables/spacing';
import { buttonColorMap, buttonStyle } from '../../utilities/mixins/buttonStyle';

export const StyledTextEditor = styled.div(
  ({ theme }) => css`
    position: relative;
    display: flex;
    flex-flow: column;
    overflow-y: auto;
    overflow-x: auto;
    flex: 1;
    ${inputBox};

    :focus-within,
    :hover {
      ${inputBoxFocus({ theme })};
    }

    .quill {
      display: flex;
      flex-flow: column;
      border-radius: 0.25rem;
      overflow-y: auto;
      overflow-x: auto;
      flex: 1;

      a {
        color: ${theme.colors.primary.default};
      }

      .ql-toolbar,
      .ql-container {
        border: none;
        font-family: ${theme.typography.family};
      }

      .ql-toolbar {
        background: ${theme.colors.neutral.xxxlight};
        border-bottom: 2px solid ${theme.colors.neutral.xlight};
        border-radius: ${theme.borderRadius.small} ${theme.borderRadius.small} 0 0;

        button:hover,
        button:focus,
        button.ql-active {
          color: ${theme.colors.text.light};
          background: ${theme.colors.primary.default};
          border-radius: ${theme.borderRadius.small};

          .ql-stroke {
            stroke: currentColor;
          }
          .ql-fill {
            fill: currentColor;
          }
        }
      }

      .ql-tooltip {
        z-index: 500;
        border-radius: ${borderRadius.r4};
        border: none;
        box-shadow: ${theme.elevation.medium};
        padding: ${spacing.s8} ${spacing.s8} ${spacing.s8} ${spacing.s12};

        :not(.ql-hidden) {
          display: flex;
          align-items: stretch;
        }

        input {
          outline: none;
          border-radius: ${borderRadius.r4};
          height: auto;
        }

        a.ql-action {
          ${buttonStyle(theme, buttonColorMap(theme)['primary'])};
          background: ${theme.colors.primary.default};
          color: ${theme.colors.text.light};
        }
        a.ql-remove {
          ${buttonStyle(theme, buttonColorMap(theme)['secondary'])};
          background: ${theme.colors.neutral.xxlight};
          color: ${theme.colors.text.default};
        }

        a.ql-action,
        a.ql-remove {
          margin-left: ${spacing.s8};
          padding: 0 ${spacing.s8};

          ::after,
          ::before {
            margin-left: 0;
            border-right: none;
            padding-right: 0;
          }
        }
      }

      .ql-container {
        font-size: 0.875rem;
        flex: 1;
        height: unset;
        // 4 lines
        min-height: 8.75em;

        margin: ${spacing.s4};
        overflow-y: auto;
        overflow-x: auto;
        display: flex;
        flex-flow: column;
      }

      .ql-editor {
        padding: ${spacing.s12};
        flex: 1;

        p:not(:last-child) {
          margin-bottom: 0.5em;
        }
      }
    }
  `
);
