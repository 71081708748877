import styled, { css } from 'styled-components';
import { BaseProps, ChildrenProp, Span, textEllipsis } from '@innobrix/components';
import { StyledStatsCard } from './StatCard.styled';

// Container progress props
export interface OverlayProps extends BaseProps, ChildrenProp {
  copied: boolean;
}

// Overlay
export const StyledOverlay = styled.div<OverlayProps>(
  ({ copied }) => css`
    z-index: 1;
    background-color: rgba(241, 242, 243, 0.9);
    transition: opacity 0.2s ease-out;
    opacity: ${copied ? 1 : 0};
    pointer-events: ${copied ? 'inherit' : 'none'};
    position: absolute;
    width: 100%;
    height: 100%;
    margin: -1rem;

    ::after {
      content: 'Copied to clipboard!';
      pointer-events: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  `
);

export const StyledAccessTokenCard = styled(StyledStatsCard)`
  line-height: inherit;
`;

export const StyledSpanEllipsis = styled(Span)`
  ${textEllipsis};
`;
