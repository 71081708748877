import { CloseButtonProps } from './CloseButton';
import styled, { css } from 'styled-components';
import { ThemedProps } from '../../interfaces/ThemedProps';
import * as React from 'react';

const CloseButtonSvg = React.forwardRef((props: ThemedProps<CloseButtonProps>, ref: React.Ref<SVGSVGElement>) => (
  <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
    <path d="M28 2L2 28M2 2L28 28" />
  </svg>
));

export const StyledCloseButtonWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  border-radius: 100%;
  cursor: pointer;

  transition: ${(props) => props.theme.transition.default};
  transition-property: background-color;

  :active,
  :focus.focus-visible,
  :hover {
    ${(p) =>
      !p.disabled &&
      css`
        background-color: ${(props) => props.theme.colors.neutral.xlight};
      `}
  }

  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const StyledCloseButton = styled(CloseButtonSvg)<CloseButtonProps>`
  width: 0.5em;
  height: 0.5em;
  fill: none;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;

  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed;
    `}

  path {
    stroke: currentColor;
    stroke-width: 4;
    stroke-linecap: round;
  }
`;
