export const weight = {
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

export const fontSize = {
  s12: '.75rem',
  s14: '.875rem',
  s16: '1rem',
  s18: '1.125rem',
  s20: '1.25rem',
  s24: '1.5rem',
  s30: '1.875rem',
  s36: '2.125rem',
  s48: '3rem',
  s60: '3.75rem',
  s72: '4.5rem',
};
