import * as React from 'react';
import { HiddenInput, Image, ImagePlaceholder, ImageUploadOverlay, StyledImageUpload } from './ImageUpload.styled';
import { NoImage } from '../graphics/Svgs';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { FaImage, FaTrashAlt } from 'react-icons/fa';
import { DropOverlayMessage } from '../overlay/DropOverlayMessage';
import { FormattedMessage } from 'react-intl';

type ImageUploadProps = {
  imageSrc?: string;
  options?: DropzoneOptions;
  onClear?: () => void;
  dropOverlay?: React.ReactNode;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
};

const ImageUpload = React.forwardRef<HTMLDivElement, ImageUploadProps>(
  ({ imageSrc, options, onClear, dropOverlay, onBlur, ...rest }, ref) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone(options);

    const rootProps = getRootProps();

    return (
      <StyledImageUpload
        {...rest}
        ref={ref}
        {...rootProps}
        onBlur={(event) => {
          rootProps?.onBlur?.(event);
          onBlur?.(event);
        }}
      >
        <HiddenInput {...getInputProps()} />

        <ImagePlaceholder>
          <Image src={imageSrc} alt={''} />
          {!imageSrc && (
            <>
              <NoImage />
              <span>
                <FormattedMessage id={'upload-image'} values={{ b: (v: string) => <b>{v}</b> }} />
              </span>
            </>
          )}

          {isDragActive && dropOverlay != null ? (
            <ImageUploadOverlay>{dropOverlay}</ImageUploadOverlay>
          ) : (
            !!imageSrc && (
              <ImageUploadOverlay>
                <FaImage />
                <FaTrashAlt
                  onClick={(event) => {
                    event.stopPropagation();
                    onClear?.();
                  }}
                />
              </ImageUploadOverlay>
            )
          )}
        </ImagePlaceholder>
      </StyledImageUpload>
    );
  }
);

ImageUpload.defaultProps = {
  dropOverlay: <DropOverlayMessage />,
};

export { ImageUpload, ImageUploadProps };
