export class AfasOption {
  [key: string]: any;

  public static RequiredOptionKeys = [
    'Hfd.grp.',
    'Hoofdgroep',
    'Subgroep',
    'Sub.grp',
    'Code',
    'Omschrijving',
    'Toelichting',
    'Bedrag incl. btw',
  ];
  public Aantal?: string;
  public Bedrag?: string;

  public get BedragInclBtw(): string {
    return this['Bedrag incl. btw'];
  }

  public Bouwfase?: string;
  public Code?: string;

  public get HoofdgroepCode(): string {
    return this['Hfd.grp.'];
  }

  public Hoofdgroep?: string;
  public Omschrijving?: string;
  public Prijs?: string;
  public Sluitingsdatum?: string;
  public Status?: string;

  public get SubgroepCode(): string {
    return this['Sub.grp'];
  }

  public Subgroep?: string;
  public Toelichting?: string;
}
