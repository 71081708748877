import styled, { css } from 'styled-components';
import { components } from 'react-select';
import { spacing } from '../../variables/spacing';

export const StyledCurrencySelect = styled.div(
  ({ theme }) => css`
    .rs__option--is-selected {
      color: ${theme.colors.primary.xxxlight};

      > :first-child {
        color: ${theme.colors.primary.xxlight};
      }
    }

    .rs__option,
    .rs__single-value {
      > :first-child {
        margin-right: ${spacing.s4};
      }
    }
  `
);

const { Option } = components;
export const StyledIconOption = styled(Option)(({ theme }) => css``);
