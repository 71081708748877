import * as React from 'react';
import {
  AvatarWrapper,
  NameLabel,
  StyledChatsListItem,
  StyledChatsListLink,
  SubtitleLabel,
  TimeLabel,
} from './ChatsListItem.styled';
import { BaseProps } from '../../interfaces/BaseProps';
import { FormattedRelativeTime } from 'react-intl';
import { differenceInMinutes } from 'date-fns';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { useIsComputer } from '../../utilities/useDevices';

type MyLinkProps = Pick<NavLinkProps, 'target'> & {
  to: string;
};

interface ChatsListItemProps extends BaseProps, MyLinkProps {
  avatar: React.ReactElement;
  noLink?: boolean;
  //
  name?: string;
  subtitle?: React.ReactElement;
  lastMessageReceived?: Date;
}

const ChatsListItem = React.forwardRef<HTMLLIElement | HTMLDivElement, ChatsListItemProps>(
  ({ avatar, to, target, subtitle, lastMessageReceived, name, noLink, ...rest }, ref) => {
    const isTabletPortrait = useIsComputer();

    const relativeMinutes =
      lastMessageReceived == null ? undefined : differenceInMinutes(lastMessageReceived, new Date());

    const linkProps: NavLinkProps | undefined = noLink
      ? undefined
      : {
          to,
          exact: true,
          target,
          rel: target === '_blank' ? 'noopener' : undefined,
        };

    return (
      <StyledChatsListItem
        {...rest}
        ref={noLink ? (ref as React.ForwardedRef<HTMLDivElement>) : (ref as React.ForwardedRef<HTMLLIElement>)}
        as={noLink ? 'div' : 'li'}
      >
        <StyledChatsListLink as={noLink ? 'div' : NavLink} {...linkProps}>
          <AvatarWrapper>{avatar}</AvatarWrapper>

          <NameLabel truncate>{name}</NameLabel>

          <SubtitleLabel size={'small'} subtle truncate>
            {subtitle}
          </SubtitleLabel>

          {relativeMinutes != null && (
            <TimeLabel size={'small'} subtle>
              <FormattedRelativeTime
                value={relativeMinutes}
                numeric={'auto'}
                unit={'minute'}
                style={isTabletPortrait ? 'long' : 'narrow'}
                updateIntervalInSeconds={60}
              />
            </TimeLabel>
          )}
        </StyledChatsListLink>
      </StyledChatsListItem>
    );
  }
);

export { ChatsListItem, ChatsListItemProps };
