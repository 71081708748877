import styled, { css } from 'styled-components';
import { Range } from '../form/Range';
import { StyledIcon } from '../Icon.styled';
import { ThemedProps } from '../../interfaces/ThemedProps';
import { Slider } from '../form/Slider';
import { CommonApiProps } from 'rc-slider';
import { breakpoints } from '../../variables/breakpoints';

const selector = '.rc-slider';
const styling = ({ theme, marks, reverse }: ThemedProps<CommonApiProps>) => css`
  margin: 0.5rem 0 0;

  ${selector} {
    --height: 0.25rem;
    padding: 0;

    &-vertical {
      width: 100%;
      height: unset;

      ${selector}-handle {
        border: 1px solid white;
        width: 4rem;
        height: 1rem;
        border-radius: ${theme.borderRadius.medium};
        left: 50%;
        transform: ${reverse ? 'translate(-50%, -50%)' : 'translate(-50%, 50%)'} !important;
        margin: 0 !important;

        display: flex;
        align-items: center;
        justify-content: center;
        color: ${theme.colors.text.light};
        box-sizing: content-box;

        transition: ${theme.transition.default};
        transition-property: bottom, top, box-shadow;
      }

      ${selector}-dot,
      ${selector}-step,
        ${selector}-rail,
        ${selector}-track {
        display: none;
      }

      ${selector}-mark {
        font-size: 1rem;
        position: static;
        display: flex;
        flex-flow: column-reverse;
      }

      ${selector}-mark-text {
        position: static;
        display: flex;
        align-items: center;
        white-space: nowrap;
        padding: 0.75em;
        line-height: 1;
        user-select: none;
        -webkit-user-select: none;

        margin-bottom: 0 !important;

        transition: ${theme.transition.slow};
        transition-property: background-color, color, border-radius;

        @media screen and ${breakpoints.tablet} {
          padding: 1em;
        }

        & > ${StyledIcon} {
          flex: 0 0 auto;
          margin-right: ${theme.spacing.small};
        }

        &-active {
          background: ${theme.colors.primary.default};
          color: ${theme.colors.text.light};

          :first-of-type {
            border-radius: 0 0 ${theme.borderRadius.medium} ${theme.borderRadius.medium};
          }

          :last-of-type {
            border-radius: ${theme.borderRadius.medium} ${theme.borderRadius.medium} 0 0;
          }

          :first-of-type:last-of-type {
            border-radius: ${theme.borderRadius.medium};
          }
        }
      }
    }
  }
`;

export const StyledLayerSwitcherSingle = styled(Slider)(styling);
export const StyledLayerSwitcherMultiple = styled(Range)(styling);
