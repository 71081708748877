import { Navigator } from 'webxr';
import { XRSession } from 'three';

export function isOculusBrowser(): boolean {
  return navigator.userAgent.includes('OculusBrowser');
}

/**
 * Check whether we're running in an iframe
 */
function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

/**
 * TypeGuard for Navigator
 * @param navigator
 */
function isXrSupported(navigator: any): navigator is Navigator {
  return 'xr' in navigator;
}

export function supportsImmersiveVr(): Promise<boolean> {
  return new Promise((resolve, reject) => {
    // iframes don't support WebXR features and will throw an error when requesting support.
    if (inIframe()) {
      resolve(false);
      return;
    }

    if (isXrSupported(window.navigator)) {
      window.navigator.xr.isSessionSupported('immersive-vr').then(resolve).catch(reject);
    } else resolve(false);
  });
}

export function requestImmersiveVrSession(): Promise<XRSession> {
  return (((window.navigator as unknown) as Navigator).xr.requestSession('immersive-vr', {
    optionalFeatures: ['local-floor', 'bounded-floor', 'hand-tracking'],
  }) as unknown) as Promise<XRSession>;
}
