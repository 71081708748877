import * as React from 'react';
import styled, { css } from 'styled-components';
import { StyledCheckmark, StyledLabel } from './Checkbox.styled';
import { ThemedProps } from '../../interfaces/ThemedProps';
import { TriState } from './TriStateCheckbox';
import { Checkbox } from './Checkbox';

// TODO use :indeterminate pseudo selector and state
export const StyledTriMark = styled(StyledCheckmark).attrs({ tri: true })(
  ({ theme, state }: ThemedProps<{ state?: TriState }>) => css`
    ${state === 'some'
      ? css`
          && {
            border-color: transparent;
            background-color: ${theme.colors.neutral.medium};

            path {
              :last-child {
                opacity: 1;
                display: unset;
                stroke: ${theme.colors.text.light};

                stroke-dasharray: 12;
                stroke-dashoffset: 0;
              }
            }
          }

          input:not(:disabled) + ${StyledLabel}:hover &,
          input:not(:disabled):focus + ${StyledLabel} & {
            border-color: currentColor;
            background-color: transparent;

            path:last-child {
              opacity: 0;
              stroke-dashoffset: 12;
            }
          }

          input:disabled + ${StyledLabel} & {
            background-color: ${theme.colors.neutral.light};
          }
        `
      : css`
          &&&& {
            path:last-child {
              stroke: currentColor;
              opacity: 0;
              stroke-dasharray: 12;
              stroke-dashoffset: 12;
            }
          }
        `};
  `
);

export const RestyledCheckbox = styled(Checkbox)<{ state?: TriState }>(
  ({ theme, state }) =>
    state === 'some' &&
    css`
      ${StyledLabel} {
        color: ${theme.colors.neutral.medium};
      }
    `
);
