import * as React from 'react';
import { Span } from '../typo/Span';
import { FormattedDate, FormattedTime } from 'react-intl';

type Props = {
  // A date or a date string
  value: Date | string;
};

export const DateTimeLabel = ({ value, ...rest }: Props) => {
  return (
    <Span {...rest}>
      <FormattedDate value={value} /> <FormattedTime value={value} />
    </Span>
  );
};
