import * as React from 'react';
import { ReactText } from 'react';
import { StyledShortcutLabel } from './ShortcutLabel.styled';
import { BaseProps } from '../../interfaces/BaseProps';

export interface ShortcutLabelProps extends BaseProps {
  children: ReactText;
}
export const ShortcutLabel = React.forwardRef((props: ShortcutLabelProps, ref: React.Ref<HTMLSpanElement>) => {
  const { children, ...rest } = props;
  return (
    <StyledShortcutLabel {...rest} ref={ref}>
      {children}
    </StyledShortcutLabel>
  );
});
