import styled from 'styled-components';
import { TextInput } from '../TextInput';

export const StyledNumberInput = styled(TextInput)`
  input {
    text-align: right;
    width: 100%;
    min-width: 4ch;
    box-sizing: content-box;
    flex-basis: 0;
  }
`;
