import * as React from 'react';
import { ChatHeaderBackArrow } from './ChatHeader.styled';
import { BaseProps, ChildrenProp } from '../../../interfaces/BaseProps';
import { Icon } from '../../Icon';
import { FaChevronLeft } from 'react-icons/fa';
import { ListItem } from '../../list/ListItem';

type Props = BaseProps &
  ChildrenProp & {
    onBackClick?: React.MouseEventHandler;
  };

const ChatHeader = React.forwardRef<HTMLDivElement, Props>(({ onBackClick, children, ...rest }, ref) => (
  <ListItem {...rest} ref={ref}>
    <ChatHeaderBackArrow onClick={onBackClick}>
      <Icon icon={FaChevronLeft} />
    </ChatHeaderBackArrow>

    <div>{children}</div>
  </ListItem>
));

export { ChatHeader };
