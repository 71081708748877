import styled, { css, keyframes } from 'styled-components';
import { Tooltip } from '../tooltip/Tooltip';

const flyIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const flyOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-1rem);
  }
`;

const arrowStyling = css`
  .tip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

  // Arrow Top
  &.tip-placement-top .tip-arrow,
  &.tip-placement-topLeft .tip-arrow,
  &.tip-placement-topRight .tip-arrow {
    bottom: calc(var(--dist) - var(--size));
    margin-left: calc(var(--size) * -1);
    border-width: var(--size) var(--size) 0;
    border-top-color: var(--bg);
  }

  &.tip-placement-top .tip-arrow {
    left: 50%;
  }

  &.tip-placement-topLeft .tip-arrow {
    left: calc(var(--arrow-margin) + var(--size));
  }

  &.tip-placement-topRight .tip-arrow {
    right: var(--arrow-margin);
  }

  // Arrow Right
  &.tip-placement-rightTop .tip-arrow,
  &.tip-placement-rightBottom .tip-arrow,
  &.tip-placement-right .tip-arrow {
    left: calc(var(--dist) - var(--size));
    margin-top: calc(var(--size) * -1);
    border-width: var(--size) var(--size) var(--size) 0;
    border-right-color: var(--bg);
  }

  &.tip-placement-right .tip-arrow {
    top: 50%;
  }

  &.tip-placement-rightTop .tip-arrow {
    top: var(--arrow-margin);
    margin-top: 0;
  }

  &.tip-placement-rightBottom .tip-arrow {
    bottom: var(--arrow-margin);
  }

  // Arrow Bottom
  &.tip-placement-bottom .tip-arrow,
  &.tip-placement-bottomLeft .tip-arrow,
  &.tip-placement-bottomRight .tip-arrow {
    top: calc(var(--dist) - var(--size));
    margin-left: calc(var(--size) * -1);
    border-width: 0 var(--size) var(--size);
    border-bottom-color: var(--bg);
  }

  &.tip-placement-bottom .tip-arrow {
    left: 50%;
  }

  &.tip-placement-bottomLeft .tip-arrow {
    left: calc(var(--arrow-margin) + var(--size));
  }

  &.tip-placement-bottomRight .tip-arrow {
    right: var(--arrow-margin);
  }

  // Arrow Left
  &.tip-placement-left .tip-arrow,
  &.tip-placement-leftBottom .tip-arrow,
  &.tip-placement-leftTop .tip-arrow {
    right: calc(var(--dist) - var(--size));
    margin-top: calc(var(--size) * -1);
    border-width: var(--size) 0 var(--size) var(--size);
    border-left-color: var(--bg);
  }

  &.tip-placement-left .tip-arrow {
    top: 50%;
  }

  &.tip-placement-leftTop .tip-arrow {
    top: var(--arrow-margin);
    margin-top: 0;
  }

  &.tip-placement-leftBottom .tip-arrow {
    bottom: var(--arrow-margin);
  }
`;

export const StyledFlyout = styled(Tooltip)(
  ({ theme }) => css`
    && {
      --bg: ${theme.colors.neutral.xxlight};
      color: currentColor;
    }

    &.tip {
      --arrow-margin: 1.25em;
      --arrowOffset: 0.25em;
      --dist: calc(var(--size) + var(--arrowOffset));

      .tip-flyout-enter,
      .tip-flyout-leave {
        display: block;
      }

      .tip-inner {
        padding: ${theme.spacing.medium};
        border-radius: ${theme.borderRadius.medium};
      }
    }

    &.tip-flyout-enter,
    &.tip-flyout-appear,
    &.tip-flyout-leave {
      animation: ${theme.transition.slow};
      animation-fill-mode: both;
      animation-play-state: paused;
    }

    &.tip-flyout-enter,
    &.tip-flyout-appear {
      opacity: 0;
    }

    &.tip-flyout-enter.tip-flyout-enter-active,
    &.tip-flyout-appear.tip-flyout-appear-active {
      animation-name: ${flyIn};
      animation-play-state: running;
    }

    &.tip-flyout-leave.tip-flyout-leave-active {
      animation-name: ${flyOut};
      animation-play-state: running;
    }

    ${arrowStyling};
  `
);
