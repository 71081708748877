import styled, { css } from 'styled-components';
import { weight } from '../../variables/typography';
import { Stack } from '../layout/Stack';
import { Scrollable } from '../layout/Scrollable';

export const StyledPriceList = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1 auto;
`;

export const PriceListTop = styled(Stack)(
  ({ theme }) => css`
    overflow-y: auto;
    overflow-x: auto;
    background-color: ${theme.colors.neutral.white};
    flex: 1 1 auto;
  `
);

export const StyledScrollable = styled(Scrollable)`
  position: relative;
  overflow-x: hidden;
`;

export const PriceListOptionsContainer = styled.div`
  flex: 1;
  font-weight: ${weight.light};

  span + span {
    font-weight: ${weight.regular};
  }
`;
