import styled, { css } from 'styled-components';
import { Size } from './Avatar';
import { spacing } from '../../variables/spacing';
import { NotificationBadge } from '../notification/NotificationBadge';
import { Image } from '../Image';

type Props = {
  $size: Size;
};

export const StyledAvatar = styled(Image)<Props>(
  ({ theme, $size }) => css`
    --avatar-size: ${$size === 'large' ? '7.5rem' : '2.5rem'};

    border-radius: 50%;
    width: var(--avatar-size);
    height: var(--avatar-size);

    object-fit: cover;
    object-position: center;
  `
);

export const StyledAvatarWrapper = styled.div`
  position: relative;
`;

export const NotificationCount = styled(NotificationBadge)`
  position: absolute;
  top: -${spacing.s4};
  left: -${spacing.s4};
`;
