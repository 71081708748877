import * as React from 'react';
import { StyledLogo } from './Logo.styled';
import { BaseProps } from '../../interfaces/BaseProps';

export interface LogoProps extends BaseProps {
  inverted?: boolean;
}

const Logo = React.forwardRef<SVGSVGElement, LogoProps>(({ inverted, className, ...rest }, ref) => (
  <StyledLogo {...rest} inverted={inverted} className={className} ref={ref} />
));

Logo.defaultProps = {
  inverted: false,
};

export { Logo };
