import { createGlobalStyle, css } from 'styled-components';

export const LayoutStyles = createGlobalStyle(
  () => css`
    :root {
      --header-height: 62px;
    }

    body {
      background-color: ${(p) => p.theme.colors.neutral.xxxlight};
    }

    #root {
      display: grid;
      grid-template-rows: auto 1fr;
      min-height: var(--viewheight);

      > header {
        position: sticky;
        top: 0;
        height: var(--header-height, auto);
      }

      > main {
      }
    }
  `
);
