import * as React from 'react';
import { PriceListOptionsContainer, PriceListTop, StyledPriceList, StyledScrollable } from './PriceList.styled';
import { Heading } from '../typo/Heading';
import { ChildrenProp } from '../../interfaces/BaseProps';
import { FormattedMessage } from 'react-intl';
import { FaPaperPlane } from 'react-icons/fa';
import { Icon } from '../Icon';
import { Button } from '../Button';
import { Span } from '../typo/Span';
import { StatusBanner } from '../tooltip/StatusBanner';
import { EstateStatus } from '@innobrix/definitions';
import { PriceListFooter } from './PriceListFooter';
import { PriceListSubtotal } from './PriceListSubtotal';
import { PriceListHeader } from './PriceListHeader';
import { PriceListStat, PriceListStats, PriceListStatsContainer, PriceListStatUnit } from './PriceListHeader.styled';
import { SpinnerButton, SpinnerButtonProps } from '../SpinnerButton';

type EstateStats = Array<{ value: React.ReactNode; unit?: React.ReactNode; description?: string }>;

interface PriceListProps extends ChildrenProp {
  heading?: string;
  description?: string;
  price?: number;
  priceSubtotal?: number;
  stats?: EstateStats;
  status?: EstateStatus;
  lite: boolean;
  hideHeader?: boolean;
  disableSubmit?: boolean;
  onSubmit?: React.MouseEventHandler;
  submitButtonContent?: string | React.ReactElement | React.ReactElement[];
  submitProgress?: SpinnerButtonProps['state'];
}

const PriceList = React.forwardRef<HTMLDivElement, PriceListProps>(
  (
    {
      children,
      onSubmit,
      hideHeader = false,
      disableSubmit,
      submitButtonContent = (
        <>
          <Icon icon={FaPaperPlane} />
          <Span>
            <FormattedMessage id={'price-list.submit'} defaultMessage={'Submit configuration'} />
          </Span>
        </>
      ),
      price = 0,
      priceSubtotal,
      heading,
      description,
      stats,
      status,
      lite,
      submitProgress,
      ...rest
    },
    ref
  ) => {
    const canSubmit = !lite && status === EstateStatus.Available;

    return (
      <StyledPriceList {...rest} ref={ref}>
        <PriceListTop>
          <StyledScrollable>
            {!hideHeader ? <StatusBanner status={status ?? EstateStatus.NotAvailable} /> : null}

            {!hideHeader ? <PriceListHeader heading={heading} description={description} /> : null}

            {!hideHeader && stats?.length ? (
              <PriceListStatsContainer>
                <PriceListStats>
                  {stats.map((stat, i) => (
                    <PriceListStat description={stat.description} key={i}>
                      {stat.value}
                      {stat.unit && <PriceListStatUnit>{stat.unit}</PriceListStatUnit>}
                    </PriceListStat>
                  ))}
                </PriceListStats>
              </PriceListStatsContainer>
            ) : undefined}

            {!lite ? (
              <PriceListOptionsContainer>
                <Heading level={5}>
                  <FormattedMessage id={'price-list.price-list'} defaultMessage={'Pricelist'} />
                </Heading>
                {children}
              </PriceListOptionsContainer>
            ) : null}
          </StyledScrollable>
        </PriceListTop>

        {priceSubtotal != null && !lite ? <PriceListSubtotal price={priceSubtotal} /> : undefined}

        <PriceListFooter price={price}>
          {canSubmit ? (
            <SpinnerButton fullWidth large onClick={onSubmit} disabled={disableSubmit} state={submitProgress}>
              {submitButtonContent}
            </SpinnerButton>
          ) : undefined}
        </PriceListFooter>
      </StyledPriceList>
    );
  }
);

export { PriceList, PriceListStatUnit, PriceListProps, PriceListTop, EstateStats, PriceListOptionsContainer };
