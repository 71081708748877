import { borderRadius, fontSize, klavika, weight } from '@innobrix/components';
import styled, { css } from 'styled-components';

export const UserGrowthCardStyled = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 2fr 1fr;
    //grid-template-columns: 1fr auto;
    gap: 1rem;

    padding: 1rem;
    border-radius: ${borderRadius.r4};
    box-shadow: ${theme.elevation.light};
    background-color: white;
    overflow: hidden;

    ${klavika};
    line-height: 1.2;
    font-weight: ${weight.regular};
  `
);

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const Right = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  align-items: center;
  justify-items: center;
  gap: 1rem;
  font-size: ${fontSize.s14};

  b {
    font-weight: ${weight.medium};
  }
`;

export const Header = styled.span`
  display: block;
  ${klavika};
  line-height: 1;
  font-size: ${fontSize.s18};
`;

export const ChartWrapper = styled.div`
  position: relative;
`;
