import * as React from 'react';
import { NotificationCount, StyledAvatar, StyledAvatarWrapper } from './Avatar.styled';
import { BaseProps } from '../../interfaces/BaseProps';
import { Image } from '../Image';

type Size = 'small' | 'large';
type ImageProps = React.ComponentProps<typeof Image>;

interface AvatarProps extends BaseProps, Pick<ImageProps, 'src' | 'alt'> {
  size: Size;
  notifications?: number;
}

const Avatar = React.forwardRef<HTMLDivElement, AvatarProps>(
  ({ size = 'small', notifications, src, alt, ...rest }, ref) => (
    <StyledAvatarWrapper {...rest} ref={ref}>
      <StyledAvatar $size={size} src={src} alt={alt} />

      <NotificationCount variant={'warning'} count={notifications} />
    </StyledAvatarWrapper>
  )
);

export { Avatar, AvatarProps, Size };
