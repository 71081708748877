import styled, { css, DefaultTheme } from 'styled-components';
import { spacing } from '../variables/spacing';
import { CloseButton } from './navigation/CloseButton';
import { hideOutline } from '../utilities/mixins/hideOutline';
import { PillProps } from './Pill';

const pillColorMap = (theme: DefaultTheme) => ({
  primary: {
    background: theme.colors.primary.default,
    color: theme.colors.text.light,
  },
  primaryLight: {
    background: theme.colors.primary.xxxlight,
    color: theme.colors.primary.dark,
  },
  error: {
    background: theme.colors.error.xlight,
    color: theme.colors.error.xdark,
  },
  warning: {
    background: theme.colors.warning.xlight,
    color: theme.colors.warning.xdark,
  },
  success: {
    background: theme.colors.success.xlight,
    color: theme.colors.success.xdark,
  },
});

export const StyledPill = styled.div<Pick<PillProps, 'empty' | 'preview' | 'variant' | 'small' | 'onClick'>>(
  ({ theme, empty, preview, variant = 'primaryLight', small, onClick }) => {
    const colors = pillColorMap(theme)[variant];

    return css`
      display: inline-flex;
      align-items: center;
      padding: ${spacing.s4} ${spacing.s12};
      font-size: ${small ? '.8em' : undefined};
      line-height: 1.33;
      border-radius: 1rem;

      background: ${empty ? theme.colors.neutral.xlight : colors.background};
      color: ${empty ? theme.colors.text.default : colors.color};
      opacity: ${preview && 0.4};

      transition: ${theme.transition.default};
      transition-property: background-color, color, opacity;

      ${onClick != null &&
      css`
        cursor: pointer;

        :hover {
          background: ${!empty && !preview && variant === 'primaryLight' ? theme.colors.primary.xxlight : undefined};
        }
      `}
    `;
  }
);

export const PillClose = styled(CloseButton)(
  ({ theme }) => css`
    display: inline-flex;
    line-height: inherit;
    margin-left: ${spacing.s8};
    margin-right: -${spacing.s8};
    font-size: 1.33em;
    ${hideOutline};

    transition: ${theme.transition.slow};
    transition-property: background-color, transform;

    :focus.focus-visible,
    :hover {
      transform: rotate(90deg);
      background-color: ${theme.colors.primary.xlight};
    }
  `
);
