import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';
import { IconButton } from '../IconButton';

export const StyledVisibilityToggle = styled(IconButton)<{ checked?: boolean }>(
  ({ theme, checked }) => css`
    display: inline-flex;
    place-content: center;
    align-items: center;
    font-size: 1.25rem;
    margin-right: ${spacing.s8};

    transition: ${theme.transition.default};
    transition-property: opacity, color;

    :hover,
    :focus {
      color: ${theme.colors.neutral.light};
    }

    // Let the children grow
    + * {
      flex: 1;
      opacity: ${checked ? 0.4 : 1};
    }
  `
);
