import { adjustHue, getLuminance, readableColor, shade, tint } from 'polished';
import { RecursivePartial } from '@innobrix/definitions';
import { DefaultTheme } from 'styled-components';

/**
 * Generate a 7-color scale based on a single color
 * @param color Input color to base the scale on
 */
function generateColors(color: string): string[] {
  const luminance = getLuminance(color);

  // Divider/Multiplier for a 7 index color scale (0.4 - 0.96 lum)
  const divider = 1 / 6;
  const multiplier = 1 / 6.25;
  // Assign index based on luminance of the color
  const index = Math.round(luminance / divider);

  const primColors = new Array(7);
  for (let i = 0; i < 7; i++) {
    if (i < index) {
      // Darken the color
      primColors[i] = shade(multiplier * (index - i), color);
    } else if (i > index) {
      // Lighten the color
      primColors[i] = tint(multiplier * (i - index), color);
    } else {
      // i === index
      primColors[i] = color;
    }
  }

  return primColors;
}

/**
 * Calculate custom theme colors (if secondary color isn't provided, it's calculated)
 * @param color {string} The chosen primary color
 * @param secColor {string} The chosen secondary color
 */
export function generateCustomTheme(color: string, secColor?: string): RecursivePartial<DefaultTheme> {
  // Generate primary color array
  const primColors = generateColors(color);
  // Get the secondary color or generate one based on the primary color
  const secondary = secColor ?? adjustHue(240, color);
  // Generate secondary color array
  const secColors = generateColors(secondary);

  return {
    colors: {
      primary: {
        xdark: primColors[0],
        dark: primColors[1],
        default: primColors[2],
        light: primColors[3],
        xlight: primColors[4],
        xxlight: primColors[5],
        xxxlight: primColors[6],
      },
      secondary: {
        xdark: secColors[0],
        dark: secColors[1],
        default: secColors[2],
        light: secColors[3],
        xlight: secColors[4],
        xxlight: secColors[5],
        xxxlight: secColors[6],
      },
    },
  };
}

/**
 * Get a readable color
 */
export function getReadableColor(color: string, theme: DefaultTheme) {
  return readableColor(color, theme.colors.text.light, theme.colors.text.default);
}
