import { BaseProps, Stack, StatLabel, useChartDefaults } from '@innobrix/components';
import { ArcElement, Chart as ChartJS } from 'chart.js';
import { map } from 'lodash-es';
import * as React from 'react';
import { PropsWithChildren, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ChartWrapper, DonutStatCardStyled } from './DonutStatCard.styled';

// Register used elements
ChartJS.register(ArcElement);

type ChartDataset = Record<string, number>;

type Props = BaseProps & {
  total: number;
  dataset: ChartDataset;
};

export const DonutStatCard = ({ total, dataset, children, ...rest }: PropsWithChildren<Props>) => {
  const [labelKey, setLabelKey] = useState<string | undefined>(undefined);
  const chartDefaults = useChartDefaults();

  return (
    <DonutStatCardStyled {...rest}>
      <Stack justify={'center'}>
        <div>
          <StatLabel stat={labelKey == null ? total : dataset[labelKey]} />
        </div>
        <span>{children}</span>
      </Stack>
      <ChartWrapper>
        <Doughnut
          options={{
            responsive: true,
            events: ['mousemove', 'mouseout', 'touchstart', 'touchmove'],
            onHover(event, elements, chart) {
              // Grab the first hovered element
              const element = elements[0];
              if (element == null) {
                // If there is none, clear the label
                setLabelKey(undefined);
                return;
              }

              // Set the label to the hovered element
              const label = chart.data.labels?.[element.index] as string | undefined;
              setLabelKey(label);
            },
            plugins: {
              tooltip: {
                enabled: false,
              },
            },
          }}
          data={{
            labels: Object.keys(dataset),
            datasets: [
              {
                data: map(dataset),
                ...chartDefaults,
              },
            ],
          }}
        />
      </ChartWrapper>
    </DonutStatCardStyled>
  );
};
