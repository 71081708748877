import * as React from 'react';
import { StyledSpan } from './Span.styled';
import Skeleton from 'react-loading-skeleton';
import { BaseProps } from '../../interfaces/BaseProps';
import { StyledListItem } from '../list/ListItem.styled';

export interface SpanProps
  extends BaseProps,
    Omit<React.HTMLAttributes<HTMLSpanElement>, 'size'>,
    Omit<React.ComponentProps<typeof StyledListItem>, ''> {
  size?: 'small' | 'normal';
  subtle?: boolean;
  forceTypo?: boolean;
  light?: boolean;
  truncate?: boolean;
}

const Span = React.forwardRef<HTMLSpanElement, SpanProps>((props, ref) => {
  const { children, ...rest } = props;

  return (
    <StyledSpan {...rest} ref={ref}>
      {children != null ? children : <Skeleton />}
    </StyledSpan>
  );
});

Span.defaultProps = {
  size: 'normal',
};

export { Span };
