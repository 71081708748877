import styled, { css, DefaultTheme } from 'styled-components';
import { MessageProps } from './Message';
import { spacing } from '../../variables/spacing';
import { borderRadius } from '../../variables/borderRadius';

const severityMap = (theme: DefaultTheme) => ({
  message: {
    color: undefined,
    background: undefined,
  },
  warning: {
    color: theme.colors.warning.xxdark,
    background: theme.colors.warning.xlight,
  },
  error: {
    color: theme.colors.error.xxdark,
    background: theme.colors.error.xlight,
  },
  success: {
    color: theme.colors.success.xxdark,
    background: theme.colors.success.xlight,
  },
});

export const StyledMessage = styled.div<MessageProps>(
  ({ theme, severity }) => css`
    display: flex;

    padding: ${spacing.s16};
    line-height: 1.5;
    border-radius: ${borderRadius.r4};

    color: ${severityMap(theme)[severity].color};
    background: ${severityMap(theme)[severity].background};

    > :first-child {
      // .25em to fake line-height.
      margin: 0.25em ${spacing.s8} 0.25em 0;
      flex: 0 0 auto;
    }
  `
);
