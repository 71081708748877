import styled, { css } from 'styled-components';
import { fontSize } from '../../variables/typography';
import { spacing } from '../../variables/spacing';

export const StyledChatDivider = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    ::before,
    ::after {
      content: '';
      flex: 1;
      background-color: ${theme.colors.primary.xxxlight};
      height: 1px;
    }
  `
);

export const StyledChatDividerLabel = styled.span(
  ({ theme }) => css`
    background-color: ${theme.colors.primary.xxxlight};
    color: ${theme.colors.primary.dark};
    border-radius: 1.5rem;
    line-height: 1.5;

    font-size: ${fontSize.s12};
    padding: ${spacing.s4} ${spacing.s12};
    flex: 0 1 auto;
    margin: 0 ${spacing.s4};
  `
);
