import styled, { css, DefaultTheme } from 'styled-components';
import { spacing } from '../../variables/spacing';
import { EstateStatus } from '@innobrix/definitions';
import { StatusBannerProps } from './StatusBanner';
import { breakpoints } from '../../variables/breakpoints';

const ColorMap = (theme: DefaultTheme, status: EstateStatus) => {
  switch (status) {
    case EstateStatus.Available:
      return theme.colors.success.default;
    case EstateStatus.UnderOption:
      return theme.colors.warning.default;
    case EstateStatus.Sold:
      return theme.colors.error.default;
    default:
      return theme.colors.neutral.medium;
  }
};

export const StyledStatusBanner = styled.div<StatusBannerProps>(({ theme, status }) => {
  const background = ColorMap(theme, status);

  return css`
    position: absolute;
    top: 20px;
    right: -50px;
    transform: rotate(35deg);
    z-index: 1;

    width: 200px;
    padding: ${spacing.s4} 0;
    text-align: center;

    background: ${background};
    color: ${theme.colors.text.light};

    @media screen and ${breakpoints.tablet} {
      width: 290px;
      transform: rotate(40deg);
      top: 30px;
      right: -100px;
    }
  `;
});

// A Shape that clips text to the shape of the status banner
export const StatusBannerClip = styled.div(
  ({ theme }) => css`
    float: right;
    display: inline-block;
    width: 120px;
    min-height: 1px;
    max-height: 101px;
    // Clip text to this polygon (triangle)
    shape-outside: polygon(0 0, 120px 0, 120px 101px);
    shape-margin: ${spacing.s2};
  `
);
