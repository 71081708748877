import * as React from 'react';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { StyledDropOverlayMessage } from './DropOverlayMessage.styled';
import { FormattedMessage } from 'react-intl';
import { BaseProps } from '../../interfaces/BaseProps';

type DropOverlayMessageProps = BaseProps;
const DropOverlayMessage = React.forwardRef<HTMLDivElement, DropOverlayMessageProps>(({ ...rest }, ref) => {
  return (
    <StyledDropOverlayMessage {...rest} align={'center'} ref={ref}>
      <FaCloudUploadAlt />
      <span>
        <FormattedMessage id={'overlay.drop'} defaultMessage={'Let it goooo'} />
      </span>
    </StyledDropOverlayMessage>
  );
});

export { DropOverlayMessage, DropOverlayMessageProps };
