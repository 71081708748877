import * as React from 'react';
import styled, { css, DefaultTheme } from 'styled-components';
import ReactTooltip from 'rc-tooltip';
import { TooltipProps } from './Tooltip';
import { fadeIn } from '../../utilities/mixins/animations';

const StyledTooltipWrapper = React.forwardRef<typeof ReactTooltip, TooltipProps>(
  ({ children, className, overlay, ...rest }, ref) => {
    return (
      <ReactTooltip {...rest} ref={ref} overlay={overlay} overlayClassName={className}>
        {children}
      </ReactTooltip>
    );
  }
);

const animation = (theme: DefaultTheme) => css`
  &.tip-fade-enter,
  &.tip-fade-appear {
    opacity: 0;
    animation: ${theme.transition.slow};
    animation-play-state: paused;
  }

  &.tip-fade-leave {
    animation: ${theme.transition.slow};
    animation-play-state: paused;
  }

  &.tip-fade-enter.tip-fade-enter-active,
  &.tip-fade-appear.tip-fade-appear-active {
    animation-name: ${fadeIn};
    animation-play-state: running;
  }

  &.tip-fade-leave.tip-fade-leave-active {
    animation-name: ${fadeIn};
    animation-direction: reverse;
    animation-play-state: running;
  }
`;

export const StyledTooltip = styled(StyledTooltipWrapper)(
  ({ theme }) => css`
    &.tip {
      --size: 8px;
      --dist: ${theme.spacing.xsmall};
      --bg: ${theme.colors.neutral.xxdark};

      display: block;
      color: ${theme.colors.text.light};
      position: absolute;
      z-index: 70;

      opacity: 1;
      pointer-events: all;

      ${animation(theme)};

      &.tip-hidden {
        opacity: 0;
        pointer-events: none;
      }

      .tip-content {
        width: max-content;
      }

      .tip-inner {
        border-radius: ${theme.borderRadius.small};
        box-shadow: ${theme.elevation.medium};
        background: var(--bg);
        padding: 0 ${theme.spacing.small};
        max-width: calc(100vw - 4rem);
      }

      &.tip-placement-top,
      &.tip-placement-topLeft,
      &.tip-placement-topRight {
        padding: var(--size) 0 var(--dist) 0;
      }
      &.tip-placement-right,
      &.tip-placement-rightTop,
      &.tip-placement-rightBottom {
        padding: 0 var(--size) 0 var(--dist);
      }
      &.tip-placement-bottom,
      &.tip-placement-bottomLeft,
      &.tip-placement-bottomRight {
        padding: var(--dist) 0 var(--size) 0;
      }
      &.tip-placement-left,
      &.tip-placement-leftTop,
      &.tip-placement-leftBottom {
        padding: 0 var(--dist) 0 var(--size);
      }
    }
  `
);

export const TooltipDisabledWrapper = styled.div`
  display: inline-block;
  cursor: not-allowed;

  > * {
    pointer-events: none;
  }
`;
