import { fontSize, klavika, SpinnerLabel, weight } from '@innobrix/components';
import styled, { css } from 'styled-components';

export const MonthStatLabelStyled = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    ${klavika};
    line-height: 1;
    font-size: ${fontSize.s14};
    font-weight: ${weight.medium};
    color: ${theme.colors.neutral.medium};
  `
);

export const StatLabel = styled(SpinnerLabel)(
  ({ theme }) => css`
    ${klavika};
    line-height: 1;
    font-size: ${fontSize.s30};
    color: ${theme.colors.text.default};
  `
);
