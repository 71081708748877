import * as React from 'react';
import { SplashScreen } from '@innobrix/components';
import { createBrowserHistory } from 'history';
import { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './pages';
import Dev from './pages/dev';
import Error404Page from './pages/error404';
import Sales from './pages/sales';
import Support from './pages/support';

export const history = createBrowserHistory();

export function Router() {
  return (
    <Suspense fallback={<SplashScreen />}>
      <BrowserRouter>
        <Switch>
          <Route exact path={'/'}>
            <Home />
          </Route>

          <Route path={'/sales'}>
            <Sales />
          </Route>

          <Route path={'/support'}>
            <Support />
          </Route>

          <Route path={'/dev'}>
            <Dev />
          </Route>

          <Route>
            <Error404Page />
          </Route>
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
}

export default Router;
