import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { PropsWithChildren, useEffect } from 'react';
import { useAsync } from 'react-use';
// import { useHideSplashScreen } from '@innobrix/framework';

export function useHideSplashScreen() {
  // Check if loading
  const { isLoading } = useAuth0();

  // Hide splash screen when loaded
  useEffect(() => {
    if (isLoading) return;
    let splashScreen = document.querySelector<HTMLElement>('.splash');
    if (splashScreen == null) return;

    splashScreen.addEventListener('transitionend', () => {
      splashScreen = document.querySelector<HTMLElement>('.splash');
      if (splashScreen == null) return;
      document.body.removeChild(splashScreen);
    });

    // Activate
    splashScreen.style.opacity = '0';
  }, [isLoading]);
}

function SplashScreenHider() {
  useHideSplashScreen();
  return null;
}

export function AuthGuard({ children }: PropsWithChildren<unknown>) {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useAsync(async () => {
    if (!isLoading && !isAuthenticated) {
      await loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  return !isLoading && isAuthenticated ? (
    <>
      <SplashScreenHider />
      {children}
    </>
  ) : null;
}
