import * as React from 'react';
import { StyledError, StyledHeader, StyledHeading } from './Error.styled';
import { ChildrenProp, BaseProps } from '../../interfaces/BaseProps';

export interface ErrorProps extends BaseProps, ChildrenProp {
  heading: React.ReactNode;
  subheading?: React.ReactNode;
}
export const Error = React.forwardRef((props: ErrorProps, ref: React.Ref<HTMLDivElement>) => {
  const { children, heading, subheading, ...rest } = props;
  return (
    <StyledError {...rest} ref={ref}>
      <StyledHeader>
        <StyledHeading>{heading}</StyledHeading>
        {subheading}
      </StyledHeader>
      {children}
    </StyledError>
  );
});
