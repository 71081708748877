import * as React from 'react';
import { ReactNode } from 'react';
import { DefaultTheme, ThemeProvider as SCThemeProvider } from 'styled-components';
import { lightTheme } from '../../theme/Light';
import { StyledSkeletonTheme } from './SkeletonTheme.styled';
import { defaultsDeep } from 'lodash-es';
import { RecursivePartial } from '@innobrix/definitions';

type Props = {
  children?: ReactNode;
  theme?: RecursivePartial<DefaultTheme>;
};

export const ThemeProvider = ({ theme = {}, children }: Props) => {
  const _theme = defaultsDeep({}, theme, lightTheme);

  return (
    <SCThemeProvider theme={_theme}>
      <StyledSkeletonTheme>{children}</StyledSkeletonTheme>
    </SCThemeProvider>
  );
};
