import * as React from 'react';
import styled, { css } from 'styled-components';
import { StyledNavbarItem } from './NavbarItem.styled';
import { StyledLogo } from '../logo/Logo.styled';
import { animated } from '@react-spring/web';
import { StyledFlexBox } from '../layout/FlexBox.styled';

export const StyledList = styled(animated.ul).withConfig({
  shouldForwardProp: (prop, valFn) => !['isOpen'].includes(prop) && valFn(prop),
})<{ isOpen: boolean }>(
  ({ theme, isOpen }) => css`
    list-style: none;

    display: inline-flex;
    flex-flow: column;
    background: ${theme.colors.neutral.white};
    border-radius: ${theme.borderRadius.medium};
    top: 2rem;
    bottom: 2rem;

    padding: 0.75rem;
    box-shadow: ${theme.elevation.medium};

    overflow: hidden;
    position: fixed;
    z-index: 100;

    ${!isOpen &&
    css`
      ${StyledNavbarItem} > span {
        opacity: 0;
      }

      ${StyledLogo} > path {
        opacity: 0;
      }
    `};

    & ${StyledNavbarItem} > span,
    & ${StyledLogo} > path {
      transition: ${theme.transition.slow} opacity;
    }

    & a {
      display: block;
      color: currentColor;
      text-decoration: none;
      margin-bottom: ${theme.spacing.medium};
      outline: none;

      :last-of-type {
        margin-bottom: 0;
      }

      ::-moz-focus-inner {
        border: 0;
      }
    }

    & > ${StyledFlexBox} {
      flex: 1;
    }

    & ${StyledLogo} {
      display: block;
      width: 200px;
      margin-bottom: ${theme.spacing.large};
    }
  `
);

export const StyledNavbar = styled.div`
  position: relative;
  height: 100%;
  width: 4rem;
`;
