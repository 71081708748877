import { GlobalStyle, ThemeProvider } from '@innobrix/components';
import { useViewHeight } from '@innobrix/utils';
import { StyleSheetManager } from 'styled-components';
import * as React from 'react';
import '../../../../../libs/components/src/fonts/fonts.css';

export const StyleProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  useViewHeight();

  return (
    <StyleSheetManager disableVendorPrefixes>
      <ThemeProvider>
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </StyleSheetManager>
  );
};
