import styled, { css } from 'styled-components';
import { resetButton } from '../utilities/mixins/buttonStyle';
import { IconButtonProps } from './IconButton';
import { spacing } from '../variables/spacing';

export const StyledIconButton = styled.button<IconButtonProps>(({ theme, size = 'medium' }) => {
  const pad =
    size === 'small' ? spacing.s4 : size === 'large' ? spacing.s12 : size === 'xlarge' ? spacing.s16 : spacing.s8;

  return css`
    display: flex;
    place-content: center;
    align-items: center;

    ${resetButton};
    cursor: pointer;
    padding: ${pad};

    transition: color ${theme.transition.default};

    :hover:not(:disabled),
    :focus.focus-visible:not(:disabled) {
      color: ${theme.colors.primary.default};
    }

    :disabled {
      color: ${theme.colors.neutral.medium};
      cursor: not-allowed;
    }
  `;
});
