import { useTheme } from 'styled-components';

/**
 * Get the default set of colors for a Chart
 */
export function useChartColors() {
  const theme = useTheme();

  return ['#489FD1', '#69BE5D', theme.colors.primary.default, '#DAB12C'];
}

/**
 * Get the default settings for a Chart
 */
export function useChartDefaults() {
  const colors = useChartColors();

  return {
    backgroundColor: colors,
    borderRadius: 8,
    spacing: 2,
  };
}
