import styled, { css } from 'styled-components';
import { visuallyHidden } from '../../utilities/mixins/visuallyHidden';
import { spacing } from '../../variables/spacing';

export const StyledToggle = styled.label(
  ({ theme }) => css`
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    > :not(:last-child) {
      margin-right: ${spacing.s8};
    }
  `
);

export const HandleBackdrop = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.neutral.xlight};
    border-radius: 1rem;
    width: 2.25rem;
    height: 0.75rem;
    margin: 0 ${spacing.s8};
    position: relative;

    transition: ${theme.transition.slow};
    transition-property: background-color;

    input:checked + & {
      background: ${theme.colors.primary.default};
    }
  `
);

export const Handle = styled.span(
  ({ theme }) => css`
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    left: -50%;
    top: 50%;
    transform: translate(50%, -50%);
    background-color: ${theme.colors.neutral.xxlight};
    border-radius: 100%;
    box-shadow: ${theme.elevation.light};

    transition: ${theme.transition.slow};
    transition-property: transform, box-shadow;

    ${StyledToggle}:hover &,
    ${StyledToggle}:focus-within & {
      box-shadow: ${theme.elevation.light}, 0 0 0 4px ${theme.colors.primary.xxlight};
    }

    input:checked + * & {
      transform: translate(150%, -50%);
    }
  `
);

export const StyledHiddenInput = styled.input.attrs({ type: 'checkbox' })(
  () => css`
    ${visuallyHidden};
    outline: none;
  `
);
