import * as React from 'react';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import {
  StyledDot,
  StyledHeader,
  StyledIcon,
  StyledInnerHeader,
  StyledNotification,
  StyledNotificationCloseButton,
  StyledNotificationContent,
  StyledNotificationHeading,
  StyledNotificationTimeLabel,
} from './Notification.styled';
import { INotification, severityIconMap } from '@innobrix/definitions';
import { Button } from '../Button';
import { XOR } from 'ts-essentials';

// Base Props
interface NotificationProps extends BaseProps, ChildrenProp, INotification {
  time?: React.ReactNode;
  expanded?: boolean;
  onClose?: React.MouseEventHandler;
  onClick?: React.MouseEventHandler;
}

// Interface for Action
interface ActionNotificationProps extends NotificationProps {
  actionLabel: string;
  onAction: React.MouseEventHandler;
}

// Combine with exclusive OR. When anything from ActionProps is defined, all are enforced.
type Props = XOR<NotificationProps, ActionNotificationProps>;

/**
 * TypeGuard for Action props
 * @param props
 */
const hasAction = (props: any): props is ActionNotificationProps => {
  return 'actionLabel' in props || 'onAction' in props;
};

const Notification = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { children, header, time, severity = 'message', expanded = false, onClose, onClick, ...rest } = props;

  return (
    <StyledNotification role={'alert'} expanded={expanded} {...rest} onClick={onClick} ref={ref}>
      <StyledHeader expanded={expanded}>
        <StyledInnerHeader>
          <StyledIcon icon={severityIconMap[severity]} severity={severity} />
          <StyledDot />
          <StyledNotificationTimeLabel>{time}</StyledNotificationTimeLabel>
          <StyledDot />
        </StyledInnerHeader>

        <StyledNotificationHeading expanded={expanded} level={4}>
          {header}
        </StyledNotificationHeading>
        {!expanded && hasAction(rest) && (
          <Button onClick={rest.onAction} small>
            {rest.actionLabel}
          </Button>
        )}
        <StyledNotificationCloseButton expanded={expanded} onClick={onClose} />
      </StyledHeader>
      <StyledNotificationContent expanded={expanded}>{children}</StyledNotificationContent>
    </StyledNotification>
  );
});

export { Notification, Props as NotificationProps };
