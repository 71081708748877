import * as React from 'react';
import { DocumentIcon, StyledDocumentListItem } from './DocumentListItem.styled';
import { BaseProps } from '../../../interfaces/BaseProps';
import { Stack } from '../../layout/Stack';
import { Span } from '../../typo/Span';
import { InlineControls } from '../../controls/InlineControls';
import { A } from '../../atoms/A';
import { ListItem } from '../../list/ListItem';
import { FileType, fileTypeIconMap } from '@innobrix/utils';

type MyLinkProps = Pick<React.ComponentProps<typeof A>, 'href'>;

interface DocumentListItemProps extends BaseProps, MyLinkProps {
  filetype: FileType;
  filename: string;
  subtitle?: React.ReactChild;
  //
  controls?: React.ReactFragment | React.ReactElement;
  children?: React.ReactFragment | React.ReactElement;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const DocumentListItem = React.forwardRef<HTMLLIElement, DocumentListItemProps>(
  ({ href, filename, subtitle, filetype, controls, children, onClick, ...rest }, ref) => {
    return (
      <StyledDocumentListItem {...rest} ref={ref}>
        <ListItem
          onClick={onClick}
          affix={
            controls != null ? (
              <InlineControls>{controls}</InlineControls>
            ) : controls == null && children != null ? (
              children
            ) : undefined
          }
          // Render as an Anchor
          as={A}
          href={href}
          download
        >
          <DocumentIcon icon={fileTypeIconMap[filetype ?? 'file']} />

          <Stack>
            <Span truncate>{filename}</Span>
            <Span size={'small'} subtle truncate>
              {subtitle}
            </Span>
          </Stack>
        </ListItem>
      </StyledDocumentListItem>
    );
  }
);

export { DocumentListItem, DocumentListItemProps };
