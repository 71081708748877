import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';
import { ChatMessageGroup } from './ChatMessageGroup';

export const StyledChatEventMessage = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: baseline;
    color: ${theme.colors.primary.dark};

    > :not(:last-child) {
      margin-right: ${spacing.s8};
    }
  `
);

export const IconBadge = styled.div(
  ({ theme }) => css`
    display: inline-flex;
    place-content: center;
    align-items: center;
    flex: 0 0 auto;

    border-radius: 50%;
    font-size: 0.625rem;

    background-color: ${theme.colors.primary.xxxlight};
    color: ${theme.colors.primary.default};
    width: 1.75rem;
    height: 1.75rem;
  `
);

export const StyledMessageGroup = styled(ChatMessageGroup)`
  width: auto;

  > :not(:last-child) {
    margin-bottom: 0;
  }
`;
