import * as React from 'react';
import { ButtonProps } from './Button';
import { forwardRef } from 'react';
import { StyledProgressButton } from './ProgressButton.styled';

interface ProgressButtonProps extends Omit<ButtonProps, 'variant'> {
  progress?: number;
}

const ProgressButton = forwardRef<HTMLButtonElement, ProgressButtonProps>((props, ref) => (
  <StyledProgressButton {...props} ref={ref} />
));

export { ProgressButtonProps, ProgressButton };
