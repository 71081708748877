import * as React from 'react';
import { MouseEventHandler } from 'react';
import { StyledIcon } from './Icon.styled';
import { IconBaseProps, IconType } from 'react-icons';
import { BaseProps } from '../interfaces/BaseProps';

export interface IconProps extends IconBaseProps, BaseProps {
  icon: IconType;
  onClick?: MouseEventHandler;
  subtle?: boolean;
}
export const Icon = React.forwardRef<SVGSVGElement, IconProps>(({ icon, ...rest }, ref) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <StyledIcon {...rest} as={icon} ref={ref} />;
});
