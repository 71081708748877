import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';
import { fontSize } from '../../variables/typography';

type Props = {
  $isMine?: boolean;
};

export const StyledChatMessageGroup = styled.div<Props>(
  ({ theme, $isMine }) => css`
    display: flex;
    flex-flow: column;
    width: 66%;

    ${$isMine
      ? css`
          align-self: flex-end;
        `
      : undefined}

    > :not(:last-child) {
      margin-bottom: ${spacing.s8};
    }
  `
);

export const Timestamp = styled.span<Props>(
  ({ theme, $isMine }) => css`
    align-self: ${$isMine ? 'flex-start' : 'flex-end'};
    font-size: ${fontSize.s12};
    color: ${theme.colors.neutral.medium};
  `
);
