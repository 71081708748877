import styled, { css } from 'styled-components';
import { Tab } from 'react-tabs';
import { spacing } from '../../variables/spacing';

export const StyledTab = styled(Tab)(
  ({ theme, disabled, selected }) => css`
    font: normal 500 1.25em/1.4 ${theme.typography.headingFamily};
    padding: ${spacing.s4} ${spacing.s8};
    user-select: none;
    -webkit-user-select: none;
    outline: none;

    border-bottom: 2px solid ${selected ? theme.colors.primary.default : 'transparent'};
    margin-bottom: -2px;

    color: ${selected ? theme.colors.primary.default : disabled ? theme.colors.primary.xxlight : 'currentColor'};

    transition: ${theme.transition.default};
    transition-property: color, border;

    :active,
    :focus,
    :hover {
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      color: ${disabled ? theme.colors.primary.xxlight : theme.colors.primary.default};
      border-bottom: 2px solid ${selected ? theme.colors.primary.default : theme.colors.primary.xlight};
    }
  `
);
