import * as React from 'react';
import styled, { css } from 'styled-components';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { IconButton } from '../IconButton';
import { hideOutline } from '../../utilities/mixins/hideOutline';

const shared = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1500;
  ${hideOutline};

  transition-property: color, background-color, opacity;

  :hover:not(:disabled),
  :focus:not(:disabled) {
    background: rgba(0, 0, 0, 0.25);
    color: ${(p) => p.theme.colors.neutral.white};
  }

  :disabled {
    opacity: 0;
    visibility: hidden;
    cursor: unset;
  }
`;

export const ArrowLeft = styled(IconButton).attrs({ children: <FaChevronLeft />, size: 'xlarge' })(
  ({ theme }) => css`
    ${shared};
    left: 0;
  `
);

export const ArrowRight = styled(IconButton).attrs({ children: <FaChevronRight />, size: 'xlarge' })(
  ({ theme }) => css`
    ${shared};
    right: 0;
  `
);
