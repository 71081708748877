import * as React from 'react';
import { SpinnerIcon } from '../SpinnerIcon';
import { FaCircleNotch } from 'react-icons/fa';
import { Alert, AlertProps } from './Alert';
import { DoneIcon } from './ProgressAlert.styled';

interface ProgressAlertProps extends Omit<AlertProps, 'icon'> {
  pending: boolean;
}

const ProgressAlert = React.forwardRef<HTMLDivElement, ProgressAlertProps>(({ pending, ...rest }, ref) => (
  <Alert {...rest} ref={ref} icon={pending ? <SpinnerIcon icon={FaCircleNotch} /> : <DoneIcon />} />
));

export { ProgressAlert, ProgressAlertProps };
