/**
 * Returns a stringified variant, excluding the single forward slash from both start and finish
 * @param regexp Input regular expression
 */
const regexStringify = (regexp: RegExp): string => {
  const s = regexp.toString();
  return s.substr(1, s.length - 2);
};

/**
 * Regular expression for GUID (case insensitive)
 */
export const regexGuid = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

/**
 * Regular expression for GUID (case insensitive)
 */
export const urlPathRegexGuid: string = regexStringify(regexGuid);
