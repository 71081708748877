import * as React from 'react';
import { StyledPriceInput } from './PriceInput.styled';
import { NumberInputProps } from './NumberInput';
import { useCurrency } from '@innobrix/utils';

type PriceInputProps = Omit<NumberInputProps, 'iconBefore' | 'scale' | 'inputMode' | 'padFractionalZeros'>;

export const PriceInput = React.forwardRef<HTMLInputElement, PriceInputProps>(({ ...rest }, ref) => {
  const { digits, symbol } = useCurrency();

  return (
    <StyledPriceInput {...rest} iconBefore={symbol} scale={digits} inputMode={'decimal'} ref={ref} padFractionalZeros />
  );
});
