import * as React from 'react';
import { Checkdot, StyledLabel, StyledRadio } from './Radio.styled';
import { CheckboxProps } from './Checkbox';
import { StyledBox } from './Checkbox.styled';
import { getRandomId } from '../../utilities/Crypto';

const Radio = React.forwardRef((props: CheckboxProps, ref: React.Ref<HTMLInputElement>) => {
  const { children, className, id = getRandomId(), error, touched, ...rest } = props;

  return (
    <StyledBox className={className}>
      <StyledRadio {...rest} id={id} ref={ref} />
      <StyledLabel htmlFor={id}>
        <Checkdot />
        {children}
      </StyledLabel>
    </StyledBox>
  );
});

export { Radio };
