import styled, { css } from 'styled-components';

export const StyledFlyoutLock = styled.svg(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    fill: currentColor;

    :hover,
    :focus {
      color: ${theme.colors.neutral.medium};
    }
  `
);
