import styled, { css } from 'styled-components';

export const ContextMenuWrapper = styled.div(
  ({ theme }) => css`
    .react-contextmenu {
      --pad: ${theme.spacing.small};

      padding: var(--pad) 0;
      background: ${theme.colors.neutral.xxxlight};
      border-radius: ${theme.borderRadius.small};
      box-shadow: ${theme.elevation.medium};
      z-index: 60;
      font-size: 0.9rem;
      outline: none;

      transition: opacity ${theme.transition.slow};

      > .react-contextmenu-item {
        min-height: 1px;
        line-height: 2;
        color: ${theme.colors.text.default};
        white-space: nowrap;
        outline: none;
        user-select: none;

        transition: ${theme.transition.slow} !important;
        transition-property: background-color, color;

        &--divider {
          background: ${theme.colors.neutral.light};
          margin: ${theme.spacing.small};
        }
      }

      .react-contextmenu-item {
        &--selected:not(.react-contextmenu-item--disabled),
        &--active:not(.react-contextmenu-item--disabled) {
          background: ${theme.colors.primary.default};
          color: ${theme.colors.text.light};
          cursor: pointer;
        }

        &--disabled {
          background: ${theme.colors.neutral.xlight};
          color: ${theme.colors.neutral.light};
          cursor: not-allowed;
        }
      }

      .react-contextmenu-submenu {
        > .react-contextmenu {
          // go up the padding so the hovers align
          top: calc(var(--pad) * -1) !important;
          transition: ${theme.transition.slow} !important;
          transition-property: opacity;

          :not(.react-contextmenu--visible) {
            opacity: 0;
            pointer-events: none;
          }
        }
      }
    }
  `
);
