import * as React from 'react';
import { Checkbox, CheckboxProps } from '../form/Checkbox';
import { ChildrenProp } from '../../interfaces/BaseProps';
import { Radio } from '../form/Radio';
import { StyledOption, StyledOptionContent, StyledOptionPrice } from './Option.styled';
import { FormattedNumber } from 'react-intl';
import { ImageRadio } from './ImageRadio';
import { ColorRadio } from './ColorRadio';
import { useOptionGroup } from './OptionGroup';
import { useOptionList } from './OptionList';
import { IToggleVisibility, VisibilityToggle } from './VisibilityToggle';
import { useCallback } from 'react';
import { Price } from '../Price';

export type OptionType = 'checkbox' | 'radio' | 'image' | 'color';
interface OptionProps
  extends ChildrenProp,
    Pick<CheckboxProps, 'checked' | 'onChange' | 'name' | 'value'>,
    Partial<IToggleVisibility> {
  // General
  optionType: OptionType;
  price: number;
  dontShowInMenu?: boolean;
  hiddenByBehaviour?: boolean;

  // Image
  src?: string;

  // Color
  color?: string;
}

const optionComponentMap: { [key in OptionType]: keyof JSX.IntrinsicElements | React.ComponentType<any> } = {
  checkbox: Checkbox,
  radio: Radio,
  image: ImageRadio,
  color: ColorRadio,
};

const OptionPrice = React.memo(({ isStack, price }: { isStack: boolean; price: number }) => (
  <StyledOptionPrice stack={isStack} order={isStack ? 1 : undefined}>
    <Price value={price} />
  </StyledOptionPrice>
));

const Option = React.forwardRef<HTMLInputElement, OptionProps>(
  (
    {
      children,
      visible,
      dontShowInMenu,
      hiddenByBehaviour,
      onChange,
      onVisibilityChange,
      optionType,
      price,
      checked,
      ...rest
    },
    ref
  ) => {
    const optList = useOptionList();
    const editVisibility = optList.editVisibility && !dontShowInMenu;

    if (editVisibility) hiddenByBehaviour = false;

    const disabled = (optList.editVisibility && dontShowInMenu) || hiddenByBehaviour;

    const { large } = useOptionGroup();

    const showPrice = optionType !== 'color' || (optionType === 'color' && large);
    const showContent = optionType !== 'color' || large;
    const isStack = optionType === 'image' || optionType === 'color';

    const onVisToggleClick = useCallback(() => onVisibilityChange?.(!visible), [onVisibilityChange, visible]);

    const option = (
      <StyledOption
        as={optionComponentMap[optionType]}
        {...rest}
        ref={ref}
        disabled={disabled || hiddenByBehaviour}
        // Disable selecting options in Edit Visibility mode
        checked={editVisibility ? false : checked}
        onChange={editVisibility || disabled || hiddenByBehaviour ? undefined : onChange}
        // Pass IToggleVisibility props to stack components TODO is this still used? disabled it as it produces an error
        // visible={visible}
        // onVisibilityChange={onVisibilityChange}
      >
        {showContent && <StyledOptionContent column={isStack}>{children}</StyledOptionContent>}
        {showPrice && <OptionPrice isStack={isStack} price={price} />}
      </StyledOption>
    );

    // Unless it's a stack, wrap the option in a VisibilityToggle when editing Visibility.
    return editVisibility ? (
      <VisibilityToggle size={'small'} checked={!visible} onClick={onVisToggleClick}>
        {option}
      </VisibilityToggle>
    ) : (
      option
    );

    /*return editVisibility && !isStack ? (
      <VisibilityToggle checked={visible} onClick={() => onVisibilityChange?.(!visible)}>
        {option}
      </VisibilityToggle>
    ) : (
      option
    );*/
  }
);

export { Option, OptionProps };
