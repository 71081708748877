import styled, { css, DefaultTheme } from 'styled-components';
import { spacing } from '../../variables/spacing';
import { Badge } from './Badge';
import { borderRadius } from '../../variables/borderRadius';

type Props = React.ComponentProps<typeof Badge>;

const colorMap = (theme: DefaultTheme) => ({
  default: {
    background: theme.colors.neutral.xxlight,
    color: theme.colors.text.default,
  },
  primary: {
    background: theme.colors.primary.default,
    color: theme.colors.text.light,
  },
  secondary: {
    background: theme.colors.secondary.default,
    color: theme.colors.secondary.xxxlight,
  },
  error: {
    background: theme.colors.error.xlight,
    color: theme.colors.error.xdark,
  },
  warning: {
    background: theme.colors.warning.xlight,
    color: theme.colors.warning.xdark,
  },
  success: {
    background: theme.colors.success.xlight,
    color: theme.colors.success.xdark,
  },
});

export const StyledBadge = styled.span<Props>(({ theme, variant = 'default' }) => {
  const colors = colorMap(theme)[variant];

  return css`
    display: inline-block;
    padding: ${spacing.s4} ${spacing.s8};
    line-height: 1;
    border-radius: ${borderRadius.r4};
    font-size: 0.6875em;
    height: min-content;

    background: ${colors.background};
    color: ${colors.color};

    transition: ${theme.transition.default};
    transition-property: background-color, color;
  `;
});
