import * as React from 'react';
import { StyledCloseButton, StyledCloseButtonWrapper } from './CloseButton.styled';
import { BaseProps } from '../../interfaces/BaseProps';

export interface CloseButtonProps extends BaseProps {
  disabled?: boolean;
  onClick?: React.MouseEventHandler;
}

export const CloseButton = React.forwardRef((props: CloseButtonProps, ref: React.Ref<SVGSVGElement>) => {
  return (
    <StyledCloseButtonWrapper {...props} disabled={props.disabled}>
      <StyledCloseButton ref={ref} disabled={props.disabled} />
    </StyledCloseButtonWrapper>
  );
});
