import * as React from 'react';
import { useCallback, useContext } from 'react';
import { StyledOptionTabs } from './OptionTabs.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { noop } from 'lodash-es';

/**
 * TODO
 *   - child onclick: scoll into view (centered)
 *   - hide scrollbar?
 *   - emulate touch on desktop? or do the horizontal scroll thing of the HorizontalScrollBox
 */

interface ITabs {
  selectedIndex: number;
  lastIndex: number;
  onSelect: (index: number, event: React.MouseEvent) => void;
  setLastIndex: (maxIndex: number) => void;
}

export const TabsContext = React.createContext<ITabs>({
  selectedIndex: 0,
  onSelect: noop,
  lastIndex: 0,
  setLastIndex: noop,
});
export const useTabs = () => useContext(TabsContext);

interface OptionTabsProps extends BaseProps, ChildrenProp, Omit<ITabs, 'setLastIndex' | 'lastIndex' | 'setTabRefs'> {}

const OptionTabs = React.forwardRef<HTMLDivElement, OptionTabsProps>(
  ({ selectedIndex, onSelect, children, ...rest }, ref) => {
    const [lastIndex, setLastIndex] = React.useState(0);

    const handleSelect = useCallback(
      (index, event) => {
        const _index = Math.max(0, Math.min(index, lastIndex));

        // const targetTab = ReactDOM.findDOMNode(tabRefs[index]) as Element;
        // if (targetTab == null) return;
        // targetTab.scrollIntoView({ inline: 'center', block: 'nearest', behavior: 'smooth' });

        onSelect?.(_index, event);
      },
      [lastIndex, onSelect]
    );

    return (
      <TabsContext.Provider value={{ selectedIndex, onSelect: handleSelect, lastIndex, setLastIndex }}>
        <StyledOptionTabs {...rest} ref={ref}>
          {children}
        </StyledOptionTabs>
      </TabsContext.Provider>
    );
  }
);

export { OptionTabs, OptionTabsProps };
