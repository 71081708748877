import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';

export const StyledOptionPillOrDivider = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    color: ${theme.colors.primary.dark};
    opacity: 0.4;

    ::before,
    ::after {
      content: '';
      height: 1px;
      flex: 1;
      background: ${theme.colors.primary.xxlight};
    }
  `
);

export const StyledSpan = styled.span(
  ({ theme }) => css`
    flex: 0 0 auto;
    margin: 0 ${spacing.s8};
  `
);
