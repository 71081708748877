/**
 * Option list item type enum
 */
export enum OptionListItemType {
  Checkbox = 0,
  Radio = 1,
  Image = 2,
  Color = 3,

  Folder = 100,
  Header = 101,
}

/**
 * Option list item data interface
 */
export interface IOptionListItemData {
  // Generic properties
  guid: string;
  type: OptionListItemType;
  name: string;
  showAsLargeThumbnail: boolean;
  orderIndex: number;
  parentGuid?: string;
  code?: string; // for integration purposes

  // Option info
  description: string;
  priceInCents: number;
  thumbnailFileExtension?: string | null;
  urlText: string;
  url: string;
  colorHex: number;

  // On toggle behaviors
  enableOptionSetOnActivate: string[][];
  disableOptionsOnActivate: string[];
  disableOptionsOnDeactivate: string[];

  // Option list behaviour
  showInMenu: boolean;
  showInMenuWhenOptionsAreOn: string[][];
  hideInMenuWhenOptionsAreOn: string[][];
}
