import { ProjectCardProps } from './ProjectCard';
import styled, { css } from 'styled-components';
import { Image } from '../Image';
import { Heading } from '../typo/Heading';
import { FlexBox } from '../layout/FlexBox';
import { textEllipsis } from '../../utilities/mixins/textOverflow';
import { ActionCard } from './ActionCard';
import { Span } from '../typo/Span';

export const StyledSkeletonWrapper = styled.div(
  ({ theme: { borderRadius } }) => css`
    span {
      border-radius: 0;
      display: block;
    }
  `
);

export const StyledImage = styled(Image)(
  ({ theme: { borderRadius } }) => css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  `
);

export const ImageContainer = styled.div`
  height: 7rem;
`;

export const StyledProjectCard = styled(ActionCard)<ProjectCardProps>`
  cursor: pointer;
  overflow: hidden;
`;

export const StyledCardHeader = styled(FlexBox)(({ theme }) => css``);

export const StyledCardHeading = styled(Heading).attrs({ level: 5 })`
  flex: 1;
  ${textEllipsis};
`;

export const StyledCardDate = styled(Span).attrs({ size: 'small' })(
  ({ theme }) => css`
    flex: 0 1 2rem;
    white-space: nowrap;
  `
);
