import * as React from 'react';
import { StyledOptionName } from './OptionName.styled';
import { ChildrenProp } from '../../interfaces/BaseProps';

type OptionNameProps = ChildrenProp;
const OptionName = React.forwardRef<HTMLDivElement, OptionNameProps>(({ children, ...rest }, ref) => (
  <StyledOptionName {...rest} ref={ref}>
    {children}
  </StyledOptionName>
));

export { OptionName, OptionNameProps };
