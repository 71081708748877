import * as React from 'react';
import { PillClose, StyledPill } from './Pill.styled';
import { BaseProps, ChildrenProp } from '../interfaces/BaseProps';

interface PillProps extends BaseProps, ChildrenProp {
  onClose?: (event: React.MouseEvent) => void;
  onClick?: (event: React.MouseEvent) => void;
  empty?: boolean;
  preview?: boolean;
  variant?: 'primary' | 'primaryLight' | 'error' | 'warning' | 'success';
  small?: boolean;
}

const Pill = React.forwardRef<HTMLDivElement, PillProps>(
  ({ children, preview, empty, onClose, onClick, ...rest }, ref) => {
    return (
      <StyledPill empty={empty} preview={preview} onClick={onClick} {...rest} ref={ref}>
        {children}
        {onClose != null && (
          <PillClose
            onClick={(e) => {
              if (onClose) {
                e.stopPropagation();
                onClose(e);
              }
            }}
            tabIndex={0}
          />
        )}
      </StyledPill>
    );
  }
);

export { Pill, PillProps };
