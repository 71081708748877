import styled, { css } from 'styled-components';
import * as React from 'react';
import { StyledActionbar } from './Actionbar.styled';
import { ToolbarProps } from './Toolbar';

type Props = Pick<ToolbarProps, 'vertical'>;
export const StyledToolbar = styled.div.withConfig<Props>({
  shouldForwardProp: (prop, valFn) => !['vertical'].includes(prop) && valFn(prop),
})`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;

  display: flex;
  flex-flow: ${(p) => (p.vertical ? 'row-reverse nowrap' : 'column')};

  ${(p) =>
    p.vertical &&
    css`
      top: 50%;
      bottom: unset;
      left: 0;
      transform: translateY(-50%);
    `};

  ${StyledActionbar} {
    border-radius: 0.4em;
  }
`;

export const ToolbarPanel = styled.div.withConfig<Props>({
  shouldForwardProp: (prop, valFn) => !['vertical'].includes(prop) && valFn(prop),
})(
  ({ theme, vertical }) => css`
    background-color: ${theme.colors.neutral.white};
    box-shadow: ${theme.elevation.light};
    padding: ${theme.spacing.medium};
    border-radius: 0.4em;

    position: absolute;
    bottom: 100%;
    min-width: 50%;

    ${vertical &&
    css`
      bottom: unset;
      left: 100%;
      min-width: 35ch;
      max-height: 100%;
      overflow-x: auto;
      overflow-y: auto;
    `};
  `
);

// Svg Items
const dividerMixin = css`
  position: relative;
  //filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.25));
  z-index: 1;
  transition: transform ${(p) => p.theme.transition.slow};

  > path {
    fill: ${(p) => p.theme.colors.neutral.white};
  }
`;

const SvgDivider = (props: React.HTMLAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 44 16" {...props}>
    <path d="M44 0a8 8 0 100 16H0A8 8 0 100 0h44z" />
  </svg>
);

export const ToolbarDivider = styled(SvgDivider)`
  ${dividerMixin};
  left: 0.25rem;
  width: 2.5rem;
  height: 0.875rem;

  @media screen and (min-width: 376px) {
    width: 2.75rem;
  }
`;

// Vertical version
export const SvgDividerVertical = (props: React.HTMLAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 16 44" {...props}>
    <path d="M0 0a8 8 0 1016 0v44a8 8 0 10-16 0V0z" />
  </svg>
);

export const ToolbarDividerVertical = styled(SvgDividerVertical)`
  ${dividerMixin};
  top: 0.25rem;
  height: 2.5rem;
  //width: 1rem;

  @media screen and (min-width: 376px) {
    height: 2.75rem;
  }
`;
