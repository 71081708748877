import * as React from 'react';
import { Footer, StyledGettingStartedModal } from './GettingStartedModal.styled';
import ReactModal from 'react-modal';
import { ModalProps } from './Modal';

type GettingStartedModalProps = Omit<ModalProps, 'severity' | 'icon'>;

const GettingStartedModal = React.forwardRef<ReactModal, GettingStartedModalProps>(
  ({ children, footer, ...rest }, ref) => {
    return (
      <StyledGettingStartedModal {...rest} ref={ref} footer={<></>}>
        <div>{children}</div>

        <Footer justify={'end'}>{footer}</Footer>
      </StyledGettingStartedModal>
    );
  }
);

export { GettingStartedModal, GettingStartedModalProps };
export { Steps as GettingStartedSteps } from './GettingStartedModal.styled';
