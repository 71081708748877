import styled, { css } from 'styled-components';
import { ErrorMessage } from './ErrorMessage';

export const StyledRadioOptionGrid = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: ${theme.spacing.small};
  `
);

export const StyledErrorMessage = styled(ErrorMessage)`
  padding: 0;
`;
