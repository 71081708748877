import styled, { css } from 'styled-components';
import { CarouselProps } from './Carousel';

type Props = Pick<CarouselProps, 'visibleItems'>;

export const StyledCarousel = styled.div<Props>(
  ({ visibleItems = 1 }) => css`
    display: flex;
    flex-flow: row nowrap;
    width: 100%;

    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;

    // Hide the scrollbar
    ::-webkit-scrollbar {
      display: none;
    }

    /* Enable Safari touch scrolling physics which is needed for scroll snap */
    -webkit-overflow-scrolling: touch;

    > * {
      scroll-snap-align: center;
      flex: 1 0 auto;
      width: 100%;

      ${visibleItems > 1
        ? css`
            width: calc(100% / ${visibleItems + 0.5});
          `
        : undefined}
    }
  `
);
