import styled, { css } from 'styled-components';
import { Stack } from '../layout/Stack';
import { Icon } from '../Icon';
import { breakpoints } from '../../variables/breakpoints';
import { Span } from '../typo/Span';
import { Heading } from '../typo/Heading';

export const StyledHelpOverlayIcon = styled(Stack).attrs({ align: 'center' })`
  font-size: max(1rem, 2.5vmin);
  max-width: 25vmin;

  @media screen and ${breakpoints.tablet} {
    max-width: 20vmin;
  }
`;

export const StyledIcon = styled(Icon)`
  font-size: 2em;
  margin-bottom: 0.25em;
`;

export const LightSubtleSpan = styled(Span).attrs({ size: 'small', subtle: true, light: false })(({ theme }) => css``);

export const StyledHeading = styled(Heading).attrs({ level: 5, span: true })(
  ({ theme }) => css`
    font-size: 1em;
  `
);
