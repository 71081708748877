import { Error } from '../graphics/Error';
import styled, { css } from 'styled-components';
import { Page } from './Page';
import { spacing } from '../../variables/spacing';

// TODO move all page components away from the framework, what are they doing here anyway?

// Styled error page
export const StyledErrorPage = styled(Page).attrs({ disableNav: true })`
  display: flex;
  flex-flow: column;
  height: 100vh;

  > main {
    min-height: unset;
    flex: 1 1 auto;
    // Margin bottom to create space for the PageGraphic
    margin-bottom: 250px;
  }
`;

// Styled error
export const StyledError = styled(Error)(
  ({ theme }) => css`
    height: 100%;
    text-align: center;

    svg {
      margin-top: ${spacing.s32};
    }
  `
);
