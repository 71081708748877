import * as React from 'react';
import {
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverDescription,
  PopoverFooter,
  PopoverHeader,
  PopoverPrice,
  PopoverStats,
  StyledPopover,
  Wrapper,
} from './Popover.styled';
import { BaseProps } from '../../interfaces/BaseProps';
import { FormattedMessage } from 'react-intl';
import { FaSlidersH } from 'react-icons/fa';
import { Icon } from '../Icon';
import { Button } from '../Button';
import { Span } from '../typo/Span';
import { EstateStatus } from '@innobrix/definitions';
import { StatusBanner, StatusBannerClip } from './StatusBanner';
import { useMeasure } from 'react-use';

type PopoverProps = BaseProps & {
  open: boolean;
  disabled?: boolean | false;
  // Events
  onClose?: React.MouseEventHandler;
  onClick?: React.MouseEventHandler;
  // Content
  header: React.ReactNode;
  description?: React.ReactNode;
  price?: React.ReactNode;
  lite?: boolean;
  stats?: React.ReactElement | React.ReactElement[];
  status?: EstateStatus;
};

const Popover = React.forwardRef<HTMLDivElement, PopoverProps>(
  (
    {
      open,
      disabled,
      onClose,
      onClick,
      header,
      description,
      price,
      lite,
      stats,
      status = EstateStatus.NotAvailable,
      ...rest
    },
    ref
  ) => {
    const [heightRef, { height }] = useMeasure<HTMLParagraphElement>();

    return (
      <Wrapper {...rest} ref={ref} open={open}>
        <StyledPopover>
          <StatusBanner status={status} />

          <PopoverHeader>
            <StatusBannerClip style={{ height }} />
            <p ref={heightRef}>{header}</p>
            <PopoverCloseButton onClick={onClose} />
          </PopoverHeader>

          <PopoverContent>
            {description && <PopoverDescription>{description}</PopoverDescription>}
            {stats != null && <PopoverStats>{stats}</PopoverStats>}
            {price && <PopoverPrice>{price}</PopoverPrice>}
          </PopoverContent>
          <PopoverFooter>
            <Button onClick={onClick} fullWidth disabled={disabled}>
              <Icon icon={FaSlidersH} />
              <Span>
                <FormattedMessage id={`estate.${lite ? 'design' : 'configure'}`} />
              </Span>
            </Button>
          </PopoverFooter>
        </StyledPopover>
        <div>
          <PopoverArrow />
        </div>
      </Wrapper>
    );
  }
);

export { Popover, PopoverProps, PopoverContent, PopoverDescription, PopoverPrice, PopoverStats };
