import { fontSize, StatLabel, weight } from '@innobrix/components';
import styled from 'styled-components';
import { StyledStatsCard } from './StatCard.styled';

export const StyledDuoStatCard = styled(StyledStatsCard)`
  b {
    font-weight: ${weight.medium};
  }
`;

export const SecondaryStatLabel = styled(StatLabel)`
  font-weight: ${weight.medium};
  font-size: ${fontSize.s36};
`;
