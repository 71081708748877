import * as React from 'react';
import { ReactElement, useState } from 'react';
import { StyledList, StyledNavbar } from './Navbar.styled';
import { NavbarItemProps } from './NavbarItem';
import { useSpring } from '@react-spring/web';
import { BaseProps } from '../../interfaces/BaseProps';

export interface NavbarProps extends BaseProps {
  children?: ReactElement<NavbarItemProps>[] | ReactElement<NavbarItemProps>;
  open?: boolean;
  onMouseEnter?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
}
export const Navbar = React.forwardRef((props: NavbarProps, ref: React.Ref<HTMLUListElement>) => {
  const { children, open, onMouseEnter, onMouseLeave, ...rest } = props;

  const [isOpenState, setIsOpenState] = useState(false);

  const isControlled = open != null;
  const isOpen = isControlled ? open : isOpenState;
  const animProps = useSpring({ width: isOpen ? '16rem' : '4rem' });

  return (
    <StyledNavbar {...rest}>
      <StyledList
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: https://github.com/pmndrs/react-spring/issues/1515
        style={animProps}
        isOpen={isOpen ?? false}
        onMouseEnter={(e: React.MouseEvent) => {
          if (isControlled) {
            // eslint-disable-next-line no-unused-expressions
            onMouseEnter?.(e);
          } else {
            setIsOpenState(true);
          }
        }}
        onMouseLeave={(e: React.MouseEvent) => {
          if (isControlled) {
            // eslint-disable-next-line no-unused-expressions
            onMouseLeave?.(e);
          } else {
            setIsOpenState(false);
          }
        }}
        ref={ref}
      >
        {children}
      </StyledList>
    </StyledNavbar>
  );
});
