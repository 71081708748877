import * as React from 'react';
import { BaseProps } from '../../interfaces/BaseProps';
import { Props } from 'react-select';
import { StyledCreatableSelect } from './CreatableSelect.styled';
import { Stack } from '../layout/Stack';
import { ErrorMessage } from './ErrorMessage';
import { SelectOptionGroup, SelectOptionProps, useSelectTheme } from './Select';
import RCCreatableSelect from 'react-select/creatable';
import { StyledSelectMenu } from './Select.styled';

export interface CreatableSelectProps extends Omit<Props, 'theme'>, BaseProps {
  isGroupSticky?: boolean;
  error?: string;
  touched?: boolean;
}
const CreatableSelect = React.forwardRef(
  (
    { components, error, touched, ...rest }: CreatableSelectProps,
    ref: React.Ref<RCCreatableSelect<SelectOptionProps>>
  ) => {
    const rsTheme = useSelectTheme();

    return (
      <Stack>
        <StyledCreatableSelect
          error={error}
          touched={touched}
          components={{ Menu: StyledSelectMenu, ...components }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - This is passed down to the react-select
          theme={rsTheme}
          {...rest}
          ref={ref}
        />
        {error && touched && <ErrorMessage error={error} />}
      </Stack>
    );
  }
);

CreatableSelect.defaultProps = {
  isGroupSticky: true,
  menuPlacement: 'auto',
  placeholder: 'Select...',
  formatGroupLabel: SelectOptionGroup,
};

export { CreatableSelect };
