import * as React from 'react';
import { FlyoutSearch, StyledFlyoutFilters } from './FlyoutFilters.styled';
import { SearchProps } from '../form/Search';

type FlyoutFiltersProps = Pick<SearchProps, 'value' | 'onChange'>;

const Search = React.memo(({ value, onChange }: FlyoutFiltersProps) => (
  <FlyoutSearch
    name={'search_dse'}
    value={value}
    onChange={onChange}
    placeholder={'Filter...'}
    onClear={(event) => onChange(event as React.ChangeEvent<HTMLInputElement>)}
  />
));

const FlyoutFilters = React.forwardRef<HTMLDivElement, FlyoutFiltersProps>(
  ({ children, value, onChange, ...rest }, ref) => {
    return (
      <StyledFlyoutFilters {...rest} ref={ref}>
        <Search value={value} onChange={onChange} />
      </StyledFlyoutFilters>
    );
  }
);

export { FlyoutFilters, FlyoutFiltersProps };
