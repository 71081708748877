import styled, { css } from 'styled-components';
import { Search } from '../form/Search';
import { spacing } from '../../variables/spacing';
import { Flyout } from './Flyout';

export const StyledFlyoutFilters = styled.div(
  ({ theme }) => css`
    display: flex;
    min-width: 0;

    > :not(:last-child) {
      margin-right: ${spacing.s16};
    }
  `
);

export const FlyoutSearch = styled(Search)(
  ({ theme }) => css`
    border: 1px solid ${theme.colors.neutral.xlight};
    background: ${theme.colors.neutral.xxlight};
    box-shadow: none;

    :hover,
    :focus-within {
      box-shadow: 0 0 0 0.25em ${theme.colors.primary.xxlight};
    }

    flex: 1;
    min-width: 0;
  `
);

export const FlyoutFilterFlyout = styled(Flyout).attrs({ placement: 'bottomRight' })(
  ({ theme }) => css`
    && {
      --size: 0px;
    }

    .tip-arrow {
      display: none;
    }

    &.tip {
      --arrow-margin: 0;

      .tip-inner {
        padding: ${spacing.s8} ${spacing.s12};
      }
    }
  `
);
