import * as React from 'react';
import { DropOverlay, HiddenInput, StyledDocumentUpload, StyledDocumentWrapper } from './DocumentUpload.styled';
import { BaseProps } from '../../../interfaces/BaseProps';
import { Button } from '../../Button';
import { Icon } from '../../Icon';
import { FaUpload } from 'react-icons/fa';
import { Span } from '../../typo/Span';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { BaseInputProps } from '../../../interfaces/InputProps';
import { Spring } from '@react-spring/web';
import { FormattedMessage } from 'react-intl';

interface DocumentUploadProps
  extends BaseProps,
    Omit<BaseInputProps<File, HTMLDivElement>, 'onChange' | 'value'>,
    Pick<DropzoneOptions, 'accept' | 'maxSize'> {
  onChange?: DropzoneOptions['onDropAccepted'];
}

const DocumentUpload = React.forwardRef<HTMLDivElement, DocumentUploadProps>(
  ({ onChange, accept, maxSize, ...rest }, ref) => {
    const options: DropzoneOptions = {
      accept,
      maxSize,
      onDropAccepted: onChange,
      preventDropOnDocument: true,
      multiple: false,
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone(options);

    return (
      <StyledDocumentUpload {...rest} ref={ref} {...getRootProps()}>
        <StyledDocumentWrapper>
          <HiddenInput {...getInputProps()} />

          <Button>
            <Icon icon={FaUpload} />
            <Span>
              <FormattedMessage id={'chat.documents.share'} />
            </Span>
          </Button>

          <Span size={'small'} subtle>
            <FormattedMessage id={'chat.documents.share.message'} />
          </Span>
        </StyledDocumentWrapper>

        <Spring to={{ opacity: isDragActive ? 1 : 0 }}>
          {(styles) => (
            <DropOverlay style={styles}>
              <FormattedMessage id={'chat.documents.share.overlay'} />
            </DropOverlay>
          )}
        </Spring>
      </StyledDocumentUpload>
    );
  }
);

export { DocumentUpload, DocumentUploadProps };
