import styled, { css } from 'styled-components';

type Props = { size: 'S' | 'M' | 'L'; center?: boolean };

export const StyledTextContainer = styled.div<Props>(
  ({ theme, size, center }) => css`
    max-width: ${size === 'S' ? '45ch' : size === 'L' ? '75ch' : '60ch'};
    text-align: ${center ? 'center' : undefined};
  `
);
