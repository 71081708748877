import * as React from 'react';
import { StyledEditorHelpItem, StyledFlexbox } from './EditorHelpItem.styled';
import { Icon, IconProps } from '../Icon';
import { KeybindLabel, KeybindLabelProps } from '../KeybindLabel';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { FileExtensionLabel, FileExtensionLabelProps } from '../FileExtensionLabel';

interface EditorHelpItemProps
  extends BaseProps,
    ChildrenProp,
    Pick<IconProps, 'icon'>,
    Pick<KeybindLabelProps, 'keybind'>,
    Pick<FileExtensionLabelProps, 'extensions'> {}
const EditorHelpItem = React.forwardRef((props: EditorHelpItemProps, ref: React.Ref<HTMLDivElement>) => {
  const { children, keybind, extensions, icon, ...rest } = props;
  return (
    <StyledEditorHelpItem {...rest} ref={ref}>
      <StyledFlexbox>
        <Icon icon={icon} />
        <div>{children}</div>
      </StyledFlexbox>
      {keybind && <KeybindLabel keybind={keybind} />}
      {extensions && <FileExtensionLabel extensions={extensions} />}
    </StyledEditorHelpItem>
  );
});

export { EditorHelpItem, EditorHelpItemProps };
