export abstract class IJsonFile {
  public abstract _version: string;
  public abstract _kind: string;

  public get version() {
    return this._version;
  }

  public get kind() {
    return this._kind;
  }
}
