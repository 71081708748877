import * as React from 'react';
import { StyledActionCard, StyledCardAction, StyledCardFooter, CardActionButton } from './ActionCard.styled';
import { CardProps } from './Card';
import { Box } from '../layout/Box';

export type ActionCardProps = Omit<CardProps, 'round'> & {
  actions?: React.ReactElement | React.ReactElement[];
};
export const ActionCard = React.forwardRef((props: ActionCardProps, ref: React.Ref<HTMLDivElement>) => {
  const { children, actions, pad, ...rest } = props;

  return (
    <StyledActionCard round={'medium'} {...rest} ref={ref}>
      <Box pad={pad}>{children}</Box>
      {actions && (
        <StyledCardFooter>
          {Array.isArray(actions) ? (
            actions.map((action, i) => <StyledCardAction key={`action-${i}`}>{action}</StyledCardAction>)
          ) : (
            <StyledCardAction>{actions}</StyledCardAction>
          )}
        </StyledCardFooter>
      )}
    </StyledActionCard>
  );
});

export { CardActionButton as ActionButton };
