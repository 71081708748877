import * as React from 'react';
import {
  StyledHiddenInput,
  StyledImage,
  StyledImageRadio,
  StyledImageRadioBox,
  StyledOptionCheckmark,
  StyledStack,
  StyledVisibilityToggle,
} from './ImageRadio.styled';
import { CheckboxProps } from '../form/Checkbox';
import { ChildrenProp } from '../../interfaces/BaseProps';
import { IToggleVisibility } from './VisibilityToggle';
import { useOptionList } from './OptionList';

interface ImageRadioProps extends CheckboxProps, ChildrenProp, Partial<IToggleVisibility> {
  src?: string;
}

const ImageRadio = React.memo(
  React.forwardRef<HTMLLabelElement, ImageRadioProps>(
    ({ src, visible, onVisibilityChange, className, children, ...rest }, ref) => {
      /*  const { editVisibility } = useOptionList();*/

      return (
        <StyledImageRadio className={className} ref={ref}>
          <StyledStack align={'center'}>
            <StyledHiddenInput {...rest} />

            {children}

            <StyledImageRadioBox>
              <StyledImage src={src} crossOrigin={'anonymous'} loading={'lazy'} />
              {/* {!editVisibility ? (
              <StyledOptionCheckmark />
            ) : (
              <StyledOptionCheckmark
                as={StyledVisibilityToggle}
                checked={visible}
                onClick={() => onVisibilityChange?.(!visible)}
              />
            )}*/}
              <StyledOptionCheckmark />
            </StyledImageRadioBox>
          </StyledStack>
        </StyledImageRadio>
      );
    }
  )
);

export { ImageRadio, ImageRadioProps };
