import styled, { css } from 'styled-components';
import { StyledIconButton } from './IconButton.styled';
import { ThemedProps } from '../interfaces/ThemedProps';
import { visuallyHidden } from '../utilities/mixins/visuallyHidden';

export const StyledIconRadioGroup = styled.div<{ value?: number }>(
  ({ theme, value = 0 }) => css`
    display: inline-flex;
    flex-flow: row;
    position: relative;

    ::after {
      content: '';
      display: block;
      position: absolute;
      background: ${theme.colors.primary.default};
      height: 2px;
      width: 3em;

      bottom: 0;
      transform: translateX(${value * 100}%);
      transition: transform ${theme.transition.slow};
    }
  `
);

export const StyledIconButtonRadio = styled.div<ThemedProps<{ active?: boolean }>>(
  ({ theme, active }) => css`
    input[type='radio'] {
      ${visuallyHidden};
    }

    ${StyledIconButton} {
      background: none;
      box-shadow: none;

      :hover {
        background: ${theme.colors.neutral.xxlight};
        box-shadow: none;
      }

      :active,
      :focus {
        box-shadow: none;
      }
    }
  `
);
