import * as React from 'react';
import { StyledOptionTabPanel } from './OptionTabPanel.styled';
import { useTabs } from './OptionTabs';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';

interface OptionTabPanelProps extends BaseProps, ChildrenProp {
  index?: number;
  height?: number;
}

const OptionTabPanel = React.forwardRef<HTMLDivElement, OptionTabPanelProps>(
  ({ height, index, children, ...rest }, ref) => {
    const { selectedIndex } = useTabs();

    return (
      <StyledOptionTabPanel {...rest} $height={height} ref={ref} selected={index === selectedIndex}>
        {children}
      </StyledOptionTabPanel>
    );
  }
);

export { OptionTabPanel, OptionTabPanelProps };
