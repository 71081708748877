import * as React from 'react';
import { StyledPriceListSubtotal } from './PriceListSubtotal.styled';
import { BaseProps } from '../../interfaces/BaseProps';
import { FlexBox } from '../layout/FlexBox';
import { Span } from '../typo/Span';
import { FormattedMessage } from 'react-intl';
import { PriceListPrice } from './PriceListFooter.styled';
import { Stack } from '../layout/Stack';
import { PriceListItem } from './PriceListItem';
import { SubtotalDivider } from './SubtotalDivider';

interface PriceListSubtotalProps extends BaseProps {
  price: number;
  inline?: boolean;
}

const PriceListSubtotal = React.forwardRef<HTMLDivElement, PriceListSubtotalProps>(
  ({ price = 0, inline = false, ...rest }, ref) => {
    return inline ? (
      <Stack {...rest} align={'end'} ref={ref}>
        <SubtotalDivider />
        <PriceListItem price={price} />
      </Stack>
    ) : (
      <StyledPriceListSubtotal {...rest} ref={ref}>
        <FlexBox justify={'space-between'} align={'end'}>
          <Span>
            <FormattedMessage id={'price-list.price-subtotal'} defaultMessage={'Subtotal'} />
          </Span>
          <PriceListPrice price={price} />
        </FlexBox>
      </StyledPriceListSubtotal>
    );
  }
);

export { PriceListSubtotal, PriceListSubtotalProps, StyledPriceListSubtotal };
