import * as React from 'react';
import { StyledOptionIcon, StyledOptionLabel, StyledSortingPicker } from './SortingPicker.styled';
import RCSelect, { components, Props } from 'react-select';
import { FaSort, FaSortAlphaDown, FaSortAlphaUp, FaSortNumericDown, FaSortNumericUp } from 'react-icons/fa';
import { FlexBox } from '../layout/FlexBox';
import { SelectOptionProps } from './Select';
import { IconType } from 'react-icons';
import { GroupedOptionsType, OptionsType } from 'react-select/src/types';
import { BaseProps } from '../../interfaces/BaseProps';

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaSort />
    </components.DropdownIndicator>
  );
};

interface IconOptionProps extends SelectOptionProps {
  icon: IconType;
}
const IconOptionLabel = (props: IconOptionProps) => {
  const { icon, label } = props;
  return (
    <FlexBox align={'center'} wrapping={false}>
      <StyledOptionIcon icon={icon} />
      <StyledOptionLabel>{label}</StyledOptionLabel>
    </FlexBox>
  );
};

const DefaultOptions = [
  { value: 'alph_asc', label: 'Alphabetical', icon: FaSortAlphaUp },
  { value: 'alph_desc', label: 'Alphabetical', icon: FaSortAlphaDown },
  { value: 'changed_asc', label: 'Last Changed', icon: FaSortNumericUp },
  { value: 'changed_desc', label: 'Last Changed', icon: FaSortNumericDown },
];

interface SortingPickerProps extends Omit<Props, 'theme' | 'options'>, BaseProps {
  options?: GroupedOptionsType<IconOptionProps> | OptionsType<IconOptionProps>;
}

const SortingPicker = React.forwardRef<RCSelect, SortingPickerProps>(
  ({ components, formatOptionLabel, options = DefaultOptions, ...rest }, ref) => (
    <StyledSortingPicker
      formatOptionLabel={IconOptionLabel}
      options={options}
      components={{ DropdownIndicator }}
      {...rest}
      ref={ref}
    />
  )
);

export { SortingPicker, SortingPickerProps };
