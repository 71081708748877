import styled, { css, DefaultTheme } from 'styled-components';
import { Link } from 'react-router-dom';

const anchorStyling = (theme: DefaultTheme) => css`
  color: ${theme.colors.secondary.xlight};

  transition: ${theme.transition.default};
  transition-property: color, outline;

  :hover,
  :active,
  :focus {
    color: ${theme.colors.secondary.default};
  }

  :focus {
    outline-color: ${theme.colors.secondary.xlight};
  }
`;

export const StyledAnchor = styled.a(({ theme }) => anchorStyling(theme));
export const StyledLink = styled(Link)(({ theme }) => anchorStyling(theme));
