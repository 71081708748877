import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { StyledTextEditor } from './RichTextEditor.styled';
import { BaseProps } from '../../interfaces/BaseProps';
import ReactQuill from 'react-quill';
import { ReactQuillProps } from 'react-quill/lib';
import 'react-quill/dist/quill.snow.css';
import { getRandomId } from '../../utilities/Crypto';
import type { Sources } from 'quill';

interface TextEditorProps extends BaseProps, Pick<ReactQuillProps, 'children'> {
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
  id?: string;
}

// TODO if we want to allow images, we need to upload them to server.

// Quill Configs
const formats = ['bold', 'italic', 'underline', 'list', 'bullet', 'indent', 'link', 'image'];
const toolbar = {
  container: [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link' /*, 'image'*/],
    ['clean'],
  ],
  handlers: {
    // image: (img: any, b: any, c: any) => {
    //   console.debug(img, b, c);
    // },
  },
};

const RichTextEditor = React.forwardRef<ReactQuill, TextEditorProps>(({ className, onChange, style, ...rest }, ref) => {
  const [uuid] = useState(() => getRandomId());

  const handleChange = useCallback(
    (value: string, _, source: Sources) => {
      // For now ignoring all "api" changes. This will break future image uploads {@see https://github.com/zenoamaro/react-quill/issues/259}.
      if (source === 'user' || source === 'silent') {
        // TODO source === "api" is used when something in quill is set by code, I don't think we do this but this could be a problem in the future
        onChange(value.replace(/<p><br><\/p>/g, ''));
      }
    },
    [onChange]
  );

  useEffect(() => {
    // Replace hardcoded placeholder
    const input = document.querySelector('input[data-link]') as HTMLInputElement;
    input.dataset.link = 'https://innobrix.nl';
    input.placeholder = 'https://innobrix.nl';
  }, []);

  return (
    <StyledTextEditor className={className} style={style}>
      <ReactQuill
        {...rest}
        onChange={handleChange}
        theme={'snow'}
        ref={ref}
        formats={formats}
        modules={{
          toolbar,
        }}
        bounds={`#${uuid}`}
      >
        <div id={uuid} />
      </ReactQuill>
    </StyledTextEditor>
  );
});

export { RichTextEditor, TextEditorProps };
