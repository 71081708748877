import * as React from 'react';
import { StyledStack } from './Stack.styled';
import { FlexBox } from './FlexBox';

type FlexBoxProps = React.ComponentProps<typeof FlexBox>;

const Stack = React.forwardRef<HTMLDivElement, FlexBoxProps>(({ children, ...rest }, ref) => (
  <StyledStack {...rest} ref={ref}>
    {children}
  </StyledStack>
));

export { Stack };
