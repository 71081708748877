import styled, { css } from 'styled-components';
import { visuallyHidden } from '../utilities/mixins/visuallyHidden';
import { StyledIconButton } from './IconButton.styled';

export const StyledIconButtonRadio = styled.div(
  ({ theme }) => css`
    input[type='radio'] {
      ${visuallyHidden};
    }

    ${StyledIconButton} {
      background: none;
      box-shadow: none;

      :hover:not(:disabled) {
        background: ${theme.colors.neutral.xxlight};
        box-shadow: none;
      }

      :active:not(:disabled),
      :focus:not(:disabled) {
        box-shadow: none;
      }
    }
  `
);
