import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Layout } from '../layout/Layout';

export const Support = () => (
  <Layout>
    <Helmet>
      <title>Support | Innobrix</title>
    </Helmet>

    <div style={{ width: '100vw', height: '200%', backgroundColor: 'goldenrod' }} />
  </Layout>
);

export default Support;
