import styled, { css } from 'styled-components';
import { PopoverStatValue } from '../tooltip/PopoverStat.styled';
import { spacing } from '../../variables/spacing';
import { Heading } from '../typo/Heading';
import { PopoverStats } from '../tooltip/Popover.styled';
import { PopoverStat } from '../tooltip/PopoverStat';
import { weight } from '../../variables/typography';
import { breakpoints } from '../../variables/breakpoints';

export const StyledPriceListHeader = styled.header(
  ({ theme }) => css`
    padding: ${spacing.s32} ${spacing.s32} ${spacing.s48};
    text-align: center;
    flex: 0 0 auto;
    // to undo the padding of Scrollable
    margin: -1rem -1rem 0;

    background-image: linear-gradient(
      100deg,
      ${theme.colors.neutral.xxlight} 0%,
      ${theme.colors.neutral.xxxlight} 100%
    );
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1rem), 0% 100%);
  `
);

export const PriceListHeaderContainer = styled.div``;

export const PriceListHeading = styled(Heading).attrs({ level: 5 })``;
export const PriceListDescription = styled.p`
  padding: 0 ${spacing.s32};

  @media screen and ${breakpoints.tablet} {
    padding: 0 ${spacing.s48};
  }
`;

export const PriceListStatsContainer = styled.div(
  () => css`
    position: relative;
    height: 3rem;
    flex: 0 0 auto;
  `
);

export const PriceListStats = styled(PopoverStats)(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    background: ${theme.colors.neutral.xxxlight};
    padding: ${theme.spacing.medium} ${theme.spacing.large};
    border-radius: ${theme.borderRadius.small};
    box-shadow: ${theme.elevation.large};
    align-items: center;
    gap: ${theme.spacing.medium};
  `
);

export const PriceListStat = styled(PopoverStat)`
  text-align: center;
  white-space: nowrap;

  ${PopoverStatValue} {
    font-size: 1.25rem;
    font-weight: ${weight.semibold};
  }
`;

export const PriceListStatUnit = styled.span`
  font-size: 1rem;
  font-weight: ${weight.regular};
`;
