import { IconButtonProps } from './IconButton';
import { StyledIconButtonRadio } from './IconButtonRadio.styled';
import * as React from 'react';

type IconButtonRadioProps = IconButtonProps<HTMLDivElement> & {
  active?: boolean;
  index: number;
  onChange: (index: number) => void;
};

export const IconButtonRadio = ({ children, active, onChange, index, ...rest }: IconButtonRadioProps) => (
  <StyledIconButtonRadio {...rest}>
    <input type={'radio'} checked={active} tabIndex={-1} onChange={() => onChange(index)} />
    {children}
  </StyledIconButtonRadio>
);
