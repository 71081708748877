import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';
import { BoxProps } from './Box';

type ScrollableProps = Pick<BoxProps, 'pad'>;

export const StyledScrollable = styled.div<ScrollableProps>(
  ({ theme, pad }) => css`
    padding: ${pad != null ? theme.spacing[pad] : spacing.s16};
    overflow-x: auto;
    overflow-y: auto;
    // overlay on webkit browsers
    overflow-x: overlay;
    overflow-y: overlay;

    --scrollbar-width: 16px;

    ::-webkit-scrollbar-thumb {
      // Hack to fake margin around the scrollbar
      background-clip: padding-box;
      border: 6px solid rgba(0, 0, 0, 0);
    }
  `
);
