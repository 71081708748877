import * as React from 'react';
import { StyledListItem } from './ListItem.styled';
import { BaseProps, ChildrenProp } from '../../interfaces/BaseProps';
import { MouseEventProps } from '../../interfaces/EventProps';

interface ListItemProps
  extends BaseProps,
    ChildrenProp,
    MouseEventProps<HTMLDivElement>,
    Omit<React.ComponentProps<typeof StyledListItem>, 'forwardedAs'> {
  affix?: React.ReactElement | React.ReactFragment;
}

const ListItem = React.forwardRef<HTMLDivElement, ListItemProps>(({ affix, children, ...rest }, ref) => (
  <StyledListItem {...rest} $hasAffix={affix != null} ref={ref}>
    {children}
    {affix}
  </StyledListItem>
));

export { ListItem, ListItemProps };
