import { environment } from '../../../../../../environments/environment';

export const dashboardEndpoint = `${environment.apiUrl}/telemetry/dashboard`;

export type Stats = {
  total: number;
  thisMonth?: number;
  lastMonth?: number;
};

export type HomeStats = {
  userGrowth: {
    activity: Record<string, number>;
    signups: Record<string, number>;
    totalUsers: number;
  };
  ibx: Stats & {
    active: [];
  };
  configurations: Required<Stats>;
  companies: { lite: number; pro: number; enterprise: number };
  projects: Stats;
  estates: Required<Stats>;
  configurables: Stats;
  decorations: { decorations: number; furniture: number };
  chat: Required<Stats>;
  documents: Required<Stats>;
};
