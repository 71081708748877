import styled, { css, useTheme } from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import { styledSelectMixin } from './Select.styled';

export const StyledCreatableSelect = styled(CreatableSelect).attrs({ classNamePrefix: 'rs' })(() => {
  const theme = useTheme();
  return css`
    height: 100%;

    ${(props) => styledSelectMixin({ ...props, theme })};
  `;
});
