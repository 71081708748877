import * as React from 'react';
import { Auth0Provider } from './Auth0Provider';
import { PropsWithChildren } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { SWRConfig, SWRConfiguration } from 'swr';
import { LayoutStyles } from '../layout/Layout.styled';
import { fetcher } from '../utils/swr';
import { StyleProvider } from './StyleProvider';
import Router from '../router';

const swrOptions: SWRConfiguration = {
  fetcher: fetcher,
  // Refresh every minute
  refreshInterval: 60000,
  suspense: true,
};

export const Providers = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <StyleProvider>
      <LayoutStyles />
      <Auth0Provider>
        <HelmetProvider>
          <SWRConfig value={swrOptions}>{children}</SWRConfig>
        </HelmetProvider>
      </Auth0Provider>
    </StyleProvider>
  );
};
