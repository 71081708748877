import * as React from 'react';
import { CheckboxProps } from './Checkbox';
import { RestyledCheckbox, StyledTriMark } from './TriStateCheckbox.styled';

type TriState = 'some' | 'all' | 'none';
type TriStateCheckboxProps = Omit<CheckboxProps, 'name' | 'checked' | 'value' | 'onChange'> & {
  name?: string;
  state: TriState;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, state: TriState) => void;
};

const TriStateCheckbox = React.forwardRef((props: TriStateCheckboxProps, ref: React.Ref<HTMLInputElement>) => {
  const { state, disabled, name = '', onChange, ...rest } = props;

  return (
    <RestyledCheckbox
      {...rest}
      disabled={disabled}
      checked={state === 'all'}
      value={''}
      name={name}
      state={state}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, e.target.checked ? 'all' : 'none')}
      checkmark={() => <StyledTriMark state={state} />}
      ref={ref}
    />
  );
});

TriStateCheckbox.defaultProps = {
  state: 'none',
};

export { TriState, TriStateCheckboxProps, TriStateCheckbox };
