import styled, { css } from 'styled-components';
import { HorizontalScrollBox } from '../layout/HorizontalScrollBox';
import { breakpoints } from '../../variables/breakpoints';
import { CarouselArrows } from '../carousel/CarouselArrows';

export const StyledOptionList = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;

    > :not(:last-child) {
      margin-bottom: ${theme.spacing.medium};
    }
  `
);

export const OptionCarouselArrows = styled(CarouselArrows)(
  () => css`
    background: rgba(0, 0, 0, 0.125);
    color: white;
    height: 100%;

    // Hide on mobile + tablet
    visibility: hidden;

    @media screen and ${breakpoints.laptop} {
      visibility: visible;
    }
  `
);

export const OptionScrollBox = styled(HorizontalScrollBox)`
  scroll-snap-type: x mandatory;

  > * {
    scroll-snap-align: center;
  }
`;
