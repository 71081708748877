import styled, { css } from 'styled-components';
import { spacing } from '../../variables/spacing';

export const StyledCarouselPager = styled.div(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    justify-content: center;
    padding: ${spacing.s8};

    > :not(:last-child) {
      margin-right: ${spacing.s8};
    }
  `
);

export const PagerBubble = styled.div<{ active?: boolean }>(
  ({ theme, active }) => css`
    height: 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;

    transition: ${theme.transition.slow};
    transition-property: background-color, width, opacity;

    ${active
      ? css`
          width: 1.5rem;
          background: ${theme.colors.primary.default};
        `
      : css`
          width: 0.5rem;
          background: ${theme.colors.neutral.light};
          opacity: 0.8;
        `};
  `
);
