import styled, { css } from 'styled-components';
import { Button } from './Button';

type HideProp = { hide?: boolean };

export const StyledSpinnerButton = styled(Button)(
  ({ theme }) => css`
    position: relative;
  `
);

export const SpinnerContent = styled.div<HideProp>(
  ({ theme, hide }) => css`
    visibility: ${hide ? 'hidden' : undefined};
  `
);

export const IconContainer = styled.div<HideProp>(
  ({ theme, hide }) =>
    css`
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      border-radius: inherit;
      color: currentColor;
      display: flex;
      place-content: center;
      align-items: center;
      margin-right: 0;

      transition: opacity ${theme.transition.slow};
      opacity: ${hide ? 0 : 1};
    `
);
