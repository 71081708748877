import styled, { css } from 'styled-components';
import { NotificationBadge } from './NotificationBadge';
import { spacing } from '../../variables/spacing';

export const AbsoluteBadge = styled(NotificationBadge)<{ $padding?: 'none' | keyof typeof spacing }>(
  ({ $padding = 's4' }) => css`
    pointer-events: none;
    position: absolute;
    top: ${$padding === 'none' ? 0 : spacing[$padding]};
    left: ${$padding === 'none' ? 0 : spacing[$padding]};
    // Below NavBar by default
    z-index: 99;
  `
);

export const StyledBadgeContainer = styled.div`
  position: relative;
`;
